import { inject as service } from '@ember/service';
import ObjectProxy from '@ember/object/proxy';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import Route from '@ember/routing/route';
import { instrumentRoutePerformance } from '@sentry/ember';

const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

function asPromiseObject(promise) {
  return ObjectPromiseProxy.create({ promise });
}

class QuickOrderGuidesRoute extends Route {
  @service store;

  async model() {
    return asPromiseObject(
      new Promise(async (done) => {
        let retries = 10;
        const worker = async () => {
          if (retries-- > 0) {
            try {
              const quickOrderGuideGroups = await this.store.findAll(
                'quick-order-guide-group',
                {
                  include: 'quick-order-guides',
                  reload: true,
                }
              );
              const quickOrderGuideGroupsSorted = quickOrderGuideGroups
                .map((group) => group)
                .sort((a, b) => a.id - b.id);
              done(quickOrderGuideGroupsSorted);
            } catch (error) {
              setTimeout(worker, 1000);
            }
          }
        };
        worker();
      })
    );
  }
}
export default instrumentRoutePerformance(QuickOrderGuidesRoute);
