import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{did-insert this.drupalNotificationRequest}}>\n</div>", {"contents":"<div {{did-insert this.drupalNotificationRequest}}>\n</div>","moduleName":"shoelace/components/apex/notifications.hbs","parseOptions":{"srcName":"shoelace/components/apex/notifications.hbs"}});
import Component from '@glimmer/component';
import type NotificationsService from 'shoelace/services/notifications';
import { inject as service } from '@ember/service';
import fetch from 'fetch';
import { action } from '@ember/object';

function hideNotificationCheck(notificationId: string) {
  let flag = false;
  const toHideThisNotification = document.cookie
    .split("; ")
    .find((row) => row.startsWith("dismissible_message_bar="))
    ?.split("=")[1];
  if (toHideThisNotification) {
    const cookieArray = toHideThisNotification.split(".");
    let index = 0;
    while (index < cookieArray.length) {
      if (notificationId == cookieArray[index]) {
        flag = true;
        return flag
      }
      index++;
    }
  }
  return flag;
}

function setNotificationCookie(button: HTMLElement | null, id: string) {
  let div_id = button.getAttribute("id");
  let id_no = div_id.substring(6);
  document.getElementById("notificationdiv" + id_no).style.display = "none";
  const addToCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("dismissible_message_bar="))
    ?.split("=")[1];
  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  if (addToCookie == null) {

    document.cookie = "dismissible_message_bar=" + id + "; expires=" + tomorrow.toUTCString() + "; path=/";
  } else {
    document.cookie = "dismissible_message_bar=" + addToCookie + "." + id + "; expires=" + tomorrow.toUTCString() + "; path=/";
  }
}

export default class ApexNotifications extends Component<never> {
  @service
  declare notifications: NotificationsService;

  @action async drupalNotificationRequest() {
    let notificationsCount = 0;

    if (!sessionStorage.getItem("dismissible_message")) {
      const response = await fetch('/api/drupal_api_call/get_drupal_notification', {
        method: 'GET',
      })

      const notificationsResponse = await response.json();
      let notifications: never[] = [];

      if (notificationsResponse.length > 0) {
        let notificationsArray: { content: string; endTime: never; }[] = [];
        notifications = notificationsResponse.map(a => ({ content: a.content, endTime: a.endTime }))
        notifications.forEach(notificationBlock => {
          let notification = null;
          notification = notificationBlock["content"]
          let notificationId = notification.split('dmb-notification-id=\"')[1].split('\"')[0];
          let endTime = new Date(notificationBlock["endTime"]);

          if (new Date().toISOString() > endTime.toISOString()) {
            return
          }

          if (hideNotificationCheck(notificationId)) {
            return
          }

          notification = notification.replace('<a href=\"\" class=\"close-dmb-notification\">Close notification</a>', '<div id="demo" href=\"\" class=\"close-dmb-notification\">Close notification</div>');
          const elemDiv = window.document.createElement('div');
          elemDiv.setAttribute('class', 'myclass');
          elemDiv.setAttribute('id', 'notificationdiv' + notificationsCount);
          elemDiv.innerHTML = notification;

          document.getElementById("header-notification")?.prepend(elemDiv);
          let button = document.createElement("Button");
          button.setAttribute("id", "button" + notificationsCount);
          button.setAttribute("class", "close-dmb-notification w-5 h-5");
          button.innerHTML = '<svg style="color: inherit" viewBox="11 4.3 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 14L34 34" stroke="currentColor" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" fill="red"></path><path d="M14 34L34 14" stroke="currentColor" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" fill="red"></path></svg>';

          const closeNotificationElement = document.getElementById('demo');
          closeNotificationElement.replaceWith(button);
          button.addEventListener("click", function () {
            setNotificationCookie(button, notificationId);
          });

          let someElement = document.getElementById('notificationdiv' + notificationsCount);
          let someElementToString;

          if (someElement && someElement.outerHTML) {
            someElementToString = someElement.outerHTML;
            notificationsArray.push({ content: someElementToString, endTime: notificationBlock["endTime"] });
          }
          notificationsCount++;
        })

        if (notificationsArray.length > 0) {
          sessionStorage.setItem("dismissible_message", JSON.stringify(notificationsArray));
        }

      } else {
        notifications = [];
      }

    } else {
      let dismissible_messages = JSON.parse(sessionStorage.getItem('dismissible_message'))
      let j = 0;

      while (j < dismissible_messages.length) {
        let elemDiv1 = dismissible_messages[j]["content"];

        if (elemDiv1) {
          let endTime = dismissible_messages[j]["endTime"];
          endTime = new Date(endTime);

          if (new Date().toISOString() > endTime.toISOString()) {
            j++;
            continue;
          }

          elemDiv1 = elemDiv1.replace('<div class="myclass" id="notificationdiv' + j + '">', '');
          elemDiv1 = elemDiv1.substring(0, (elemDiv1.length - 7));
          let notificationId = elemDiv1.split('dmb-notification-id=\"')[1].split('\"')[0];

          if (hideNotificationCheck(notificationId)) {
            j++;
            continue;
          }

          const elemDiv = window.document.createElement('div');
          elemDiv.setAttribute('class', 'myclass');
          elemDiv.setAttribute('id', 'notificationdiv' + j);
          elemDiv.innerHTML = elemDiv1;
          document.getElementById("header-notification")?.prepend(elemDiv);
          let button = document.getElementById("button" + j);

          button.addEventListener("click", function () {
            let div_id = this.getAttribute("id");
            let id_no = div_id.substring(6);
            const elemDiv2 = dismissible_messages[id_no]["content"];
            let notificationId1 = elemDiv2.split('dmb-notification-id=\"')[1].split('\"')[0];
            setNotificationCookie(this, notificationId1);
          });
        }
        j++;
      }
    }
  }
}
