import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { EXCEPTION_1052, EXCEPTION_1053 } from 'shoelace/exceptions/mod';
import AccountVerificationModel from 'shoelace/models/account-verification';
import CustomerRegistrationModel from 'shoelace/models/customer-registration';
//import type RollbarService from 'shoelace/services/rollbar';
import type RouterService from '@ember/routing/router';
import type StoreService from '@ember-data/store';

export default class RegisterIndexController extends Controller {
//@service declare rollbar: RollbarService;
@service declare store: StoreService;
@service declare router: RouterService;

  @action async onSetHasAccount(
    accountVerification: AccountVerificationModel,
    isChecked: boolean,
  ): Promise<void> {
    if (isChecked) {
      accountVerification.shouldValidateRequestedAccounts = true;
    } else {
      accountVerification.shouldValidateRequestedAccounts = false;
    }
  }

  @action async onSetRequestedAccess(
    accountVerification: AccountVerificationModel,
    permission: string,
    isChecked: boolean,
  ): Promise<void> {
    const permissions = accountVerification.requestedAccess?.split(',') || [];

    if (isChecked) {
      accountVerification.requestedAccess = permissions
        .concat(permission)
        .filter(Boolean)
        .join(',');
    } else {
      accountVerification.requestedAccess = permissions
        .filter(p => p != permission)
        .filter(Boolean)
        .join(',');
    }
  }

  @action async onNewsletterOptIn(
    customerRegistration: CustomerRegistrationModel,
  ): Promise<void> {
    try {
      const formData = new FormData();
      formData.append("Email", customerRegistration.email ? customerRegistration.email : "");

      fetch('https://forms.zohopublic.com/summitelectricsupply/form/SummitcomNewsletterFooterSummitcom/formperma/UqCv0hLIFFpcEqzWQczfYNsPQH6V5CJnyqlZfzGLlU4/htmlRecords/submit', {
        method: 'POST',
        body: formData,
        mode: 'no-cors'
    })
    } catch (error) {

      throw error;
    }
  }

  @action async onRegister(
    customerRegistration: CustomerRegistrationModel,
    accountVerification: AccountVerificationModel,
  ): Promise<void> {
    try {
      await customerRegistration.validate();

      let isValid = customerRegistration.validations.isValid;

      if (accountVerification.shouldValidateRequestedAccounts) {
        await accountVerification.validate();

        const isAccountVerificationValid = [
          'jobTitle',
          'companyName',
          'phoneNumber',
          'requestedAccounts',
        ].every(field => accountVerification.validations.attrs[field].isValid);

        isValid = isValid && isAccountVerificationValid;
      }

      if (isValid) {
        await customerRegistration.save();
        if(customerRegistration)
        {
          const email = customerRegistration.email.split("@");
          let rbacRole;
          if(email[1] == "summit.com")
          {
            rbacRole = await this.store.query('rbac-role', {
              filter: {
                'name': "associate",
              },
            });
          } else{
            rbacRole = await this.store.query('rbac-role', {
              filter: {
                'name': "customer",
              },
            });
          }

        if(rbacRole){
          const rbacCustomerRole = this.store
          .createRecord('rbac-customer-role', {
            customer_id: customerRegistration.id,
            rbac_role_id: rbacRole.firstObject.id,
          });

        await rbacCustomerRole.save();
        }
      }
        
        if (accountVerification.shouldValidateRequestedAccounts) {
          this.store.pushPayload({
            data: {
              id: customerRegistration.id,
              type: 'customers',
              attributes: {},
            },
          });

          const customer = this.store.peekRecord(
            'customer',
            customerRegistration.id,
          );
          if (customer) {
            customer.id = customerRegistration.id;
          }
          let permission_acess: string;
          if(accountVerification.requestedAccess){
            permission_acess = "customer.ordering," + accountVerification.requestedAccess
          } else{
            permission_acess = "customer.ordering"
          }
          String(accountVerification.requestedAccounts)
            .split(',')
            .map(async accountNumber => {
              const verification = this.store.createRecord(
                'account-verification',
                {
                  firstName: customerRegistration.firstName,
                  lastName: customerRegistration.lastName,
                  hasAuthority: false,
                  customer: customer,
                  jobTitle: accountVerification.jobTitle,
                  phoneNumber: accountVerification.phoneNumber,
                  requestedAccess: permission_acess,
                  accountNumber: accountNumber,
                  companyName: accountVerification.companyName,
                },
              );

              await verification.save();
            });
        }

        this.router.transitionTo('register.confirmation');
      }
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1053, error);

      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'register/index': RegisterIndexController;
  }
}
