import Helper from '@ember/component/helper';
import { A } from '@ember/array';

export default Helper.extend({
  compute(params) {
    const [property, collection] = params;

    return A(collection).getEach(property).reduce(add, 0);
  },
});

function add(a, b) {
  return a + b;
}
