import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

const refreshModel = { refreshModel: true };

export interface RouteParams {
  token: string | number;
}

class StoreCustomersConfirmationIndexRoute extends Route {
  @service declare router: RouterService;
  @service declare store: StoreService;

  queryParams = {
    token: refreshModel,
  };

  async model({ token: confirmationToken }: RouteParams) {
    const customerConfirmation = this.store.createRecord(
      'customer-confirmation',
      {
        confirmationToken,
      }
    );

    if (customerConfirmation.validations.isValid) {
      return customerConfirmation.save();
    } else {
      throw customerConfirmation.validations.messages.join(' ');
    }
  }

 async afterModel() {
    return this.router.transitionTo('store.customers.confirmation.fulfilled');
  }
}
export default instrumentRoutePerformance(StoreCustomersConfirmationIndexRoute);
