import type StoreService from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
import type TranslationModel from 'shoelace/models/translation';
import type { BootOptions } from 'shoelace/services/coordinator';

const LOCALE = 'en-us';

export default class TranslationsService extends Service {
@service declare store: StoreService;

@service declare intl: IntlService;

  @tracked
  translations: TranslationModel[] = [];

  async findAndMergeTranslations(): Promise<void> {
    const json: Record<string, string> = {};

    const translations = await this.store.findAll('translation', {
      reload: true,
    });

    this.translations = translations.toArray();

    this.translations.forEach(translation => {
      json[`${translation.generated_key}`] = translation.value ?? '';
    });

    this.intl.addTranslations(LOCALE, json);
  }

  async onCoordinatorSetup(_options?: BootOptions): Promise<void> {
    await this.findAndMergeTranslations();
  }

  async onCoordinatorTeardown(): Promise<void> {
    this.translations = [];
  }
}

declare module '@ember/service' {
  interface Registry {
    translations: TranslationsService;
  }
}
