import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default class IndexController extends Controller {
  @service cart;

  @action async addToCart(searchResult) {
    const product = await searchResult;
    const cartLineItem = await this.cart.addItem(product, product.minQty);
    searchResult.addedToCart = true;
    searchResult.lineItem = cartLineItem;
  }
}
