import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreAccountHistoryInvoiceRoute extends Route {
  @service session;
  @service store;
  @service router;

  async beforeModel(transition) {
     if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }
  }

   model({ id }) {
    const customer = this.session.get('customer');
    const accountHistoryInvoice = this.store.findRecord(
      'account-history-invoice',
      id,
      {
        include: [
          'account-history-invoice-bill-address',
          'account-history-invoice-fund-address',
          'account-history-invoice-ship-address',
          'account-history-invoice-sold-address',
          'account-history-invoice-comments',
          'account-history-invoice-line-items',
          'account-history-invoice-line-items.account-history-invoice-line-item-comments',
          'account-history-invoice-line-items.account-history-invoice-line-item-deliveries',
          'account-history-invoice-deliveries',
          'account-history-invoice-quotes',
        ].join(','),
      },
    );
    return {
      customer,
      accountHistoryInvoice,
    };
  }
}
export default instrumentRoutePerformance(
  StoreAccountHistoryInvoiceRoute
);