import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and this.isDownloadable.isLoaded this.isDownloadable.value)}}\n  <span class=\"\">\n    <a href=\"{{this.url}}\" class=\"\" rel=\"noopener\" target=\"_blank\">\n      {{this.filename}}\n    </a>\n  </span>\n{{else}}\n  <span class=\"\">\n    {{this.filename}}\n  </span>\n{{/if}}\n", {"contents":"{{#if (and this.isDownloadable.isLoaded this.isDownloadable.value)}}\n  <span class=\"\">\n    <a href=\"{{this.url}}\" class=\"\" rel=\"noopener\" target=\"_blank\">\n      {{this.filename}}\n    </a>\n  </span>\n{{else}}\n  <span class=\"\">\n    {{this.filename}}\n  </span>\n{{/if}}\n","moduleName":"shoelace/components/apex/downloadable-file.hbs","parseOptions":{"srcName":"shoelace/components/apex/downloadable-file.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { load } from 'shoelace/helpers/load';

export interface ApexDownloadableFileArgs {
  filename: string;
  urlBase?: string;
  filetype?: string;
  url?: string;
}

export default class ApexDownloadableFileComponent extends Component<ApexDownloadableFileArgs> {
  @tracked urlBase = this.args.urlBase ?? '/api/documents/';
  @tracked filename = this.args.filename ?? '';
  @tracked filetype = this.args.filetype ?? '.pdf';

  get url() {
    return this.args.url ?? `${this.urlBase}${this.filename}${this.filetype}`;
  }

  get isDownloadable() {
    return load(this.getDownloadableStatus());
  }

  async getDownloadableStatus() {
    try {
      const response = await fetch(this.url, { method: 'HEAD' });

      return response?.status === 200;
    } catch (error) {
      return false;
    }
  }
}
