import Route from '@ember/routing/route';
import TranslationModel from 'shoelace/models/translation';
import { refreshModel } from 'shoelace/utils/query-params';
import type ArrayProxy from '@ember/array/proxy';
import type StoreService from '@ember-data/store';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';
import type RouterService from '@ember/routing/router';
import SessionService from 'shoelace/services/-session';

export interface RouteModel {
  translations: ArrayProxy<TranslationModel>;
}

export interface RouteParams {
  page?: string;
  size?: string;
  sort?: string;
}

class AdminDynamicTranslationIndexRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare router: RouterService; 

  queryParams = {
    page: refreshModel,
    size: refreshModel,
    sort: refreshModel,
  };

  async model({ page: number, size, sort }: RouteParams): Promise<RouteModel> {

    const customer = await this.session.get('customer');

    if (customer) {
      if (customer?.hasRbacAdminCredit || customer?.hasRbacCustomerAdmin) {
        this.router.transitionTo('store.customer', customer.id);
      } 
    }

    const translations = await this.store.query('translation', {
      filter: {},
      page: {
        number,
        size,
      },
      sort,
      include: ['account', 'account-project'].join(','),
    });

    return {
      translations,
    };
  }
}

export default instrumentRoutePerformance(AdminDynamicTranslationIndexRoute);
