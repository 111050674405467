import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';
import { refreshModel } from '../../utils/query-params';
import SessionService from 'shoelace/services/-session';

export interface RouteParams {
  account_id: string | number;
  page: string | number;
  size: string | number;
  sort: string | number;
}

class AdminManageAccountRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;

  queryParams = {
    page: refreshModel,
    size: refreshModel,
    sort: refreshModel,
  };

  async model({ account_id: id, page: number, size, sort }: RouteParams) {
    const account = await this.store.findRecord('account', id);
    const customer = await this.session.get('customer');

    const customers = await this.store.query('customer', {
      filter: {
        account_customer: id,
      },
      page: {
        number,
        size,
      },
      sort,
    });

    return {
      account,
      customers,
      customer
    };
  }
}

export default instrumentRoutePerformance(AdminManageAccountRoute);
