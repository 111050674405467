import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

const MAX_RETRIES = 60;
class OrderConfirmationProcessingRoute extends Route {
  @service router;

  async model({ id }) {
    const model = this.modelFor('store.review-quote-order');
    const procurement = await model.procurement;
    let retries = 0;

    const worker = async () => {
      try {
        await procurement.reload();

        if (
          procurement.get('procurementState') === 'error' ||
          retries++ > MAX_RETRIES
        ) {
          this.router.transitionTo('store.order-confirmation-error');
        } else if (procurement.get('procurementState') === 'success') {
          this.router.transitionTo('store.order-confirmation-success');
        } else {
          setTimeout(worker, 1000);
        }
      } catch (e) {
        this.router.transitionTo('store.order-confirmation-error');
      }
    };

    setTimeout(worker, 1000);

    return procurement;
  }
}

export default instrumentRoutePerformance(OrderConfirmationProcessingRoute);
