import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ArrayProxy from '@ember/array/proxy';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';

const ArrayPromiseProxy = ArrayProxy.extend(PromiseProxyMixin);
const isInvoice = /^9\d{9}$/;
const isQuote = /^2\d{9}$/;

function asPromiseObject(promise) {
  return ArrayPromiseProxy.create({ promise });
}

export default class SuggestedSearchComponent extends Component {
  @service store;
  @service session;

  @tracked tagName = '';
  @tracked q = '';
  @tracked forProduct = this.args.forProduct;
  @tracked _timeout = 0;
  @tracked _lastResults = [];

  @computed('_timeout', 'q')
  get model() {
    return asPromiseObject(
      new Promise(async done => {
        const worker = async () => {
          const q = this.q;
          try {
            const h = await fetch(
              `/api/v2/information?filter[q]=${encodeURIComponent(q)}`,
            );
            const r = await h.json();

            this._lastResults = r.hits.hits;
            this._timeout = 0;

            done(r.hits.hits);
          } catch (e) {
            e;
            done([]);
          }
        };
        if (this.q) {
          if (this._timeout) {
            clearTimeout(this._timeout);
            this._timeout = setTimeout(worker.bind(this), 150);
          } else {
            this._timeout = setTimeout(worker.bind(this), 150);
          }
        } else {
          done([]);
        }
      }),
    );
  }

  @computed('invoices')
  get isInvoice() {
    const invoices = this.invoices;
    return invoices.length > 0;
  }

  @computed('q')
  get invoices() {
    const q = this.q.trim().split(' ');
    return q.filter(q => isInvoice.test(q));
  }

  @computed('quotes')
  get isQuote() {
    const quotes = this.quotes;
    return quotes.length > 0;
  }

  @computed('q')
  get quotes() {
    const q = this.q.trim().split(' ');
    return q.filter(q => isQuote.test(q));
  }

  @computed('session.customer')
  get customer() {
    return this.session.get('customer');
  }
}
