import {
  validator,
  buildValidations,
} from '@summit-electric-supply/ember-cp-validations';
import Model, { attr } from '@ember-data/model';

const Validations = buildValidations({
  currentPassword: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      message: 'Password must not be blank.',
    }),
  ],
  password: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      message: 'Password must not be blank.',
    }),
  ],
  passwordConfirmation: [
    validator('ds-error'),
    validator('presence', true),
    validator('confirmation', {
      on: 'password',
      message: 'Passwords do not match.',
    }),
  ],
});

export default class CustomerChangePasswordModel extends Model.extend(
  Validations,
) {
  @attr('string')
  declare currentPassword?: string;

  @attr('string')
  declare password?: string;

  @attr('string')
  declare passwordConfirmation?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'customer-change-password': CustomerChangePasswordModel;
  }
}
