import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type SessionService from 'shoelace/services/-session';
import type KartService from 'shoelace/services/kart';
import type ProcurementService from 'shoelace/services/procurement';
import type RouterService from '@ember/routing/router';
import { instrumentRoutePerformance } from '@sentry/ember';
import type NoticeService from 'shoelace/services/notice';

export interface SecureCheckoutBillingRouteModel {
  id?: number | string;
}

export interface SecureCheckoutBillingRouteParams {
  id?: number | string;
}

class SecureCheckoutBillingRoute extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService;
  @service declare kart: KartService;
  @service declare procurement: ProcurementService;
  @service declare notice: NoticeService;

  async beforeModel() {
    try {
      if (!this.kart.isCartValid && !this.kart.itemsLoading) {
        return this.router.transitionTo('secure-checkout.index');
      }

      if (!this.procurement.procurement) {
        await this.procurement.createRecord();
      }

      if (!this.session.customer && !this.procurement.procurement?.isGuest) {
        return this.router.transitionTo('login', {
          queryParams: { guest: true },
        });
      }
    } catch (error) {
      throw error;
    }
  }

  async model({
    id,
  }: SecureCheckoutBillingRouteParams): Promise<SecureCheckoutBillingRouteModel> {
    return {
      id,
    };
  }
}

export default instrumentRoutePerformance(SecureCheckoutBillingRoute);
