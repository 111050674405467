import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @value}}\n  <Input\n    @focusOut={{this.validate}}\n    class=\"{{if this.showMessage 'is-invalid'}}\"\n    ...attributes\n  />\n{{else}}\n  <Input\n    @value={{mut (get @model @property)}}\n    @focusOut={{this.validate}}\n    class=\"{{if this.showMessage 'is-invalid'}}\"\n    ...attributes\n  />\n{{/if}}\n{{#if this.showMessage}}\n  <div class=\"invalid-feedback\">{{message}}</div>\n{{/if}}", {"contents":"{{#if @value}}\n  <Input\n    @focusOut={{this.validate}}\n    class=\"{{if this.showMessage 'is-invalid'}}\"\n    ...attributes\n  />\n{{else}}\n  <Input\n    @value={{mut (get @model @property)}}\n    @focusOut={{this.validate}}\n    class=\"{{if this.showMessage 'is-invalid'}}\"\n    ...attributes\n  />\n{{/if}}\n{{#if this.showMessage}}\n  <div class=\"invalid-feedback\">{{message}}</div>\n{{/if}}","moduleName":"shoelace/components/apex/inputs/validated-input.hbs","parseOptions":{"srcName":"shoelace/components/apex/inputs/validated-input.hbs"}});
import { action, computed } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ValidatedInputComponent extends Component {
  @tracked visited = false;
  @tracked model = this.args.model;
  @tracked property = this.args.property;

  get showMessage() {
    return this.message && (this.args.showAllMessages || this.visited);
  }

  get message() {
    return this.model?.validations?.attrs[this.property]?.message;
  }

  @action validate() {
    this.visited = true;
  }
}
