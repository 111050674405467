import {
  validator,
  buildValidations,
} from '@summit-electric-supply/ember-cp-validations';
import Model, { attr } from '@ember-data/model';

const Validations = buildValidations({
  email: {
    validators: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        message: 'Email must not be blank.',
      }),
      validator('format', {
        type: 'email',
        message: 'Invalid email format.',
      }),
    ],
  },

  password: {
    description: 'Password',
    validators: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        message: 'Password must not be blank.',
      }),
      validator('length', {
        min: 8,
        message(type) {
          if (type === 'tooShort') {
            return 'Password must be at least {min} characters.';
          }
        },
      }),
    ],
  },
});

export default class SignInModel extends Model.extend(Validations) {
  @attr('string')
  declare email?: string;

  @attr('string')
  declare password?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sign-in': SignInModel;
  }
}
