import Route from '@ember/routing/route';
import type CustomerModel from 'shoelace/models/customer';
import CustomerChangeEmailModel from 'shoelace/models/customer-change-email';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type RouterService from '@ember/routing/router';
import type SessionService from 'shoelace/services/session';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface RouteModel {
  customer: CustomerModel | undefined;
  customerChangeEmail: CustomerChangeEmailModel | undefined;
}

class StoreCustomerEmailRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare router: RouterService;

  async model(): Promise<RouteModel> {
    const customer: CustomerModel | undefined = this.session.customer;
    const customerId = customer?.get('id');
    let customerChangeEmail;

    if (customerId) {
      customerChangeEmail = await this.store.findRecord(
        'customer-change-email',
        customerId
      );
    } else {
      this.router.transitionTo('login');
    }

    return {
      customer,
      customerChangeEmail,
    };
  }
}
export default instrumentRoutePerformance(StoreCustomerEmailRoute);
