import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class PunchoutsPo2goLandingRoute extends Route {
  @service store;
  @service session;
  @service router;

  queryParams = {
    pos: {
      refreshModel: true,
    },
  };


  async model({ pos: pos }) {
    return pos;
  }
}

export default instrumentRoutePerformance(
  PunchoutsPo2goLandingRoute
);
