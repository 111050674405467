import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type ServiceCenterService from 'shoelace/services/service-center';
import type SessionService from 'shoelace/services/session';
import StoreService from '@ember-data/store';
import type RouterService from '@ember/routing/router';
import ProcurementModel from 'shoelace/models/procurement';
import MetricsService from 'shoelace/services/metrics';

export default class StoreReviewQuoteOrderController extends Controller {
  queryParams = ["quoteNoId", "procurementPo"]

  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare serviceCenter: ServiceCenterService;
  @service declare metrics: MetricsService;

  @action async confirm(quoteNo: number, procurement: ProcurementModel) {
    const logPurchase = async (quoteNo: number) => {
      try {
        const quote = await this.store.findRecord(
          'account-history-quote',
          quoteNo,
          {
            include: ['account-history-quote-line-items'].join(','),
          }
        );

        const quoteLineItems = await Promise.all(
          quote.accountHistoryQuoteLineItems.map((li) => li)
        );

        if (!this.metrics?.enabled) return;
        if (quoteLineItems) {
          let items = quoteLineItems.map((lineItem: any, index) => {
            return this.metrics.productAsEcommerceItem(
              lineItem?.product ?? '',
              lineItem?.quantity ?? '',
              lineItem?.total ?? '',
              index,
              'begin_checkout'
            );
          });

          this.metrics.events.ecommerce.begin_checkout({
            currency: 'USD',
            value: quote?.total ?? 0,
            items: items,
          });

          items = quoteLineItems.map((lineItem: any, index) => {
            return this.metrics.productAsEcommerceItem(
              lineItem?.product ?? '',
              lineItem?.quantity ?? '',
              lineItem?.total ?? '',
              index,
              'add_shipping_info'
            );
          });

          this.metrics.events.ecommerce.add_shipping_info({
            currency: 'USD',
            value: quote?.total ?? 0,
            shipping_tier: 'Ground',
            items: items,
          });

          items = quoteLineItems.map((lineItem: any, index) => {
            return this.metrics.productAsEcommerceItem(
              lineItem?.product ?? '',
              lineItem?.quantity ?? '',
              lineItem?.total ?? '',
              index,
              'add_payment_info'
            );
          });

          this.metrics.events.ecommerce.add_payment_info({
            currency: 'USD',
            value: quote?.total ?? 0,
            payment_type: 'Bill to Account',
            items: items,
          });

          items = quoteLineItems.map((lineItem: any, index) => {
            return this.metrics.productAsEcommerceItem(
              lineItem?.product ?? '',
              lineItem?.quantity ?? '',
              lineItem?.total ?? '',
              index,
              'purchase'
            );
          });

          this.metrics.events.ecommerce.purchase({
            currency: 'USD',
            affiliation: 'Summit.com',
            transaction_id: String(quoteNo),
            value: quote?.total ?? 0,
            items: items,
          });
        }
      } catch (e) {
        e;
      }
    };
    logPurchase(quoteNo);

    try {
      await procurement.save();
    } catch (error) {
      ////this.rollbar.error(EXCEPTION_1009, error);
    }


    this.router.transitionTo('store.order-confirmation-processing', procurement.id);


  }
}
