import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';

export default class AccountContractModel extends Model {
  @attr('string') name!: string;
  @attr('string') startsAt!: string;
  @attr('string') endsAt!: string;
  @attr('string') isActive!: string;

  @attr('string') contractValue!: string;
  @attr('string') contractReleased!: string;
  @attr('string') sapAccountId!: string;
  @attr('string') sapAccountJobAccountId!: string;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-contract': AccountContractModel;
  }
}
