import Helper from '@ember/component/helper';

export default Helper.extend({
  compute(params) {
    const num1 = parseInt(params[0], 10),
      num2 = parseInt(params[1], 10);
    if (params.length == 2) {
      return num1 % num2;
    } else if (params.length == 3) {
      return num1 % num2 == parseInt(params[2], 10);
    } else {
      throw new Error(
        'Unexpected parameters to ModHelper. Expected (mod 10 3) or (mod 10 3 1)',
      );
    }
  },
});
