import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class AdManager extends Component {
  @service store;
  @service session;
  @tracked ad = this.args?.ad ?? false;

  @computed('session.customer.id', 'session.isAuthenticated', 'store')
  get customer() {
    const id = this.session?.customer?.id;

    if (isPresent(id)) {
      return this.store.find('customer', id);
    } else {
      return null;
    }
  }

  // @computed('customer.aclAccountPermissions.@each.name')
  // get hasOrdering() {
  //   return (
  //     this.customer
  //       ?.get('aclAccountPermissions')
  //       ?.isAny('name', 'account::ecommerce') ?? false
  //   );
  // }

  // @computed('customer.aclAccountPermissions.@each.name')
  // get hasAccountFinancials() {
  //   return (
  //     this.customer
  //       ?.get('aclAccountPermissions')
  //       ?.isAny('name', 'account::financial') ?? false
  //   );
  // }
}
