import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type RouterService from '@ember/routing/router';
import type SessionService from 'shoelace/services/session';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreCustomerEditNameRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare router: RouterService;

  async model() {
    return this.session?.customer ?? this.router.transitionTo('login');
  }
}
export default instrumentRoutePerformance(StoreCustomerEditNameRoute);
