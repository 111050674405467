import type StoreService from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { EXCEPTION_1014, EXCEPTION_1015 } from 'shoelace/exceptions/mod';
import type ProcurementModel from 'shoelace/models/procurement';
import type { BootOptions } from 'shoelace/services/coordinator';
//import type RollbarService from 'shoelace/services/rollbar';
import type ServiceCenterService from 'shoelace/services/service-center';
import type SessionService from 'shoelace/services/session';
import { v1 } from 'uuid';
import OrderingDictionary from '../order_dictionary';

export default class ProcurementService extends Service {
  @service declare account: any;
  @service declare accountJobAccount: any;
  @service declare serviceCenter: ServiceCenterService;
  @service declare session: SessionService;
  @service declare store: StoreService;
  //@service declare rollbar: RollbarService;

  @tracked procurement?: ProcurementModel;
  @tracked isCreatingRecord = false;
  @tracked isSaving = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async onCoordinatorSetup(_options?: BootOptions): Promise<void> {
    this.procurement = await this.createRecord();

    this.procurement.set('account', this.account.account);
    this.procurement.set('customer', this.session.customer);
    this.procurement.set('isCod', isEmpty(this.account.account));
    this.procurement.set(
      'accountJobAccount',
      this.accountJobAccount.accountJobAccount,
    );
    this.procurement.set('serviceCenter', this.serviceCenter.serviceCenter);
    this.procurement.set(
      'activeAccountAddress',
      this.account.validAccountAddresses?.firstObject ??
        this.store.createRecord('account-address'),
    );

    if (this.procurement.isGuest) {
      // only consider resetting guest if already selected
      this.procurement.set('isGuest', isEmpty(this.session.customer));
    }
  }

  async onCoordinatorTeardown(): Promise<void> {
    this.procurement = undefined;
  }

  async save(): Promise<ProcurementModel | undefined> {
    try {
      this.isSaving = true;

      await this.procurement?.save();

      return this.procurement;
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1014, error);

      throw error;
    } finally {
      this.isSaving = false;
    }
  }

  async createRecord(): Promise<ProcurementModel> {
    try {
      this.isCreatingRecord = true;

      if (
        isEmpty(this.procurement) ||
        isPresent(this.procurement!.get('confirmedAt'))
      ) {
        this.procurement = await this.createProcurement();
      }

      return this.procurement!;
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1015, error);

      throw error;
    } finally {
      this.isCreatingRecord = false;
    }
  }

  procurementKindFor(session: SessionService): string {
    if (session.get('session').get('authenticator') === 'authenticator:po2go') {
      return OrderingDictionary.KIND.PUNCHOUT;
    } else {
      return OrderingDictionary.KIND.SAPORDER;
    }
  }

  async createProcurement(): Promise<ProcurementModel> {
    const id = v1();

    const account = this.account.account;
    const accountJobAccount = this.accountJobAccount.accountJobAccount;
    const customer = this.session.customer;
    const deliveryBillingContactSame = true;
    const expectedAt = new Date();
    const isCod = isEmpty(this.account.account);
    const isGuest = false;
    const kind = this.procurementKindFor(this.session);
    const serviceCenter = this.serviceCenter.serviceCenter;
    const zcod = { country: 'US' };
    const willCallShipTo = this.store.createRecord('account-address');
    willCallShipTo.set('name', 'will call'); // BUG?: Not sure why it needs to be done like this. Setting directly when createRecord does not work

    const activeAccountAddress =
      this.account.validAccountAddresses?.firstObject ??
      this.store.createRecord('account-address');

    const deliveryMethod = OrderingDictionary.DELIVERY_METHOD.WILL_CALL;
    const paymentMethod = OrderingDictionary.PAYMENT_METHOD.WILL_CALL;
    const billingContactFirstName = customer?.get('firstName');
    const billingContactLastName = customer?.get('lastName');
    const billingContactPhone =
      customer?.get('cellPhone') || customer?.get('officePhone');

    const procurement = this.store.createRecord('procurement', { id });

    procurement.set('account', account);
    procurement.set('accountJobAccount', accountJobAccount);
    procurement.set('customer', customer);
    procurement.set('serviceCenter', serviceCenter);
    procurement.set('accountAddress', willCallShipTo);

    procurement.set('activeAccountAddress', activeAccountAddress);
    procurement.set('billingContactFirstName', billingContactFirstName);
    procurement.set('billingContactLastName', billingContactLastName);
    procurement.set('billingContactPhone', billingContactPhone);
    procurement.set('deliveryBillingContactSame', deliveryBillingContactSame);
    procurement.set('deliveryContactFirstName', billingContactFirstName);
    procurement.set('deliveryContactLastName', billingContactLastName);
    procurement.set('deliveryContactPhone', billingContactPhone);
    procurement.set('deliveryMethod', deliveryMethod);
    procurement.set('expectedAt', expectedAt);
    procurement.set('isGuest', isGuest);
    procurement.set('isCod', isCod);
    procurement.set('kind', kind);
    procurement.set('paymentMethod', paymentMethod);
    procurement.set('willCallShipTo', willCallShipTo);
    procurement.set('zcod', zcod);

    return procurement;
  }
}

declare module '@ember/service' {
  interface Registry {
    procurement: ProcurementService;
  }
}
