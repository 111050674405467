import Helper from '@ember/component/helper';
import { A } from '@ember/array';

export default Helper.extend({
  compute(params) {
    const [label] = params;
    const branches = label.split('/').filter(item => item && item.trim());
    if(branches[1] == "Summit Hierarchy Root"){
      const parts = label.split('/').filter(item => item && item.trim()).slice(2);
      const partData = parts.map((branches, ix, all) => all.slice(0, 1 + ix).join('/'))

      if(partData.length > 2){
        partData.pop()
        const data = partData.length - 1;
        console.log("partdata", partData)
        return partData[data].toLowerCase().replace(",","").replace(" & "," ").replace(" & "," ").replace(" ","-").replace(" ","-").replace(" ","-");
      } else{
        const data = partData.length - 1;
      
        return partData[data].toLowerCase().replace(",","").replace(" & "," ").replace(" & "," ").replace(" ","-").replace(" ","-").replace(" ","-");
      }
    }
 

    
  },
});
