import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (has-block \"decrement\")}}\n  {{#if (has-block \"decrement\")}}\n    <button\n      type=\"button\"\n      class=\"w-24\"\n      ...attributes\n      {{on \"click\" (fn this.onDecrement)}}\n      for=\"{{this.id}}\"\n    >\n      {{yield to=\"decrement\"}}\n    </button>\n  {{/if}}\n  <input\n    class=\"hide-number-arrows text-dark-gray-800 outline-none bg-white font-semibold text-md flex items-center text-center w-full\"\n    data-tag=\"spinner-input\"\n    type=\"number\"\n    step={{@step}}\n    min={{@min}}\n    value={{@value}}\n    max={{@max}}\n    {{on \"change\" (fn this.setChange)}}\n    {{did-insert this.setRef}}\n  />\n  {{#if (has-block \"increment\")}}\n    <button\n      type=\"button\"\n      class=\"w-24\"\n      ...attributes\n      {{on \"click\" (fn this.onIncrement)}}\n    >\n      {{yield to=\"increment\"}}\n    </button>\n  {{/if}}\n{{/if}}", {"contents":"{{#if (has-block \"decrement\")}}\n  {{#if (has-block \"decrement\")}}\n    <button\n      type=\"button\"\n      class=\"w-24\"\n      ...attributes\n      {{on \"click\" (fn this.onDecrement)}}\n      for=\"{{this.id}}\"\n    >\n      {{yield to=\"decrement\"}}\n    </button>\n  {{/if}}\n  <input\n    class=\"hide-number-arrows text-dark-gray-800 outline-none bg-white font-semibold text-md flex items-center text-center w-full\"\n    data-tag=\"spinner-input\"\n    type=\"number\"\n    step={{@step}}\n    min={{@min}}\n    value={{@value}}\n    max={{@max}}\n    {{on \"change\" (fn this.setChange)}}\n    {{did-insert this.setRef}}\n  />\n  {{#if (has-block \"increment\")}}\n    <button\n      type=\"button\"\n      class=\"w-24\"\n      ...attributes\n      {{on \"click\" (fn this.onIncrement)}}\n    >\n      {{yield to=\"increment\"}}\n    </button>\n  {{/if}}\n{{/if}}","moduleName":"shoelace/components/presenters/peak-spinner.hbs","parseOptions":{"srcName":"shoelace/components/presenters/peak-spinner.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

interface PresenterPeakSpinnerArgs {
  step?: number | string;
  value?: Event;
  onChange?: (event?: Event) => Promise<void>;
}

export default class PresenterPeakSpinnerComponent extends Component<PresenterPeakSpinnerArgs> {
  @tracked ref = null;
  @tracked value = this.args.value;
  @tracked step = this.args.step;
  @tracked onChange = this.args.onChange;


  @action async setChange(event?: Event): Promise<void> {
    if (typeof(event?.target) === 'object') {
      await this.args?.onChange?.(event?.target?.value);
    } else {
      await this.args?.onChange?.(event);
    }
  }

  @action setRef(ref: any) {
    this.ref = ref;
  }

  @action async onIncrement() {
    const lastValue = this.value;

    this.ref?.stepUp();

    const value = this.ref?.value;
    if (lastValue !== value) {
        return await this.setChange(value);
    }
  }

  @action async onDecrement() {
    const lastValue = this.value;

    this.ref?.stepDown();
    const value = this.ref?.value;

    if (lastValue !== value) {
        return await this.setChange(value);
   
    }
  }
}
