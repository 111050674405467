import Route from '@ember/routing/route';
import { inject } from '@ember/service';
import { get } from '@ember/object';

const selectors = {
  1354: [
    'Surge Protection Device',
    'Varset Low Voltage Capacitor Banks',
    '9080 Open Power Distribution Blocks',
    'Advanced Optimum panels - Magelis GTO',
    'Advanced Touchscreen/Keyboard Panels',
    'Altistart Line of Soft Starters',
    'Cordsets & Connectors OsiSense XZ',
    'Definite Purpose Contactors',
    'Fractional Horsepower Manual Starters with Melting Alloy Overload Relays',
    'GS2 Fusible Disconnect Switch',
    'Harmony 60mm Towerlights XVU',
    'Harmony Pushbuttons 16 mm',
    'Harmony Pushbuttons 22mm',
    'Harmony Pushbuttons 30mm',
    'High performance modular HMI - Magelis GTU',
    'Industrial PC and Displays',
    'Lexium 32i Integrated servo drive',
    'Limit Switches OsiSense XC',
    'Linergy Terminal Blocks',
    'LK4 Non Fusible Disconnect Switch',
    'Machine Drives Altivar',
    'Magelis Line of Small Panel HMI (Human Machine Interface) with Keyboard',
    'Manual Starters - Integral Horsepower with Melting Alloy Overload Relays',
    'Manual Switches - Horsepower Rated for Self-Protected Motors',
    'MD, Vario Disconnect Switches',
    'Modicon Line of M340/580 Micro Programmable Automation Controllers (PAC)',
    'Modicon PLCs M2XX',
    'Modicon Premium to M340/M580 converter',
    'NEMA Pressure & Float switch',
    'NEMA Pressure switch',
    'OsiSense XU Photoelectric Sensors',
    'Power Distribution Blocks',
    'Power Supplies & Transformer Phaseo',
    'Pressure Sensors OsiSense XM',
    'Preventa XPS MP, MC, MCM',
    'Proximity Sensors OsiSense XS & XT',
    'Rotary Encoder',
    'Safety Modules Preventa XPS',
    'Sensors',
    'Small HMI controllers - Magelis SCU',
    'Small Panels',
    'Small panels with keypad',
    'TeSys Motor Control Solutions',
    'TeSys N Contactors and Starters',
    'TeSys VLS Disconnect Switches',
    'Thermal Units Selection',
    'Type S NEMA Starters',
    'ultrasonic sensor',
    'XVC monolithic tower light',
    'Zelio Control Monitoring & Control Relays',
    'Zelio Logic',
    'Zelio Plug-in Relays and Zelio Solid State Relays',
    'Zelio Time - timing relays',
    'Enclosures',
    'I-Line Panelboards (Merchandised)',
    'Lighting & Appliance Panelboards (NF)',
    'Lighting & Appliance Panelboards (NQ)',
    'Low Voltage Motor Control Center (Unit Selection Only)',
    'LV Transformers',
    'Metering',
    'Molded Case Circuit Breakers',
    'Safety Switches',
    'Combination Service Entrance Devices (CSEDs)',
    'Load Center Selector (Max 240Vac)',
    'Miniature Circuit Breakers',
    'Multi 9 Minature Circuit Breaker',
  ],
  0: {},
};

const slugify = name =>
  name
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace(/&|\(|\)|\/|,/g, '')
    .replace(/--/g, '-');

export default class StoreConfiguratorsSelectorRoute extends Route {
  model(params) {
    return params;
  }

  setupController(controller, model) {
    const slug = model.selector.toLowerCase();
    controller.set('model', model);

    if (model.manufacturer_id in selectors) {
      if (selectors[model.manufacturer_id].map(slugify).includes(slug)) {
        controller.set(
          'selector',
          selectors[model.manufacturer_id]
            .map(s => ({ real: s, lower: slugify(s) }))
            .filter(s => s.lower == slug)[0].real,
        );
      } else {
        // 404
      }
    } else {
      // 404
    }
  }
}


