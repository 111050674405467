import { belongsTo } from 'ember-data/relationships';
import { attr } from '@ember-data/model';
import Model from '@ember-data/model';

export default Model.extend({
  city: attr('string'),
  country: attr('string'),
  county: attr('string'),
  name: attr('string'),
  name2: attr('string'),
  poBox: attr('string'),
  state: attr('string'),
  street: attr('string'),
  street2: attr('string'),
  zipCode: attr('string'),

  account: belongsTo('account'),
});
