import type StoreService from '@ember-data/store';
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Classification from 'shoelace/models/classification';
import type AccountService from 'shoelace/services/account';
import type { BootOptions } from 'shoelace/services/coordinator';
import type SessionService from 'shoelace/services/session';

export default class CatalogService extends Service {
@service declare store: StoreService;

@service declare session: SessionService;

@service declare account: AccountService;

  @tracked catalog?: Classification;
  @tracked catalogs?: Classification[];

  async onCoordinatorSetup(_options?: BootOptions): Promise<void> {
    this.loadCatalogs();
  }

  async onCoordinatorTeardown(): Promise<void> {
    this.catalog = undefined;
    this.catalogs = undefined;
  }

  get id() {
    return this.catalog?.id;
  }

  @action async setCatalog(catalog: Classification | string | number) {
    if (catalog === 'default') {
      this.catalog = undefined;
    } else if (typeof catalog === 'string' || typeof catalog === 'number') {
      this.catalog = await this.store.findRecord('classification', catalog);
    } else if (catalog instanceof Classification) {
      this.catalog = catalog;
    } else {
      console.error('Invalid catalog selected', catalog);
    }
  }

  @action async loadCatalogs() {
    const customer = this.session.customer?.id;
    const account = this.account.account;

    if (customer) {
      const permissions = await this.store.query('classification-permission', {
        filter: {
          customer: customer,
        },
        include: ['classification', 'classification.account'].join(','),
      });

      this.catalogs = await permissions
        .filter(permission => {
          return (
            account?.account_number ===
            permission.classification?.get('account')?.get('account_number')
          );
        })
        .map(permission => permission.classification)
        .sort();

      this.catalogs?.forEach(catalog => {
        if (catalog.get('isDefault')) {
          this.setCatalog(catalog.get('id'));
        }
      });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    catalog: CatalogService;
  }
}
