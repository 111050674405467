import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class CustomerDisplayNameComponent extends Component {
  @service store;
  @service session;

  @tracked tagName = '';

  @computed('session.isAuthenticated', 'store')
  get customer() {
    const id = this.session?.get('customer.id');

    if (isPresent(id)) {
      return this.store.find('customer', id);
    } else {
      return null;
    }
  }

  @computed('customer.account')
  get account() {
    return this.customer.get('account');
  }
}
