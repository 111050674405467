import type { AsyncBelongsTo } from '@ember-data/model';
import type Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  EXCEPTION_1016,
  EXCEPTION_1017,
  EXCEPTION_1018,
  EXCEPTION_1019,
  EXCEPTION_1020,
} from 'shoelace/exceptions/mod';
import AccountVerification from 'shoelace/models/account-verification';
// import RoleModel from 'shoelace/models/role';
//import type RollbarService from 'shoelace/services/rollbar';
import { AccountVerificationStatus } from '../../../constants/mod';
import RbacAccountCustomerRoleModel from 'shoelace/models/rbac-account-customer-role';

export default class AdminAccountVerificationIndexController extends Controller {
  queryParams = ['page', 'size', 'sort'];

  @service declare store: Store;
  //@service declare rollbar: RollbarService;
  @service declare router: RouterService;

  @tracked page = 1;
  @tracked size = 10;
  @tracked sort = 'id';
  @tracked isValidated = false;

  @action async validate(
    accountVerification: AccountVerification,
  ): Promise<void> {
    await accountVerification.validate();
    this.isValidated = true;
  }

  @action async onRemoveLinkedAccount(
    role: RbacAccountCustomerRoleModel,
  ): Promise<void> {
    try {
      await role.destroyRecord();
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1020, error);

      throw error;
    }
  }

  @action async onEmailApproverNotification(
    accountVerification: AccountVerification,
  ): Promise<void> {
    try {
      accountVerification.set('status', AccountVerificationStatus.Notify);
      const customer = await accountVerification.get('customer');
      await customer.save();
      await accountVerification.save();
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1016, error);

      throw error;
    } finally {
      this.isValidated = false;
    }
  }

  @action async onDeny(accountVerification: AccountVerification): Promise<void> {
    try {
      await accountVerification.set('status', AccountVerificationStatus.Denied);
      await accountVerification.save();
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1017, error);

      throw error;
    } finally {
      this.isValidated = false;
    }
  }

  @action async accountExists(accountNumber: string | number): Promise<boolean> {
    try {
      await this.store.findRecord('account', accountNumber);
      return true;
    } catch (error) {
      return false;
    }
  }

  @action async onApprove(
    accountVerification: AccountVerification,
  ): Promise<void> {
    try {
         const customer = await accountVerification.get('customer');
      const customerServiceCenter = await customer.get('serviceCenter');

      if (!customerServiceCenter) {
        customer.set('serviceCenter', accountVerification.get('serviceCenter'));
      }

      await customer.save();
      await accountVerification.set('rbacRoleId',customer.rbacCustomerRoles.firstObject?.rbac_role_id);
      await accountVerification.set('status', AccountVerificationStatus.Approved);

      await accountVerification.save();
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1018, error);

      throw error;
    } finally {
      this.isValidated = false;
    }
  }

  @action async onClose(accountVerification: AccountVerification): Promise<void> {
    try {
      await accountVerification.set('status', AccountVerificationStatus.Closed);
      await accountVerification.save();
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1019, error);

      throw error;
    } finally {
      this.isValidated = false;
    }
  }

  @action onSuccessResponseAction(): void {
    this.router.transitionTo('admin.account-verifications.in-process');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/account-verification/index': AdminAccountVerificationIndexController;
  }
}
