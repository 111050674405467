import Helper from '@ember/component/helper';

export default Helper.extend({
  compute([receipt]) {
    try {
      return {
        id: receipt.get('transactionId'),
        affiliation: 'Bill Pay',
        revenue: receipt.get('amount'),
      };
    } catch (e) {
      return {};
    }
  },
});
