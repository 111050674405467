import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import { instrumentRoutePerformance } from '@sentry/ember';
import { action } from '@ember/object';

class FeaturedListRoute extends Route {
  @service cart;
  @service store;
  @service router;

  model(params) {
    const list = this.store.findRecord('featured-list', params.list_id);
    list.then((list) => {
      const li = list.cartLineItems;
      li.then((lineItems) => {
        lineItems.forEach((li) => {
          li.set('quantity', 0);
        });
      });
      return li;
    });

    return list;
  }

  @action async addToCart(li) {
    this.cart.addItem(await li.product.id, li.quantity);
  }

  @action rename(source) {
    const name = prompt('New name', source.title);
    if (name) {
      source.set('title', name);
      source.save();
    }
  }

  @action async delete(source) {
    if (confirm(`Are you sure you want to delete ${source.title}?`)) {
      source.deleteRecord();
      await source.save();
      this.router.transitionTo('customer.lists');
    }
  }

  @action setList(list) {
    this.set('controller.selectedList', list);
    this.set('controller.showListForm', true);
  }
}

export default instrumentRoutePerformance(FeaturedListRoute);
