import { errors } from '../mixins/protected-route';
import Route from '@ember/routing/route';
import { instrumentRoutePerformance } from '@sentry/ember';
import { inject as service } from '@ember/service';

class ErrorRoute extends Route {
  @service router;

  renderTemplate(controller, model) {
    try {
      const router = this.router;
      const intent = router.targetState.routerJs.activeTransition.intent;
      const targetUrl = router.generate(intent.name);
      router.location.replaceURL(targetUrl);
      setTimeout(() => window.location.reload(), 500);
    } catch (e) {
      e;
    }
    const error = (model && model['error']) || null;
    const template = errors.includes(error) ? error : 'standard';

    this.render(`error/${template}`);
  }
}
export default instrumentRoutePerformance(ErrorRoute);
