import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type AccountOrderLineItemModel from 'shoelace/models/account-order-line-item';
import type AccountProjectOrderModel from 'shoelace/models/account-project-order';

export default class AccountOrderModel extends Model {
  @attr('string') name!: null | string;
  @attr('date') createdAt!: null | Date;
  @attr('date') expectedAt!: null | Date;
  @attr('string') comments!: null | string;
  @attr('number') lineItemCount!: null | number;
  @attr('string') jobAccountName!: null | string;
  @attr('string') purchaseOrder!: null | string;
  @attr('string') shippingType!: null | string;
  @attr('string') status!: null | string;
  @attr('number') subTotal!: null | number;
  @attr('number') tax!: null | number;
  @attr('number') totalCost!: null | number;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @hasMany('account-project-order')
  accountProjectOrders!: AsyncHasMany<AccountProjectOrderModel>;

  @hasMany('account-order-line-item')
  accountOrderLineItems!: AsyncHasMany<AccountOrderLineItemModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-order': AccountOrderModel;
  }
}
