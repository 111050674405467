import ApplicationSerializer, { exclude } from './application';

export default class AccountSerializer extends ApplicationSerializer {
  attrs = {
    imagePreviewUrl: exclude,
    imageUrl: exclude,
    name: exclude,
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    account: AccountSerializer;
  }
}
