import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type ProductModel from 'shoelace/models/product';

export default class CategoryModel extends Model {
  @attr('string') name!: null | string;
  @attr('string') url!: null | string;

  @belongsTo('category', { inverse: null })
  category!: AsyncBelongsTo<CategoryModel>;

  @hasMany('category', { inverse: null })
  categories!: AsyncHasMany<CategoryModel>;

  @hasMany('product')
  products!: AsyncHasMany<ProductModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    category: CategoryModel;
  }
}
