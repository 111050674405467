import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type SessionModel from 'shoelace/models/session';
import type CoordinatorService from 'shoelace/services/coordinator';
import type SessionService from 'shoelace/services/session';
import { scrollToTop } from '../utils/dom-helpers';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

class ApplicationRoute extends Route {
  @service declare coordinator: CoordinatorService;
  @service declare store: StoreService;
  @service declare intl: IntlService;
  @service declare session: SessionService;
  @service declare router: RouterService;

  isHashChangeListening = false;

  title(tokens: string[]): string {
    return [
      ...tokens,
      'Summit Electric Supply › Wholesale Electrical Supplies and Tools Distributor',
    ].join(' - ');
  }

  async beforeModel(): Promise<void> {
    if (!this.router?.currentURL?.includes('sso/auth')) {
      await this.coordinator.setup();
    } 
      this.router.on('routeDidChange', (transition: Transition) => {
        if (!window.location.href.includes('login') || !window.location.href.includes('forgot-password') || !window.location.href.includes('reset-password') || !window.location.href.includes('reset-password') || !window.location.href.includes('unlock')) {
          localStorage.setItem(
            'storage:url-cache',
            JSON.stringify({ url: window.location.href })
          );
        }
        if (
          !this.session.isAuthenticated &&
          !transition.to?.name?.startsWith('login') &&
          !transition.from?.name?.startsWith('login')
        ) {
          this.session.set('attemptedTransition', transition);
        }
      });
      if (!this.isHashChangeListening) {
        window.addEventListener('hashchange', () => {
          scrollToTop(
            0 - (document?.querySelector?.('.header')?.scrollHeight ?? 0)
          );
        });

        this.isHashChangeListening = true;
      }

      return this.intl.setLocale(['en-us']);
  }

  model(): SessionModel | undefined {
    return this.session.model;
  }

  @action didTransition(): void {
    window.scrollTo(0, 0);

    if (
      'from' in Array &&
      Array.from(document.body.classList).includes('device-mobile')
    ) {
      // mobile
      // if (window && 'document' in window) {
      //   setTimeout(
      //     () =>
      //       window.scrollTo(
      //         0,
      //         document.getElementsByTagName('main')[0].offsetTop,
      //       ),
      //     1,
      //   );
      // }
    } else {
      // desktop
      if (window && 'document' in window) {
        setTimeout(() => window.scrollTo(0, 0), 1);
      }
    }
  }
}
export default instrumentRoutePerformance(ApplicationRoute);
