import { belongsTo } from 'ember-data/relationships';
import { hasMany } from '@ember-data/model';
import { attr } from '@ember-data/model';
import Model from '@ember-data/model';

export default Model.extend({
  validAt: attr('date'),
  expiresAt: attr('date'),
  isLotKit: attr('boolean'),
  isSummitBuck: attr('boolean'),
  name: attr('string'),
  customerPartNumber: attr('string'),
  jobAccountName: attr('string'),
  purchaseOrder: attr('string'),
  quantity: attr('number'),
  unitOfMeasure: attr('string'),
  numberPerUnit: attr('number'),
  price: attr('number'),
  total: attr('number'),
  totalLot: attr('number'),
  unknownItemType: attr('string'),
  unknownTextFlag: attr('string'),
  forceLineItemDescription: attr('string'),

  account: belongsTo('account'),
  accountJobAccount: belongsTo('account-job-account'),
  accountQuoteAddress: belongsTo('account-quote-address'),
  accountQuote: belongsTo('account-quote'),
  product: belongsTo('product'),

  accountQuoteLineItemComments: hasMany('account-quote-line-item-comment'),
});
