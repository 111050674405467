import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { belongsTo } from '@ember-data/model';
import type PermissionModel from 'shoelace/models/permission';
import type RoleModel from 'shoelace/models/role';

export default class GrantModel extends Model {
  @belongsTo('permission')
  permission!: AsyncBelongsTo<PermissionModel>;

  @belongsTo('role')
  role!: AsyncBelongsTo<RoleModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    grant: GrantModel;
  }
}
