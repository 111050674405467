import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each this.terms as |term|}}\n  <button\n    class=\"btn btn-sm btn-primary badge\"\n    type=\"button\"\n    {{on \"click\" (fn this.onPop term)}}\n    ...attributes\n  >\n    {{term}}\n    <span class=\"badge\"><FaIcon @icon=\"times\" /></span>\n  </button>\n{{/each}}", {"contents":"{{#each this.terms as |term|}}\n  <button\n    class=\"btn btn-sm btn-primary badge\"\n    type=\"button\"\n    {{on \"click\" (fn this.onPop term)}}\n    ...attributes\n  >\n    {{term}}\n    <span class=\"badge\"><FaIcon @icon=\"times\" /></span>\n  </button>\n{{/each}}","moduleName":"shoelace/components/amps/filter-tokens.hbs","parseOptions":{"srcName":"shoelace/components/amps/filter-tokens.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class AmpsFilterTokensComponent extends Component {
  get terms() {
    return this.args.terms ?? [];
  }

  @action onPop(term) {
    if (this.args.onPop) {
      const terms = this.terms.filter(_term => _term !== term);
      return this.args.onPop(terms);
    }
  }
}
