import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import type AccountModel from 'shoelace/models/account';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type ProductModel from 'shoelace/models/product';
import type ServiceCenterModel from 'shoelace/models/service-center';

export default class ProductPriceModel extends Model {
  @attr('boolean') accountPriceIsPrimary!: boolean;
  @attr('number') accountId!: number;
  @attr('number') accountJobAccountId!: number | null;
  @attr('number') unitPrice!: number;
  @attr('number') extendedPrice!: number;
  @attr('string') formattedPrice!: string;
  @attr('string') formattedUnitPrice!: string;
  @attr('string') formattedExtendedPrice!: string;
  @attr('number') pricingQuantity!: number;
  @attr('number') requestedQuantity!: number;
  @attr('string') unit!: string;
  @attr('string') matnr!: string;
  @attr('number') minQty!: number;
  @attr('boolean') minQtyGt!: boolean;
  @attr('string') nicePrice!: string;
  @attr('string') niceRetailPrice!: string;
  @attr('string') niceSavings!: string;
  @attr('number') price!: number;
  @attr('number') retailPrice!: number;
  @attr('number') savings!: number;
  @attr('string') uom!: string;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-job-account')
  accountJobAccount!: AsyncBelongsTo<AccountJobAccountModel>;

  @belongsTo('product')
  product!: AsyncBelongsTo<ProductModel>;

  @belongsTo('service-center')
  serviceCenter!: AsyncBelongsTo<ServiceCenterModel>;

  @tracked cachedAt: Date | null = null;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'product-price': ProductPriceModel;
  }
}
