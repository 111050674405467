import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type ServiceCenterModel from 'shoelace/models/service-center';

export default class ServiceCenterDowHourModel extends Model {
  @attr('number')
  declare dow?: number;

  @attr('date')
  declare open?: Date;

  @attr('date')
  declare close?: Date;

  @attr('date')
  declare openUtc?: Date;

  @attr('date')
  declare closeUtc?: Date;

  @belongsTo('service-center')
  serviceCenter!: AsyncBelongsTo<ServiceCenterModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'service-center-dow-hour': ServiceCenterDowHourModel;
  }
}
