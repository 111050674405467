import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountProjectOrderModel from 'shoelace/models/account-project-order';
import type AccountProjectOrderAttributeDefinitionModel from 'shoelace/models/account-project-order-attribute-definition';

export default class AccountProjectOrderAttributeModel extends Model {
  @attr('string') name!: null | string;

  @belongsTo('account-project-order')
  accountProjectOrder!: AsyncBelongsTo<AccountProjectOrderModel>;

  @belongsTo('account-project-order-attribute-definition')
  accountProjectOrderAttributeDefinition!: AsyncBelongsTo<AccountProjectOrderAttributeDefinitionModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-project-order-attribute': AccountProjectOrderAttributeModel;
  }
}
