/* eslint-disable ember/no-computed-properties-in-native-classes */
import Metrics from 'ember-metrics/services/metrics';
import { inject as service } from '@ember/service';
import { EXCEPTION_1076, EXCEPTION_1077 } from 'shoelace/exceptions/mod';
import type ProductModel from 'shoelace/models/product';
import type AccountService from 'shoelace/services/account';
import type { BootOptions } from 'shoelace/services/coordinator';
//import type RollbarService from 'shoelace/services/rollbar';
import type ServiceCenterService from 'shoelace/services/service-center';
import type SessionService from 'shoelace/services/session';

// Interfaces and events below are based on Recommended Events for GA4:
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events

export interface EcommerceBase {
  currency: string;
  value: number;
  items: EcommerceItem[];
}

export interface EcommerceAddPaymentInfoOptions extends EcommerceBase {
  coupon?: string;
  payment_type?: string;
}

export interface EcommerceAddShippingInfoOptions extends EcommerceBase {
  coupon?: string;
  shipping_tier?: string;
}

export interface EcommerceBeginCheckoutOptions extends EcommerceBase {
  coupon?: string;
}

export interface EcommercePurchaseOptions extends EcommerceBase {
  transaction_id: string;
  affiliation?: string;
  coupon?: string;
  shipping?: number;
  tax?: number;
}

export interface EcommerceSelectItemOptions {
  items: EcommerceItem[]; // should only have one item
  item_list_id?: string;
  item_list_name?: string;
}

export interface EcommercePayBill {
  currency: string;
  value: number;
}

export interface EcommerceViewItemListOptions {
  items: EcommerceItem[]; // can have multiple items
  item_list_id?: string;
  item_list_name?: string;
}

export interface EcommerceItem {
  item_id: string;
  item_name: string;
  affiliation?: string;
  coupon?: string;
  currency?: string;
  discount?: number;
  index?: number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  location_id?: string;
  price?: number;
  quantity?: number;
}

export interface ShareOptions {
  method?: string;
  content_type?: string;
  item_id?: string;
}

export default class MetricsService extends Metrics {
  @service declare account: AccountService;
  @service declare session: SessionService;
  @service declare serviceCenter: ServiceCenterService;

  enabled = true;

  async onCoordinatorSetup(options?: BootOptions): Promise<void> {
    super.identify({
      user_id: this.session?.id,
    });
  }

  async onCoordinatorTeardown(): Promise<void> {}
  // Default GA4 ecommerce evnts as per google reference docs
  events = {
    ecommerce: {
      add_payment_info: (options: EcommerceAddPaymentInfoOptions) =>
        this.event('add_payment_info', options),
      add_shipping_info: (options: EcommerceAddShippingInfoOptions) =>
        this.event('add_shipping_info', options),
      add_to_cart: (options: EcommerceBase) =>
        this.event('add_to_cart', options),
      add_to_wishlist: (options: EcommerceBase) =>
        this.event('add_to_wishlist', options),
      begin_checkout: (options: EcommerceBeginCheckoutOptions) =>
        this.event('begin_checkout', options),
      purchase: (options: EcommercePurchaseOptions) =>
        this.event('purchase', options),
      refund: (options: EcommercePurchaseOptions) =>
        this.event('refund', options),
      remove_from_cart: (options: EcommerceBase) =>
        this.event('remove_from_cart', options),
      pay_bill_account: (options: EcommercePayBill) =>
        this.event('pay_bill_account', options),
      select_item: (options: EcommerceSelectItemOptions) =>
        this.event('select_item', options),
      view_cart: (options: EcommerceBase) => this.event('view_cart', options),
      view_item: (options: EcommerceBase) => this.event('view_item', options),
      view_item_list: (options: EcommerceViewItemListOptions) =>
        this.event('view_item_list', options),
    },
    login: (options: { method: string }) => this.event('login', options),
    search: (options: { search_term: string }) => this.event('search', options),
    select_content: (options: object) => this.event('select_content', options),
    share: (options: ShareOptions) => this.event('share', options),
    sign_up: (options: { method: string }) => this.event('sign_up', options),
  };

  //// Setting this up a better way after knowing how data shows in google analytics
  // exception
  // search **
  // select_content (for service center?)
  // select_promotion
  // share
  // view_promotion
  // view_search_results

  productAsEcommerceItem(
    product: ProductModel,
    quantity: number,
    price?: number,
    index?: number,
    item_list_id?: string
  ): any{
    try {
      const category = product?.categoryPath?.split('/');

      return {
        item_id: product?.id ?? '',
        item_name: product?.name ?? '',
        affiliation: "Summit Electric Supply",
        currency: 'USD',
        index,
        item_brand: product?.manufacturerName ?? '',
        item_category: category?.[0] ?? '',
        item_category2: category?.[1] ?? '',
        item_category3: category?.[2] ?? '',
        item_category4: category?.[3] ?? '',
        item_category5: category?.[4] ?? '',
        item_list_id,
        location_id: this.serviceCenter?.serviceCenter?.longName?.toString(),
        quantity: parseInt(quantity.toString(), 10) ?? '',
        price,
      };
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1077, error);
      console.log(error);
      return undefined;
    }
  }

  event(name: string, options?: object): void {
    console.log(name, options);
    try {
      this.trackEvent({
        _name: name,
        account: this.account.account?.id,
        email: this.session?.customer?.email,
        isInternal: this.session?.customer?.isInternal,
        ...options,
      });
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1076, error);
      console.log(error);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    metrics: MetricsService;
  }
}
