import type { AsyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany } from '@ember-data/model';
import type ServiceCenterModel from 'shoelace/models/service-center';

export default class ServiceCenterTypeModel extends Model {
  @attr('string')
  declare name?: string;

  @attr('string')
  declare lookup?: string;

  @hasMany('service-center')
  serviceCenters!: AsyncHasMany<ServiceCenterModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'service-center-type': ServiceCenterTypeModel;
  }
}
