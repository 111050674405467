import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type CustomerModel from 'shoelace/models/customer';
import type RbacRoleModel from 'shoelace/models/rbac-role';

export default class RbacCustomerRoleModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('date') updatedAt!: null | Date;
  @attr('string') rbac_role_id!: null | string;
  @attr('string') customer_id!: null | string;

  @belongsTo('customer')
  customer!: AsyncBelongsTo<CustomerModel>;

  @belongsTo('rbac-role')
  rbacRole!: AsyncBelongsTo<RbacRoleModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'rbac-customer-role': RbacCustomerRoleModel;
  }
}
