import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { DateRange } from '../../utils/range';
import { instrumentRoutePerformance } from '@sentry/ember';
import moment from 'moment';

const dontRefreshModel = { refreshModel: false };
const refreshModel = { refreshModel: true };

const statuses = [
  { key: 'all', value: 'All' },
  { key: 'pending', value: 'Pending' },
  { key: 'partially-delivered', value: 'Partially Delivered' },
  { key: 'completed', value: 'Completed' },
];

export const sortProperties = [
  { key: 'createdAt', value: 'Date' },
  { key: 'id', value: 'Order #' },
  { key: 'status', value: 'Status' },
  { key: 'shippingType', value: 'Delivery' },
  { key: 'purchaseOrder', value: 'PO #' },
  { key: 'total', value: 'Extended Price' },
];

class StoreAccountHistoryOrdersRoute extends Route {
  @service session;
  @service store;
  @service router;

  titleToken = 'Orders and POD';

  queryParams = {
    query: refreshModel,
    range: refreshModel,
    sortAscending: dontRefreshModel,
    sortProperty: dontRefreshModel,
    status: refreshModel,
  };
  async beforeModel(transition) {
    if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }

    const customer = await this.session.get('customer');

    if (customer) {
      if ((!customer?.hasCustomerFinancial) && (!customer?.hasCustomerDefault) && (customer?.hasRbacCustomer)) {
        this.router.transitionTo('store.customer', customer.id);
      } 
      return await customer.get('account');  
    }
  }


  async model({
    query = '',
    range,
    sortAscending = '',
    sortProperty = 'id',
    status = 'all',
  }) {
    const customer = this.session.get('customer');
    const account = this.session.get('customerRecord.account.id');
    const sort = sortAscending ? `-${sortProperty}` : sortProperty;
    const _status = (statuses.find(({ key }) => key === status) || statuses[0])
      .value;

    let dateRange;
    if (range === undefined) {
      if (account === '1000910') {
        const endsAt = new Date();
        const startsAt = moment().subtract(7, 'days').toDate();
        const startingDate = startsAt.toISOString();
        const endingDate = endsAt.toISOString();
        const dateRangeFormat = `${startingDate}..${endingDate}`;

        dateRange = new DateRange(dateRangeFormat);
      } else {
        dateRange = new DateRange(range);
      }
    } else {
      dateRange = new DateRange(range);
    }

    const filter = {
      ...dateRange.toFilter(),
      'account-id': account,
      status: _status,
      'purchase-order': query,
    };

    const accountHistoryAllOrders = await this.store.query(
      'account-history-all-order',
      {
        filter,
        sort,
      }
    );

    return {
      accountHistoryAllOrders,
      dateRange,
      query,
      sortAscending: !!sortAscending,
      sortProperties,
      sortProperty,
      status,
      statuses,
      customer,
    };
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.sortProperty = model.sortProperty;
    controller.sortAscending = model.sortAscending;
  }
}
export default instrumentRoutePerformance(StoreAccountHistoryOrdersRoute);
