import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class QuickOrderPropertyModel extends Model {
  @attr('string') value;

  @belongsTo('quick-order-property-key') quickOrderPropertyKey;

  @hasMany('quick-order-property-group') quickOrderPropertyGroups;
  @hasMany('quick-order-products', { inverse: null }) quickOrderProductsByTitle;
  @hasMany('quick-order-products', { inverse: null }) quickOrderProductsBySize;
  @hasMany('quick-order-products', { inverse: null }) quickOrderProductsByColor;
}
