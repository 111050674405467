import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class AdminManageCustomerRoute extends Route {
  @service store;
  @service session;

  async model({ customer_id }) {
    const login_customer = await this.session.get('customer');

    // if (login_customer) {
    //   if (login_customer?.hasRbacAdminMarketing) {
    //     window.location.href = '/store/customers/'+login_customer.id;
    //   } 
    // }

    const customer = await this.store.findRecord('customer', customer_id, {
      include: [
        'customer-notes',
        'customer-notes.creator',
        'customer-notes.customer',
        'service-center',
        'rbac-account-customer-roles',
        'rbac-account-customer-roles.account',
        // 'rbac-account-customer-roles.rbac-account-roles',
        // 'rbac-customer-roles',
        // 'rbac-customer-roles.rbac-role'
      ].join(','),
    });

    const permissions = await this.store.findAll('rbac-permission');
    const role = await this.store.findAll('rbac-role');

    return {
      customer,
      permissions,
      role,
      login_customer,
    };
  }
}


export default instrumentRoutePerformance(AdminManageCustomerRoute);
