import Controller from '@ember/controller';
import { action } from '@ember/object';
import type ObjectProxy from '@ember/object/proxy';
import { inject as service } from '@ember/service';
import { EXCEPTION_1071 } from 'shoelace/exceptions/mod';
import type Account from 'shoelace/models/account';
import type Classification from 'shoelace/models/classification';
//import type RollbarService from 'shoelace/services/rollbar';

export default class AdminAccountCatalogsController extends Controller {
  //@service declare rollbar: RollbarService;

  @action async onCreateCatalog(
    catalog: Classification & ObjectProxy<Classification>,
    account: Account & ObjectProxy<Account>,
  ): Promise<void> {
    try {
      catalog.set('account', account);
      catalog.set('parentId', 3167);
      catalog.save();

      location.reload();
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1071, error);
      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/account/catalogs': AdminAccountCatalogsController;
  }
}
