import ProtectedRoute from '../../mixins/protected-route';
import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { baseJobAccounts, sortProperties } from './balance';
import { group } from '../../helpers/group';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreBillPayRoute extends Route {
  @service store;

  async model({ id }) {
    const accountOpenItemReceipts = await this.store.query(
      'account-open-item-receipt',
      {
        filter: { id },
        include: [
          'account',
          'customer',
          'account-open-items',
          'account-open-items.account-job-account',
        ].join(','),
      }
    );

    const accountOpenItemReceipt = accountOpenItemReceipts.firstObject;

    try {
      await accountOpenItemReceipt.save();
    } catch (error) {
      console.error('save', 'accountOpenItemReceipt', error);
    }

    const meta = accountOpenItemReceipts.meta;
    const velocityId = meta?.velocity?.id || '';
    const velocityUrl = meta?.velocity?.url || '';

    const accountOpenItems = await accountOpenItemReceipt.accountOpenItems;

    const jobAccounts = baseJobAccounts.concat(
      accountOpenItems
        .mapBy('accountJobAccount.id')
        .uniq()
        .sort()
        .reverse()
        .filter((id) => isPresent(id))
        .map((key) => {
          return {
            key,
            filter(item) {
              return item.accountJobAccount.id === this.key;
            },
          };
        })
    );

    const groups = group('accountJobAccount.id', accountOpenItems);
    const sortDirection = null;
    const sortProperty = null;

    return {
      accountOpenItemReceipt,
      accountOpenItems,
      groups,
      jobAccounts,
      sortDirection,
      sortProperties,
      sortProperty,
      velocityId,
      velocityUrl,
    };
  }
}
export default instrumentRoutePerformance(StoreBillPayRoute);
