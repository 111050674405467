import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreServicesMyProjectsProjectRoute extends Route {
  @service store;
 
  async model({ 'account-project_id': accountProjectId }) {
    return await this.store.findRecord('account-project', accountProjectId);
  }
}

export default instrumentRoutePerformance(
  StoreServicesMyProjectsProjectRoute
);