import { schedule , run as emberRunLoop } from '@ember/runloop';
import { mergeAndConcat } from 'merge-anything';
import { defer } from 'rsvp';
import ApplicationAdapter from './application';

const productIDKey = 'product-id';
const run = emberRunLoop.backburner;

export default class ProductPriceAdapter extends ApplicationAdapter {
  pendingQueries = new Map();

  decodeCompositeId(id) {
    return String(id).split('-')[2];
  }

  query(store, type, query) {
    const resolver = defer(`Query ${type} with ${JSON.stringify(query)}`);
    const pendingQuery = {
      query,
      resolver,
    };

    const promise = resolver.promise;

    if (this.pendingQueries.size === 0) {
      schedule('actions', this, this.flushAllPendingQueries);
    }

    if (!this.pendingQueries.has(type)) {
      this.pendingQueries.set(type, []);
    }

    this.pendingQueries.get(type).push(pendingQuery);

    return promise;
  }

  flushAllPendingQueries() {
    this.pendingQueries.forEach(this.flushAllPendingQueriesForType, this);
    this.pendingQueries.clear();
  }

  flushAllPendingQueriesForType(pendingQueries, type) {
    const queries = pendingQueries.map(({ query }) => {
      const filter = { ...query.filter };
      const include = query.include;

      Object.keys(filter).forEach(key => {
        if (!Array.isArray(filter[key])) {
          filter[key] = [filter[key]];
        }
      });

      if (include) {
        return {
          filter,
          include: include.split(',').map(s => s.trim()),
        };
      } else {
        return { filter };
      }
    });

    let query = mergeAndConcat(...queries);
    let url = this.buildURL(type.modelName, null, null, 'query', query);

    if (this.sortQueryParams) {
      query = this.sortQueryParams(query);
    }

    Object.keys(query.filter).forEach(key => {
      if (key === 'quantity') {
        query.filter[key] = query.filter[key].join(',');
      } else {
        query.filter[key] = query.filter[key].uniq().join(',');
      }
    });

    if (query.include) {
      query.include = query.include.uniq().join(',');
    }

    this.ajax(url, 'GET', { data: query }).then(response => {
      pendingQueries.forEach(pendingQuery => {
        const { query, resolver } = pendingQuery;
        const productID = String(query.filter[productIDKey] ?? '');
        const data = response.data.filter(data => {
          return productID === this.decodeCompositeId(data?.id ?? '');
        });

        resolver.resolve(Object.assign({}, { ...response, data: [...data] }));
      });
    });
  }
}
