import ApplicationSerializer, { exclude } from './application';

export default class CustomerSerializer extends ApplicationSerializer {
  attrs = {
    accounts: exclude,
    aclAccountPermissions: exclude,
    aclPermissions: exclude,
    acls: exclude,
    lastSearch: exclude,
    isInternal: exclude,
    hasRbacAdmin: exclude,
    hasRbacAdminCredit: exclude,
    hasRbacAdminMarketing: exclude,
    hasRbacAssociate: exclude,
    hasCustomerDefault: exclude,
    hasCustomerFinancial: exclude,
    hasCustomerProjects: exclude,
    hasCustomerOrdering: exclude,
    hasCustomerPunchout: exclude,
    hasCustomerLineItemShipto: exclude,
    hasRbacCustomer: exclude,
    hasRbacCustomerAdmin: exclude,
    hasCustomerManageUser: exclude,
    hasCustomerRestrictionsServiceCenter:exclude,
    hasCustomerRemoveRealTimeInventory: exclude,
    hasRbacCustomerCheckout: exclude,
    hasRbacCustomerRole: exclude,
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    customer: CustomerSerializer;
  }
}
