import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import type AccountAddressModel from 'shoelace/models/account-address';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type AccountContractModel from 'shoelace/models/account-contract';
import type ClassificationModel from 'shoelace/models/classification';
import type CustomerModel from 'shoelace/models/customer';
import type RbacAccountCustomerPermissionModel from 'shoelace/models/rbac-account-customer-permission';
import type RbacAccountCustomerRoleModel from 'shoelace/models/rbac-account-customer-role';
import type RbacAccountPermissionModel from 'shoelace/models/rbac-account-permission';
import type RoleModel from 'shoelace/models/role';
import type ServiceCenterModel from 'shoelace/models/service-center';

export default class AccountModel extends Model {
  @attr('string') address!: null | string;
  @attr('string') billingAddress!: null | string;
  @attr('string') billingZip!: null | string;
  @attr('string') city!: null | string;
  @attr('string') country!: null | string;
  @attr('string') county!: null | string;
  @attr('string') email!: null | string;
  @attr('string') fax!: null | string;
  @attr('string') faxExt!: null | string;
  @attr('string') imagePreviewUrl!: null | string;
  @attr('string') imageSignedId!: null | string;
  @attr('string') imageUrl!: null | string;
  @attr('boolean') isNameVisible!: null | boolean;
  @attr('string') name!: null | string;
  @attr('string') nameOverride!: null | string;
  @attr('string') phone!: null | string;
  @attr('string') phoneExt!: null | string;
  @attr('string') state!: null | string;
  @attr('string') zip!: null | string;

  @belongsTo('account-status')
  accountStatus: any;

  @belongsTo('service-center')
  serviceCenter!: AsyncBelongsTo<ServiceCenterModel>;

  @hasMany('account-address')
  accountAddresses!: AsyncHasMany<AccountAddressModel>;

  @hasMany('account-quote')
  accountQuotes: any;

  @hasMany('classification')
  classifications!: AsyncHasMany<ClassificationModel>;

  @hasMany('customer-note')
  customerNotes: any;

  @hasMany('customer', { inverse: null })
  customers!: AsyncHasMany<CustomerModel>;

  @hasMany('role')
  roles!: AsyncHasMany<RoleModel>;

  @hasMany('rbac-account-customer-permission')
  rbacAccountCustomerPermissions!: AsyncHasMany<RbacAccountCustomerPermissionModel>;

  @hasMany('rbac-account-customer-role')
  rbacAccountCustomerRoles!: AsyncHasMany<RbacAccountCustomerRoleModel>;

  @hasMany('rbac-account-permission')
  rbacAccountPermissions!: AsyncHasMany<RbacAccountPermissionModel>;

  // @hasMany('rbac-account-role')
  // rbacAccountRoles!: AsyncHasMany<RbacAccountRoleModel>;

  @hasMany('account-job-account')
  accountJobAccounts!: AsyncHasMany<AccountJobAccountModel>;

  @hasMany('account-contract')
  accountContracts!: AsyncHasMany<AccountContractModel>;

  @alias('id')
  accountNumber!: null | string;

  @alias('id')
  account_number!: null | string;

  @computed('name')
  get isCOD(): boolean {
    return (this.name ?? '').match(/^(closed )?cod /i) !== null;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    account: AccountModel;
  }
}
