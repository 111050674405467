import Model, { attr } from '@ember-data/model';

export default class AccountHistoryInvoiceQuoteModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('number') number!: null | number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-invoice-quote': AccountHistoryInvoiceQuoteModel;
  }
}
