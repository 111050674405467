import type StoreService from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type AccountVerification from 'shoelace/models/account-verification';
import type NoticeService from 'shoelace/services/notice';

const clone = (record: AccountVerification) => {
  const attrs = record.changedAttributes();
  return Object.keys(attrs).reduce((record, key) => {
    record[key] = attrs[key]?.[1];
    return record;
  }, {} as Record<string, unknown>);
};

export default class AdminManageCustomerAddAccountVerificationController extends Controller {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare notice: NoticeService;

  @tracked isValidated = false;

  @action async validate(
    accountVerification: AccountVerification,
  ): Promise<void> {
    await accountVerification.validate();
    this.isValidated = true;
  }

  @action async onAddingAccountToCustomer(
    accountVerification: AccountVerification,
    accounts: string[],
  ): Promise<void> {
    try {
      const customer = await accountVerification.get('customer');

      await customer.save();

      if (accounts.length) {
        await Promise.all(
          accounts.map(account => {
            const duped = clone(accountVerification);
            duped.customer = accountVerification.get('customer');
            duped.accountNumber = account;
            duped.accountVerificationStatusId = 3;

            const verif = this.store.createRecord('account-verification', duped);
            // can't approve until verification is created
            // so save, then set approved, then save (update) again
            return verif.save().then(() => {
              verif.set('status', 'approved');
              verif.set('approvedAccountNumber', verif.get('accountNumber'));
              return verif.save();
            });
          }),
        );
        accountVerification.deleteRecord();
      } else {
        await accountVerification.save();
      }
      // (await accountVerification.get('customer')).get('roles').reload();

      await this.notice.success('Customer was added to the account(s).');

      this.router.transitionTo(
        'admin.manage-customers',
      );
    } catch (error) {
      throw error;
    } finally {
      this.isValidated = false;
    }
  }

  @action onSuccess(accountVerification: AccountVerification): void {
    this.router.transitionTo(
      'admin.customer',
      accountVerification.get('customer').get('id'),
    );
  }
}
declare module '@ember/controller' {
  interface Registry {
    'admin/manage-customer/add-account-verification': AdminManageCustomerAddAccountVerificationController;
  }
}
