import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { DateRange } from '../../utils/range';
import { instrumentRoutePerformance } from '@sentry/ember';

const dontRefreshModel = { refreshModel: false };
const refreshModel = { refreshModel: true };

export const sortProperties = [
  { key: 'createdAt', value: 'Date' },
  { key: 'id', value: 'Return #' },
  { key: 'purchaseOrder', value: 'PO #' },
  { key: 'total', value: 'Extended Price' },
];

class StoreAccountHistoryReturnsRoute extends Route {
  @service session;
  @service store;
  @service router;

  titleToken = 'Returns and POD';

  queryParams = {
    query: refreshModel,
    range: refreshModel,
    sortAscending: dontRefreshModel,
    sortProperty: dontRefreshModel,
  };

  async beforeModel(transition) {
    if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }
    const customer = await this.session.get('customerRecord');

    if (customer) {
      if ((!customer?.hasCustomerFinancial) && (!customer?.hasCustomerDefault) && (customer?.hasRbacCustomer)) {
        this.router.transitionTo('store.customer', customer.id);
      } 
      return await customer.get('account');
    }
  }

  model({ query = '', range, sortAscending = '', sortProperty = 'createdAt' }) {
    const customer = this.session.get('customer');
    const account = this.session.get('customerRecord.account.id');
    const dateRange = new DateRange(range);
    const sort = sortAscending ? `-${sortProperty}` : sortProperty;

    const filter = {
      ...dateRange.toFilter(),
      'account-id': account,
      'purchase-order': query,
    };

    const accountHistoryReturns = this.store.query('account-history-return', {
      filter,
      sort,
    });

    return {
      accountHistoryReturns,
      dateRange,
      query,
      customer,
      sortAscending: !!sortAscending,
      sortProperties,
      sortProperty,
    };
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.sortProperty = model.sortProperty;
    controller.sortAscending = model.sortAscending;
  }
}
export default instrumentRoutePerformance(StoreAccountHistoryReturnsRoute);
