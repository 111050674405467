import Service, { inject as service } from '@ember/service';
import type CustomerModel from 'shoelace/models/customer';
import type { BootOptions } from 'shoelace/services/coordinator';
import type SessionService from 'shoelace/services/session';

export default class GoogleAnalyticsService extends Service {
  @service declare session: SessionService;

  identify(customer?: CustomerModel): void {
    if ('ga' in window) {
      // eslint-disable-next-line no-undef
      ga('set', 'userId', customer?.id);
    }
  }

  async onCoordinatorSetup(options?: BootOptions): Promise<void> {
    if (options?.customer) {
      this.identify(options.customer);
    } else if (this.session.customer) {
      this.identify(this.session.customer);
    }
  }

  async onCoordinatorTeardown(): Promise<void> {
    this.identify();
  }
}

declare module '@ember/service' {
  interface Registry {
    'google-analytics': GoogleAnalyticsService;
  }
}
