import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type CustomerModel from 'shoelace/models/customer';
import type RbacPermissionModel from 'shoelace/models/rbac-permission';

export default class RbacCustomerPermissionModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('date') updatedAt!: null | Date;

  @belongsTo('customer')
  customer!: AsyncBelongsTo<CustomerModel>;

  @belongsTo('rbac-permission')
  rbacPermission!: AsyncBelongsTo<RbacPermissionModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'rbac-customer-permission': RbacCustomerPermissionModel;
  }
}
