import { computed } from '@ember/object';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

function asPromiseObject(promise) {
  return ObjectPromiseProxy.create({ promise });
}

export default class ManufacturerDataComponent extends Component {
  @service store;

  @tracked id = null;
  @tracked manufactuers = null;

  @computed('id', 'manufacturers', 'store')
  get model() {
    const id = this.id;
    const manufacturers = this.manufacturers;
    return asPromiseObject(
      new Promise(done => {
        if (id) {
          const r = this.store.peekRecord('manufacturer', this.id);
          if (r) {
            return done(r);
          } else if (manufacturers) {
            return done(manufacturers.toArray().filter(m => m.esID == id)[0]);
          } else {
            this.store.findRecord('manufacturer', this.id).then(done);
          }
        } else {
          return null;
        }
      }),
    );
  }
}
