import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { refreshModel } from '../../../utils/query-params';
import { instrumentRoutePerformance } from '@sentry/ember';
import type RouterService from '@ember/routing/router';
import SessionService from 'shoelace/services/-session';

export interface RouteParams {
  page: string | number;
  size: string | number;
  sort: string | number;
}

class AdminManageCustomersIndexRoute extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService;
  @service declare store: StoreService;
  
  queryParams = {
    page: refreshModel,
    size: refreshModel,
    sort: refreshModel,
  };

  async model({ page: number, size, sort }: RouteParams) {
    const customer = await this.session.get('customer');

    let customers = await this.store.query('customer', {
      filter: { customer_account: customer?.id },
      page: {
        number,
        size,
      },
      sort,
    });

    return {
      customers,
    };
  }
}

export default instrumentRoutePerformance(AdminManageCustomersIndexRoute);

