import type StoreService from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import type ProductModel from 'shoelace/models/product';
import { AsyncObject, ObjectPromiseProxy } from '../utils/async-helpers';

const MAX_CACHE_AGE_SECONDS = 60 * 5;

export default class ProductService extends Service {
  @service declare store: StoreService;

  async getProductStrict(id: number | string): Promise<ProductModel> {
    const cachedProduct = this.store.peekRecord('product', id);

    if (cachedProduct && !this.isCacheExpired(cachedProduct)) {
      return cachedProduct;
    } else {
      const product = await this.store.findRecord('product', id);

      product.cachedAt = new Date();

      return product;
    }
  }

  isCacheExpired(ProductModel?: ProductModel): boolean {
    if (!ProductModel) {
      return true;
    } else {
      const now = new Date().getTime();
      const cachedAt = ProductModel?.cachedAt?.getTime() ?? 0;
      const diff = (now - cachedAt) / 1000;

      return diff > MAX_CACHE_AGE_SECONDS;
    }
  }

  getProduct(
    product: number | string | ProductModel,
  ): ProductModel | ObjectPromiseProxy<ProductModel> {
    const id =
      typeof product === 'number' || typeof product === 'string'
        ? product
        : product?.get('id');

    const cachedProduct = this.store.peekRecord('product', id);

    if (cachedProduct) {
      return cachedProduct;
    } else {
      const product = this.store.findRecord('product', id);

      return AsyncObject(product);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    product: ProductService;
  }
}
