import Route from '@ember/routing/route';
import { refreshModel } from '../../../../utils/query-params';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

const decision = 'null';

class StoreServicesMyProjectsIndexRoute extends Route {
  @service store;

  queryParams = {
    page: refreshModel,
    size: refreshModel,
  };

  async model({ page: number, size }) {
    const { accountProjectsIds, accountProjects, customerId } = this.modelFor(
      'store.services.my-projects',
    );

    const accountProjectsPaginated = await this.store.query('account-project', {
      page: {
        number,
        size,
      },
    });

    const accountProjectReleaseSigners = await this.store.query(
      'account-project-release-signer',
      {
        filter: {
          'account-project-release.account-project': accountProjectsIds.join(','),
          customer: customerId,
          decision,
        },
      },
    );

    const pendingAccountProjectReleases = await this.store.query(
      'account-project-release',
      {
        filter: {
          'account-project': accountProjectsIds.join(','),
          status: 'pending',
        },
      },
    );

    const newAccountProjectReleases = await this.store.query(
      'account-project-release',
      {
        filter: {
          'account-project': accountProjectsIds.join(','),
          customer: customerId,
          status: 'new',
        },
      },
    );

    return {
      accountProjects,
      accountProjectsPaginated,
      accountProjectReleaseSigners,
      pendingAccountProjectReleases,
      newAccountProjectReleases,
    };
  }
}

export default instrumentRoutePerformance(
  StoreServicesMyProjectsIndexRoute
);