import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class CategoriesController extends Controller {
  queryParams = [
    'manufacturers',
    'sub_class',
    'sub_class1',
    'classification',
    'entire_query',
    'service_centers',
    'catalog',
    'prevbuy',
    'product_includes',
  ];

  @tracked manufacturers = '';
  @tracked catalog = '';
  @tracked classification = '';
  @tracked sub_class = '';
  @tracked sub_class1 = '';
  @tracked service_centers_id = '';
  @tracked entire_query = false;
  @tracked product_includes = false;
  @tracked prevbuy = false;
}
declare module '@ember/controller' {
  interface Registry {
    categories: CategoriesController;
  }
}
