import Helper from '@ember/component/helper';

export default Helper.extend({
  compute(params) {
    return params.reduce(function (array, val) {
      if ('toArray' in array) {
        return array.concat(val.toArray());
      } else {
        return array.concat(val);
      }
    }, []);
  },
});
