import Helper from '@ember/component/helper';
import { labels } from '../order_dictionary';
export default Helper.extend({
  compute([label, code]) {
    const l = labels[label.toUpperCase()][code];
    if (l) {
      return l;
    } else {
      throw new Error(`Undefined kind ${label}:${code}`);
    }
  },
});
