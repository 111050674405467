import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type CategoryModel from 'shoelace/models/category';
import type ClassificationPermissionModel from 'shoelace/models/classification-permission';
import type ProductModel from 'shoelace/models/product';
import { tracked } from '@glimmer/tracking';

export default class Classification extends Model {
  @attr('string') name!: null | string;
  @attr('string') url!: null | string;
  @attr('string') categoryPath!: null | string;
  @attr('string') parentId!: null | string;
  // @attr('string') parentName!: null | string;
  @attr('string') stiboIdentifier!: null | string;
  @attr('boolean') isDefault!: null | boolean;

  @belongsTo('category', { inverse: null })
  category!: AsyncBelongsTo<CategoryModel>;

  @belongsTo('account', { inverse: null })
  account!: AsyncBelongsTo<AccountModel>;

  @hasMany('product', { inverse: null })
  products!: AsyncHasMany<ProductModel>;

  @hasMany('category', { inverse: null })
  categories!: AsyncHasMany<CategoryModel>;

  @hasMany('classification-permission')
  classificationPermissions!: AsyncHasMany<ClassificationPermissionModel>;

  @tracked isAddingCustomer?: string;
  @tracked isAddingProduct?: string;
  @tracked isShowingProduct?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    classification: Classification;
  }
}
