import Route from '@ember/routing/route';
import { v1 } from 'uuid';
import type ServiceCenter from 'shoelace/services/service-center';
import type SessionService from 'shoelace/services/session';
import type RouterService from '@ember/routing/router';
import OrderingDictionary from '../../order_dictionary';
import AccountHistoryQuoteModel from 'shoelace/models/account-history-quote';
import ProcurementModel from 'shoelace/models/procurement';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface RouteParams {
  quoteNoId: string | number;
  procurementPo: string | number;
}

export interface StoreReviewQuoteOrderModel {
  quote: AccountHistoryQuoteModel | null;
  id: string;
  procurement: ProcurementModel;
  po: string | null;
}

class ReviewQuoteOrderRoute extends Route {
  @service declare store: StoreService;
  @service declare serviceCenter: ServiceCenter;
  @service declare session: SessionService;
  @service declare router: RouterService;

  async model({
    quoteNoId,
    procurementPo,
  }: RouteParams): Promise<StoreReviewQuoteOrderModel> {
    const id = v1();
    const quote = await this.store.findRecord(
      'account-history-quote',
      quoteNoId
    );
    const customer = await this.session.customer;
    const account = customer !== null ? await customer?.account : null;
    const serviceCenter = this.serviceCenter.serviceCenter;
    const procurement = await this.store.createRecord('procurement', {
      id,
      account,
      customer,
      serviceCenter,
      purchaseOrder: procurementPo,
      confirmedAt: new Date(),
      kind: OrderingDictionary.KIND.QUOTETOORDER,
      inputReferences: {
        quote_id: quoteNoId,
      },
    });

    const po = procurement.purchaseOrder;

    return {
      quote,
      id,
      procurement,
      po,
    };
  }
}
export default instrumentRoutePerformance(ReviewQuoteOrderRoute);
