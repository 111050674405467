import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import type SweetAlertService from 'ember-sweetalert/services/swal';
import type { SweetAlertResult } from 'sweetalert2';

export default class NoticeService extends Service {
  @service declare swal: SweetAlertService;

  async success(message: string): Promise<SweetAlertResult<boolean>>;
  async success(
    title: string,
    message: string,
  ): Promise<SweetAlertResult<boolean>>;
  @action async success(
    title: string,
    message?: string,
  ): Promise<SweetAlertResult<boolean>> {
    return await this.swal.fire(title, message, 'success');
  }

  async error(message: string): Promise<SweetAlertResult<boolean>>;
  async error(title: string, message: string): Promise<SweetAlertResult<boolean>>;
  @action async error(
    title: string,
    message?: string,
  ): Promise<SweetAlertResult<boolean>> {
    return await this.swal.fire(title, message, 'error');
  }

  async warning(message: string): Promise<SweetAlertResult<boolean>>;
  async warning(
    title: string,
    message: string,
  ): Promise<SweetAlertResult<boolean>>;
  @action async warning(
    title: string,
    message?: string,
  ): Promise<SweetAlertResult<boolean>> {
    return await this.swal.fire(title, message, 'warning');
  }

  async info(message: string): Promise<SweetAlertResult<boolean>>;
  async info(title: string, message: string): Promise<SweetAlertResult<boolean>>;
  @action async info(
    title: string,
    message?: string,
  ): Promise<SweetAlertResult<boolean>> {
    return await this.swal.fire(title, message, 'info');
  }

  async confirm(message: string): Promise<SweetAlertResult<boolean>>;
  async confirm(
    title: string,
    message: string,
  ): Promise<SweetAlertResult<boolean>>;
  @action async confirm(
    title: string,
    message?: string,
  ): Promise<SweetAlertResult<boolean>> {
    return await this.swal.fire({
      html: message,
      icon: 'question',
      showCancelButton: true,
      title,
    });
  }

  async prompt(title: string): Promise<SweetAlertResult<string>>;
  async prompt(title: string, message: string): Promise<SweetAlertResult<string>>;
  async prompt(
    title: string,
    message: string,
    placeholder: string,
  ): Promise<SweetAlertResult<string>>;
  async prompt(
    title: string,
    message: string,
    placeholder: string,
    defaultValue: string,
  ): Promise<SweetAlertResult<string>>;
  @action async prompt(
    title: string,
    message?: string,
    placeholder?: string,
    defaultValue?: string,
  ): Promise<SweetAlertResult<string>> {
    return await this.swal.fire({
      icon: 'question',
      input: 'text',
      inputLabel: message,
      inputPlaceholder: placeholder ?? '',
      inputValue: defaultValue ?? '',
      showCancelButton: true,
      title,
    });
  }

  @action async toast(
    title: string,
    timer?: number,
  ): Promise<SweetAlertResult<boolean>> {
    return await this.swal.fire({
      icon: 'success',
      position: 'top-end',
      showConfirmButton: true,
      timer: timer ?? 7500,
      toast: true,
      title,
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    notice: NoticeService;
  }
}
