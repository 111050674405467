import type StoreService from '@ember-data/store';
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import type CanService from 'ember-can/services/can';
import type { Storage } from 'ember-local-storage';
import { storageFor } from 'ember-local-storage';
import { EXCEPTION_1074 } from 'shoelace/exceptions/mod';
import type ServiceCenterModel from 'shoelace/models/service-center';
import type { BootOptions } from 'shoelace/services/coordinator';
import type KartService from 'shoelace/services/kart';
import type MetricsService from 'shoelace/services/metrics';
//import type RollbarService from 'shoelace/services/rollbar';
import type ServiceCentersService from 'shoelace/services/service-centers';
import type SessionService from 'shoelace/services/session';
import ProcurementService from './procurement';

function tryParseInt(value: null | number | string | undefined): null | number {
  if (isEmpty(value) || value === null || value === undefined) {
    return null;
  }

  if (typeof value === 'number') {
    return value;
  }

  return parseInt(`${value}`, 10);
}

interface ServiceCenterStorage {
  id?: null | string;
}

export default class ServiceCenterService extends Service {
  @service declare can: CanService;
  @service declare serviceCenters: ServiceCentersService;
  @service declare session: SessionService;
  @service declare store: StoreService;
  @service declare kart: KartService;
  @service declare metrics: MetricsService;
  @service declare procurement: ProcurementService;
  //@service declare rollbar: RollbarService;

  @tracked isRunning = false;
  @tracked serviceCenter?: ServiceCenterModel;

  @storageFor('service-center')
  localStorageServiceCenter!: Storage<ServiceCenterStorage>;

  get id(): number | string | undefined {
    if (this.serviceCenter?.get?.('id') || this.localStorageServiceCenter?.get?.('id')) {
      return (
        this.localStorageServiceCenter?.get?.('id') ??        
        this.serviceCenter?.get?.('id') ??
        this.serviceCenters?.nearest?.get?.('id')
      );
    } else {
      return (
        this.serviceCenter?.get?.('id') ??
        this.serviceCenters?.nearest?.get?.('id')
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async onCoordinatorSetup(_options?: BootOptions): Promise<void> {
    try {
      if (this.id) {
        if (this.id === 34) {
          await this.setById(14);
        }
        if (this.id === 47) {
          await this.setById(3);
        }
        if (this.id === 37) {
          await this.setById(15);
        } else {
          await this.setById(this.id);
        }
      } else {
        await this.setByNearest();
      }
    } catch (error) {
      console.log(error);
      //this.rollbar.error(EXCEPTION_1074, error);
      await this.setByNearest();
    }
  }

  async onCoordinatorTeardown(): Promise<void> {
    return;
  }

  async setByNearest(): Promise<void> {
    await this.serviceCenters.load();

    if (this.serviceCenters.nearest?.get?.('id')) {
      await this.setById(this.serviceCenters.nearest.get('id'));
    }
  }

  @action async setById(id?: number | string): Promise<void> {
    if (id) {
      this.serviceCenter = await this.getById(id);
      this.localStorageServiceCenter.set('id', this.serviceCenter?.get?.('id'));

      if (this.serviceCenter) {
        this.session.customer?.set?.('serviceCenter', this.serviceCenter);
        this.procurement.procurement?.set?.('serviceCenter', this.serviceCenter);
        this.kart.setServiceCenter(this.serviceCenter);
        this.metrics.events.select_content({ service_center: id });
      }
    }
  }

  async getById(
    id: number | string | undefined = this.id,
  ): Promise<ServiceCenterModel | undefined> {
    if (id) {
      return await this.store.findRecord('service-center', id);
    }

    return undefined;
  }
}

declare module '@ember/service' {
  interface Registry {
    'service-center': ServiceCenterService;
  }
}
