import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AccountProjectOrderLineItemModel from 'shoelace/models/account-project-order-line-item';
import type AccountProjectOrderLineItemDeliveryAttributeModel from 'shoelace/models/account-project-order-line-item-delivery-attribute';

export default class AccountProjectOrderLineItemDeliveryModel extends Model {
  @attr('number') deliveredQuantity!: null | number;
  @attr('number') deliveredQuantityMeters!: null | number;
  @attr('number') priorityDelivery!: null | number;
  @attr('number') priorityLineItem!: null | number;
  @attr('number') releasedQuantity!: null | number;
  @attr('number') releasedQuantityMeters!: null | number;
  @attr('number') sapDeliveryId!: null | number;
  @attr('boolean') isLinked!: null | boolean;

  @belongsTo('account-project-order-line-item')
  accountProjectOrderLineItem!: AsyncBelongsTo<AccountProjectOrderLineItemModel>;

  @hasMany('account-project-order-line-item-delivery-attribute')
  accountProjectOrderLineItemDeliveryAttributes!: AsyncHasMany<AccountProjectOrderLineItemDeliveryAttributeModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-project-order-line-item-delivery': AccountProjectOrderLineItemDeliveryModel;
  }
}
