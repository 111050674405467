import moment from 'moment';

/**
 * Provides some date utility methods
 *
 * @module date
 */

/**
 * Advance by number of dates based on the index correlating to current day
 *
 * @for date
 * @property advanceOffsets
 * @type Array
 * @readOnly
 */
const advanceOffsets = [1, 0, 0, 0, 0, 0, 2];

/**
 * Returns today's date or the next business day
 *
 * @for date
 * @method todayOrNextBusinessDay
 * @return {Date} Today or next business day
 */
export function todayOrNextBusinessDay(date) {
  if (date instanceof Date) {
    date = moment(date);
  }

  const advanceBy = advanceOffsets[date.day()];

  return date.add(advanceBy, 'd').toDate();
}
