import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type AccountPaymentModel from 'shoelace/models/account-payment';

export default class AccountPaymentLineItemModel extends Model {
  @attr('date') clearedAt!: null | Date;
  @attr('number') accountInvoiceId!: null | number;
  @attr('number') total!: null | number;
  @attr('string') poNumber!: null | string;
  @attr('boolean') isCredit!: null | boolean;
  @attr('boolean') isDebit!: null | boolean;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-payment')
  accountPayment!: AsyncBelongsTo<AccountPaymentModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-payment-line-item': AccountPaymentLineItemModel;
  }
}
