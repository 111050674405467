import Route from '@ember/routing/route';
import type TranslationModel from 'shoelace/models/translation';
import { instrumentRoutePerformance } from '@sentry/ember';
import type RouterService from '@ember/routing/router';
import SessionService from 'shoelace/services/-session';
import { service } from '@ember/service';

export interface RouteModel {
  translation: TranslationModel;
}

class AdminDynamicTranslationIndexRouteModel extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService;

  async model(): Promise<RouteModel> {
    const customer = await this.session.get('customer');

    if (customer) {
      if (customer?.hasRbacAdminCredit || customer?.hasRbacCustomerAdmin) {
        this.router.transitionTo('store.customer', customer.id);
      } 
    }

    const translation = (await this.modelFor(
      'admin.dynamic-translation'
    )) as TranslationModel;

    await translation?.translation?.reload?.(); // must reload to avoid bug where parent (translation.translation) does not load

    return {
      translation,
    };
  }
}

export default instrumentRoutePerformance(
  AdminDynamicTranslationIndexRouteModel
);
