import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and (has-block \"trigger\") (has-block))}}\n  <span {{on \"click\" this.showNotice}} ...attributes>\n    {{yield to=\"trigger\"}}\n  </span>\n  <div class=\"hidden\" {{did-insert this.onDidInsert}}>\n    {{yield}}\n  </div>\n{{/if}}", {"contents":"{{#if (and (has-block \"trigger\") (has-block))}}\n  <span {{on \"click\" this.showNotice}} ...attributes>\n    {{yield to=\"trigger\"}}\n  </span>\n  <div class=\"hidden\" {{did-insert this.onDidInsert}}>\n    {{yield}}\n  </div>\n{{/if}}","moduleName":"shoelace/components/apex/notice-info.hbs","parseOptions":{"srcName":"shoelace/components/apex/notice-info.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type NoticeService from 'shoelace/services/notice';

interface ApexNoticeArgs {
  data: string;
  title?: string;
}

export default class ApexNotice extends Component<ApexNoticeArgs> {
@service declare notice: NoticeService;

  @tracked data = this.args.data ?? '';
  @tracked title = this.args.title ?? '';

  @action async showNotice() {
    this.notice.info(this.title, this.data);
  }

  @action async onDidInsert(element: HTMLElement) {
    this.data = element?.innerHTML;
  }
}
