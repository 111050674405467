import StoreService from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  EXCEPTION_1056,
  EXCEPTION_1057,
  EXCEPTION_1059,
} from 'shoelace/exceptions/mod';
import type AccountService from 'shoelace/services/account';
import type KartService from 'shoelace/services/kart';
import NoticeService from 'shoelace/services/notice';
import type ProcurementService from 'shoelace/services/procurement';
//import type RollbarService from 'shoelace/services/rollbar';
import type SessionService from 'shoelace/services/session';
import jwt from 'shoelace/utils/jwt';

export default class SecureCheckoutBillingController extends Controller {
@service declare store: StoreService;
@service declare session: SessionService;
@service declare account: AccountService;
@service declare kart: KartService;
@service declare procurement: ProcurementService;
@service declare router: RouterService;
@service declare notice: NoticeService;
//@service declare rollbar: RollbarService;

  @tracked isValidated = false;
  @tracked isValidatingForReview = false;
  @tracked isValidatingForEmailExist = false;
  splitName(fullName: string): {
    first: string;
    last?: string;
  } {
    const full = fullName?.trim()?.split(' ');
    const last = full?.length > 1 ? full?.pop() : undefined;
    const first = full?.join(' ')?.trim();

    return { first, last };
  }

  @action async isEmailExists(email: string): Promise<boolean> {
    try {
        const attributes = {
        email: email
      };

     const response =  await fetch('/api/account_update_details/get_email_exist', {
              body: JSON.stringify({ data: { attributes } }),
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
              },
            })
      if(response.status == 200){
        this.isValidatingForEmailExist = false;
        return false;
      }else{
        this.isValidatingForEmailExist = true;
        return true;
      }
      
    } catch (error) {
      //this.rollbar.warning(EXCEPTION_1056, error);
      this.isValidatingForEmailExist = true;
      return error.errors?.find((e: any) => {
        return JSON.parse(e.detail).error === 'Email - has already been taken';
      });
    }
  }

  @action async onValidateForReview() {
    try {
      if (this.isValidatingForEmailExist || await this.isEmailExists(this.procurement.procurement?.guestEmail)){
        this.notice.error(`Email already registered. Please Log In to continue`);
        this.isValidatingForEmailExist = true;
      }else{

      this.isValidatingForEmailExist = false;
      this.isValidated = true;
      this.isValidatingForReview = true;

      await this.kart.save.perform();

      const { validations } = this.procurement.procurement
        ? await this.procurement.procurement.validate()
        : { validations: [] };

      if (validations?.isValid) {
        if (this.procurement.procurement?.paymentMethod !== 'credit_card') {
          this.procurement.procurement?.clearCreditCardFields();
        }

        await this.procurement.save();

        if (!this.session.customer || this.procurement.procurement?.isGuest) {
          await this.authenticate(this.procurement.procurement?.guestToken);
        }

        if (
          this.procurement.procurement?.isGuest ||
          this.procurement.procurement?.isCod
        ) {
          const account = await this.procurement.procurement?.account;

          this.account.account = account;

          await this.account.loadValidAccountAddresses(); // force loading new account-address from SAP
          await this.kart.setAccount(account);
        }

        this.router.transitionTo('secure-checkout.review');

        this.isValidated = false;
      } else {
        // //this.rollbar.warning(EXCEPTION_1058, validations);
      }
    }
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1057, error);

      throw error;
    } finally {
      this.isValidatingForReview = false;
      this.isValidatingForEmailExist = false;
    }
  }

  @action async authenticate(token: string | null | undefined) {
    try {
      await this.session.authenticate(
        'authenticator:as',
        token,
        jwt(token).customer_id,
      );
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1059, error);

      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'secure-checkout/billing': SecureCheckoutBillingController;
  }
}
