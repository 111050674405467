import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { EXCEPTION_1005 } from 'shoelace/exceptions/mod';
import jwt from '../../../utils/jwt';
import { refreshModel } from '../../../utils/query-params';
import { storageFor } from 'ember-local-storage';

export default class PunchoutsPo2goPickPlantRoute extends Route {
  @service store;
  @service session;
  @service procurement;
  @service serviceCenter;
  
  @storageFor('customer') storage;
  @storageFor('service-center') localStorageServiceCenter;

  queryParams = {
    search: refreshModel,
  };

  async model({ search: name }) {
    const customer = this.session.customer;
    const accountIds = jwt(this.session.token).punchout?.accounts;
    const serviceCenter = customer.serviceCenter;

    this.storage?.set('name', customer?.get?.('account.name'));
    this.storage?.set('id', customer?.id);
    this.storage?.set('accountId', customer?.get?.('account.id'));
    

    if (isEmpty(accountIds)) {
      const error = new Error('punchout token has empty accounts array');

      //this.rollbar.error(EXCEPTION_1005, error);

      throw error;
    } else {
      const accounts = await this.store.query('account', {
        filter: {
          id: accountIds.join(','),
          name,
        },
        include: ['service-center'].join(','),
        page: {
          size: accountIds.length,
        },
      });

      const account = customer.get('account');
      this.localStorageServiceCenter?.set('id',account.get('serviceCenter.id'));

      // force creating a new procurement to ensure all data has been loaded
      this.procurement.procurement = undefined;
      await this.procurement.createRecord();

      return {
        accounts,
        account,
        customer,
      };
    }
  }
}
