import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';
import type ProductModel from 'shoelace/models/product';

const Validations = buildValidations({
  customerName: {
    description: 'Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Name must not be blank.',
      }),
    ],
  },

  email: {
    description: 'From Email Address',
    validators: [
      validator('format', {
        type: 'email',
        allowNonTld: false,
        message: 'Your email must not be blank.',
      }),
    ],
  },

  suggestedName: {
    description: 'Suggested Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Suggested Name field must not be blank.',
      }),
    ],
  },
});

export default class ProductNicknameModel extends Model.extend(Validations) {
  @attr('string')
  declare customerName?: string;

  @attr('string')
  declare email?: string;

  @attr('string')
  declare suggestedName?: string;

  @belongsTo('product')
  product!: AsyncBelongsTo<ProductModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'product-nickname': ProductNicknameModel;
  }
}
