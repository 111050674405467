import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type SessionService from 'shoelace/services/-session';
import type KartService from 'shoelace/services/kart';
import type ProcurementService from 'shoelace/services/procurement';
import type RouterService from '@ember/routing/router';
import { instrumentRoutePerformance } from '@sentry/ember';
import type NoticeService from 'shoelace/services/notice';

export interface SecureCheckoutBillingRouteModel {
  id?: number | string;
}

export interface SecureCheckoutBillingRouteParams {
  id?: number | string;
}

class SecureCheckoutBillingRoute extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService;
  @service declare kart: KartService;
  @service declare procurement: ProcurementService;
  @service declare notice: NoticeService;



  async model({
    id,
  }: SecureCheckoutBillingRouteParams): Promise<SecureCheckoutBillingRouteModel> {
    await this.notice.success(
      `Sorry you dont have permission to procees checkout `,
      `In the meantime, if you have any questions regarding checkout the product, please call +18554044800 or email us at info@summit.com and we will be happy to help!`,
    );
    return {
      id,
    };
  }
}

export default instrumentRoutePerformance(SecureCheckoutBillingRoute);
