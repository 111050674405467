import type CartLineItemModel from 'shoelace/models/cart-line-item';
import type { AsyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany } from '@ember-data/model';

export default class FeaturedListModel extends Model {
  @attr('string') title!: null | string;

  @hasMany('cart-line-item')
  declare cartLineItems?: AsyncHasMany<CartLineItemModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'featured-list': FeaturedListModel;
  }
}
