import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreAccountHistoryReturnRoute extends Route {
  @service session;
  @service store;
  @service router;

  async beforeModel(transition) {
     if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }
  }

  async model({ id }) {
    const customer = this.session.get('customer');
    const accountHistoryReturn = await this.store.findRecord(
      'account-history-return',
      id,
      {
        include: [
          'account-history-return-bill-address',
          'account-history-return-fund-address',
          'account-history-return-ship-address',
          'account-history-return-sold-address',
          'account-history-return-comments',
          'account-history-return-line-items',
          'account-history-return-line-items.account-history-return-line-item-comments',
          'account-history-return-line-items.account-history-return-line-item-deliveries',
          'account-history-return-line-items.account-history-return-line-item-invoices',
          'account-history-return-line-items.account-history-return-line-item-orders',
          'account-history-return-line-items.account-history-return-line-item-quotes',
          'account-history-return-deliveries',
          'account-history-return-invoices',
          'account-history-return-orders',
          'account-history-return-quotes',
        ].join(','),
      },
    );
    return {
      customer,
      accountHistoryReturn,
    };
  }
}
export default instrumentRoutePerformance(
  StoreAccountHistoryReturnRoute
);