import { tracked } from '@glimmer/tracking';
import moment from 'moment';

export default class Range {
  @tracked end;
  @tracked start;

  constructor(start, end) {
    this.start = start;
    this.end = end;
  }

  toString() {
    return `${this.start}..${this.end}`;
  }
}

export class DateRange extends Range {
  constructor() {
    if (arguments.length === 1 && typeof arguments[0] === 'string') {
      const [startString, endString] = arguments[0].split('..');

      super(new Date(Date.parse(startString)), new Date(Date.parse(endString)));
    } else if (arguments.length <= 1) {
      super(moment().subtract(30, 'days').toDate(), new Date());
    } else {
      super(...arguments);
    }
  }

  toFilter(startAs = 'start-date', endAs = 'end-date') {
    return {
      [endAs]: this.end.toISOString(),
      [startAs]: this.start.toISOString(),
    };
  }

  toString() {
    return `${this.start}..${this.end}`;
  }
}
