import { helper } from '@ember/component/helper';
import { assert } from '@ember/debug';
import { tracked } from '@glimmer/tracking';

// Credit: https://v5.chriskrycho.com/journal/async-data-and-autotracking-in-ember-octane/

type Data<Value> =
  | { state: 'LOADING' }
  | { state: 'LOADED'; value: Value }
  | { state: 'ERROR'; error: unknown };

export class AsyncData<Value, Err = unknown> {
  @tracked private _data: Data<Value> = { state: 'LOADING' };

  get data(): Data<Value> {
    return this._data;
  }

  get state(): Data<Value>['state'] {
    return this._data.state;
  }

  get value(): Value {
    assert(
      `cannot get 'value' with state ${this._data.state}`,
      this._data.state === 'LOADED',
    );

    return this._data.value;
  }

  get error(): unknown {
    assert(
      `cannot get 'reason' with state ${this._data.state}`,
      this._data.state === 'ERROR',
    );

    return this._data.error;
  }

  get isLoading(): boolean {
    return this._data.state === 'LOADING';
  }

  get isLoaded(): boolean {
    return this._data.state === 'LOADED';
  }

  get isError(): boolean {
    return this._data.state === 'ERROR';
  }

  resolveWith(value: Value): void {
    this._data = { state: 'LOADED', value };
  }

  rejectWith(error: Err): void {
    this._data = { state: 'ERROR', error };
  }
}

const MAP: WeakMap<Promise<unknown>, AsyncData<unknown>> = new WeakMap();

export function load<Value>(somePromise: Promise<Value>): AsyncData<Value> {
  const existingAsyncData = MAP.get(somePromise);
  if (existingAsyncData) {
    // SAFETY: this cast only holds because we *know* that we've
    // kept the `Promise` and the `AsyncData` instances in sync
    // via the `WeakMap`. If that were not the case, this cast
    // would be `unsafe`.
    return existingAsyncData as unknown as AsyncData<Value>;
  }

  // SAFETY: this only holds because we are working with
  // `Promise<Value>`.
  const asyncData = new AsyncData<Value>();
  MAP.set(somePromise, asyncData);

  somePromise.then(
    value => asyncData.resolveWith(value),
    error => asyncData.rejectWith(error),
  );

  return asyncData;
}

export default helper(([somePromise]: [Promise<unknown>]) => load(somePromise));
