import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import type AccountModel from 'shoelace/models/account';
import type AccountHistoryInvoiceBillAddressModel from 'shoelace/models/account-history-invoice-bill-address';
import type AccountHistoryInvoiceCommentModel from 'shoelace/models/account-history-invoice-comment';
import type AccountHistoryInvoiceDeliveryModel from 'shoelace/models/account-history-invoice-delivery';
import type AccountHistoryInvoiceFundAddressModel from 'shoelace/models/account-history-invoice-fund-address';
import type AccountHistoryInvoiceLineItemModel from 'shoelace/models/account-history-invoice-line-item';
import type AccountHistoryInvoiceQuoteModel from 'shoelace/models/account-history-invoice-quote';
import type AccountHistoryInvoiceShipAddressModel from 'shoelace/models/account-history-invoice-ship-address';
import type AccountHistoryInvoiceSoldAddressModel from 'shoelace/models/account-history-invoice-sold-address';
import type AccountHistoryOrderModel from 'shoelace/models/account-history-order';
import type AccountHistoryReturnModel from 'shoelace/models/account-history-return';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type { ObjectPromiseProxy } from 'shoelace/utils/async-helpers';
import { AsyncObject } from 'shoelace/utils/async-helpers';

async function head(url: string): Promise<number> {
  return (await fetch(url, { method: 'HEAD' })).status;
}

async function hasInvoicePdf(url: string): Promise<boolean> {
  try {
    return (await head(url)) === 200;
  } catch (_) {
    return false;
  }
}

export default class AccountHistoryInvoiceModel extends Model {
  @attr('date') clearedAt!: null | Date;
  @attr('date') createdAt!: null | Date;
  @attr('number') discount!: null | number;
  @attr('date') discountAt!: null | Date;
  @attr('date') dueAt!: null | Date;
  @attr('date') expectedAt!: null | Date;
  @attr('string') jobAccountName!: null | string;
  @attr('string') kind!: null | string;
  @attr('number') numberOfItems!: null | number;
  @attr('string') purchaseOrder!: null | string;
  @attr('number') salesTax!: null | number;
  @attr('string') shippingType!: null | string;
  @attr('string') status!: null | string;
  @attr('number') subtotal!: null | number;
  @attr('number') total!: null | number;
  @attr('string') accountHistoryOrderId!: null | string;
  @attr('string') accountHistoryReturnId!: null | string;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-job-account')
  accountJobAccount!: AsyncBelongsTo<AccountJobAccountModel>;

  @belongsTo('account-history-invoice-bill-address')
  accountHistoryInvoiceBillAddress!: AsyncBelongsTo<AccountHistoryInvoiceBillAddressModel>;

  @belongsTo('account-history-invoice-fund-address')
  accountHistoryInvoiceFundAddress!: AsyncBelongsTo<AccountHistoryInvoiceFundAddressModel>;

  @belongsTo('account-history-invoice-ship-address')
  accountHistoryInvoiceShipAddress!: AsyncBelongsTo<AccountHistoryInvoiceShipAddressModel>;

  @belongsTo('account-history-invoice-sold-address')
  accountHistoryInvoiceSoldAddress!: AsyncBelongsTo<AccountHistoryInvoiceSoldAddressModel>;

  @belongsTo('account-history-order')
  accountHistoryOrder!: AsyncBelongsTo<AccountHistoryOrderModel>;

  @belongsTo('account-history-return')
  accountHistoryReturn!: AsyncBelongsTo<AccountHistoryReturnModel>;

  @hasMany('account-history-invoice-comment')
  accountHistoryInvoiceComments!: AsyncHasMany<AccountHistoryInvoiceCommentModel>;

  @hasMany('account-history-invoice-line-item')
  accountHistoryInvoiceLineItems!: AsyncHasMany<AccountHistoryInvoiceLineItemModel>;

  @hasMany('account-history-invoice-delivery')
  accountHistoryInvoiceDeliveries!: AsyncHasMany<AccountHistoryInvoiceDeliveryModel>;

  @hasMany('account-history-invoice-quote')
  accountHistoryInvoiceQuotes!: AsyncHasMany<AccountHistoryInvoiceQuoteModel>;

  @computed('id')
  // eslint-disable-next-line @typescript-eslint/ban-types
  get hasInvoicePdf(): ObjectPromiseProxy<object> {
    return AsyncObject(
      hasInvoicePdf(
        `/api/documents/${this.id}`,
        // eslint-disable-next-line @typescript-eslint/ban-types
      ) as unknown as Promise<object>,
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-invoice': AccountHistoryInvoiceModel;
  }
}
