import Route from '@ember/routing/route';
import { refreshModel } from '../../../../../utils/query-params';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreServicesMyProjectsReleasesIndexRoute extends Route {
  @service store;
  
  queryParams = {
    page: refreshModel,
    project: refreshModel,
    search: refreshModel,
    size: refreshModel,
    sort: refreshModel,
  };

  async model({ project, page: number, search, size, sort }) {
    const { accountProjects, accountProjectsIds } = this.modelFor(
      'store.services.my-projects',
    );

    const accountProjectReleases = await this.store.query(
      'account-project-release',
      {
        filter: {
          'account-project': project ?? accountProjectsIds.join(','),
          search,
        },
        include: ['account-project', 'customer'].join(','),
        page: {
          number,
          size,
        },
        sort,
      },
    );

    return {
      accountProjectReleases,
      accountProjects,
    };
  }
}
export default instrumentRoutePerformance(
  StoreServicesMyProjectsReleasesIndexRoute
);