import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import StoreService from '@ember-data/store';
import ProcurementModel from 'shoelace/models/procurement';
import { instrumentRoutePerformance } from '@sentry/ember';
import type ProcurementService from 'shoelace/services/procurement';
import type RouterService from '@ember/routing/router';
import MetricsService from 'shoelace/services/metrics';

const { TimeoutError } = DS;
const MAX_RETRIES = 60;

export interface SecureCheckoutProcessingRouteModel {
  id?: number | string;
  procurement: ProcurementModel;
}

export interface SecureCheckoutProcessingRouteParams {
  id?: number | string;
}

class SecureCheckoutProcessingRoute extends Route {
  @service declare procurement: ProcurementService;
  @service declare router: RouterService;
  @service declare metrics: MetricsService;
  @service declare store: StoreService;

  async beforeModel() {
    if (this.procurement.procurement?.isNew) {
      return this.router.transitionTo('secure-checkout.billing');
    }
  }

  async model({
    id,
  }: SecureCheckoutProcessingRouteParams): Promise<SecureCheckoutProcessingRouteModel> {
    const procurement = this.procurement.procurement!;
    let retries = 0;

    procurement.confirmedAt = new Date();
    
    // const cartProducts = await Promise.all(
    //   procurement.cart.items.map((li) => this.store.findRecord('product', li.id))
    // );

    // const items = cartProducts.map((lineItem: any, index: any) => {
    //   return this.metrics.productAsEcommerceItem(
    //     lineItem,
    //     procurement.cart.items[index].quantity,
    //     procurement?.externalResult?.sap_create_simulated_order[0].ORDER_ITEMS_OUT[index].NET_VALUE1,
    //     index,
    //     'purchase'
    //   );
    // });

    // this.metrics.events.ecommerce.purchase({
    //   currency: 'USD',
    //   value: procurement.total ?? 0,
    //   transaction_id: String(procurement?.id),
    //   affiliation: "Summit Electric Supply",
    //   items: items
    // })

    await procurement?.save();

    const worker = async () => {
      try {
        await procurement.reload();

        const id = this.procurement.procurement?.id;

        if (procurement.get('procurementState') == 'error') {
          if (procurement.get('hasAuthorizationErrorKind')) {
            this.router.transitionTo('secure-checkout.billing');
          } else {
            this.router.transitionTo('secure-checkout.error');
          }
        } else if (retries++ > MAX_RETRIES) {
          this.router.transitionTo('secure-checkout.error');
        } else if (procurement.get('procurementState') == 'success') {
          this.router.transitionTo('secure-checkout.confirmation');
        } else {
          setTimeout(worker, 1000);
        }
      } catch (error) {
        if (error instanceof TimeoutError) {
          this.router.transitionTo('secure-checkout.confirmation');
        } else {
          this.router.transitionTo('secure-checkout.error');
        }
      }
    };

    worker();

    return {
      id,
      procurement,
    };
  }
}
export default instrumentRoutePerformance(SecureCheckoutProcessingRoute);
