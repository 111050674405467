import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<source srcset=\"{{this.url}} 1x\" type=\"image/webp\" media=\"(max-width: 608px)\" />\n<source srcset=\"{{this.url}} 3x\" type=\"image/webp\" media=\"(min-width: 608px)\" />\n<img\n  src=\"{{this.url}}\"\n  width=\"245\"\n  height=\"177\"\n  class=\"h-auto w-auto\"\n  alt=\"Image slide\"\n  {{on \"error\" this.onError}}\n  onerror=\"this.onerror=null;\n          this.src='https://s3.amazonaws.com/summit-website-assets/images/Product-Image-Not-Available-1.jpg'\"\n  ...attributes\n/>\n", {"contents":"<source srcset=\"{{this.url}} 1x\" type=\"image/webp\" media=\"(max-width: 608px)\" />\n<source srcset=\"{{this.url}} 3x\" type=\"image/webp\" media=\"(min-width: 608px)\" />\n<img\n  src=\"{{this.url}}\"\n  width=\"245\"\n  height=\"177\"\n  class=\"h-auto w-auto\"\n  alt=\"Image slide\"\n  {{on \"error\" this.onError}}\n  onerror=\"this.onerror=null;\n          this.src='https://s3.amazonaws.com/summit-website-assets/images/Product-Image-Not-Available-1.jpg'\"\n  ...attributes\n/>\n","moduleName":"shoelace/components/apex/image.hbs","parseOptions":{"srcName":"shoelace/components/apex/image.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import config from 'shoelace/config/environment';

export interface ApexExistingImageArgs {
  url: string;
  fallback?: string;
}

export default class ApexExistingImageComponent extends Component<ApexExistingImageArgs> {
  @tracked fallback = this.args.fallback ?? config.defaults.invalidImageUrl;

  get url() {
    return this.args.url ?? this.fallback;
  }

  @action onError(event) {
    try {
      event.target.src = this.fallback;
    } catch (error) {
      console.error('Error loading image', error);
    }
  }
}
