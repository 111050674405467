import type { AsyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import type GrantModel from 'shoelace/models/grant';

export default class PermissionModel extends Model {
  @attr('string') resource!: null | string;
  @attr('string') action!: null | string;

  @hasMany('grant')
  grants!: AsyncHasMany<GrantModel>;

  @computed('action', 'resource')
  get name(): string {
    return `${this.resource}::${this.action}`;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    permission: PermissionModel;
  }
}
