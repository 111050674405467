import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class StoreAccountHistoryOrdersController extends Controller {
  queryParams = ['query', 'range', 'sortAscending', 'sortProperty', 'status'];

  @tracked query;
  @tracked range;
  @tracked sortProperty;
  @tracked status;
  @tracked sortAscending;

  get sort() {
    if (this.sortAscending) {
      return `${this.sortProperty}`;
    } else {
      return `${this.sortProperty}:desc`;
    }
  }

  @action onRange(dateRange) {
    this.range = dateRange;
  }
}
