import Mixin from '@ember/object/mixin';
import RSVP from 'rsvp';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
// import { CanMixin } from 'ember-can';
import { isPresent } from '@ember/utils';

export const errors = A([401, 404, 408, 500, 502, 503, 504]);

export default Mixin.create({
  session: service(),
  ensureAbilities: [],
  ensureAbility: null,

  beforeModel() {
    const ensureAbilities = this.ensureAbilities;
    const ensureAbility = this.ensureAbility;
    const session = this.session;
    const isUnauthenticated = session.isUnauthenticated;

    const abilities = ensureAbilities
      .concat(ensureAbility)
      .filter(ability => isPresent(ability));

    let isUnauthorized = false;

    if (isPresent(abilities)) {
      isUnauthorized = abilities.every(ability => this.cannot(ability));
    }

    if (isUnauthenticated || isUnauthorized) {
      return RSVP.reject({ error: 401 });
    }
  },

  actions: {
    error(model) {
      const error = (model && model['error']) || null;

      if (errors.includes(error)) {
        this.intermediateTransitionTo('error', model);
      } else {
        this.intermediateTransitionTo('error');
      }
    },
  },
});
