import Route from '@ember/routing/route';
import { refreshModel } from '../../../../../utils/query-params';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreServicesMyProjectsReleaseIndexRoute extends Route {
  @service store;
  
  queryParams = {
    page: refreshModel,
    search: refreshModel,
    size: refreshModel,
    sort: refreshModel,
  };

  async model({ page: number, search, size, sort }) {
    const { accountProjects } = this.modelFor('store.services.my-projects');
    const accountProjectRelease = this.modelFor(
      'store.services.my-projects.release',
    );
    const accountProjectReleaseId = accountProjectRelease.get('id');

    const accountProjectReleaseLineItems = await this.store.query(
      'account-project-release-line-item',
      {
        filter: {
          'account-project-release': accountProjectReleaseId,
          search,
        },
        include: [
          'account-project-order-line-item',
          'account-project-order-line-item.account-order-line-item',
          'account-project-order-line-item.account-order-line-item.product',
        ].join(','),
        page: {
          number,
          size,
        },
        sort,
      },
    );

    return {
      accountProjectRelease,
      accountProjectReleaseLineItems,
      accountProjects,
    };
  }
}
export default instrumentRoutePerformance(
  StoreServicesMyProjectsReleaseIndexRoute
);