import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';

const Validations = buildValidations({
  address: {
    description: 'Address',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Address is Required',
      }),
      validator('length', {
        max: 60,
        message: 'Address cannot be more than {max} characters.',
      }),
    ],
  },
  city: {
    description: 'City',
    validators: [
      validator('presence', {
        presence: true,
        message: 'City is Required',
      }),
      validator('length', {
        max: 40,
        message: 'City cannot be more than {max} characters.',
      }),
    ],
  },
  state: {
    description: 'State',
    validators: [
      validator('presence', {
        presence: true,
        message: 'State is Required',
      }),
      validator('length', {
        min: 2,
        max: 3,
        message:
          'State cannot be more than {max} characters and less than {min}.',
      }),
    ],
  },
  zip: {
    description: 'Zip',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Zip is Required',
      }),
      validator('length', {
        max: 10,
        message: 'Zip cannot be more than {max} characters.',
      }),
    ],
  },
});

export default class AccountAddressModel extends Model.extend(Validations) {
  @attr('string') name!: null | string;
  @attr('string') address!: null | string;
  @attr('string') city!: null | string;
  @attr('string') state!: null | string;
  @attr('string') zip!: null | string;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-job-account')
  accountJobAccount!: AsyncBelongsTo<AccountJobAccountModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-address': AccountAddressModel;
  }
}
