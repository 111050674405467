import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.outputRichData}}\n  {{#in-element this.inElement insertBefore=null}}\n    <script type=\"application/ld+json\">\n      {{this.outputRichData}}\n    </script>\n  {{/in-element}}\n{{/if}}", {"contents":"{{#if this.outputRichData}}\n  {{#in-element this.inElement insertBefore=null}}\n    <script type=\"application/ld+json\">\n      {{this.outputRichData}}\n    </script>\n  {{/in-element}}\n{{/if}}","moduleName":"shoelace/components/product/rich-data.hbs","parseOptions":{"srcName":"shoelace/components/product/rich-data.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ProductModel from 'shoelace/models/product';
import ServiceCenterModel from 'shoelace/models/service-center';

interface ProductRichDataComponentArgs {
  product: ProductModel;
  serviceCenter?: ServiceCenterModel;
  price?: number;
}

export default class ProductRichDataComponent extends Component<ProductRichDataComponentArgs> {
  @tracked product: ProductModel = this.args.product;
  @tracked serviceCenter?: ServiceCenterModel = this.args.serviceCenter;
  @tracked price = this.args.price;

  get outputRichData() {
    const categories = [];
    const nameProduct = this.product.name;
    const descriptionProduct = this.product.description;

    categories.push({
      '@type': 'ListItem',
      position: 1,
      item: {
        '@type': 'Thing',
        '@id': 'https://www.summit.com',
        name: 'Home',
      },
    });

    if (this.args.product.categoryPath) {
      const categoryPath = this.args.product.categoryPath.split('/');
      let pathUrl = '';
      const baseUrl = 'https://www.summit.com/store/categories/';
      for (let i = 0; i < categoryPath.length; i++) {
        if (i > 0) {
          pathUrl += '/' + encodeURIComponent(categoryPath[i]);
        } else {
          pathUrl += encodeURIComponent(categoryPath[i]);
        }
        categories.push({
          '@type': 'ListItem',
          position: i + 2,
          item: {
            '@type': 'Thing',
            '@id': baseUrl + pathUrl,
            name: categoryPath[i],
          },
        });
      }
    }
    if (this.price && this.price != 0) {
      return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: nameProduct?.replace(/\W/g, ' '),
        url: 'https://www.summit.com/store/products/' + this.product.id,
        description: descriptionProduct?.replace(/\W/g, ' '),
        keywords: this.product.name,
        breadcrumb: {
          '@type': 'BreadcrumbList',
          itemListElement: categories,
        },
      });
    }
  }

  get inElement() {
    return document.head;
  }
}
