import Helper from '@ember/component/helper';
import { A } from '@ember/array';

export default Helper.extend({
  compute(params) {
    const [label] = params;
    const branches = (label || '')
      .replace(
        encodeURIComponent('Classification 1 root') +
          '/' +
          encodeURIComponent('Summit Hierarchy Root') +
          '/',
        '',
      )
      .split('/');

    return branches.map((branches, ix, all) => all.slice(0, 1 + ix).join('/'));
  },
});
