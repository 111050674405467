import Model from '@ember-data/model';
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  availableQuantity: attr('number'),
  availability: attr('string'),

  inStock: attr('boolean'),
  mixedStock: attr('boolean'),
  limitedStock: attr('boolean'),

  batchStocked: attr('boolean'),

  notAvailable: attr('boolean'),
  unknown: attr('boolean'),

  stocked: attr('boolean'),
  isNormallyStocked: attr('boolean'),

  qty: computed('availableQuantity', function () {
    if (this.availableQuantity <= 0) {
      return false;
    } else {
      return this.availableQuantity;
    }
  }),

  hasStock: computed('availableQuantity', function () {
    if (this.availableQuantity > 0) {
      return true;
    } else {
      return false;
    }
  })
});
