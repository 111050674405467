import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { belongsTo } from '@ember-data/model';
import { notEmpty } from '@ember/object/computed';
import type AccountModel from 'shoelace/models/account';
import type CustomerModel from 'shoelace/models/customer';

export default class SessionModel extends Model {
  @belongsTo('customer')
  customer!: AsyncBelongsTo<CustomerModel>;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  isAuthenticated = notEmpty('customer.id');
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    session: SessionModel;
  }
}
