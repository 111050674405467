import Model, { attr } from '@ember-data/model';

export default class AccountVerificationStatusModel extends Model {
  @attr('boolean') active!: null | boolean;
  @attr('boolean') approved!: null | boolean;
  @attr('date') createdAt!: null | Date;
  @attr('date') updatedAt!: null | Date;
  @attr('string') name!: null | string;

  get tag(): string {
    return (this.name ?? '').toLowerCase().replace(/\s/g, '-');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-verification-status': AccountVerificationStatusModel;
  }
}
