import { get } from '@ember/object';
import { typeOf } from '@ember/utils';

function _read(object, key, type) {
  if (type === 'class' || type === 'instance' || type === 'object') {
    return get(object, key);
  }

  return object;
}

export default function read(object, key) {
  return _read(object, key, typeOf(object));
}
