import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class StoreServicesMyProjectsReleasesNewController extends Controller {
  @service store;
  @service router;

  @tracked isValidated = false;

  @action async saveAccountProjectRelease(accountProjectRelease) {
    await accountProjectRelease.validate();

    try {
      this.isValidated = accountProjectRelease.validations.isValid;

      if (this.isValidated) {
        await accountProjectRelease.save();
        this.router.transitionTo(
          'store.services.my-projects.release',
          accountProjectRelease.id,
        );
      }
    } catch (error) {
      console.error('testing error', error);
    }
  }
}
