import { atob } from './base64';

function parse(string) {
  return JSON.parse(atob(string));
}

function generateToken(time = parseInt(new Date().getTime() / 1000)) {
  return {
    typ: 'JWT',
    alg: 'none',
    exp: time,
    iat: time,
    nbf: time,
  };
}

export default function jwt(string) {
  const token = generateToken();

  try {
    const [header, payload] = string.split('.');

    return {
      ...token,
      ...parse(header),
      ...parse(payload),
    };
  } catch (_) {}

  return token;
}
