import Model from '@ember-data/model';
import { attr } from '@ember-data/model';
import { not } from '@ember/object/computed';
import {
  validator,
  buildValidations,
} from '@summit-electric-supply/ember-cp-validations';

const Validations = buildValidations({
  firstName: {
    description: 'First Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'First name must not be blank.',
      }),
    ],
  },
  lastName: {
    description: 'Last Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Last name must not be blank.',
      }),
    ],
  },
  location: {
    description: 'Location',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Location must not be blank.',
      }),
    ],
  },
  phone: {
    description: 'Phone Number',
    validators: [
      validator('format', {
        type: 'phone',
        disabled: not('model.phoneRequired'),
      }),
    ],
  },
  email: {
    description: 'Email Address',
    validators: [
      validator('format', {
        type: 'email',
        allowNonTld: false,
      }),
    ],
  },
  contact_preference: {
    description: 'Contact Preference',
    validators: [validator('inclusion', { in: ['email', 'phone'] })],
  },
  quantity: [
    validator('presence', {
      presence: true,
      message: 'Please enter a quantity.',
    }),
  ],
  company: {
    description: 'Company',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Company must not be blank.',
      }),
    ],
  },
});

export default Model.extend(Validations, {
  first_name: attr('string'),
  last_name: attr('string'),
  company: attr('string'),
  location: attr('string'),
  email: attr('string'),
  phone: attr('string'),
  ext: attr('string'),
  contact_preference: attr('string'),
  quantity: attr('string'),
  message: attr('string'),
});
