import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type ServiceCenterModel from 'shoelace/models/service-center';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';

const validations = buildValidations({
  reason: {
    description: 'Service Center Day Off Reason',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Please type an event name.',
      }),
    ],
  },
  overrideDate: {
    description: 'Service Center Type Day off Date',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Please indicate date for event.',
      }),
    ],
  },
});

export default class ServiceCenterDateHourModel extends Model.extend(
  validations,
) {
  @attr('string')
  declare reason?: string;

  @attr('date')
  declare overrideDate?: Date;

  @belongsTo('service-center')
  serviceCenter!: AsyncBelongsTo<ServiceCenterModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'service-center-date-hour': ServiceCenterDateHourModel;
  }
}
