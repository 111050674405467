export default function (cb) {
  return cb.call();

  // const oldCookies = document.cookie;
  // document.cookie.split(";").forEach(function(c) {
  //   document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString());
  // });
  // const r = cb.call();
  // document.cookie = oldCookies;
  // return r;
}
