import type { AsyncBelongsTo } from '@ember-data/model';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import { EXCEPTION_1046 } from 'shoelace/exceptions/mod';
import type ServiceCenter from 'shoelace/models/service-center';
//import type RollbarService from 'shoelace/services/rollbar';

export default class AdminServiceCenterEditServiceCenterController extends Controller {
//@service declare rollbar: RollbarService;

@service declare router: RouterService;

  @action async onEditServiceCenter(
    serviceCenter: AsyncBelongsTo<ServiceCenter>,
  ): Promise<void> {
    try {
      const serviceCenterId = await serviceCenter.get('id');

      await serviceCenter.save();

      this.router.transitionTo('admin.service-center', serviceCenterId);
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1046, error);
      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/service-center/edit-service-center': AdminServiceCenterEditServiceCenterController;
  }
}
