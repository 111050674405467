import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { EXCEPTION_1008 } from 'shoelace/exceptions/mod';

export default class PunchoutsPo2goLandingController extends Controller {
  //@service rollbar;

  @action continueToSummit(pos) {
    try {
      const url = window.location.href.replace('landing', 'setup');

      top.location.replace(url);
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1008, error);

      throw error;
    }
  }
}
