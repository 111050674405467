import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreServicesMyProjectsReleaseSignersRoute extends Route {
  @service store;

  async model() {
    const accountProjectRelease = this.modelFor(
      'store.services.my-projects.release'
    );

    const id = await accountProjectRelease.get('accountProject.account.id');

    const account = await this.store.query('account', {
      filter: {
        id,
      },
      include: ['customers'].join(','),
      page: {
        number: 1,
        size: 100,
      },
    });

    const customers = await account?.firstObject?.get('customers');

    const approvals = await accountProjectRelease.get(
      'accountProjectReleaseSigners'
    );
    const approvedCustomers = await Promise.all(
      approvals.map((a) => a.get('customer'))
    );

    customers.forEach((customer) => {
      customer['isApprover'] = approvedCustomers.indexOf(customer) > -1;
    });

    const approvers = customers.filter((customer) => {
      // always return "real" customers
      if (customer.get('isInternal') == false) return true;
      // only return summit customers if a summit release
      if (accountProjectRelease.get('customer.isInternal')) return true;
      // return all approvers
      if (customer.get('isApprover')) return true;
      // should leave unused summit customers
      return false;
    });

    return {
      accountProjectRelease,
      approvers,
    };
  }
}
export default instrumentRoutePerformance(
  StoreServicesMyProjectsReleaseSignersRoute
);
