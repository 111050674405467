import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Apex::Catalog as |catalog|>\n  {{#if catalog.catalogs}}\n    <a class=\"pl-0\" ...attributes>\n      <div class=\"form-group\">\n        <select\n          class=\"form-control focus:outline-none outline-none bg-opacity-50 bg-white\"\n          {{on \"change\" (pick \"target.value\" this.pickCatalog)}}\n        >\n          <option value=\"default\" selected={{empty catalog.catalog}}>Summit\n            Default Catalog</option>\n          <option disabled>----------</option>\n          {{#each catalog.catalogs as |cat|}}\n            <option\n              value={{cat.id}}\n              selected={{eq catalog.id cat.id}}\n            >{{cat.name}}</option>\n          {{/each}}\n        </select>\n      </div>\n    </a>\n  {{/if}}\n</Apex::Catalog>\n", {"contents":"<Apex::Catalog as |catalog|>\n  {{#if catalog.catalogs}}\n    <a class=\"pl-0\" ...attributes>\n      <div class=\"form-group\">\n        <select\n          class=\"form-control focus:outline-none outline-none bg-opacity-50 bg-white\"\n          {{on \"change\" (pick \"target.value\" this.pickCatalog)}}\n        >\n          <option value=\"default\" selected={{empty catalog.catalog}}>Summit\n            Default Catalog</option>\n          <option disabled>----------</option>\n          {{#each catalog.catalogs as |cat|}}\n            <option\n              value={{cat.id}}\n              selected={{eq catalog.id cat.id}}\n            >{{cat.name}}</option>\n          {{/each}}\n        </select>\n      </div>\n    </a>\n  {{/if}}\n</Apex::Catalog>\n","moduleName":"shoelace/components/catalog-picker.hbs","parseOptions":{"srcName":"shoelace/components/catalog-picker.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import Classification from 'shoelace/models/classification';

export default class CatalogPickerComponent extends Component {
  @service catalog;
  @service router;

  @action async pickCatalog(catalog) {
    try {
      await this.catalog.setCatalog(catalog);

      // Reload product search list after changing catalog
      this.router.transitionTo('store.manufacturers.index');
      this.router.transitionTo(this.router.currentURL);
    } catch (error) {
      console.error('Error reloading page after switching catalog', error);
    }
  }
}
