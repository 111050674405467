import {
  validator,
  buildValidations,
} from '@summit-electric-supply/ember-cp-validations';
import Model, { attr } from '@ember-data/model';

const Validations = buildValidations({
  confirmationToken: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      message: 'Must have a confirmation token.',
    }),
  ],
});

export default class CustomerConfirmationModel extends Model.extend(Validations) {
  @attr('string') confirmationToken;
}
