import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import ResetPasswordModel from 'shoelace/models/reset-password';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router';
import fetch from 'fetch';

export default class ResetPasswordController extends Controller {
  @service declare router: RouterService;

  @tracked reset_password_token = '';

  queryParams = ['reset_password_token'];

  @action async onResest(model: ResetPasswordModel) {
    const options = {
      body: JSON.stringify({
        utf8: '✓',
        _method: 'PUT',
        authenticity_token:
          'O19J/vEERzHwMJwbZTmTGM6v0hnP7px66d11cJp7qHwPuFcKa8G4G3iuOQDKQcrafx8w0j6fNAdPFM90ltOKWA==',
        customer: {
          reset_password_token: model.get('reset_password_token'),
          passwordConfirmation: model.get('password'),
          password: model.get('password'),
        },
      }),

      cache: false,
      method: 'PUT',

      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    await fetch('/store/customers/password', options);
    await this.router.transitionTo('/');
  }
}
