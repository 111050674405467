import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.connect}}\n", {"contents":"{{this.connect}}\n","moduleName":"shoelace/components/zendesk-chat.hbs","parseOptions":{"srcName":"shoelace/components/zendesk-chat.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ZendeskChatComponent extends Component {
  @service media;

  get connect() {
    try {
      window?.$zoho?.salesiq?.chat?.start()
    } catch (_) {
      // noop
    }
  }

  @action onResize() {
  }
}
