import Model from '@ember-data/model';
import { attr } from '@ember-data/model';
import { computed, get } from '@ember/object';
import { belongsTo } from 'ember-data/relationships';
import { hasMany } from '@ember-data/model';
import { alias } from '@ember/object/computed';

export default Model.extend({
  firstName: attr('string'),
  lastName: attr('string'),
  email: attr('string'),
  officePhone: attr('string'),
  cellPhone: attr('string'),
  city: attr('string'),
  state: attr('string'),
  zip: attr('string'),
  acl: attr(),
  permissions: attr(),
  isInternal: attr('boolean'),

  accounts: hasMany('account', { inverse: null }),
  account: belongsTo('account', { inverse: null }),
  cart: belongsTo('cart', { inverse: null }),
  customerNotes: hasMany('customer-note'),
  createdAt: attr('date'),
  updatedAt: attr('date'),

  // jobAccount:   belongsTo('account-job-account', { async: false }),

  first_name: alias('firstName'),
  last_name: alias('lastName'),
  office_phone: alias('officePhone'),
  cell_phone: alias('cellPhone'),

  address1: attr(),
  address2: attr(),

  hasPermission(permission) {
    return (this.permissions || []).filter(p => p == permission).length > 0;
  },

  jobAccount: computed(
    'account.accountJobAccounts',
    'account.content.accountJobAccounts.[]',
    'activeJobAccountNumber',
    function () {
      const myJobAccount = this.activeJobAccountNumber;
      const selectedJA = (get(this, 'account.accountJobAccounts') || []).filter(
        ja => ja.id == myJobAccount,
      );
      return selectedJA[0] || null;
    },
  ),

  accountVerifications: hasMany('account-verification'),
  roles: hasMany('roles'),

  carts: hasMany('cart', { async: true, inverse: null }),

  name: computed('first_name', 'last_name', function () {
    let name = [this.first_name || '', this.last_name || ''];

    return name.join(' ');
  }),

  lists_link: computed('id', function () {
    return '/store/customers/' + this.id + '/lists';
  }),

  hasAccount: computed('accounts', function () {
    let accts = this.accounts;

    if (accts && accts.get('length') > 0) {
      return 1;
    } else {
      return 0;
    }
  }),
});
