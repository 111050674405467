import Controller from '@ember/controller';
import moment from 'moment';
import RSVP from 'rsvp';
import { action, computed, set, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

const slugify = name =>
  name
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace(/&|\(|\)|\/|,/g, '')
    .replace(/--/g, '-');

export default class AbstractDocumentsController extends Controller {
}

