import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Modifier, { ArgsFor, PositionalArgs, NamedArgs } from 'ember-modifier';
import type {
  FilePond,
  FilePondErrorDescription,
  FilePondFile,
  FilePondInitialFile,
  FilePondOptions,
  ProgressServerConfigFunction,
} from 'filepond';
import { create, registerPlugin } from 'filepond';
import FilepondPluginImagePreview from 'filepond-plugin-image-preview';
import ENV from 'shoelace/config/environment';
import type ActiveStorageService from 'shoelace/services/active-storage';
import type NoticeService from 'shoelace/services/notice';

const { 'ember-active-storage': emberActiveStorage } = ENV;

interface NeatSignature {
  Args: {
    Named: {
      files?: string | string[];
      onPush?: (id: string) => Promise<void> | void;
      onPop?: (id: string) => Promise<void> | void;
      onReorder?: (ids: string[]) => Promise<void> | void;
    };
    Positional: [string];
  };
}

export default class FilePondModifier extends Modifier<NeatSignature> {
  @service('active-storage') activeStorage!: ActiveStorageService;
  @service declare notice: NoticeService;

  constructor(owner: unknown, args: ArgsFor<NeatSignature>) {
    super(owner, args);
  }

  @tracked filesFiles?: string | string[];
  @tracked onPushFiles?: (id: string) => Promise<void> | void;
  @tracked onPopFiles?: (id: string) => Promise<void> | void;
  @tracked onReorderFiles?: (ids: string[]) => Promise<void> | void;

  instance?: FilePond | null;
  allowFilesSync = true;

  modify(
    element: Element,
    [lengthOfInput]: PositionalArgs<NeatSignature>,
    { files, onPush, onPop, onReorder }: NamedArgs<NeatSignature>
  ) {
    if (files === null) {
      this.filesFiles = [];
    } else {
      this.filesFiles = files;
    }
    this.onPushFiles = onPush;
    this.onPopFiles = onPop;
    this.onReorderFiles = onReorder;
    
    registerPlugin(FilepondPluginImagePreview);

    this.instance = create(element, this.options);

    this.instance.on('processfile', this.onPush.bind(this));
    this.instance.on('removefile', this.onPop.bind(this));
    this.instance.on('reorderfiles', this.onReorder.bind(this));
  }

  get allowReorder(): boolean {
    return this.onReorderFiles !== null || this.onReorderFiles !== undefined;
  }

  get files(): string[] {
    return typeof this.filesFiles === 'string' || Array.isArray(this.filesFiles)
      ? Array.isArray(this.filesFiles)
        ? this.filesFiles
        : [this.filesFiles]
      : [];
  }

  get initialFiles(): FilePondInitialFile[] {
    return this.files.map((source) => {
      return {
        source,
        options: {
          type: 'local',
        },
      };
    });
  }

  get options(): FilePondOptions {
    return {
      allowReorder: this.allowReorder,
      files: this.initialFiles,
      beforeRemoveFile: async () => {
        return (
          await this.notice.confirm('Remove Image?', 'This cannot be undone.')
        ).isConfirmed;
      },
      server: {
        headers: this.activeStorage.headers,
        revert: null,
        load: async (
          source: any,
          load: (blob: Blob) => void,
          error: (errorText: string) => void,
          progress: ProgressServerConfigFunction,
          abort: () => void
        ) => {
          try {
            progress(true, 0, 100);

            const response = await fetch(
              `/api/storage/blobs/redirect/${source}/image`
            );

            const blob = await response.blob();

            load(blob);

            progress(true, 100, 100);
          } catch (err) {
            this.notice.error((err as Error).message);
            error((err as Error).message);
          }

          return {
            abort: () => abort(),
          };
        },
        process: async (
          _fieldName: string,
          file: File,
          _metadata: Record<string, any>,
          load: (p: string | { [key: string]: any }) => void,
          error: (errorText: string) => void,
          progress: ProgressServerConfigFunction,
          abort: () => void
        ) => {
          try {
            const blob = await this.activeStorage.upload(
              file,
              emberActiveStorage?.url,
              {
                onProgress: (currentProgress: any) =>
                  progress(true, currentProgress, 100),
              }
            );

            if (blob.signedId) {
              load(blob.signedId);
            } else {
              throw new Error('blob signed id is blank');
            }
          } catch (err) {
            this.notice.error((err as Error).message);
            error((err as Error).message);
          }

          return {
            abort: () => abort(),
          };
        },
      },
    };
  }

  async onPush(
    error: FilePondErrorDescription,
    file: FilePondFile
  ): Promise<void> {
    try {
      if (this.allowFilesSync) {
        this.allowFilesSync = false;

        return await this.onPushFiles?.(file.serverId);
      }
    } catch (err) {
      this.notice.error((err as Error).message);
    }
  }

  async onPop(
    error: FilePondErrorDescription,
    file: FilePondFile
  ): Promise<void> {
    if (error) {
      this.notice.error(error.code.toString(), error.body);
    } else {
      try {
        if (this.allowFilesSync) {
          this.allowFilesSync = false;

          return await this.onPopFiles?.(file.serverId);
        }
      } catch (err) {
        this.notice.error((err as Error).message);
      }
    }
  }

  async onReorder(files: FilePondFile[]): Promise<void> {
    try {
      if (this.allowFilesSync) {
        this.allowFilesSync = false;

        return await this.onReorderFiles?.(files.map((file) => file.serverId));
      }
    } catch (err) {
      this.notice.error((err as Error).message);
    }
  }

  didUpdateArguments(): void {
    if (this.instance) {
      this.instance.setOptions(this.options);
      this.instance.onOnce('updatefiles', () => (this.allowFilesSync = true));
    }
  }

  willDestroy(): void {
    this.instance?.destroy();
    this.instance = null;
  }
}
