import Route from '@ember/routing/route';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreBillPaySuccessRoute extends Route {
  @service router;
  @service store;

  queryParams = {
    remittance_id: {
      refreshModel: true,
    },
  };

  async model({ remittance_id = null }) {
    if (isPresent(remittance_id)) {
      const accountOpenItemReceipts = await this.store.query(
        'account-open-item-receipt',
        {
          filter: {
            'remittance-id': remittance_id,
          },
        }
      );

      return accountOpenItemReceipts.get('firstObject.id');
    } else {
      return null;
    }
  }

  afterModel(id) {
    if (isPresent(id)) {
      return this.router.transitionTo('store.bill-pay.success', id);
    } else {
      return this.router.transitionTo('store.balance');
    }
  }
}
export default instrumentRoutePerformance(StoreBillPaySuccessRoute);
