import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
export default class StoreAccountHistoryStatementsController extends Controller {
  queryParams = ['range', 'sortProperty','accountHistoryStatements',"dateRange","customer","accountId"];


  @tracked range;
  @tracked sortProperty;
  @tracked accountHistoryStatements
  @tracked groups
  @tracked accountId



  @action onRange(dateRange) {
    this.range = dateRange;
  }
  
  @action onAccountId(id){
    this.accountId = id;
  }
}