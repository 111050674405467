import Route from '@ember/routing/route';
import { refreshModel } from '../../../../../utils/query-params';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreServicesMyProjectsProjectOrderRoute extends Route {
  @service store;
 
  async model({ 'account-project-order_id': accountProjectOrderId }) {
    return this.store.findRecord('account-project-order', accountProjectOrderId, {
      include: [
        'account-project',
        'account-project-order-line-items',
        'account-project-order-line-items.account-order-line-item',
        'account-project-order-line-items.account-order-line-item.product',
        'account-project-order-line-items.account-project-order',
        'account-project-order-line-items.account-project-order-line-item-deliveries',
        'account-project-order-line-items.account-project-order-line-item-deliveries.account-project-order-line-item',
        'account-project-order-line-items.account-project-order-line-item-deliveries.account-project-order-line-item-delivery-attributes',
        'account-project-order-line-items.account-project-order-line-item-deliveries.account-project-order-line-item-delivery-attributes.account-project-order-line-item-delivery-attribute-definition',
        'account-project-order-line-items.account-project-order-line-item-attributes',
        'account-project-order-line-items.account-project-order-line-item-attributes.account-project-order-line-item-attribute-definition',
      ].join(','),
      page: {
        number,
        size,
      },
      sort,
      reload: true,
    });
  }
}
export default instrumentRoutePerformance(
  StoreServicesMyProjectsProjectOrderRoute
);