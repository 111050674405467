import Route from '@ember/routing/route';
import type AccountModel from 'shoelace/models/account';
import type CustomerModel from 'shoelace/models/customer';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';
import RbacPermissionModel from 'shoelace/models/rbac-permission';
import type SessionService from 'shoelace/services/session';

export interface RouteModel {
  account: AccountModel;
  customer: CustomerModel;
  permissions: RbacPermissionModel[];
}

class AdminManageAccountNewCustomerRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;
 
  async model(): Promise<RouteModel> {
    const account = this.modelFor('admin.manage-account') as AccountModel;
    const customer = await this.store.createRecord('customer');

    const login_customer = await this.session.get('customer');
    customer.requestedPermissionsRequired = true;

    const allPermissions = await this.store.findAll('rbac-permission');
    const permissions = allPermissions.filter(permission =>
      permission?.get('name')?.includes('customer.')).filter(permission =>
        permission?.get('name') !== ('customer.punchout') && permission?.get('name') !== ('customer.projects')
      );
    
    return {
      account,
      permissions,
      customer,
      login_customer,
    };
  }
}
export default instrumentRoutePerformance(AdminManageAccountNewCustomerRoute);