import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type ServiceCenterService from 'shoelace/services/service-center';
import type SessionService from 'shoelace/services/session';
import StoreService from '@ember-data/store';
import type RouterService from '@ember/routing/router';

export default class StoreOrderConfirmationController extends Controller {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare serviceCenter: ServiceCenterService;

  @action async confirm(quoteNo: number, purchaseOrder: number) {
    try {
      this.router.transitionTo('store.review-quote-order', {
        queryParams: {
          quoteNoId: quoteNo,
          procurementPo: purchaseOrder
        },
      });
    } catch (error) { }
  }
}
