import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';

export default class AccountDisplayName extends Component {
  @service store;
  @service session;

  @computed('session.customer.id', 'session.isAuthenticated', 'store')
  get customer() {
    const store = this.store;
    const id = this.session?.customer.id;

    if (isPresent(id)) {
      return store.find('customer', id);
    } else {
      return null;
    }
  }

  @computed('customer.account')
  get account() {
    return this.customer.account;
  }
}
