import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountHistoryInvoiceModel from 'shoelace/models/account-history-invoice';

export default class AccountHistoryInvoiceCommentModel extends Model {
  @attr('string') body!: null | string;

  @belongsTo('account-history-invoice')
  accountHistoryInvoice!: AsyncBelongsTo<AccountHistoryInvoiceModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-invoice-comment': AccountHistoryInvoiceCommentModel;
  }
}
