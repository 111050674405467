import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreServicesMyProjectsOrderRoute extends Route {
  @service store;
 
  model(...args) {
    return this.loadModel(...args);
  }

  async loadModel({ 'account-project-order_id': accountProjectOrderId }) {
    return await this.store.findRecord(
      'account-project-order',
      accountProjectOrderId,
    );
  }
}

export default instrumentRoutePerformance(
  StoreServicesMyProjectsOrderRoute
);