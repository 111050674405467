import Route from '@ember/routing/route';
import { refreshModel } from '../../../../../utils/query-params';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreServicesMyProjectsProjectLateOrdersRoute extends Route {
  @service store;

  queryParams = {
    page: refreshModel,
    search: refreshModel,
    size: refreshModel,
    sort: refreshModel,
  };

  async model({ page: number, search, size, sort }) {
    const accountProject = this.modelFor('store.services.my-projects.project');
    const accountProjectId = accountProject.get('id');

    /**
     * TODO: maybe we should move this up to the parent route?
     */
    const lateAccountProjectOrders = this.store.query('account-project-order', {
      filter: {
        'is-order-late': accountProjectId,
        search,
      },
      include: [
        'account-order',
        'account-project-circuits',
        'account-project-drawing',
        'account-project-order-attributes',
        'account-project-order-attributes.account-project-order-attribute-definition',
      ].join(','),
      page: {
        number,
        size,
      },
      sort,
    });

    return {
      accountProject,
      lateAccountProjectOrders,
    };
  }
}
export default instrumentRoutePerformance(
  StoreServicesMyProjectsProjectLateOrdersRoute
);
