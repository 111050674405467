import type ObjectProxy from '@ember/object/proxy';
import Route from '@ember/routing/route';
import type AccountProject from 'shoelace/models/account-project';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface RouteModel {
  accountProject: ObjectProxy<AccountProject>;
}

class AdminAccountProjectIndexRoute extends Route {
  async model(): Promise<RouteModel> {
    const accountProject = (await this.modelFor(
      'admin.account-project',
    )) as ObjectProxy<AccountProject>;

    return {
      accountProject,
    };
  }
}

export default instrumentRoutePerformance(AdminAccountProjectIndexRoute);