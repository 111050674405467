import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type AccountProjectCircuitModel from 'shoelace/models/account-project-circuit';
import type AccountProjectDrawingModel from 'shoelace/models/account-project-drawing';
import type AccountProjectOrderModel from 'shoelace/models/account-project-order';
import type AccountProjectReleaseModel from 'shoelace/models/account-project-release';
import type ServiceCenterModel from 'shoelace/models/service-center';

export default class AccountProjectModel extends Model {
  @attr('number') late!: null | number;
  @attr('string') imagePreviewUrl!: null | string;
  @attr('string') imageSignedId!: null | string;
  @attr('string') imageUrl!: null | string;
  @attr('string') name!: null | string;
  @attr('boolean') isNameVisible!: null | boolean;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('service-center')
  serviceCenter!: AsyncBelongsTo<ServiceCenterModel>;

  @hasMany('account-project-circuit')
  accountProjectCircuits!: AsyncHasMany<AccountProjectCircuitModel>;

  @hasMany('account-project-drawing')
  accountProjectDrawings!: AsyncHasMany<AccountProjectDrawingModel>;

  @hasMany('account-project-order')
  accountProjectOrders!: AsyncHasMany<AccountProjectOrderModel>;

  @hasMany('account-project-release')
  accountProjectReleases!: AsyncHasMany<AccountProjectReleaseModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-project': AccountProjectModel;
  }
}
