import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import type ProductModel from 'shoelace/models/product';
import type ServiceCenterModel from 'shoelace/models/service-center';

export default class ProductAvailabilityModel extends Model {
  @attr('string') availability!: null | string;
  @attr('number') availableQuantity!: null | number;
  @attr('boolean') batch!: null | boolean;
  @attr('boolean') batchStocked!: null | boolean;
  @attr('boolean') inStock!: null | boolean;
  @attr('boolean') isNormallyStocked!: null | boolean;
  @attr('boolean') limitedStock!: null | boolean;
  @attr('boolean') mixedStock!: null | boolean;
  @attr('boolean') nonBatch!: null | boolean;
  @attr('boolean') notAvailable!: null | boolean;
  @attr('boolean') stocked!: null | boolean;
  @attr('number') eodQty!: null | number;
  @attr('number') requestedQuantity!: null | number;

  @belongsTo('product')
  product!: AsyncBelongsTo<ProductModel>;

  @belongsTo('service-center')
  serviceCenter!: AsyncBelongsTo<ServiceCenterModel>;

  @tracked cachedAt: Date | null = null;

  accountQualifiesFor2020 = true;

  hasServiceCenter = notEmpty('serviceCenter.code');

  @computed('product.isTwentyTwenty', 'inStock', 'mixedStock', 'stocked')
  get qualifiesForImmediatePickup(): boolean {
    return (
      (this.product.get('isTwentyTwenty') ?? false) &&
      ((this.inStock ?? false) ||
        ((this.mixedStock ?? false) && (this.stocked ?? false)))
    );
  }

  @computed('accountQualifiesFor2020', 'qualifiesForImmediatePickup')
  get qualifiesFor2020(): boolean {
    return this.qualifiesForImmediatePickup && this.accountQualifiesFor2020;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'product-availability': ProductAvailabilityModel;
  }
}
