import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class StoreAccountHistoryOrderController extends Controller {
  @service notice;
  @service store;

  @tracked emailProductInformationIsPending = false;

  @action async emailProductInformation(customer, accountHistoryOrder) {
    this.emailProductInformationIsPending = true;

    const orderId = accountHistoryOrder.get('id');
    const products = await Promise.all(
      (
        await accountHistoryOrder.get('accountHistoryOrderLineItems')
      ).map(
        async accountHistoryOrderLineItem =>
          await accountHistoryOrderLineItem.get('product'),
      ),
    );

    const emailProductCutsheet = this.store.createRecord(
      'email-product-cutsheet',
      {
        customer,
        orderId,
      },
    );

    const validProducts = products.filter(Boolean);

    emailProductCutsheet.get('products').pushObjects(validProducts);

    await emailProductCutsheet.save();

    this.emailProductInformationIsPending = false;
    await this.notice.success('Emailed!');
  }
}
