import ArrayProxy from '@ember/array/proxy';
import ObjectProxy from '@ember/object/proxy';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';

const ArrayPromiseProxy = ArrayProxy.extend(PromiseProxyMixin);
const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

export function AsyncArray(promise) {
  return ArrayPromiseProxy.create({ promise });
}

export function AsyncObject(promise) {
  return ObjectPromiseProxy.create({ promise });
}
