import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type ProductModel from 'shoelace/models/product';

export default class ProductPartNumberModel extends Model {
  @attr('string') value!: null | string;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('product')
  product!: AsyncBelongsTo<ProductModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'product-part-number': ProductPartNumberModel;
  }
}
