import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import type AccountModel from 'shoelace/models/account';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type { ObjectPromiseProxy } from 'shoelace/utils/async-helpers';
import { AsyncObject } from 'shoelace/utils/async-helpers';

async function head(url: string): Promise<number> {
  return (await fetch(url, { method: 'HEAD' })).status;
}

async function hasInvoicePdf(url: string): Promise<boolean> {
  try {
    return (await head(url)) === 200;
  } catch (_) {
    return false;
  }
}

export default class AccountHistoryAllInvoiceModel extends Model {
  @attr('date') clearedAt!: null | Date;
  @attr('date') createdAt!: null | Date;
  @attr('number') discount!: null | number;
  @attr('date') discountAt!: null | Date;
  @attr('date') dueAt!: null | Date;
  @attr('date') expectedAt!: null | Date;
  @attr('string') jobAccountName!: null | string;
  @attr('string') kind!: null | string;
  @attr('number') numberOfItems!: null | number;
  @attr('string') purchaseOrder!: null | string;
  @attr('number') salesTax!: null | number;
  @attr('string') shippingType!: null | string;
  @attr('string') status!: null | string;
  @attr('number') subtotal!: null | number;
  @attr('number') total!: null | number;
  @attr('string') accountHistoryOrderId!: null | string;
  @attr('string') accountHistoryReturnId!: null | string;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-job-account')
  accountJobAccount!: AsyncBelongsTo<AccountJobAccountModel>;

  @computed('id')
  // eslint-disable-next-line @typescript-eslint/ban-types
  get hasInvoicePdf(): ObjectPromiseProxy<object> {
    return AsyncObject(
      hasInvoicePdf(
        `/api/documents/${this.id}`,
        // eslint-disable-next-line @typescript-eslint/ban-types
      ) as unknown as Promise<object>,
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-all-invoice': AccountHistoryAllInvoiceModel;
  }
}
