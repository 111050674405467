import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';
import type RouterService from '@ember/routing/router';
import SessionService from 'shoelace/services/-session';

export interface RouteParams {
  account_verification_id: string | number;
}

class AdminAccountVerificationRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare router: RouterService; 

  async model({
    account_verification_id: id,
  }: RouteParams) {
  
   const customer = await this.session.get('customer');

    if (customer) {
      if (customer?.hasRbacCustomerAdmin) {
        this.router.transitionTo('store.customer', customer.id);
      } 
    }
    
    const accountVerification = await this.store.findRecord(
      'account-verification',
      id,
      {
        include: [
          'customer',
          'account-verification-status',
        ].join(','),
      }
    );

    return accountVerification;
  }
}

export default instrumentRoutePerformance(
  AdminAccountVerificationRoute
);