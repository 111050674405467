import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';
import { Service as IntlService } from 'ember-intl';
import type ServiceCenterDateHourModel from 'shoelace/models/service-center-date-hour';
import type ServiceCenterDowHourModel from 'shoelace/models/service-center-dow-hour';
import type ServiceCenterTypeModel from 'shoelace/models/service-center-type';

const validations = buildValidations({
  code: {
    description: 'Service Center code',
    validators: [
      validator('length', {
        is: 4,
        message: 'Service Center code must be {is} length characters.',
      }),
    ],
  },
  longName: {
    description: 'Service Center long name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Service Center long name must not be blank.',
      }),
      validator('length', {
        min: 1,
        max: 60,
        message:
          'Service Center long name must be between {min} and {max} characters.',
      }),
    ],
  },
  phone: {
    description: 'Service Center phone',
    validators: [
      validator('length', {
        min: 1,
        max: 30,
        message: 'Service Center phone must be between {min} and {max} numbers.',
      }),
    ],
  },
  serviceCenterType: {
    description: 'Service Center Type',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Please select a service center type.',
      }),
    ],
  },
  shortName: {
    description: 'Service Center short name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Service Center short name must not be blank.',
      }),
      validator('length', {
        min: 1,
        max: 60,
        message:
          'Service Center short name must be between {min} and {max} characters.',
      }),
    ],
  },
  zip: {
    description: 'Service Center zip code area',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Service Center zip code area must not be blank.',
      }),
      validator('length', {
        min: 1,
        max: 30,
        message:
          'Service Center zip code area must be between {min} and {max} characters.',
      }),
    ],
  },
});

export default class ServiceCenterModel extends Model.extend(validations) {
  @service declare intl: IntlService;

  @attr('boolean')
  declare active?: boolean;

  @attr('boolean')
  declare isOpen?: boolean;

  @attr('boolean')
  declare pickupIn20?: boolean;

  @attr('date')
  declare createdAt?: Date;

  @attr('date')
  declare deletedAt?: Date;

  @attr('date')
  declare currentPickupTime?: Date;

  @attr('date')
  declare pickup2020Time?: Date;

  @attr('date')
  declare updatedAt?: Date;

  @attr('number')
  declare distance?: number;

  @attr('number')
  declare latitude?: number;

  @attr('number')
  declare longitude?: number;

  @attr('number')
  declare zip?: number;

  @attr('string')
  declare address?: string;

  @attr('string')
  declare code?: string;

  @attr('string')
  declare deliveryArea?: string;

  @attr('string')
  declare longName?: string;

  @attr('string')
  declare phone?: string;

  @attr('string')
  declare shortName?: string;

  @attr('string')
  declare timezone?: string;

  @attr('string')
  declare url?: string;

  @attr()
  declare exceptionHours?: string[];

  @attr()
  declare standardHours?: string[];

  @belongsTo('service-center-type')
  serviceCenterType!: AsyncBelongsTo<ServiceCenterTypeModel>;

  @hasMany('service-center-dow-hour')
  serviceCenterDowHours!: AsyncHasMany<ServiceCenterDowHourModel>;

  @hasMany('service-center-date-hour')
  serviceCenterDateHours!: AsyncHasMany<ServiceCenterDateHourModel>;

  // @computed('currentPickupTime', 'timezone')
  // get timezonePickupTime(): null | Date {
  //   if (
  //     this.get('currentPickupTime') !== null &&
  //     this.get('timezone') !== null
  //   ) {
  //     return utcToZonedTime(
  //       // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //       this.get('currentPickupTime')!,
  //       // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //       this.get('timezone')!,
  //     );
  //   } else {
  //     return null;
  //   }
  // }

  @computed('intl', 'pickup2020Time')
  get pickupDate(): string {
    return this.pickup2020Time ? this.intl.formatDate(this.pickup2020Time) : "undefined";
  }

  @computed('intl', 'pickup2020Time')
  get pickupTime(): string {
    return this.pickup2020Time ? this.intl.formatTime(this.pickup2020Time) : "undefined";
  }

  @computed('phone')
  get phoneHref(): string {
    return `tel:+${this.phone}`;
  }

  @computed('url')
  get relativeUrl(): string {
    return (this.url ?? '').replace('https://www.summit.com', '');
  }

  @computed('distance')
  get softDistance(): number {
    return Math.round(this.distance ?? 9999);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'service-center': ServiceCenterModel;
  }
}
