import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class OrderConfirmationSuccessRoute extends Route {
  @service router;

  model() {
    const procurement = this.modelFor('store.order-confirmation-processing');

    setTimeout(() => {
      return this.router.transitionTo(
        'store.account-history-order',
        procurement.orderNumber
      );
    }, 3000);

    return procurement;
  }
}
export default instrumentRoutePerformance(OrderConfirmationSuccessRoute);
