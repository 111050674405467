import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { inject as service } from '@ember/service';

export default class CacheAdapter extends JSONAPIAdapter {
  @service cache;

  // @override
  query() {
    const model = arguments[1]['modelName'];
    const key = this.cache.generateKey(model, arguments[2]);

    if (this.cache.isModelAllowed(model)) {
      const result = this.cache.getValue(model, key) ?? super.query(...arguments);

      this.cache.setValue(model, key, result);

      this.cache.validateValue(model, key, result);

      return result;
    }

    return super.query(...arguments);
  }

  // @override
  createRecord() {
    const model = arguments[1]['modelName'];

    this.cache.clearCacheStore(model);

    return super.createRecord(...arguments);
  }

  // @override
  deleteRecord() {
    const model = arguments[1]['modelName'];

    this.cache.clearCacheStore(model);

    return super.deleteRecord(...arguments);
  }
}
