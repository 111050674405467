import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class LogosRoute extends Route {
  @service router;

  model() {
    this.router.transitionTo('summit-logo');
  }
}
export default instrumentRoutePerformance(LogosRoute);
