import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type RouterService from '@ember/routing/router';
import type SessionService from 'shoelace/services/session';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface RouteParams {
  posid: string | number;
}

class JWTRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare router: RouterService;

  queryParams = {
    posid: {
      refreshModel: true,
    },
  }

  titleToken = 'Login';

  async model({ posid: id }: RouteParams) {
    this.session.authenticate('authenticator:po2go', { id }).then(() => {
      this.router.transitionTo('store/categories');
    });
  }
}
export default instrumentRoutePerformance(
  JWTRoute
);