import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import type Account from 'shoelace/models/account';
import type AccountJobAccount from 'shoelace/models/account-job-account';
import type AccountOpenItemReceipt from 'shoelace/models/account-open-item-receipt';

export default class AccountOpenItemModel extends Model {
  @attr('number') amount!: null | number;
  @attr('number') amountWithDiscount!: null | number;
  @attr('number') discountAmount!: null | number;
  @attr('date') discountDueDate!: null | Date;
  @attr('string') documentCode!: null | string;
  @attr('date') documentDate!: null | Date;
  @attr('string') documentNumber!: null | string;
  @attr('string') documentType!: null | string;
  @attr('date') dueDate!: null | Date;
  @attr('number') orderNumber!: null | number;
  @attr('string') poNumber!: null | string;
  @attr('string') comment!: null | string;
  @attr('boolean') isCredit!: null | boolean;
  @attr('boolean') isDebit!: null | boolean;

  @belongsTo('account')
  account!: AsyncBelongsTo<Account>;

  @belongsTo('account-job-account')
  accountJobAccount!: AsyncBelongsTo<AccountJobAccount>;

  @belongsTo('account-open-item-receipt')
  accountOpenItemReceipt!: AsyncBelongsTo<AccountOpenItemReceipt>;

  @computed('amount', 'amountWithDiscount')
  get applicableDiscount(): number {
    return (this.amount ?? 0) - (this.amountWithDiscount ?? 0);
  }

  isInvoice = equal('documentType', 'invoice');

  @computed('orderNumber')
  get isOrder(): boolean {
    return (this.orderNumber ?? 0) >= 1 && (this.orderNumber ?? 0) <= 499999999;
  }

  @computed('orderNumber')
  get isReturn(): boolean {
    return (
      (this.orderNumber ?? 0) >= 6000000000 &&
      (this.orderNumber ?? 0) <= 6499999999
    );
  }

  @computed('accountOpenItemReceipt')
  get isPayable(): boolean {
    const accountOpenItemReceipt = this.belongsTo(
      'accountOpenItemReceipt',
    ).value();

    const isNew = accountOpenItemReceipt?.get('isNew') ?? false;

    return isEmpty(accountOpenItemReceipt) || isNew;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-open-item': AccountOpenItemModel;
  }
}
