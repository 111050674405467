import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { instrumentRoutePerformance } from '@sentry/ember';

class PunchoutConfirmationProcesssingRoute extends Route {
  @service router;

  async model({ id }) {
    const model = this.modelFor('store.punchout-confirmation');
    const procurement = await model.procurement;

    const worker = async () => {
      try {
        await procurement.reload();

        if (procurement.procurementState !== 'error') {
          const externalResult = procurement.externalResult;
          if (externalResult) {
            const punchout = externalResult['punchout_response'];
            if (!isEmpty(punchout)) {
              window.location.href = punchout;
            } else {
              setTimeout(worker, 1000);
            }
          } else {
            setTimeout(worker, 1000);
          }
        } else {
          return this.router.transitionTo('store.punchout-confirmation-error');
        }
      } catch (error) {
        const message = 'Error # 82912: Error converting quote to punchout:';

        try {
          //this.rollbar.critical(message, error);
        } catch (_) {
          console.error(message, error);
        }

        return this.router.transitionTo('store.punchout-confirmation-error');
      }
    };

    setTimeout(worker, 1000);

    return procurement;
  }
}
export default instrumentRoutePerformance(PunchoutConfirmationProcesssingRoute);
