import Helper from '@ember/component/helper';

export default Helper.extend({
  compute([event, details]) {
    if (details) {
      setTimeout(() => {
        try {
          if (event == 'transaction') {
            ga('ecommerce:addTransaction', details);
            ga('ecommerce:send');
          }
        } catch (e) {}
      }, 1000);
      return '';
    }
  },
});
