import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type ProductModel from 'shoelace/models/product';

export default class AttrModel extends Model {
  @attr('string') name!: null | string;
  @attr('string') value!: null | string;

  @belongsTo('product')
  product!: AsyncBelongsTo<ProductModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    attr: AttrModel;
  }
}
