import Helper from '@ember/component/helper';
import { A } from '@ember/array';

export default Helper.extend({
  compute(params) {
    const [label] = params;
    const branches = label.split('/').filter(item => item && item.trim());
    if(branches[1] == "Summit Hierarchy Root"){
      const parts = label.split('/').filter(item => item && item.trim()).slice(2);
      if(parts.length > 2){
        parts.pop()
        const data = parts.length - 1;
        const nextData =  parts[data].split("/");
      return nextData[nextData.length-1].toUpperCase();
      } else{
        const data = parts.length - 1;
        const nextData =  parts[data].split("/");
        return nextData[nextData.length-1].toUpperCase();
      }
    } else if (branches[1]== "Summit Catalog Root"){
      const data="products"
      return data.toUpperCase();
    }
    
  },
});
