
import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import {
  validator,
  buildValidations,
} from '@summit-electric-supply/ember-cp-validations';
import type CartQuoteListModel from 'shoelace/models/cart-quote-list';

const Validations = buildValidations({
  name: {
    description: 'Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Name must not be blank.',
      }),
    ],
  },

  phone: {
    description: 'Phone Number',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Phone number must not be blank.',
      }),
      validator('format', {
        type: 'phone',
      }),
      validator('length', {
        min: 10,
        max: 11,
        message: 'Phone number must be 10 or 11 digits',
      }),
    ],
  },

  email: {
    description: 'From Email Address',
    validators: [
      validator('format', {
        type: 'email',
        allowNonTld: false,
        message: 'Your email must not be blank.',
      }),
    ],
  },
  location: {
    description: 'City',
    validators: [
      validator('presence', {
        presence: true,
        message: 'City must not be blank.',
      }),
    ],
  },
  state: {
    description: 'State',
    validators: [
      validator('presence', {
        presence: true,
        message: 'State must not be blank.',
      }),
    ],
  },
  contactPreference: {
    description: 'contactPreference',
    validators: [
      validator('presence', {
        presence: true,
        message: 'ContactPreference must not be blank.',
      }),
    ],
  },
});

export default class CartQueryModel extends Model.extend(Validations) {
  @attr('number') cartQuoteListId!: null | number;
  @attr('string') job_account?: string;
  @attr('string') source!: null | string;
  @attr('string') name!: null | string;
  @attr('string') company!: null | string;
  @attr('string') location!: null | string;
  @attr('string') email!: null | string;
  @attr('number') customer_id!: null | number;
  @attr('number') account_id!: null | number;
  @attr('number') product_id!: null | string;
  @attr('string') product_mapping!: null | string;
  @attr('string') phone!: null | string;
  @attr('string') contactPreference!: null | string;
  @attr('string') message!: null | string;
  @attr('string') sapDocumentNumber!: null | string;

  @belongsTo('cartQuoteList')
  cartQuoteList?: AsyncBelongsTo<CartQuoteListModel>;
}
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'cart-query': CartQueryModel;
  }
}
