import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class StoreServicesMyProjectsReleaseSignersController extends Controller {
  @service notice;

  @action back() {
    window.history.back();
  }
}
