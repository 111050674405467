import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountHistoryReturnModel from 'shoelace/models/account-history-return';
import type AccountHistoryReturnLineItemModel from 'shoelace/models/account-history-return-line-item';

export default class AccountHistoryReturnLineItemOrderModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('number') number!: null | number;

  @belongsTo('account-history-return')
  accountHistoryReturn!: AsyncBelongsTo<AccountHistoryReturnModel>;

  @belongsTo('account-history-return-line-item')
  accountHistoryReturnLineItem!: AsyncBelongsTo<AccountHistoryReturnLineItemModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-return-line-item-order': AccountHistoryReturnLineItemOrderModel;
  }
}
