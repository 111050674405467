import Service from '@ember/service';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class GeolocationService extends Service {
  @tracked hasBrowserApi = false;
  @tracked here = { latitude: 0, longitude: 0 };

  @task(function* () {
    if ('geolocation' in navigator) {
      try {
        const position = yield new Promise(d =>
          navigator.geolocation.getCurrentPosition(d),
        );
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        this.here = { latitude, longitude };
        this.hasBrowserApi = true;
        return this.here;
      } catch (e) {
        this.here = { latitude: 0, longitude: 0 };
        return this.here;
      }
    } else {
      return this.here;
    }
  })
  currentLocation;
}
