import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountProjectOrderLineItemModel from 'shoelace/models/account-project-order-line-item';
import type AccountProjectOrderLineItemAttributeDefinitionModel from 'shoelace/models/account-project-order-line-item-attribute-definition';

export default class AccountProjectOrderLineItemAttributeModel extends Model {
  @attr('string') name!: null | string;

  @belongsTo('account-project-order-line-item')
  accountProjectOrderLineItem!: AsyncBelongsTo<AccountProjectOrderLineItemModel>;

  @belongsTo('account-project-order-line-item-attribute-definition')
  accountProjectOrderLineItemAttributeDefinition!: AsyncBelongsTo<AccountProjectOrderLineItemAttributeDefinitionModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-project-order-line-item-attribute': AccountProjectOrderLineItemAttributeModel;
  }
}
