import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type AccountVerificationStatus from 'shoelace/models/account-verification-status';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface RouteModel {
  accountVerificationStatuses: ArrayProxy<AccountVerificationStatus>;
}

class AdminAccountVerificationsRoute extends Route {
  @service declare store: StoreService;
 
  async model(): Promise<RouteModel> {
    const accountVerificationStatuses = await this.store.findAll(
      'account-verification-status',
      { reload: true },
    );

    return {
      accountVerificationStatuses,
    };
  }
}

export default instrumentRoutePerformance(
  AdminAccountVerificationsRoute
);