import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class QuickOrderGuideController extends Controller {
  @service store;
  @service cart;
  @service session;

  @tracked selectedQuickOrderProperty = null;
  @tracked selectedProperty;
  
  @action selectSize(id) {
    this.selectedQuickOrderProperty = this.store.peekRecord(
      'quick-order-property',
      id,
    );
  }

  @action filter(set, item) {
    return set.includes(item.get('id'));
  }

  @action onSelectProperty(property) {
    this.selectedProperty = property;
  }
}
