import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  class=\"outline-none focus:outline-none flex items-center flex-row\"\n  ...attributes\n  {{on \"click\" this.onChange}}\n>\n  <span\n    class=\"border bg-white rounded-sm flex items-center mr-1 {{if @search '' 'min-w-8 text-center justify-center'}}\"\n  >\n    <svg\n      class=\"fill-current h-4 w-4 transform\n        {{if @checked 'rotate-180'}}\n        transition duration-150 ease-in-out\"\n      xmlns=\"http://www.w3.org/2000/svg\"\n      viewBox=\"0 0 20 20\"\n    >\n      <path\n        d=\"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z\"\n      ></path>\n    </svg>\n  </span>\n  {{#if @name}}\n    {{@name}}\n  {{/if}}\n</button>\n", {"contents":"<button\n  class=\"outline-none focus:outline-none flex items-center flex-row\"\n  ...attributes\n  {{on \"click\" this.onChange}}\n>\n  <span\n    class=\"border bg-white rounded-sm flex items-center mr-1 {{if @search '' 'min-w-8 text-center justify-center'}}\"\n  >\n    <svg\n      class=\"fill-current h-4 w-4 transform\n        {{if @checked 'rotate-180'}}\n        transition duration-150 ease-in-out\"\n      xmlns=\"http://www.w3.org/2000/svg\"\n      viewBox=\"0 0 20 20\"\n    >\n      <path\n        d=\"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z\"\n      ></path>\n    </svg>\n  </span>\n  {{#if @name}}\n    {{@name}}\n  {{/if}}\n</button>\n","moduleName":"shoelace/components/apex/toggle-expand.hbs","parseOptions":{"srcName":"shoelace/components/apex/toggle-expand.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface ApexToggleExpandArgs {
  id: string;
  checked: boolean;
  onChange: (event: Event) => Promise<void>;
}

export default class ApexToggleExpand extends Component<ApexToggleExpandArgs> {
  @action async onChange(event: Event): Promise<void> {
    await this.args?.onChange?.(event);
  }
}
