import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type CartModel from 'shoelace/models/cart';
import type ProductModel from 'shoelace/models/product';


export default class LineItemModel extends Model {
  @attr('number') quantity?: number;
  @attr('string') price?: string;
  @attr('number') extendedPrice?: number;
  @attr('string') formattedExtendedPrice?: string;


  @belongsTo('cart') declare Cart?: AsyncBelongsTo<CartModel>;
  @belongsTo('product') declare product?: AsyncBelongsTo<ProductModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'line-item': LineItemModel;
  }
}
