import Model, { attr } from '@ember-data/model';

export default class NotificationModel extends Model {
  @attr('string')
  declare title?: string;

  @attr('string')
  declare message?: string;

  @attr('string')
  declare link?: string;

  @attr('string')
  declare linkText?: string;

  @attr('string')
  declare component?: string;

  @attr('date')
  declare showDtTm?: string;

  @attr('date')
  declare hideDtTm?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    notification: NotificationModel;
  }
}
