import {
  validator,
  buildValidations,
} from '@summit-electric-supply/ember-cp-validations';
import Model, { attr } from '@ember-data/model';

const Validations = buildValidations({
  email: {
    description: 'Email Address',
    validators: [
      validator('format', {
        type: 'email',
        allowNonTld: false,
      }),
    ],
  },
});

export default class CustomerForgotPasswordModel extends Model.extend(
  Validations,
) {
  @attr('string') email;
}
