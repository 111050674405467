import { inject as service } from '@ember/service';
import OrderingDictionary, { labels } from '../order_dictionary';
import Route from '@ember/routing/route';
import { instrumentRoutePerformance } from '@sentry/ember';

class CheckoutRoute extends Route {
  @service cart;
  @service procurement;

  titleToken = 'Shopping Cart';

  async model() {
    return this.procurement.createRecord();
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.orderKinds = Object.values(OrderingDictionary.KIND)
      .map((kind) => ({ id: kind, label: labels.KIND[kind] }))
      .filter((o) => o.label.includes('Order'));
  }
}
export default instrumentRoutePerformance(CheckoutRoute);
