import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';
import type KartService from 'shoelace/services/kart';
import type ProcurementService from 'shoelace/services/procurement';
import type RouterService from '@ember/routing/router';
import MetricsService from 'shoelace/services/metrics';
import StoreService from '@ember-data/store';

export interface SecureCheckoutReviewRouteModel {
  id?: number | string;
}

export interface SecureCheckoutReviewRouteParams {
  id?: number | string;
}

class SecureCheckoutReviewRoute extends Route {
  @service declare kart: KartService;
  @service declare router: RouterService;
  @service declare procurement: ProcurementService;
  @service declare metrics: MetricsService;
  @service declare store: StoreService;

  async beforeModel() {
    const procurement = this.procurement.procurement;

    const creditCardError =
      procurement?.creditCard && procurement?.hasAuthorizationErrorKind;

    if (creditCardError || procurement?.isNew) {
      return this.router.transitionTo('secure-checkout.billing');
    }
  }

  async model({
    id,
  }: SecureCheckoutReviewRouteParams): Promise<SecureCheckoutReviewRouteModel> {
    const procurement: any = this.procurement.procurement;

    const cartProducts = await Promise.all(
      procurement?.cart?.items?.map((li: any) => this.store.findRecord('product', li.id))
    );

    const items = cartProducts.map((lineItem: any, index: any) => {
      return this.metrics.productAsEcommerceItem(
        lineItem,
        procurement.cart.items[index].quantity,
        procurement?.externalResult?.sap_create_simulated_order[0].ORDER_ITEMS_OUT[index].NET_VALUE1,
        index,
        'purchase'
      );
    });

    this.metrics.events.ecommerce.begin_checkout({
      currency: 'USD',
      value: procurement.total ?? 0,
      items: items
    });

    this.metrics.events.ecommerce.add_shipping_info({
      currency: 'USD',
      value: procurement.total ?? 0,
      shipping_tier: procurement?.pickup ? "Pick Up" : "Shipping",
      items: items
    });

    this.metrics.events.ecommerce.add_payment_info({
      currency: 'USD',
      value: procurement.total ?? 0,
      payment_type: procurement?.creditCard ? "Credit Card" : procurement?.billableToAccount ? "Bill to Account" : "Pay At Call",
      items: items
    });

    this.metrics.events.ecommerce.purchase({
      currency: 'USD',
      value: procurement.total ?? 0,
      tax: procurement?.salesTax ?? 0,
      transaction_id: String(procurement?.id),
      affiliation: "Summit Electric Supply",
      items: items
    })
    return {
      id,
    };
  }
}

export default instrumentRoutePerformance(SecureCheckoutReviewRoute);
