import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountHistoryOrderLineItemModel from 'shoelace/models/account-history-order-line-item';

export default class AccountHistoryOrderLineItemCommentModel extends Model {
  @attr('string') body!: null | string;

  @belongsTo('account-history-order-line-item')
  accountHistoryOrderLineItem!: AsyncBelongsTo<AccountHistoryOrderLineItemModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-order-line-item-comment': AccountHistoryOrderLineItemCommentModel;
  }
}
