import Controller from '@ember/controller';
import { computed, get, set } from '@ember/object';
import { inject } from '@ember/service';
import { reads } from '@ember/object/computed';

export default Controller.extend({
  session: inject(),
  store: inject(),
  account: computed('customerRecord.account', function () {
    return get(this, 'customerRecord.account');
    // return get(this, 'store').findRecord('account', '1000736');
  }),
  customerRecord: reads('session.customerRecord'),
});
