import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield this.translation}}", {"contents":"{{yield this.translation}}","moduleName":"shoelace/components/apex/dynamic-translation.hbs","parseOptions":{"srcName":"shoelace/components/apex/dynamic-translation.hbs"}});
import Component from '@glimmer/component';
import { Service as IntlService } from 'ember-intl';
import { inject as service } from '@ember/service';

interface ApexDynamicTranslationArgs {
  key: string;
  accountId?: string;
  accountProjectId?: string;
}

export default class ApexDynamicTranslation extends Component<ApexDynamicTranslationArgs> {
@service declare intl: IntlService;

  get translation() {
    const keyDefault = `${this.args.key}`;
    const keyWithAccount = `${this.args.key}.${this.args.accountId}`;
    const keyWithAccountProject = `${this.args.key}.${this.args.accountId}.${this.args.accountProjectId}`;

    return this.intl.t(keyWithAccountProject, {
      default: [keyWithAccount, keyDefault],
    });
  }
}
