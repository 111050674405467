import Route from '@ember/routing/route';
import type SessionService from 'shoelace/services/session';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

class AccountServicesRoute extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  model() {
    const customer = this.session.customer;
    let signIn;
    if (customer) {
      this.router.transitionTo('store.customer', customer.id);
    } else {
      signIn = this.store.createRecord('sign-in');
    }
    return {
      signIn,
    };
  }
}
export default instrumentRoutePerformance(AccountServicesRoute);
