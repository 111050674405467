import Helper from '@ember/component/helper';

export default Helper.extend({
  compute([grants, permission]) {
    // load all grants
    // find first grant that includes supplied permission
    const aGrants = grants ? grants.content.toArray() : [];
    const releventGrant = aGrants.find(grant => {
      return grant.get('permission') == permission;
    });
    return releventGrant != null;
  },
});
