import DS from 'ember-data';

export default DS.JSONSerializer.extend({
  normalizeQueryRecordResponse(store, hostModel, response, id, method) {
    response.id = Date.now(); // need an id in place for JSONSerializer to proceed ok
    var jsonapi = DS.JSONSerializer.prototype.normalizeQueryRecordResponse.apply(
      this,
      [store, hostModel, response, response.id, method],
    );

    // json api expects stuff in attributes
    try {
      jsonapi.data.attributes['numPerPage'] = response['paging']['num_per_page'];
      jsonapi.data.attributes['page'] = response['paging']['page_num'];
      jsonapi.data.attributes['numPages'] = response['paging']['num_pages'];
      jsonapi.data.attributes['search'] = response['paging']['search'];
      jsonapi.data.attributes['entire'] = response['paging']['entire_item_count'];
      jsonapi.data.attributes['local'] = response['paging']['local_item_count'];
      // jsonapi.data.attributes['totalResults'] = response['paging']['entire_item_count'];
    } catch (e) {
      e;
    }
    response.documents = response.documents || [];

    // relationships needs split into relationships and included
    // jsonapi.data.relationships.searchResults.data.forEach(document => {
    response.documents.forEach(document => {
      jsonapi.included.push({
        type: 'search-result',
        id: jsonapi.data.id + '-' + document.id,
        attributes: Object.keys(document).reduce(
          (attributes, key) => {
            attributes[camelize(key)] = document[key];
            return attributes;
          },
          { productId: document.id },
        ),
        relationships: {
          search: {
            data: {
              type: 'search',
              id: jsonapi.data.id,
            },
          },
          product: {
            data: {
              type: 'product',
              id: document.id,
            },
          },
        },
      });
    });
    jsonapi.data.relationships.searchResults = {
      data: response.documents.map(document => {
        return {
          type: 'search-result',
          id: jsonapi.data.id + '-' + document.id,
        };
      }),
    };
    response.manufacturers = response.manufacturers || [];
    jsonapi.data.relationships.manufacturers = {
      data: response.manufacturers.map(mfr => {
        jsonapi.included.push({
          type: 'search-response-manufacturer',
          id: jsonapi.data.id + '-' + mfr.key,
          attributes: {
            resultCount: mfr.count,
            name: mfr.name,
            esID: mfr.key.toString(),
          },
          relationships: {
            search: {
              data: {
                type: 'search',
                id: jsonapi.data.id,
              },
            },
          },
        });
        return {
          type: 'search-response-manufacturer',
          id: jsonapi.data.id + '-' + mfr.key,
        };
      }),
    };
    response.categories = response.categories || [];
    jsonapi.data.relationships.categories = {
      data: response.categories.map(cat => {
        jsonapi.included.push({
          type: 'search-response-category',
          id: jsonapi.data.id + '-' + cat.key,
          attributes: {
            resultCount: cat.count,
            name: cat.name,
            esID: cat.key.toString(),
          },
          relationships: {
            search: {
              data: {
                type: 'search',
                id: jsonapi.data.id,
              },
            },
          },
        });
        return {
          type: 'search-response-category',
          id: jsonapi.data.id + '-' + cat.key,
        };
      }),
    };
    response.attributes = response.attributes || [];
    jsonapi.data.relationships.attributes = {
      data: response.attributes.map(attr => {
        jsonapi.included.push({
          type: 'search-response-attribute',
          id: jsonapi.data.id + '-' + attr.name,
          attributes: {
            name: attr.name,
            values: attr.values.map(val => val.table),
          },
          relationships: {
            search: {
              data: {
                type: 'search',
                id: jsonapi.data.id,
              },
            },
          },
        });
        return {
          type: 'search-response-attribute',
          id: jsonapi.data.id + '-' + attr.name,
        };
      }),
    };
    return jsonapi;
  },
});
