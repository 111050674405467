import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import type AttrModel from 'shoelace/models/attr';
import type CategoryModel from 'shoelace/models/category';
import type ExternalFileModel from 'shoelace/models/external-file';
import type ProductAvailabilityModel from 'shoelace/models/product-availability';
import type ProductPartNumberModel from 'shoelace/models/product-part-number';
import type ProductPriceModel from 'shoelace/models/product-price';
import type RelationshipModel from 'shoelace/models/relationship';
import type StockStatusModel from 'shoelace/models/stock-status';
import type VideoModel from 'shoelace/models/video';
import type { ArrayPromiseProxy } from 'shoelace/utils/async-helpers';
import { AsyncArray } from 'shoelace/utils/async-helpers';

async function relatedProducts(
  relationships: AsyncHasMany<RelationshipModel>,
  kind: string,
): Promise<AsyncBelongsTo<ProductModel>[]> {
  return (await relationships).filterBy('kind', kind).mapBy('forProduct');
}

const kinds = [
  'Image',
  'Thumbnail',
  'ProductImageSecondary',
  'ProductVideoOverview',
  'ProductVideoInstallation',
];

export default class ProductModel extends Model {
  @attr('boolean') batch!: null | boolean;
  @attr('string') categoryPath!: null | string;
  @attr('string') categoryName!: null | string[];
  @attr('string') categoryId!: null | string;
  @attr('string') description!: null | string;
  @attr('string') flags!: null | string;
  @attr('string') imageUrl!: null | string;
  @attr('boolean') isAvailable!: null | boolean;
  @attr('boolean') isTwentyTwenty!: null | boolean;
  @attr('string') itemPik!: null | string;
  @attr('string') longDescription!: null | string;
  @attr('string') manufacturerId!: null | string;
  @attr('string') manufacturerLogoUrl!: null | string;
  @attr('string') manufacturerName!: null | string;
  @attr('string') manufacturerPartNumber!: null | string;
  @attr('string') matnr!: null | string;
  @attr('number') minQty!: null | number;
  @attr('string') name!: null | string;
  @attr() otherImages!: null | string[];
  @attr('boolean') isPromotion!: null | boolean;
  @attr('string') promoPrice!: null | string;
  @attr('string') originalPrice!: null | string;
  @attr('boolean') restricted!: null | boolean;
  @attr('string') summitPartNumber!: null | string;
  @attr('string') thumbnailUrl!: null | string;
  @attr('string') uom!: null | string;
  @attr() serviceCentersStocks?: string[];

  @tracked cachedAt: Date | null = null;

  @hasMany('attr')
  attrs!: AsyncHasMany<AttrModel>;

  @hasMany('category')
  categories!: AsyncHasMany<CategoryModel>;

  @hasMany('external-file')
  externalFiles!: AsyncHasMany<ExternalFileModel>;

  @hasMany('product-availability')
  productAvailabilities!: AsyncHasMany<ProductAvailabilityModel>;

  @hasMany('product-part-number')
  productPartNumbers!: AsyncHasMany<ProductPartNumberModel>;

  @hasMany('product-price')
  productPrices!: AsyncHasMany<ProductPriceModel>;

  @hasMany('relationship')
  relationships!: AsyncHasMany<RelationshipModel>;

  @hasMany('stock-status')
  stockStatuses!: AsyncHasMany<StockStatusModel>;

  @hasMany('video')
  videos!: AsyncHasMany<VideoModel>;

  compilations = alias('frequentlyPurchasedTogether');

  @computed('relationships')
  get frequentlyPurchasedTogether(): ArrayPromiseProxy<AsyncBelongsTo<ProductModel>> {
    return this.relatedProducts('GoldenFreqBought');
  }

  @computed('relationships')
  get comparables(): ArrayPromiseProxy<AsyncBelongsTo<ProductModel>> {
    return this.relatedProducts('GoldenComparable');
  }

  @computed('relationships')
  get accessories(): ArrayPromiseProxy<AsyncBelongsTo<ProductModel>> {
    return this.relatedProducts('Accessory');
  }

  @computed('relationships')
  get related(): ArrayPromiseProxy<AsyncBelongsTo<ProductModel>> {
    return this.relatedProducts('Related');
  }

  @computed('relationships')
  get supersession(): ArrayPromiseProxy<AsyncBelongsTo<ProductModel>> {
    return this.relatedProducts('Supersession');
  }

  @computed('relationships')
  get upgrades(): ArrayPromiseProxy<AsyncBelongsTo<ProductModel>> {
    return this.relatedProducts('Upgrade');
  }

  @computed('relationships')
  get frequentlyPurchasedTogetherRelationships(): RelationshipModel[] {
    return this.relationships.filterBy('kind', 'GoldenFreqBought');
  }

  @computed('relationships')
  get comparablesRelationships(): RelationshipModel[] {
    return this.relationships.filterBy('kind', 'GoldenComparable');
  }

  @computed('relationships')
  get accessoriesRelationships(): RelationshipModel[] {
    return this.relationships.filterBy('kind', 'Accessory');
  }

  @computed('relationships')
  get relatedRelationships(): RelationshipModel[] {
    return this.relationships.filterBy('kind', 'Related');
  }

  @computed('relationships')
  get supersessionRelationships(): RelationshipModel[] {
    return this.relationships.filterBy('kind', 'Supersession');
  }

  @computed('relationships')
  get upgradesRelationships(): RelationshipModel[] {
    return this.relationships.filterBy('kind', 'Upgrade');
  }


  @computed('externalFiles.@each.{kind,name}')
  get downloads(): ExternalFileModel[] {
    return this.externalFiles
      .sortBy('name')
      .filter(
        externalFile => kinds.indexOf(externalFile.get('kind') ?? '') === -1,
      );
  }

  @computed('id')
  get storeLink(): string {
    return `/store/products/${this.id}`;
  }

  @computed('attrs.@each.{name,value}')
  get unspc(): string {
    return (
      this.attrs.filterBy('name', 'UNSPSC').get('firstObject')?.get('value') ?? ''
    );
  }

  @computed('externalFiles.@each.kind')
  get productVideoOverview(): ExternalFileModel | undefined {
    return this.externalFiles
      .filterBy('kind', 'ProductVideoOverview')
      .get('firstObject');
  }

  @computed('externalFiles.@each.kind')
  get productVideoInstallation(): ExternalFileModel | undefined {
    return this.externalFiles
      .filterBy('kind', 'ProductVideoInstallation')
      .get('firstObject');
  }

  relatedProducts(kind: string): ArrayPromiseProxy<AsyncBelongsTo<ProductModel>> {
    return AsyncArray(relatedProducts(this.relationships, kind));
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    product: ProductModel;
  }
}
