import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class QuickOrderGuideGroupModel extends Model {
  @attr('string') name;

  @belongsTo('customer') customer;
  @belongsTo('account') account;

  @hasMany('quick-order-guide') quickOrderGuides;
}
