import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<input\n  class=\"form-control\"\n  type=\"text\"\n  placeholder=\"Enter Filter Terms\"\n  {{on \"change\" this.onChange}}\n  ...attributes\n/>", {"contents":"<input\n  class=\"form-control\"\n  type=\"text\"\n  placeholder=\"Enter Filter Terms\"\n  {{on \"change\" this.onChange}}\n  ...attributes\n/>","moduleName":"shoelace/components/amps/filter-input.hbs","parseOptions":{"srcName":"shoelace/components/amps/filter-input.hbs"}});
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';

export default class AmpsFilterInputComponent extends Component {
  @action async onChange(event) {
    event.preventDefault();
    event.stopPropagation();

    const value = event?.target?.value;

    if (!isEmpty(value)) {
      const search = this.args.search.concat(value.trim());

      event.target.value = '';

      return await this.args.onChange(search);
    }
  }
}
