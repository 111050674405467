import Helper from '@ember/component/helper';
import { get } from '@ember/object';
import { isPresent } from '@ember/utils';

export default Helper.extend({
  compute(params, hash) {
    const { mapBy, items } = hash;

    return group(mapBy, items);
  },
});

export function group(mapBy, items) {
  if (!mapBy || !items) return '';
  const mapChain = mapBy.split(/\./g);
  const mapObject = mapChain.shift();

  if (isPresent(items) && isPresent(mapBy)) {
    return items
      .mapBy(mapBy)
      .uniq()
      .sort()
      .reverse()
      .map(property => {
        const parent = get(items.findBy(mapBy, property), mapObject);
        const children = items.filterBy(mapBy, property);

        return { parent, children };
      });
  }
}
