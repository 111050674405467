import { cancel, later, throttle } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type AccountService from 'shoelace/services/account';
import type { BootOptions } from 'shoelace/services/coordinator';
import type PushActionsService from 'shoelace/services/push-actions';
import type SessionService from 'shoelace/services/session';
import config from '../config/environment';

const WSS_ENABLED = config.websockets.enabled;
const WSS_PATH = config.websockets.path;
const WSS_PORT = config.websockets.port;

const RECONNECT_BASE_TIMEOUT = 1000;
const RECONNECT_MAX_TIMEOUT = 10000;
const DISCONNECT_TIMEOUT = 30000;
const KEEPALIVE_TIMEOUT = 45000;

interface Request {
  data: {
    to?: string;
    type?: string;
    attributes: {
      action: string;
      payload: Record<string, unknown>;
    };
  };
  meta?: Record<string, unknown>;
}

interface Socket {
  on: (event: string, callback: any, context: any) => void;
  onclose: () => void;
  onerror: (event: Event) => void;
  onmessage: (event: MessageEvent) => void;
  onopen: () => void;
  readyState: () => number;
  send(data: object, stringify: boolean): void;
}

export default class PushService extends Service {
  @service declare account: AccountService;
  @service declare pushActions: PushActionsService;
  @service declare session: SessionService;
  @service declare websockets: any;

  @tracked socket: Socket | undefined = undefined;
  @tracked boundOnVisibilityChange: any = undefined;
  @tracked failedReconnects = 0;
  @tracked reconnectHandler: any = undefined;
  @tracked disconnectHandler: any = undefined;
  @tracked keepalive: any = undefined;

  get isTabActive() {
    return !document.hidden;
  }

  get reconnectTimeout() {
    const timeout = 100 + RECONNECT_BASE_TIMEOUT * this.failedReconnects;

    return timeout > RECONNECT_MAX_TIMEOUT ? RECONNECT_MAX_TIMEOUT : timeout;
  }

  get url() {
    const protocol = location.protocol === 'https:' ? 'wss' : 'ws';
    const host = location.hostname;
    const port = WSS_PORT ?? location.port;
    const path = WSS_PATH ?? '';

    return `${protocol}://${host}:${port}/${path}`;
  }

  get meta() {
    if (this.session.isAuthenticated) {
      return {
        meta: {
          token: this.session.token,
          version: config.APP.version,
          account: {
            id: this.account.account?.id,
            name: this.account.account?.name,
          },
          customer: {
            id: this.session.customer?.id,
            name: this.session.customer?.name,
          },
        },
      };
    } else {
      return {
        meta: {
          version: config.APP.version,
        },
      };
    }
  }

  async onCoordinatorSetup(_options?: BootOptions): Promise<void> {
    if (!WSS_ENABLED) return;

    try {
      this.cancelPendingReconnects();

      this.disconnect();

      later(this, this.connect, 100);

      this.addVisibilityListener();
      this.addKeepaliveTimer();
    } catch (error) {
      console.error('Error setting up: ', error);
    }
  }

  async onCoordinatorTeardown(): Promise<void> {
    if (!WSS_ENABLED) return;

    try {
      if (this.session.customer) {
        this.unsubscribe(`shoelace::customer::${this.session.customer.id}`);
      }

      if (this.account.account) {
        this.unsubscribe(`shoelace::account::${this.account.account.id}`);
      }
    } catch (error) {
      console.error('Error tearing down: ', error);
    } finally {
      this.cancelPendingReconnects();
      this.disconnect();
      this.connect(); // reconnect as guest
    }
  }

  async connect() {
    if (this.isTabActive) {
      try {
        if (this.socket?.readyState?.() !== WebSocket.OPEN) {
          this.socket = this.websockets.socketFor(this.url);

          if (this.socket) {
            this.socket.on('open', this.onOpen, this);
            this.socket.on('message', this.onMessage, this);
            this.socket.on('close', this.onClose, this);
            this.socket.on('error', this.onError, this);
          }
        }
      } catch (error) {
        console.error('Error connecting: ', error);
      } finally {
        later(this, this.verifyConnection, this.reconnectTimeout);
      }
    }
  }

  verifyConnection() {
    try {
      if (this.socket?.readyState?.() !== WebSocket.OPEN) {
        this.failedReconnects++;

        throttle(this, this.connect, this.reconnectTimeout, false);
      } else {
        this.failedReconnects = 0;
        console.log('Connected');
      }
    } catch (error) {
      console.error('Error verifying connection: ', error);
    }
  }

  disconnect() {
    try {
      this.websockets.closeSocketFor(this.url);

      this.socket = undefined;
    } catch (error) {
      console.error('Error disconnecting: ', error);
    }
  }

  cancelPendingReconnects() {
    try {
      if (this.reconnectHandler) {
        cancel(this.reconnectHandler);

        this.failedReconnects = 0;
        this.reconnectHandler = undefined;
      }
    } catch (error) {
      console.error('Error cancelling pending reconnects: ', error);
    }
  }

  onVisibilityChangeHandler() {
    if (this.isTabActive) {
      cancel(this.disconnectHandler);
      this.cancelPendingReconnects();
      this.addKeepaliveTimer();

      throttle(this, this.connect, this.reconnectTimeout, true);
    } else {
      this.disconnectHandler = later(this, this.disconnect, DISCONNECT_TIMEOUT);

      this.cancelKeepaliveTimer();
    }
  }

  addVisibilityListener() {
    if (!this.boundOnVisibilityChange) {
      this.boundOnVisibilityChange = this.onVisibilityChangeHandler.bind(this);

      document.addEventListener(
        'visibilitychange',
        this.boundOnVisibilityChange
      );
    }
  }

  addKeepaliveTimer() {
    if (!this.keepalive) {
      this.keepalive = this.keepAliveTimer();
    }
  }

  cancelKeepaliveTimer() {
    if (this.keepalive) {
      clearTimeout(this.keepalive);

      this.keepalive = undefined;
    }
  }

  keepAliveTimer() {
    try {
      return setInterval(() => {
        if (this.isTabActive) {
          this.send({
            data: {
              attributes: {
                action: 'keepalive',
                payload: {},
              },
            },
          });
        }
      }, KEEPALIVE_TIMEOUT);
    } catch (error) {
      console.error('Error setting keepalive timer: ', error);

      return undefined;
    }
  }

  async onOpen(event: WebSocket) {
    try {
      await this.subscribe('shoelace::general');

      if (this.session.customer) {
        this.subscribe(`shoelace::customer::${this.session.customer.id}`);
      }

      if (this.account.account) {
        this.subscribe(`shoelace::account::${this.account.account.id}`);
      }

      await this.check_for_updates();
    } catch (error) {
      console.error('Error on open: ', error);
    }
  }

  async onMessage(event: any) {
    try {
      this.pushActions.receive(JSON.parse(event.data));
    } catch (error) {
      console.error('Error parsing message as JSON: ', event?.data?.toString());
    }
  }

  async onClose(event: any) {
    try {
      this.disconnect();

      this.reconnectHandler = throttle(
        this,
        this.connect,
        this.reconnectTimeout,
        false
      );
    } catch (error) {
      //
    }

    this.failedReconnects++;
  }

  async onError(event: any) {}

  /**
   * Conveniences
   */

  async send(json: Request) {
    try {
      if (this.socket) {
        await this.socket.send({ ...json, ...this.meta }, true);
      }
    } catch (error) {
      console.error('Error sending message: ', error);
    }
  }

  /**
   * Actions to be SENT to the server
   */

  async subscribe(name = 'shoelace::general') {
    await this.send({
      data: {
        attributes: {
          action: 'subscribe',
          payload: {
            name,
          },
        },
      },
    });
  }

  async unsubscribe(name = 'shoelace::general') {
    await this.send({
      data: {
        attributes: {
          action: 'unsubscribe',
          payload: {
            name,
          },
        },
      },
    });
  }

  async acknowledge(message: string) {
    await this.send({
      data: {
        attributes: {
          action: 'acknowledge',
          payload: {
            message,
          },
        },
      },
    });
  }

  async check_for_updates() {
    await this.send({
      data: {
        attributes: {
          action: 'check_for_updates',
          payload: {},
        },
      },
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    push: PushService;
  }
}
