import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';
import AccountProjectOrderLineItemModel from './account-project-order-line-item';
import AccountProjectReleaseModel from './account-project-release';

const Validations = buildValidations({
  validQuantity: validator('inclusion', {
    in: [true],
    message: 'Invalid quantity',
  }),
});

export default class AccountProjectReleaseLineItemModel extends Model.extend(
  Validations,
) {
  @attr('number') quantity!: null | number;
  @attr('string') notes!: null | string;

  @belongsTo('account-project-release')
  accountProjectRelease!: AsyncBelongsTo<AccountProjectReleaseModel>;

  @belongsTo('account-project-order-line-item')
  accountProjectOrderLineItem!: AsyncBelongsTo<AccountProjectOrderLineItemModel>;

  @computed('quantity', 'accountProjectOrderLineItem.remainingQuantity')
  get validQuantity(): boolean {
    return (
      (this.quantity ?? 0) <=
      this.accountProjectOrderLineItem.get('remainingQuantity')
    );
  }

  @computed('accountProjectOrderLineItem.accountProjectOrder.id')
  get orderId(): string {
    return this.accountProjectOrderLineItem.get('accountProjectOrder').get('id');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-project-release-line-item': AccountProjectReleaseLineItemModel;
  }
}
