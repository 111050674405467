import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class QuickOrderGuideModel extends Model {
  @attr('string') name;
  @attr('string') slug;
  @attr('string') imageUrl;

  @belongsTo('quick-order-guide-group') quickOrderGuideGroup;

  @hasMany('quick-order-component') quickOrderComponents;
}
