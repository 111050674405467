import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreBalanceIndexRoute extends Route {
  @service account;
  @service store;

  isChecked = false;
  queryParams = {
    sortAscending: {
      refreshModel: true,
    },
    sortProperty: {
      refreshModel: true,
    },
  };

  model(params) {
    const routeParts = this.routeName.split('.'); // => ['store', 'balance', 'index']
    const parentRouteName = routeParts
      .slice(0, routeParts.length - 1)
      .join('.'); // => ["store", "balance"].join('.') => "store.balance"
    const model = this.modelFor(parentRouteName);
    const { sortProperties } = model;
    const { groups = [] } = model;
    let { sortAscending, sortProperty } = params;

    sortProperty = sortProperties.findBy('key', sortProperty);

    if (isEmpty(sortProperty)) {
      sortProperty = sortProperties.findBy('key', 'documentDate');
    }

    if (isEmpty(sortAscending)) {
      sortAscending = true;
    } else if (sortAscending === 'false') {
      sortAscending = false;
    }

    if (sortAscending) {
      groups.forEach((group) =>
        group.children = group.children.sortBy(sortProperty.key)
      );
    } else {
      groups.forEach((group) =>
        group.children = group.children.sortBy(sortProperty.key).reverse()
      );
    }

    // Mutate the parent state
    model.sortAscending = sortAscending;
    model.sortProperty = sortProperty;

    return model;
  }
}
export default instrumentRoutePerformance(StoreBalanceIndexRoute);
