import Route from '@ember/routing/route';
import type ServiceCenter from 'shoelace/models/service-center';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface AdminServiceCenterRouteParams {
  service_center_id: number | string;
}

class AdminServiceCenterRoute extends Route {
  @service declare store: StoreService;
 
  async model({
    service_center_id: id,
  }: AdminServiceCenterRouteParams): Promise<ServiceCenter> {
    const serviceCenter = await this.store.findRecord('service-center', id);
    return serviceCenter;
  }
}

export default instrumentRoutePerformance(
  AdminServiceCenterRoute
);