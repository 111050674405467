import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each this.model as |attribute attributeNumber|}}\n  {{#if (lt attributeNumber this.numberOfRowsToShow)}}\n    {{yield attribute}}\n  {{/if}}\n{{/each}}\n{{#if (gt this.numberOfAdditionalRows 0)}}\n  <button class=\"btn btn-link pl-0\" {{on \"click\" (action this.toggle)}}>\n    {{#if this.expanded}}\n      <FaIcon @icon=\"minus\" />\n      Show Less\n    {{else}}\n      <FaIcon @icon=\"plus\" />\n      Show\n      {{this.numberOfAdditionalRows}}\n      More\n    {{/if}}\n  </button>\n{{/if}}", {"contents":"{{#each this.model as |attribute attributeNumber|}}\n  {{#if (lt attributeNumber this.numberOfRowsToShow)}}\n    {{yield attribute}}\n  {{/if}}\n{{/each}}\n{{#if (gt this.numberOfAdditionalRows 0)}}\n  <button class=\"btn btn-link pl-0\" {{on \"click\" (action this.toggle)}}>\n    {{#if this.expanded}}\n      <FaIcon @icon=\"minus\" />\n      Show Less\n    {{else}}\n      <FaIcon @icon=\"plus\" />\n      Show\n      {{this.numberOfAdditionalRows}}\n      More\n    {{/if}}\n  </button>\n{{/if}}","moduleName":"shoelace/components/expandable-table.hbs","parseOptions":{"srcName":"shoelace/components/expandable-table.hbs"}});
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

interface ExpandableTableComponentArgs {
  defaultVisibleRows: number;
  expanded: boolean;
  model: any;
  numberOfColumns: number;
}

export default class ExpandableTableComponent extends Component<ExpandableTableComponentArgs> {
  @tracked defaultVisibleRows = (this.args && this.args.defaultVisibleRows) || 1;
  @tracked expanded = (this.args && this.args.expanded) || false;
  @tracked model = (this.args && this.args.model) || [];
  @tracked numberOfColumns = (this.args && this.args.numberOfColumns) || 1;

  get numberOfRowsToShow() {
    if (this.expanded) {
      return this.model.length;
    } else {
      return this.defaultVisibleRows;
    }
  }

  get numberOfAdditionalRows() {
    return Math.max(0, this.model.length - this.defaultVisibleRows);
  }

  @action toggle() {
    this.expanded = !this.expanded;
  }
}
