import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"relative inline-block w-8 align-middle select-none transition duration-200 ease-in flex-shrink-0\">\n  <input\n    class=\"absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer toggle-checkbox {{if @checked 'border-indigo-500' 'border-gray-300'}} {{if (or @disabled) 'cursor-not-allowed'}}\"\n    data-tag={{@id}}\n    type=\"checkbox\"\n    checked={{@checked}}\n    {{on \"change\" this.onChange}}\n    id={{@id}}\n    ...attributes\n  />\n  <label\n    for=\"{{@id}}\"\n    class=\"block overflow-hidden h-5 rounded-full  cursor-pointer toggle-label  {{if @checked 'bg-indigo-500 border-indigo-500' 'border-gray-300 bg-gray-300'}} {{if (or @disabled) 'cursor-not-allowed'}}\"\n  ></label>\n</div>\n", {"contents":"<div class=\"relative inline-block w-8 align-middle select-none transition duration-200 ease-in flex-shrink-0\">\n  <input\n    class=\"absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer toggle-checkbox {{if @checked 'border-indigo-500' 'border-gray-300'}} {{if (or @disabled) 'cursor-not-allowed'}}\"\n    data-tag={{@id}}\n    type=\"checkbox\"\n    checked={{@checked}}\n    {{on \"change\" this.onChange}}\n    id={{@id}}\n    ...attributes\n  />\n  <label\n    for=\"{{@id}}\"\n    class=\"block overflow-hidden h-5 rounded-full  cursor-pointer toggle-label  {{if @checked 'bg-indigo-500 border-indigo-500' 'border-gray-300 bg-gray-300'}} {{if (or @disabled) 'cursor-not-allowed'}}\"\n  ></label>\n</div>\n","moduleName":"shoelace/components/apex/toggle-switch.hbs","parseOptions":{"srcName":"shoelace/components/apex/toggle-switch.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface ApexSecureCheckoutIndexOrderSummaryArgs {
  id: string;
  checked: boolean;
  onChange: (event: Event) => Promise<void>;
}

export default class ApexSecureCheckoutIndexOrderSummary extends Component<ApexSecureCheckoutIndexOrderSummaryArgs> {
  @action async onChange(event: Event): Promise<void> {
    await this.args?.onChange?.(event);
  }
}
