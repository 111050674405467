import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import CustomerChangeEmailModel from 'shoelace/models/customer-change-email';
import Session from 'ember-simple-auth/services/session';

export default class StoreCustomerEmailController extends Controller {
  @tracked isValidationVisible = false;

  @service declare router: RouterService;
  @service declare session: Session;

  @action async changeEmail(
    customerChangeEmailModel: CustomerChangeEmailModel,
    event: Event,
  ) {
    event.preventDefault();

    await customerChangeEmailModel.validate();

    if (customerChangeEmailModel.validations.isValid) {
      try {
        this.isValidationVisible = false;

        await customerChangeEmailModel.save();

        alert('Email change pending: Check your email to finish this process!');

        return this.router.transitionTo(
          'store.customer',
          this.session?.customer?.id,
        );
      } catch (error) {
        this.isValidationVisible = true;
      }
    } else {
      this.isValidationVisible = true;
    }
  }
}
