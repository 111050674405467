import Model from '@ember-data/model';
import { attr } from '@ember-data/model';

export default Model.extend({
  name: attr('string'),
  name2: attr('string'),
  street: attr('string'),
  street2: attr('string'),
  po_box: attr('string'),
  city: attr('string'),
  county: attr('string'),
  state: attr('string'),
  zip_code: attr('string'),
  country: attr('string'),
});
