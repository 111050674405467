import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountProjectOrderLineItemDeliveryModel from 'shoelace/models/account-project-order-line-item-delivery';
import type AccountProjectOrderLineItemDeliveryAttributeDefinitionModel from 'shoelace/models/account-project-order-line-item-delivery-attribute-definition';

export default class AccountProjectOrderLineItemDeliveryAttributeModel extends Model {
  @attr('string') name!: null | string;

  @belongsTo('account-project-order-line-item-delivery')
  accountProjectOrderLineItemDelivery!: AsyncBelongsTo<AccountProjectOrderLineItemDeliveryModel>;

  @belongsTo('account-project-order-line-item-delivery-attribute-definition')
  accountProjectOrderLineItemDeliveryAttributeDefinition!: AsyncBelongsTo<AccountProjectOrderLineItemDeliveryAttributeDefinitionModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-project-order-line-item-delivery-attribute': AccountProjectOrderLineItemDeliveryAttributeModel;
  }
}
