import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type CustomerModel from 'shoelace/models/customer';
// import type RbacRoleModel from 'shoelace/models/rbac-role';
// import RbacAccountRoleModel from './rbac-account-role';

export default class RbacAccountCustomerRoleModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('date') updatedAt!: null | Date;
  // @attr('string') rbac_account_role_id!: null | string;
  @attr('string') account_id!: null | string;
  @attr('string') customer_id!: null | string;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('customer')
  customer!: AsyncBelongsTo<CustomerModel>;

  @hasMany('rbac-account-customer-permission')
  rbacAccountCustomerPermissions!: any;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'rbac-account-customer-role': RbacAccountCustomerRoleModel;
  }
}
