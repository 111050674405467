import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type ProcurementService from 'shoelace/services/procurement';
import type RouterService from '@ember/routing/router';

export default class LoginController extends Controller {
  @service declare procurement: ProcurementService;
  @service declare router: RouterService;

  queryParams = ['guest'];

  @tracked guest = false;

  @action async onContinueAsGuest(): Promise<void> {
    try {
      if (this.procurement.procurement) {
        this.procurement.procurement.isGuest = true;

        this.router.transitionTo('secure-checkout.billing');
      } else {
        const error = new Error('Error Continuing as Guest');
        throw error;
      }
    } catch (error) {
      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    login: LoginController;
  }
}
