import Helper from '@ember/component/helper';

export default Helper.extend({
  compute(params) {
    let count = params[0];
    let max = params[1];
    let noun;

    if (count === max) {
      count = 'All';
    }

    if (count === 1) {
      noun = 'item in your order qualifies';
    } else {
      noun = 'items in your order qualify';
    }
    return count + ' ' + noun;
  },
});
