import Controller from '@ember/controller';
import { computed, action } from '@ember/object';
import { equal, or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default class StoreCustomerIndexController extends Controller {
  @service session;
  @service router;
  @service kart;

  @equal('router.currentRouteName', 'store.accounts.index') hideSearchForAccount;
  @equal('session.session.authenticated.authenticator', 'authenticator:po2go')
  isPunchout;
  @or('hasOrdering', 'isPunchout') hasQuotes;
  @readOnly('session.customer') customer;

  // @computed('customer.aclPermissions.@each.name')
  // get hasAMPS() {
  //   return this.get('customer.aclPermissions')?.isAny(
  //     'name',
  //     'account::project_management',
  //   );
  // }

  // @computed('customer.aclPermissions.@each.name')
  // get hasCreditAdmin() {
  //   return this.get('customer.aclPermissions')?.isAny('name', 'credit::manage');
  // }

  // @computed('customer.aclAccountPermissions.@each.name')
  // get hasAccountFinancials() {
  //   return this.get('customer.aclAccountPermissions')?.isAny(
  //     'name',
  //     'account::financial',
  //   );
  // }

  // @computed('customer.acls.@each.name')
  // get hasGlobalAccountFinancials() {
  //   return this.get('customer.acls')?.isAny('name', 'account::financial');
  // }

  // @computed('customer.aclAccountPermissions.@each.name')
  // get hasOrdering() {
  //   return this.get('customer.aclAccountPermissions')?.isAny(
  //     'name',
  //     'account::ecommerce',
  //   );
  // }

  // @computed('customer.acls.@each.name')
  // get hasGlobalOrdering() {
  //   return this.get('customer.acls')?.isAny('name', 'account::ecommerce');
  // }

  @action async logout() {
    await Promise.all([
      fetch('/api/tokens/1', {
        method: 'DELETE',
      }).then(res => res.text()),
    ]);

    this.session.invalidate('authenticator:jwt');
    this.kart.items.forEach(async item => {
      await this.kart.removeItem(item.product);
    });

    this.router.transitionTo('/');
  }
}
