import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type AccountHistoryOrderBillAddressModel from 'shoelace/models/account-history-order-bill-address';
import type AccountHistoryOrderCommentModel from 'shoelace/models/account-history-order-comment';
import type AccountHistoryOrderDeliveryModel from 'shoelace/models/account-history-order-delivery';
import type AccountHistoryOrderFundAddressModel from 'shoelace/models/account-history-order-fund-address';
import type AccountHistoryOrderInvoiceModel from 'shoelace/models/account-history-order-invoice';
import type AccountHistoryOrderLineItemModel from 'shoelace/models/account-history-order-line-item';
import type AccountHistoryOrderQuoteModel from 'shoelace/models/account-history-order-quote';
import type AccountHistoryOrderShipAddressModel from 'shoelace/models/account-history-order-ship-address';
import type AccountHistoryOrderSoldAddressModel from 'shoelace/models/account-history-order-sold-address';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';

export default class AccountHistoryOrderModel extends Model {
  @attr('string') category!: null | string;
  @attr('date') createdAt!: null | Date;
  @attr('date') expectedAt!: null | Date;
  @attr('string') jobAccountName!: null | string;
  @attr('string') kind!: null | string;
  @attr('number') numberOfItems!: null | number;
  @attr('string') overallStatus!: null | string;
  @attr('string') purchaseOrder!: null | string;
  @attr('number') salesTax!: null | number;
  @attr('string') shippingType!: null | string;
  @attr('string') status!: null | string;
  @attr('number') subtotal!: null | number;
  @attr('number') total!: null | number;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-job-account')
  accountJobAccount!: AsyncBelongsTo<AccountJobAccountModel>;

  @belongsTo('account-history-order-bill-address')
  accountHistoryOrderBillAddress!: AsyncBelongsTo<AccountHistoryOrderBillAddressModel>;

  @belongsTo('account-history-order-fund-address')
  accountHistoryOrderFundAddress!: AsyncBelongsTo<AccountHistoryOrderFundAddressModel>;

  @belongsTo('account-history-order-ship-address')
  accountHistoryOrderShipAddress!: AsyncBelongsTo<AccountHistoryOrderShipAddressModel>;

  @belongsTo('account-history-order-sold-address')
  accountHistoryOrderSoldAddress!: AsyncBelongsTo<AccountHistoryOrderSoldAddressModel>;

  @hasMany('account-history-order-comment')
  accountHistoryOrderComments!: AsyncHasMany<AccountHistoryOrderCommentModel>;

  @hasMany('account-history-order-line-item')
  accountHistoryOrderLineItems!: AsyncHasMany<AccountHistoryOrderLineItemModel>;

  @hasMany('account-history-order-delivery')
  accountHistoryOrderDeliveries!: AsyncHasMany<AccountHistoryOrderDeliveryModel>;

  @hasMany('account-history-order-invoice')
  accountHistoryOrderInvoices!: AsyncHasMany<AccountHistoryOrderInvoiceModel>;

  @hasMany('account-history-order-quote')
  accountHistoryOrderQuotes!: AsyncHasMany<AccountHistoryOrderQuoteModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-order': AccountHistoryOrderModel;
  }
}
