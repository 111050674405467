import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type RouterService from '@ember/routing/router';
import type CustomerModel from 'shoelace/models/customer';
import type SessionService from 'shoelace/services/session';
import { instrumentRoutePerformance } from '@sentry/ember';

class ManageAccountVerificationsRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare session: SessionService;
  
  async model() {
    const customer: CustomerModel | undefined = this.session.customer;
    const customerId = customer?.get('id');
    let accountVerification;

    if (customerId) {
      accountVerification = this.store.createRecord('account-verification');
    } else {
      this.router.transitionTo('login');
    }
    return {
      customer,
      accountVerification,
    };
  }
}
export default instrumentRoutePerformance(ManageAccountVerificationsRoute);
