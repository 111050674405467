import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountProjectReleaseModel from 'shoelace/models/account-project-release';
import type CustomerModel from 'shoelace/models/customer';

export default class AccountProjectReleaseSignerModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('string') decision!: null | string;
  @attr('date') updatedAt!: null | Date;

  @belongsTo('account-project-release')
  accountProjectRelease!: AsyncBelongsTo<AccountProjectReleaseModel>;

  @belongsTo('customer')
  customer!: AsyncBelongsTo<CustomerModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-project-release-signer': AccountProjectReleaseSignerModel;
  }
}
