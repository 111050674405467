import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type RbacAccountCustomerRoleModel from 'shoelace/models/rbac-account-customer-role';
import type RbacAccountRoleModel from 'shoelace/models/rbac-account-role';
import type RbacCustomerRoleModel from 'shoelace/models/rbac-customer-role';
import type RbacPermissionRoleModel from 'shoelace/models/rbac-permission-role';
import { computed } from '@ember/object';

export default class RbacRoleModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('date') updatedAt!: null | Date;
  @attr('string') name!: null | string;

  @belongsTo('rbac-role')
  rbacRole!: AsyncBelongsTo<RbacRoleModel>;

  // @hasMany('rbac-role')
  // rbacRoles!: AsyncHasMany<RbacRoleModel>;

  @hasMany('rbac-permission-role')
  rbacPermissionRoles!: AsyncHasMany<RbacPermissionRoleModel>;

  @hasMany('rbac-customer-role')
  rbacCustomerRoles!: AsyncHasMany<RbacCustomerRoleModel>;

  @hasMany('rbac-account-role')
  rbacAccountRoles!: AsyncHasMany<RbacAccountRoleModel>;

  @hasMany('rbac-account-customer-role')
  rbacAccountCustomerRoles!: AsyncHasMany<RbacAccountCustomerRoleModel>;

  @computed('name')
  get rolename(): string {
    return `${this.name}`;
  }

}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'rbac-role': RbacRoleModel;
  }
}
