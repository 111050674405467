import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type SessionService from 'shoelace/services/session';
import type RouterService from '@ember/routing/router';
import { instrumentRoutePerformance } from '@sentry/ember';

class AdminRoute extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService;

  async beforeModel(): Promise<void> {
    if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    } else {
      const customer = await this.session.get('customer');
      if (customer) {
           if ((!customer?.hasRbacAdmin) && (!customer?.hasRbacAdminCredit) && (!customer?.hasRbacAdminMarketing) && (!customer?.hasRbacCustomerAdmin)) {
          this.router.transitionTo('store.customer', customer.id);
      }
    }
  }
}
async model() {
    const customer = await this.session.get('customer');
    return {
      customer
    };
  }
}
export default instrumentRoutePerformance(AdminRoute);
