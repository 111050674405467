import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { instrumentRoutePerformance } from '@sentry/ember';

class OrderConfirmationRoute extends Route {
  async model({ id }) {
    const model = {
      id: id,
    };

    return RSVP.hash(model);
  }
}
export default instrumentRoutePerformance(OrderConfirmationRoute);
