import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import type AccountModel from 'shoelace/models/account';
import type AccountHistoryOrderModel from 'shoelace/models/account-history-order';
import type AccountHistoryOrderLineItemCommentModel from 'shoelace/models/account-history-order-line-item-comment';
import type AccountHistoryOrderLineItemDeliveryModel from 'shoelace/models/account-history-order-line-item-delivery';
import type AccountHistoryOrderLineItemInvoiceModel from 'shoelace/models/account-history-order-line-item-invoice';
import type AccountHistoryOrderLineItemQuoteModel from 'shoelace/models/account-history-order-line-item-quote';
import type AccountHistoryOrderShipAddressModel from 'shoelace/models/account-history-order-ship-address';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type ProductModel from 'shoelace/models/product';

export default class AccountHistoryOrderLineItemModel extends Model {
  @attr('string') customerPartNumber!: null | string;
  @attr('number') deliveredQuantity!: null | number;
  @attr('string') deliveryStatus!: null | string;
  @attr('boolean') isLotKit!: null | boolean;
  @attr('boolean') isSummitBuck!: null | boolean;
  @attr('string') name!: null | string;
  @attr('number') numberPerUnit!: null | number;
  @attr('number') position!: null | number;
  @attr('number') postGoodsIssueQuantity!: null | number;
  @attr('number') price!: null | number;
  @attr('number') pricePerUnit!: null | number;
  @attr('string') purchaseOrder!: null | string;
  @attr('number') quantity!: null | number;
  @attr('string') shippingType!: null | string;
  @attr('string') status!: null | string;
  @attr('number') total!: null | number;
  @attr('number') totalLot!: null | number;
  @attr('string') unitOfMeasure!: null | string;
  @attr('string') unknownItemType!: null | string;
  @attr('string') unknownTextFlag!: null | string;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-job-account')
  accountJobAccount!: AsyncBelongsTo<AccountJobAccountModel>;

  @belongsTo('account-history-order-ship-address')
  accountHistoryOrderShipAddress!: AsyncBelongsTo<AccountHistoryOrderShipAddressModel>;

  @belongsTo('account-history-order')
  accountHistoryOrder!: AsyncBelongsTo<AccountHistoryOrderModel>;

  @belongsTo('product')
  product!: AsyncBelongsTo<ProductModel>;

  @hasMany('account-history-order-line-item-comment')
  accountHistoryOrderLineItemComments!: AsyncHasMany<AccountHistoryOrderLineItemCommentModel>;

  @hasMany('account-history-order-line-item-delivery')
  accountHistoryOrderLineItemDeliveries!: AsyncHasMany<AccountHistoryOrderLineItemDeliveryModel>;

  @hasMany('account-history-order-line-item-invoice')
  accountHistoryOrderLineItemInvoices!: AsyncHasMany<AccountHistoryOrderLineItemInvoiceModel>;

  @hasMany('account-history-order-line-item-quote')
  accountHistoryOrderLineItemQuotes!: AsyncHasMany<AccountHistoryOrderLineItemQuoteModel>;

  @computed('deliveredQuantity', 'quantity')
  get remainingQuantity(): number {
    return (this.quantity ?? 0) - (this.deliveredQuantity ?? 0);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-order-line-item': AccountHistoryOrderLineItemModel;
  }
}
