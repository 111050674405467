import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import { EXCEPTION_1066 } from 'shoelace/exceptions/mod';
import TranslationModel from 'shoelace/models/translation';
//import type RollbarService from 'shoelace/services/rollbar';
import StoreService from '@ember-data/store';

export default class AdminDynamicTranslationController extends Controller {
//@service declare rollbar: RollbarService;

@service declare router: RouterService;

@service declare store: StoreService;

  @action async onEditDynamicTranslation(
    translation: TranslationModel,
  ): Promise<void> {
    try {
      await translation.save();

      this.router.transitionTo('admin.dynamic-translations');
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1066, error);

      throw error;
    }
  }

  @action async onSetTranslationParent(
    translation: TranslationModel,
    parentId: string,
  ): Promise<void> {
    translation.translation = await this.store.findRecord(
      'translation',
      parentId,
    );
  }

  @action async onSetTranslationAccount(
    translation: TranslationModel,
    accountId: string,
  ): Promise<void> {
    translation.account = await this.store.findRecord('account', accountId);
  }

  @action async onSetTranslationAccountProject(
    translation: TranslationModel,
    accountProjectId: string,
  ): Promise<void> {
    translation.accountProject = await this.store.findRecord(
      'account-project',
      accountProjectId,
    );
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/dynamic-translation/index': AdminDynamicTranslationController;
  }
}
