import Controller from '@ember/controller';
import { action } from '@ember/object';
import ObjectProxy from '@ember/object/proxy';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import { EXCEPTION_1041, EXCEPTION_1042 } from 'shoelace/exceptions/mod';
import type ServiceCenter from 'shoelace/models/service-center';
import type {
  default as ServiceCenterDowHourModel,
  default as ServiceCenterDowHours,
} from 'shoelace/models/service-center-dow-hour';
//import type RollbarService from 'shoelace/services/rollbar';

export default class AdminServiceCenterEditServiceCenterHoursController extends Controller {
//@service declare rollbar: RollbarService;

@service declare router: RouterService;

  @action async onEditServiceCenterHours(
    serviceCenter: ServiceCenter & ObjectProxy<ServiceCenter>,
    serviceCenterDowHours: ServiceCenterDowHourModel[],
  ): Promise<void> {
    try {
      const serviceCenterId = await serviceCenter.get('id');

      serviceCenterDowHours.forEach(serviceCenterDowHour => {
        if (serviceCenterDowHour.hasDirtyAttributes) {
          const openHours = serviceCenterDowHour.openUtc;
          const closeHours = serviceCenterDowHour.closeUtc;

          if (openHours && closeHours) {
            if (Array.isArray(openHours)) {
              const convertedUtcTime = openHours[0];
              const hour = convertedUtcTime.getHours();

              convertedUtcTime.setUTCHours(hour);
              convertedUtcTime.toUTCString();

              serviceCenterDowHour.set('open', convertedUtcTime);
            }

            if (Array.isArray(closeHours)) {
              const newTime = closeHours[0];
              const hour = newTime.getHours();

              newTime.setUTCHours(hour);
              newTime.toUTCString();

              serviceCenterDowHour.set('close', newTime);
            }
            serviceCenterDowHour.save();
          }
        }
      });

      this.router.transitionTo('admin.service-center', serviceCenterId);
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1041, error);
      throw error;
    }
  }

  @action async onRemoveHours(
    serviceCenter: ServiceCenter & ObjectProxy<ServiceCenter>,
    serviceCenterDowHour: ServiceCenterDowHours &
      ObjectProxy<ServiceCenterDowHours>,
  ): Promise<void> {
    try {
      const serviceCenterId = await serviceCenter.get('id');

      await serviceCenterDowHour.destroyRecord();

      this.router.transitionTo('admin.service-center', serviceCenterId);
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1042, error);
      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/service-center/edit-service-center-hours': AdminServiceCenterEditServiceCenterHoursController;
  }
}
