import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreServicesMyProjectsReleasesNewRoute extends Route {
  @service store;

  async model() {
    const { accountProjects, customer } = this.modelFor(
      'store.services.my-projects'
    );

    const newAccountProjectRelease = this.store.createRecord(
      'account-project-release',
      {
        customer,
      }
    );

    return {
      accountProjects,
      newAccountProjectRelease,
    };
  }

  @action willTransition() {
    if (this.controller.model.newAccountProjectRelease.isNew) {
      this.controller.model.newAccountProjectRelease.deleteRecord();
    }
  }
}
export default instrumentRoutePerformance(
  StoreServicesMyProjectsReleasesNewRoute
);
