import type StoreService from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type NotificationModel from 'shoelace/models/notification';
import type { BootOptions } from 'shoelace/services/coordinator';

export default class NotificationsService extends Service {
  @service
  declare store: StoreService;

  @tracked
  notifications: NotificationModel[] = [];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async onCoordinatorSetup(_options?: BootOptions): Promise<void> {
    this.notifications = (await this.store.findAll('notification')).toArray();
  }

  async onCoordinatorTeardown(): Promise<void> {
    // this.notifications = [];
  }
}

declare module '@ember/service' {
  interface Registry {
    notifications: NotificationsService;
  }
}
