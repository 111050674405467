import Helper from '@ember/component/helper';

export default Helper.extend({
  compute([array, attrName, attrVal]) {
    if (attrVal)
      return (
        array.filter(a => a.attribute == attrName && a.value.includes(attrVal))
          .length > 0
      );
    else return array.filter(a => a.attribute == attrName).length > 0;
  },
});
