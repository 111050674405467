import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class TooltipForManager extends Component {
  @tracked tagName = this.args?.tagName ?? 'span';
  @tracked icon = this.args?.icon ?? 'icon-flag';
  @tracked open = this.args?.open ?? false;

  @action async click() {
    this.toggleProperty('open');
  }
}
