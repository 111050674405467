import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import ProtectedRoute from '../../mixins/protected-route';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreAccountPaymentRoute extends Route {
  @service session;
  @service store;
  @service router;

  async beforeModel(transition) {
    if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }
  }

  async model({ id }) {
    try {
      const customer = this.session.get('customer');
      const payment = await this.store.findRecord('account-payment', id, {
        include: 'account-payment-line-items',
      });
      const lineItems = await payment.accountPaymentLineItems;

      const clearingDocNo = payment.id.split('-')[1];

      const lineItemsTotal = lineItems
        .map((li) => {
          return li.isCredit ? -li.get('total') : li.get('total');
        })
        .reduce((a, b) => a + b);

      payment.clearingDocNo = clearingDocNo;
      payment.lineItemsTotal = lineItemsTotal;

      const model = { payment, customer };
      return RSVP.hash(model);
    } catch (e) {
      return this.router.transitionTo('store.account-payments', {
        queryParams: { paymentNotFound: true },
      });
    }
  }
}
export default instrumentRoutePerformance(StoreAccountPaymentRoute);
