import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class StoreAccountPaymentsController extends Controller {
  // queryParams = ['query', 'range', 'sortAscending', 'sortProperty'];

  @tracked query;
  @tracked range;
  @tracked sortProperty;
  @tracked status;
  @tracked sortAscending;

  get sort() {
    if (this.sortAscending) {
      return `${this.sortProperty}`;
    } else {
      return `${this.sortProperty}:desc`;
    }
  }

  @action onRange(dateRange) {
    this.range = dateRange;
  }
}
