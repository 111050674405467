import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';
import type AccountModel from 'shoelace/models/account';
import type CustomerModel from 'shoelace/models/customer';
import type ProductModel from 'shoelace/models/product';

const Validations = buildValidations({
  email: {
    description: 'From Email Address',
    validators: [
      validator('format', {
        type: 'email',
        allowNonTld: false,
        message: 'Your email must not be blank.',
      }),
    ],
  },

  to_email: {
    description: 'To Email Address',
    validators: [
      validator('format', {
        type: 'email',
        allowNonTld: false,
        message: "Recipient's must not be blank.",
      }),
    ],
  },

  message: {
    description: 'Message',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Message field must not be blank.',
      }),
    ],
  },
});

export default class ProductInfoModel extends Model.extend(Validations) {
  @attr('string')
  declare name?: string;

  @attr('string')
  declare email?: string;

  @attr('string')
  declare phone?: string;

  @attr('string')
  declare company?: string;

  @attr('string')
  declare to_email?: string;

  @attr('string')
  declare message?: string;

  @attr('string')
  declare job_account?: string;

  @attr('string')
  declare contact_preference?: string;

  @attr('string')
  declare extension?: string;

  @attr('string')
  declare location?: string;

  @attr('number')
  declare customer_id?: number;

  @attr('number')
  declare product_id?: number;

  @attr('number')
  declare quantity?: number;

  @attr('boolean')
  declare include_order_items?: boolean;

  @attr('boolean')
  declare include_request_info?: boolean;

  @belongsTo('account')
  declare account?: AsyncBelongsTo<AccountModel>;

  @belongsTo('customer')
  declare customer?: AsyncBelongsTo<CustomerModel>;

  @belongsTo('product')
  declare product?: AsyncBelongsTo<ProductModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    productInfo: ProductInfoModel;
  }
}
