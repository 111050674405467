import Route from '@ember/routing/route';
import type AccountModel from 'shoelace/models/account';
import type CustomerModel from 'shoelace/models/customer';
import type PermissionModel from 'shoelace/models/permission';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';
import RbacRoleModel from 'shoelace/models/rbac-role';

export interface RouteModel {
  account: AccountModel;
  customer: CustomerModel;
  permissions: RbacRoleModel[];
}

class AdminAccountNewCustomerRoute extends Route {
  @service declare store: StoreService;
 
  async model(): Promise<RouteModel> {
    const account = this.modelFor('admin.account') as AccountModel;
    const customer = await this.store.createRecord('customer');

    customer.requestedPermissionsRequired = true;

    const allPermissions = await this.store.findAll('rbac-permission');
    const permissions = allPermissions.filter(permission =>
      permission.get('name').includes('customer.'),
    );
    permissions.forEach(async (permission) => {
      if (permission.name == "customer.default") {
        permissions.removeObject(permission);
      }
    });
    
    return {
      account,
      permissions,
      customer,
    };
  }
}
export default instrumentRoutePerformance(AdminAccountNewCustomerRoute);