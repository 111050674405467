// app/helpers/json-pretty-print.js
// usage {{json-pretty-print someJson}}
// see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#The_space_argument

import Helper from '@ember/component/helper';

export default Helper.extend({
  compute(params, hash) {
    const [json] = params;
    return JSON.stringify(json, null, '  ');
  },
});
