import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class PunchoutsPo2goLandingErrorRoute extends Route {
}

export default instrumentRoutePerformance(
  PunchoutsPo2goLandingErrorRoute
);
