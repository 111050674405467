import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { v1 } from 'uuid';
import OrderingDictionary from '../../order_dictionary';
import type ServiceCenterService from 'shoelace/services/service-center';
import type AccountService from 'shoelace/services/account';
import type SessionService from 'shoelace/services/session';
import StoreService from '@ember-data/store';
import type RouterService from '@ember/routing/router';
import { EXCEPTION_1009 } from 'shoelace/exceptions/mod';
import MetricsService from 'shoelace/services/metrics';
//import type RollbarService from 'shoelace/services/rollbar';

export default class StoreOrderConfirmationController extends Controller {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare account: AccountService;
  @service declare serviceCenter: ServiceCenterService;
  @service declare metrics: MetricsService;

  @action async confirm(quoteNo: number) {
    const id = v1();
    const logPurchase = async (procurementId: any, quoteNo: any) => {
      try {
        const quote = await this.store.findRecord(
          'account-history-quote',
          quoteNo,
          {
            include: ['account-history-quote-line-items'].join(','),
          }
        );

        const quoteLineItems = await Promise.all(
          quote.accountHistoryQuoteLineItems.map((li) => li)
        );

        if (!this.metrics?.enabled) return;
        if (quoteLineItems) {
          let items = quoteLineItems.map((lineItem: any, index) => {
            return this.metrics.productAsEcommerceItem(
              lineItem?.product ?? '',
              lineItem?.quantity ?? '',
              lineItem?.total ?? '',
              index,
              'begin_checkout'
            );
          });

          this.metrics.events.ecommerce.begin_checkout({
            currency: 'USD',
            value: quote?.total ?? 0,
            items: items,
          });

          items = quoteLineItems.map((lineItem: any, index) => {
            return this.metrics.productAsEcommerceItem(
              lineItem?.product ?? '',
              lineItem?.quantity ?? '',
              lineItem?.total ?? '',
              index,
              'add_shipping_info'
            );
          });

          this.metrics.events.ecommerce.add_shipping_info({
            currency: 'USD',
            value: quote?.total ?? 0,
            shipping_tier: 'Ground',
            items: items,
          });

          items = quoteLineItems.map((lineItem: any, index) => {
            return this.metrics.productAsEcommerceItem(
              lineItem?.product ?? '',
              lineItem?.quantity ?? '',
              lineItem?.total ?? '',
              index,
              'add_payment_info'
            );
          });

          this.metrics.events.ecommerce.add_payment_info({
            currency: 'USD',
            value: quote?.total ?? 0,
            payment_type: 'Bill to Account',
            items: items,
          });

          items = quoteLineItems.map((lineItem: any, index) => {
            return this.metrics.productAsEcommerceItem(
              lineItem?.product ?? '',
              lineItem?.quantity ?? '',
              lineItem?.total ?? '',
              index,
              'purchase'
            );
          });

          this.metrics.events.ecommerce.purchase({
            currency: 'USD',
            affiliation: 'Summit.com',
            transaction_id: quoteNo,
            value: quote?.total ?? 0,
            items: items,
          });
        }
      } catch (e) {
        e;
      }
    };
    logPurchase(id, quoteNo);

    const customer = this.session.customer;
    const account = this.account.get('account');
    const accountServiceCenter = await account?.serviceCenter;
    const serviceCenter =
      this.serviceCenter.serviceCenter || accountServiceCenter;
    const purchaseOrder = null;

    const procurement = await this.store.createRecord('procurement', {
      id,
      account,
      customer,
      serviceCenter,
      purchaseOrder,
      confirmedAt: new Date(),
      kind: OrderingDictionary.KIND.QUOTETOPUNCHOUT,
      inputReferences: {
        quote_id: quoteNo,
      },
    });

    try {
      await procurement.save();
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1009, error);
    }

    const model = this.model;
    model.procurement = procurement;

    this.router.transitionTo('store.punchout-confirmation-processing', id);

    return model;
  }
}
