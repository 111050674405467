/* eslint-disable ember/no-computed-properties-in-native-classes */
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { action, computed } from '@ember/object';
import { dependentKeyCompat } from '@ember/object/compat';
import type RouterService from '@ember/routing/router-service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { storageFor } from 'ember-local-storage';
import EmberSimpleAuthSessionService from 'ember-simple-auth/services/session';
import fetch from 'fetch';
import ENV from 'shoelace/config/environment';
import type CustomerModel from 'shoelace/models/customer';
import type SessionModel from 'shoelace/models/session';
import type {
  BootOptions,
  default as CoordinatorService,
} from 'shoelace/services/coordinator';
import type ProcurementService from 'shoelace/services/procurement';
import jwt from '../utils/jwt';
import type { Storage } from 'ember-local-storage';

const { environment } = ENV;

type Awaited<T> = T extends Promise<infer U> ? U : T;

interface ServiceCenterStorage {
  id?: null | string;
}

export default class SessionService extends EmberSimpleAuthSessionService {
@service declare coordinator: CoordinatorService;
@service declare router: RouterService;
@service declare procurement: ProcurementService;

  @service('store')
  declare emberDataStore: StoreService;
  @storageFor('service-center')
  localStorageServiceCenter!: Storage<ServiceCenterStorage>;  

  @tracked cache = storageFor('cache');
  @tracked customer?: CustomerModel;
  @tracked currentModel?: SessionModel;
  @tracked fromlogin = false;

  /**
   * @deprecated use `customer` instead;
   */
  get _customer(): CustomerModel | undefined {
    return this.customer;
  }

  get id(): number | undefined {
    return this.token && jwt(this.token).customer_id;
  }

  get isInternal(): boolean {
    return this.customer?.get('isInternal') ?? false;
  }

  get isNotInternal(): boolean {
    return !this.isInternal;
  }

  /**
   * @deprecated use `id` instead.
   */
  get customerId(): number | undefined {
    return this.id;
  }

  get token(): string | undefined {
    return this.data?.authenticated?.data?.attributes?.token;
  }

  /**
   * @deprecated needs to be removed.
   */
  @computed('isAuthenticated')
  get isUnauthenticated(): boolean {
    return !this.isAuthenticated;
  }

  /**
   * @deprecated need to stop using `SessionModel`
   */
  @dependentKeyCompat
  get model(): SessionModel | undefined {
    return this.currentModel;
  }

  /**
   * @deprecated use `customer` instead
   */
  @dependentKeyCompat
  get customerRecord(): CustomerModel | undefined {
    return this.customer;
  }

  async onCoordinatorSetup(options?: BootOptions): Promise<void> {
    if (options?.customer) {
      this.customer = options.customer;
    } else {
      if (this.isAuthenticated && this.id) {
        this.customer = await this.emberDataStore.findRecord('customer', this.id);
        if(this.fromlogin){
          if(this.customer.defaultServiceCenterId){
            this.localStorageServiceCenter.set('id', this.customer.defaultServiceCenterId);
          }
        this.fromlogin = false
      }

        this.currentModel = this.emberDataStore.createRecord('session', {
          customer: this.customer,
          account: await this.customer.get('account'),
        });
      }
    }
  }

  async onCoordinatorTeardown(): Promise<void> {
    this.customer = undefined;
    this.currentModel = undefined;

    await this.procurement.createRecord();
  }

  _setupHandlers(): void {
    return;
  }

  @action async switchCustomer(customerId?: number): Promise<void> {
    const token = this.token;
    const id = customerId ?? prompt('Customer ID', '');

    if (!isEmpty(id)) {
      await this.invalidate();
      this.emberDataStore.unloadAll();

      if (environment === 'test') {
        const headers = { Authorization: `Bearer ${token}` };
        const response = await fetch(`/api/tokens/${id}`, { headers });
        const json = await response.json();

        await this.authenticate('authenticator:test', json);
      } else {
        await this.authenticate('authenticator:as', token, id);
      }
    }
  }

  async invalidate<T, U = Awaited<T>>(...args: any[]): Promise<U> {
    const value = (await super.invalidate(...args)) as U;

    await this.coordinator.teardown();

    return value;
  }

  async authenticate<T, U = Awaited<T>>(...args: any[]): Promise<U> {
    const value = (await super.authenticate(...args)) as U;
    if(args.includes("login")){
      this.fromlogin = true;
     }
    await this.coordinator.setup();

    return value;
  }
}

declare module '@ember/service' {
  interface Registry {
    session: SessionService;
  }
}
