import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { EXCEPTION_1061 } from 'shoelace/exceptions/mod';
import CustomerResetPasswordGuestModel from 'shoelace/models/customer-reset-password-guest';
//import type RollbarService from 'shoelace/services/rollbar';

export default class SecureCheckoutConfirmationController extends Controller {
//@service declare rollbar: RollbarService;

  @action async onSavePassword(
    customerResetPasswordGuest: CustomerResetPasswordGuestModel,
  ) {
    try {
      await customerResetPasswordGuest.save();
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1061, error);

      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'secure-checkout/confirmation': SecureCheckoutConfirmationController;
  }
}
