import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.availabilityStrict.isLoaded}}\n  {{yield this.availabilityStrict.value}}\n{{else}}\n  {{yield this.availabilityStrict}}\n{{/if}}\n", {"contents":"{{#if this.availabilityStrict.isLoaded}}\n  {{yield this.availabilityStrict.value}}\n{{else}}\n  {{yield this.availabilityStrict}}\n{{/if}}\n","moduleName":"shoelace/components/product/availability.hbs","parseOptions":{"srcName":"shoelace/components/product/availability.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { load } from 'shoelace/helpers/load';
import ProductModel from 'shoelace/models/product';
import ServiceCenterModel from 'shoelace/models/service-center';
import type ProductAvailabilityService from 'shoelace/services/product-availability';
import type ServiceCenterService from 'shoelace/services/service-center';

interface ProductAvailabilityComponentArgs {
  product: ProductModel;
  quantity?: number;
  serviceCenter?: ServiceCenterModel;
  serviceCenterId?: number;
}

export default class ProductAvailabilityComponent extends Component<ProductAvailabilityComponentArgs> {
@service declare productAvailability: ProductAvailabilityService;
@service declare serviceCenter: ServiceCenterService;

  get availabilityStrict() {
    return load(
      this.productAvailability.getAvailabilityStrict(
        this.args.product,
        this.args.quantity ?? this.args.product?.minQty ?? 1,
        this.args.serviceCenterId ?? this.serviceCenter?.serviceCenter?.id,
      ),
    );
  }

  get availability() {
    return this.productAvailability.getAvailability(
      this.args.product,
      this.args.quantity,
      this.serviceCenter?.serviceCenter?.id,
    );
  }
}
