import Helper from '@ember/component/helper';

export default Helper.extend({
  compute(params) {
    let quantity = params[0];
    let uom = params[1];

    if (uom !== 'EA') {
      return uom;
    } else if (quantity <= 1) {
      return 'EA';
    } else {
      return '';
    }
  },
});
