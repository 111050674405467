import Route from '@ember/routing/route';
import CartModel from 'shoelace/models/cart';
import type SessionService from 'shoelace/services/session';
import CustomerModel from 'shoelace/models/customer';
import type ServiceCenterService from 'shoelace/services/service-center';
import type StoreService from '@ember-data/store';
import { inject as service } from '@ember/service';
import CartQueryModel from 'shoelace/models/cart-query';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface ItemRouteModel {
  cartQuery: CartQueryModel;
  cart: CartModel;
  customer: CustomerModel;
}

export interface RouteParams {
  list_id: string;
}

class StoreCustomerListRoute extends Route {
  @service declare session: SessionService;
  @service declare serviceCenter: ServiceCenterService;
  @service declare store: StoreService;

  titleToken = 'Saved List';

  async model({ list_id: id }: RouteParams) {
    const cart = await this.store.findRecord('cart', id, {
      include: ['cart-line-items', 'cart-line-items.product', 'customer'].join(
        ','
      ),
    });

    const customer = this.session.customer;
    const serviceCenter = await this.serviceCenter;
    let cartQuery;

    if (customer) {
      cartQuery = this.store.createRecord('cart-query', {
        email: customer?.get('email'),
        customer_id: customer?.get('id'),
        account_id: customer?.account?.get('id'),
        phone: customer?.get('cellPhone'),
        name: customer?.get('name'),
        company: customer?.account?.get('name'),
        location: customer?.get('city'),
        state: customer?.get('state'),
        serviceCenter: this.serviceCenter.id,
      });
    } else {
      cartQuery = this.store.createRecord('cart-query', {});
    }

    return {
      cart,
      customer,
      serviceCenter,
      cartQuery,
    };
  }
}
export default instrumentRoutePerformance(StoreCustomerListRoute);
