import Helper from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
import { isBlank, isPresent } from '@ember/utils';

export default Helper.extend({
  compute() {
    let name = document
      .querySelector('meta[name=csrf-param]')
      .getAttribute('content');
    let value = document
      .querySelector('meta[name=csrf-token]')
      .getAttribute('content');

    if (isBlank(name)) {
      name = 'authenticity_token';
    }

    if (isBlank(value) && isPresent(window.EmberENV['X-CSRF-Token'])) {
      value = window.EmberENV['X-CSRF-Token'];
    }

    return htmlSafe(`<input name="${name}" type="hidden" value="${value}">`);
  },
});
