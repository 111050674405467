import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class QuickOrderComponentModel extends Model {
  @attr('string') name;

  @belongsTo('quick-order-guide') quickOrderGuide;
  @belongsTo('quick-order-property-group') quickOrderPropertyGroup;
  @belongsTo('quick-order-component-heading-override')
  quickOrderComponentHeadingOverride;

  @hasMany('quick-order-product') quickOrderProducts;
}
