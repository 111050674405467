import Model, { attr, belongsTo } from '@ember-data/model';

export default class QuickOrderProductModel extends Model {
  @attr('boolean') hasProduct;
  @attr('boolean') hasTitle;
  @attr('boolean') hasSize;
  @attr('boolean') hasColor;

  @belongsTo('quick-order-component') quickOrderComponent;
  @belongsTo('product') product;

  @belongsTo('quick-order-property') title;
  @belongsTo('quick-order-property') size;
  @belongsTo('quick-order-property') color;
}
