import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class AdminCustomersIndexController extends Controller {
  queryParams = ['page', 'size', 'sort', 'search'];

  @tracked page = 1;
  @tracked size = 10;
  @tracked sort = '-created-at';
  @tracked search = [];
}

declare module '@ember/controller' {
  interface Registry {
    'admin/customers/index': AdminCustomersIndexController;
  }
}
