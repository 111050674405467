import Controller from '@ember/controller';

export default Controller.extend({
  query: null,

  actions: {
    search(event) {
      // console.info(event);

      if (event.keyCode === 13) {
        const query = event.target.value;

        this.set('search', query || this.query || this.get('model.search'));
        this.set('model.search', query || this.query || this.get('model.search'));
      }
    },
  },
});
