import type Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { EXCEPTION_1030 } from 'shoelace/exceptions/mod';
//import type RollbarService from 'shoelace/services/rollbar';
import { DateRange } from 'shoelace/utils/range';

export default class AdminAccountOpenItemReceiptReportController extends Controller {
  @service declare store: Store;
  //@service declare rollbar: RollbarService;

  @action async onSendingReport(start: string, end: string): Promise<void> {
    try {
      const dateRange = new DateRange(start, end);
      const convertedDates = dateRange.toString();
      const accountOpenItemReciept = await this.store.createRecord(
        'account-open-item-receipt',
      );

      await accountOpenItemReciept.report({
        filter: { 'notified-at': convertedDates },
      });

      await accountOpenItemReciept.destroy();
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1030, error);

      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/account-open-item-receipt/report': AdminAccountOpenItemReceiptReportController;
  }
}
