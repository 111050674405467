import ApplicationSerializer, { exclude } from './application';

export default class ServiceCenterSerializer extends ApplicationSerializer {
  normalize(typeClass, payload) {
    const {
      ['pickup-in-20']: pickupIn20,
      ['pickup-20-20-time']: pickup2020Time,
    } = payload.attributes;
    const json = super.normalize(typeClass, payload);

    json.data.attributes.pickupIn20 = pickupIn20;
    json.data.attributes.pickup2020Time = pickup2020Time;

    return json;
  }

  attrs = {
    createdAt: exclude,
    currentPickupTime: exclude,
    distance: exclude,
    exceptionHours: exclude,
    isOpen: exclude,
    pickup2020Time: exclude,
    pickupIn20: exclude,
    standardHours: exclude,
    updatedAt: exclude,
  };
}
