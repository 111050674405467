import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AccountProjectModel from 'shoelace/models/account-project';
import type AccountProjectOrderAttributeModel from 'shoelace/models/account-project-order-attribute';

export default class AccountProjectOrderAttributeDefinitionModel extends Model {
  @attr('string') name!: null | string;
  @attr('number') priorityOrderAttribute!: null | number;

  @belongsTo('account-project')
  accountProject!: AsyncBelongsTo<AccountProjectModel>;

  @hasMany('account-project-order-attribute')
  accountProjectOrderAttributes!: AsyncHasMany<AccountProjectOrderAttributeModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-project-order-attribute-definition': AccountProjectOrderAttributeDefinitionModel;
  }
}
