import Route from '@ember/routing/route';
import type CustomerModel from 'shoelace/models/customer';
import CustomerChangePasswordModel from 'shoelace/models/customer-change-password';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type SessionService from 'shoelace/services/session';
import type RouterService from '@ember/routing/router';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface RouteModel {
  customer: CustomerModel | undefined;
  customerChangePassword: CustomerChangePasswordModel | undefined;
}

class StoreCustomerPasswordRoute extends Route {
  @service declare router: RouterService;
  @service declare store: StoreService;
  @service declare session: SessionService;

  async beforeModel() {
    if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }
  }

  async model(): Promise<RouteModel> {
    const customer: CustomerModel | undefined = this.session.customer;
    const customerId = customer?.get('id');
    let customerChangePassword;

    if (customerId) {
      customerChangePassword = await this.store.findRecord(
        'customer-change-password',
        customerId
      );
    } else {
      this.router.transitionTo('login');
    }

    return {
      customer,
      customerChangePassword,
    };
  }
}
export default instrumentRoutePerformance(StoreCustomerPasswordRoute);
