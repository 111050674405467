import Route from '@ember/routing/route';
import type ServiceCenterModel from 'shoelace/models/service-center';
import type ServiceCenterDowHourModel from 'shoelace/models/service-center-dow-hour';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface AdminServiceCenterEditServiceCenterHoursModel {
  serviceCenter: ServiceCenterModel;
  serviceCenterDowHours: ServiceCenterDowHourModel[];
}

class AdminServiceCenterEditServiceCenterHours extends Route {
  @service declare store: StoreService;

  async model(): Promise<AdminServiceCenterEditServiceCenterHoursModel> {
    const serviceCenter = (await this.modelFor(
      'admin.service-center'
    )) as ServiceCenterModel;

    const existingServiceCenterDowHours = await this.store.query(
      'service-center-dow-hour',
      {
        filter: {
          serviceCenters: serviceCenter.get('id'),
        },
      }
    );

    const serviceCenterDowHours: ServiceCenterDowHourModel[] = [
      ...existingServiceCenterDowHours.toArray(),
    ];

    for (let i = 1; i <= 7; i++) {
      const exists = existingServiceCenterDowHours.find(
        (serviceCenterDowHour) => {
          return serviceCenterDowHour.dow === i;
        }
      );

      if (!exists) {
        serviceCenterDowHours.push(
          this.store.createRecord('service-center-dow-hour', {
            serviceCenter,
            dow: i,
          })
        );
      }
    }

    return {
      serviceCenter,
      serviceCenterDowHours,
    };
  }

  @action willTransition() {
    const { serviceCenterDowHours: model } = this.modelFor(this.fullRouteName);
    model.forEach((element) => {
      if (!element.close) {
        element.deleteRecord();
      }
    });
  }
}

export default instrumentRoutePerformance(
  AdminServiceCenterEditServiceCenterHours
);
