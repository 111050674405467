import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import type AccountOrderLineItemModel from 'shoelace/models/account-order-line-item';
import type AccountProjectCircuitModel from 'shoelace/models/account-project-circuit';
import type AccountProjectOrderModel from 'shoelace/models/account-project-order';
import type AccountProjectOrderLineItemAttributeModel from 'shoelace/models/account-project-order-line-item-attribute';
import type AccountProjectOrderLineItemDeliveryModel from 'shoelace/models/account-project-order-line-item-delivery';
import type AccountProjectReleaseLineItemModel from 'shoelace/models/account-project-release-line-item';
import type ProductModel from 'shoelace/models/product';

const invalidDate = new Date(2100, 11, 31);

export default class AccountProjectOrderLineItemModel extends Model {
  @attr('boolean') isRejected!: null | boolean;
  @attr('date') availableAt!: null | Date;
  @attr('date') scheduleAt!: null | Date;
  @attr('number') deliveredQuantity!: null | number;
  @attr('number') itemNum!: null | number;
  @attr('number') remainingQuantity!: null | number;
  @attr('number') requestedQuantity!: null | number;
  @attr('number') reservedQuantity!: null | number;
  @attr('number') sapProductId!: null | number;
  @attr('number') serviceCenterAtp!: null | number;
  @attr('number') trailerAtp!: null | number;
  @attr('string') circuitId!: null | string;
  @attr('string') enteredAt!: null | string;
  @attr('string') inventoryLocation!: null | string;
  @attr('string') name!: null | string;
  @attr('string') serviceCenter!: null | string;
  @attr('string') storageLocation!: null | string;
  @attr('string') unitOfMeasure!: null | string;

  @belongsTo('account-order-line-item')
  accountOrderLineItem!: AsyncBelongsTo<AccountOrderLineItemModel>;

  @belongsTo('account-project-order')
  accountProjectOrder!: AsyncBelongsTo<AccountProjectOrderModel>;

  @belongsTo('product')
  product!: AsyncBelongsTo<ProductModel>;

  @belongsTo('account-project-circuit')
  accountProjectCircuit!: AsyncBelongsTo<AccountProjectCircuitModel>;

  @belongsTo('account-project-release-line-item')
  accountProjectReleaseLineItem!: AsyncBelongsTo<AccountProjectReleaseLineItemModel>;

  @hasMany('account-project-order-line-item-delivery')
  accountProjectOrderLineItemDeliveries!: AsyncHasMany<AccountProjectOrderLineItemDeliveryModel>;

  @hasMany('account-project-order-line-item-attribute')
  accountProjectOrderLineItemAttributes!: AsyncHasMany<AccountProjectOrderLineItemAttributeModel>;

  @computed('accountOrderLineItem.totalQuantity')
  get totalQuantity(): number {
    return this.accountOrderLineItem.get('totalQuantity') ?? 0;
  }

  @computed(
    'accountOrderLineItem.deliveryStatus',
    'accountProjectOrder.accountOrder.expectedAt',
    'availableAt',
    'validDate',
  )
  get isStatusOK(): boolean {
    const expectedAt =
      this.accountProjectOrder.get('accountOrder').get('expectedAt') ?? 0;
    const availableAt = this.availableAt ?? 0;
    const validDate = this.validDate;
    const status = this.accountOrderLineItem.get('deliveryStatus');

    return status === 'Completed' || expectedAt > availableAt || !validDate;
  }

  @computed('availableAt', 'accountProjectOrder.expectedAt')
  get isLate(): boolean {
    const expectedAt = this.accountProjectOrder.get('expectedAt') ?? 0;
    const availableAt = this.availableAt ?? 0;

    return availableAt > expectedAt;
  }

  @computed('availableAt')
  get validDate(): boolean {
    const availableAt = this.availableAt ?? 0;

    return availableAt < invalidDate;
  }

  @computed('deliveredQuantity', 'unitOfMeasure')
  get deliveredQuantityUnit(): string {
    return `${this.deliveredQuantity} ${this.unitOfMeasure}`;
  }

  @computed('remainingQuantity', 'unitOfMeasure')
  get remainingToReleaseUnit(): string {
    return `${this.remainingQuantity} ${this.unitOfMeasure}`;
  }

  @computed('accountProjectOrder.id')
  get orderId(): string {
    return this.accountProjectOrder.get('id');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-project-order-line-item': AccountProjectOrderLineItemModel;
  }
}
