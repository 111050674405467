import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';
import { collectionAction } from 'ember-api-actions';
import type AccountModel from 'shoelace/models/account';
import type AccountOpenItemModel from 'shoelace/models/account-open-item';
import type CustomerModel from 'shoelace/models/customer';

const Validations = buildValidations({
  _amount: {
    description: 'Amount',
    validators: [
      validator('number', {
        gt: 0,
      }),
    ],
  },
});

export default class AccountOpenItemReceiptModel extends Model.extend(
  Validations,
) {
  @attr('date') createdAt!: null | Date;
  @attr('date') updatedAt!: null | Date;
  @attr('string') remittanceId!: null | string;
  @attr('string') status!: null | string;
  @attr('number') amount!: null | number;
  @attr('string') billingFirstname!: null | string;
  @attr('string') billingLastname!: null | string;
  @attr('string') billingAddress!: null | string;
  @attr('string') billingAddress2!: null | string;
  @attr('string') billingCity!: null | string;
  @attr('string') billingState!: null | string;
  @attr('number') billingCountryId!: null | number;
  @attr('string') billingZip!: null | string;
  @attr('number') clientAccountNumber!: null | number;
  @attr('string') userDefined1!: null | string;
  @attr('string') userDefined2!: null | string;
  @attr('string') userDefined3!: null | string;
  @attr('number') transactionId!: null | number;
  @attr('number') routingTransitNumber!: null | number;
  @attr('string') partialAcctNumber!: null | string;
  @attr('number') failCode!: null | number;

  report = collectionAction({ path: 'report', type: 'post' });

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('customer')
  customer!: AsyncBelongsTo<CustomerModel>;

  @hasMany('account-open-item')
  accountOpenItems!: AsyncHasMany<AccountOpenItemModel>;

  @computed('status')
  get isPending(): boolean {
    return (
      this.status === 'created' ||
      this.status === 'accepted' ||
      this.status === 'payment-required'
    );
  }

  // Calculated amount for validation. The server will send down the Truth
  // when we save, but this will help us drive the UI before an actual record
  // is pushed to the server.
  @computed('accountOpenItems.@each.amountWithDiscount')
  get _amount(): number {
    const amounts = this.accountOpenItems.getEach('amountWithDiscount');

    return amounts.reduce((a, b) => a + b, 0);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-open-item-receipt': AccountOpenItemReceiptModel;
  }
}
