import BaseValidator from '@summit-electric-supply/ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const IsUnique = BaseValidator.extend({
  store: service(),

  async validate(value, options, model, attribute) {
    const filter = Object.entries(options.on)
      .map(([key, value]) => {
        return JSON.parse(`{"${key}" : "${model.get(value)}"}`);
      })
      .reduce((first, next) => {
        return Object.assign(first, next);
      });

    return this.store
      .query(options.modelName, { filter })
      .then(release => (release.length > 0 ? options.message : true))
      .catch(error => options.message);
  },
});

IsUnique.reopenClass({
  /**
   * Define attribute specific dependent keys for your validator
   *
   * [
   * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
   * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
   * ]
   *
   * @param {String}  attribute   The attribute being evaluated
   * @param {Unknown} options     Options passed into your validator
   * @return {Array}
   */
  getDependentsFor(/* attribute, options */) {
    return [];
  },
});

export default IsUnique;
