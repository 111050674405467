import type StoreService from '@ember-data/store';
import { next } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import type ProductModel from 'shoelace/models/product';
import type ProductAvailabilityModel from 'shoelace/models/product-availability';
import type ServiceCenterModel from 'shoelace/models/service-center';
import { read } from '../utils';
import type { ObjectPromiseProxy } from '../utils/async-helpers';
import { AsyncObject } from '../utils/async-helpers';
import type ServiceCenterService from './service-center';

const MAX_CACHE_AGE_SECONDS = 60;
const MAX_CACHE_ENTRIES = 1000;

export default class ProductAvailabilityService extends Service {
  @service declare serviceCenter: ServiceCenterService;

  @service declare store: StoreService;

  cache: Map<string, Promise<ProductAvailabilityModel>> = new Map();
  strictCache: Map<string, Promise<ProductAvailabilityModel>> = new Map();

  async getSharedAvailability(
    product: ProductModel,
    quantity: number,
    serviceCenter?: string | number,
  ): Promise<ProductAvailabilityModel> {
    const productAvailabilityModels = await this.store.query(
      'product-availability',
      {
        filter: {
          'product-id': product.id,
          'service-center-id': serviceCenter,
          quantity,
        },
      },
    );

    if (productAvailabilityModels) {
      if (productAvailabilityModels.length > 1) {
        if (product.isPromotion) {
          if (productAvailabilityModels?.firstObject?.id.split('-')[1] === "15") {
            const productAvailabilityModel = productAvailabilityModels.firstObject;
            productAvailabilityModel.cachedAt = new Date();

            return productAvailabilityModel;
          } else {
            if (productAvailabilityModels?.lastObject?.id.split('-')[1] === "15") {
              const productAvailabilityModel = productAvailabilityModels.lastObject;
              productAvailabilityModel.cachedAt = new Date();

              return productAvailabilityModel;
            }
          }
        } else {
          if (productAvailabilityModels.length > 1) {
            if (productAvailabilityModels?.firstObject?.id.split('-')[1] !== "15") {
              const productAvailabilityModel = productAvailabilityModels.firstObject;
              productAvailabilityModel.cachedAt = new Date();

              return productAvailabilityModel;
            } else {
              if (productAvailabilityModels?.lastObject?.id.split('-')[1] !== "15") {
                const productAvailabilityModel = productAvailabilityModels.lastObject;
                productAvailabilityModel.cachedAt = new Date();

                return productAvailabilityModel;
              }
            }
          } else {
            const productAvailabilityModel = productAvailabilityModels.firstObject;
            productAvailabilityModel.cachedAt = new Date();

            return productAvailabilityModel;
          }
        }
      } else {
        const productAvailabilityModel = productAvailabilityModels.firstObject;
        productAvailabilityModel.cachedAt = new Date();

        return productAvailabilityModel;
      }
    }

    throw new Error(
      `could not find product-availability for product ${product.id}`,
    );
  }

  async getAvailabilityStrict(
    product: ProductModel,
    quantity: number,
    serviceCenterId?: string | number,
  ): Promise<ProductAvailabilityModel> {
    const key = this.generateAvailabilityIdKey(product, serviceCenterId, quantity);

    if (this.strictCache.has(key)) {
      const cachedAvailability = await this.strictCache.get(key);
      const isCacheExpired = this.isCacheExpired(cachedAvailability);

      if (!isCacheExpired) {
        return cachedAvailability!;
      } else {
        this.strictCache.delete(key);
      }
    }

    if (this.strictCache.size >= MAX_CACHE_ENTRIES) {
      const oldestKey = this.strictCache.keys().next().value;

      if (oldestKey) {
        this.strictCache.delete(oldestKey);
      }
    }

    this.strictCache.set(
      key,
      this.getSharedAvailability(product, quantity, serviceCenterId),
    );

    return await this.strictCache.get(key)!;
  }

  generateAvailabilityIdKey(
    product: string | number | ProductModel,
    serviceCenter?: string | number,
    quantity?: string | number,
  ): string {
    const productId = read(product, 'id') ?? '';
    const serviceCenterId = serviceCenter ?? '';
    const quantityNumber = quantity ?? 1;

    return `${productId}-${serviceCenterId}-${quantityNumber}`;
  }

  isCacheExpired(productAvailabilityModel?: ProductAvailabilityModel): boolean {
    if (!productAvailabilityModel) {
      return true;
    } else {
      const now = new Date().getTime();
      const cachedAt = productAvailabilityModel?.cachedAt?.getTime() ?? 0;
      const diff = (now - cachedAt) / 1000;

      return diff > MAX_CACHE_AGE_SECONDS;
    }
  }

  getAvailability(
    product: number | string | ProductModel,
    qty: null | number | string | undefined,
    serviceCenter:
      | null
      | number
      | string
      | undefined
      | ServiceCenterModel
      | ServiceCenterService,
  ):
    | ObjectPromiseProxy<ProductAvailabilityModel>
    | ProductAvailabilityModel
    | null {
    const quantity = qty || 1;
    const productId = read(product, 'id') ?? null;

    const serviceCenterId =
      read(serviceCenter, 'id') ?? this.serviceCenter.id ?? null;

    if (!productId) return null;

    const idToPeek = `${productId}-${serviceCenterId}`;

    const availability = this.store.peekRecord('product-availability', idToPeek);

    const success =
      availability && availability.get('requestedQuantity') === quantity;

    if (success) {
      availability?.set('requestedQuantity', quantity);

      return availability;
    } else {
      const filter = {
        quantity: quantity,
        'product-id': productId,
        'service-center-id': serviceCenterId,
      };

      const key = idToPeek;

      if (!this.cache.has(key)) {
        const result = this.store
          .query('product-availability', { filter })
          .then(array => {
            array.get('firstObject')!.set('requestedQuantity', quantity);

            next(this, () => this.cache.delete(key));

            return array.get('firstObject')!;
          });

        this.cache.set(key, result);
      }

      return AsyncObject<ProductAvailabilityModel>(this.cache.get(key)!);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'product-availability': ProductAvailabilityService;
  }
}
