import { inject as service } from '@ember/service';
import ObjectProxy from '@ember/object/proxy';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import Route from '@ember/routing/route';
import { instrumentRoutePerformance } from '@sentry/ember';

const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

function asPromiseObject(promise) {
  return ObjectPromiseProxy.create({ promise });
}

class QuickOrderGuideRoute extends Route {
  @service store;

  model({ id }) {
    const options = {
      reload: true,
    };

    options.include = [
      'quick-order-guide-group',
      'quick-order-components',
      // 'quick-order-components.quick-order-component-heading-override',
      'quick-order-components.quick-order-products',
      'quick-order-components.quick-order-products.product',
      'quick-order-components.quick-order-products.title',
      'quick-order-components.quick-order-products.size',
      'quick-order-components.quick-order-products.color',
      'quick-order-components.quick-order-property-group',
      'quick-order-components.quick-order-property-group.quick-order-property-key',
      'quick-order-components.quick-order-property-group.quick-order-properties',
    ].join(',');

    return asPromiseObject(
      new Promise(async (done) => {
        let retries = 10;
        const worker = async () => {
          if (retries-- > 0) {
            try {
              const guide = await this.store.findRecord(
                'quick-order-guide',
                id,
                options
              );
              done(guide);
            } catch (error) {
              setTimeout(worker, 1000);
            }
          }
        };
        worker();
      })
    );
  }
}
export default instrumentRoutePerformance(QuickOrderGuideRoute);
