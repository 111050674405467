import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { action, computed } from '@ember/object';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';
import type AccountProjectModel from 'shoelace/models/account-project';
import type AccountProjectReleaseLineItemModel from 'shoelace/models/account-project-release-line-item';
import type AccountProjectReleaseSignerModel from 'shoelace/models/account-project-release-signer';
import type CustomerModel from 'shoelace/models/customer';

const STATUS_ARCHIVE = 'archive';
const STATUS_DENY = 'deny';
const STATUS_NEW = 'new';
const STATUS_PENDING = 'pending';
const STATUS_RELEASE = 'released';

const validations = buildValidations({
  name: validator('presence', {
    presence: true,
    message: 'Please enter a name for your release',
  }),
  dateRequired: validator('presence', {
    presence: true,
    message: 'Please enter a valid date',
  }),
  earliestDeliveryAllowed: validator('presence', {
    presence: true,
    message: 'Please enter a valid date',
  }),
  accountProject: validator('presence', {
    presence: true,
    message: 'Please select a project',
  }),
  requestNumber: [
    validator('ds-error', {
      message: 'Request Number must be unique',
    }),
    validator('presence', {
      presence: true,
      message: 'Please enter a release number',
    }),
  ],
});

export default class AccountProjectReleaseModel extends Model.extend(
  validations,
) {
  @attr('date') createdAt!: null | Date;
  @attr('date') updatedAt!: null | Date;
  @attr('string', { defaultValue: STATUS_NEW }) status!: string;
  @attr('string') deliverTo!: null | string;
  @attr('string') deliveryLocation!: null | string;
  @attr('string') message!: null | string;
  @attr('string') name!: null | string;
  @attr('string') notes!: null | string;
  @attr('string') requestNumber!: null | string;
  @attr('string') dateRequired!: null | string;
  @attr('string') earliestDeliveryAllowed!: null | string;
  @attr('date') releasedAt!: null | Date;

  @belongsTo('account-project')
  accountProject!: AsyncBelongsTo<AccountProjectModel>;

  @belongsTo('customer')
  customer!: AsyncBelongsTo<CustomerModel>;

  @belongsTo('customer')
  releasedBy!: AsyncBelongsTo<CustomerModel>;

  @hasMany('account-project-release-line-item')
  accountProjectReleaseLineItems!: AsyncHasMany<AccountProjectReleaseLineItemModel>;

  @hasMany('account-project-release-signer')
  accountProjectReleaseSigners!: AsyncHasMany<AccountProjectReleaseSignerModel>;

  @computed('status')
  get canDelete(): boolean {
    return this.status === STATUS_NEW;
  }

  @computed('status')
  get canChangeLineItems(): boolean {
    return this.status === STATUS_NEW;
  }

  @computed('status')
  get canChangeApprovers(): boolean {
    return [STATUS_NEW, STATUS_PENDING].includes(this.status);
  }

  @computed('status')
  get canRelease(): boolean {
    return [STATUS_NEW, STATUS_PENDING].includes(this.status);
  }

  @computed('status')
  get canChangeRelease(): boolean {
    return this.status === STATUS_NEW;
  }

  @computed('status')
  get canSeeSignersTable(): boolean {
    return this.status !== STATUS_NEW;
  }

  @computed('status')
  get canChangeNotes(): boolean {
    return this.status === STATUS_NEW;
  }

  @computed('accountProjectReleaseLineItems.@each.validQuantity')
  get validLineItemQuantities(): boolean {
    return this.accountProjectReleaseLineItems
      .filter(li => !!li.get('accountProjectOrderLineItem').get('id'))
      .every(li => li.get('validQuantity'));
  }

  @computed('status')
  get showQuantityProblems(): boolean {
    return ![STATUS_RELEASE, STATUS_DENY].includes(this.status);
  }

  @action archive(): Promise<ThisType<AccountProjectReleaseModel>> {
    this.set('status', STATUS_ARCHIVE);

    return this.save();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-project-release': AccountProjectReleaseModel;
  }
}
