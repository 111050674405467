import Route from '@ember/routing/route';
import { instrumentRoutePerformance } from '@sentry/ember';
import RSVP from 'rsvp';

class PunchoutConfirmationRoute extends Route {
  async model({
    id,
  }) {
    const model = {
      id: id,
    };

    return RSVP.hash(model);
  }
}
export default instrumentRoutePerformance(PunchoutConfirmationRoute);
