import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';
import CustomerModel from 'shoelace/models/customer';

class AdminCustomersIndexRoute extends Route {
  @service store;
  @service session;
  @service router;

  async model() {
    const customer = (await this.modelFor('admin.customer')?.customer);
    const accountVerification = this.store.createRecord('account-verification', {
      firstName: customer?.get('firstName'),
      lastName: customer?.get('lastName'),
      hasAuthority: true,
      customer: customer,
    });

    const serviceCenters = this.store.query('service-center', {
      filter: {
        service_center_type: '1',
      },
      sort: 'long-name',
    });

    return {
      accountVerification,
      customer,
      serviceCenters,
    };
  }
}
export default instrumentRoutePerformance(
  AdminCustomersIndexRoute
);
