import { action } from '@ember/object';
import ObjectProxy from '@ember/object/proxy';
import Route from '@ember/routing/route';
import CustomerForgotPasswordModel from 'shoelace/models/customer-forgot-password';
import { instrumentRoutePerformance } from '@sentry/ember';

class ForgotPasswordIndexRoute extends Route {
  @action willTransition() {
    const model = this.modelFor('forgot-password');

    if (model.isNew) {
      model.deleteRecord();
    }
  }
}
export default instrumentRoutePerformance(
  ForgotPasswordIndexRoute
);
