import SignIn from './sign-in';
import { attr } from '@ember-data/model';
import { get } from '@ember/object';
import { not } from '@ember/object/computed';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

const jwtSchema = {
  typ: 'JWT',
  alg: 'none',
  exp: new Date().getTime(),
  iat: new Date().getTime(),
  nbf: new Date().getTime(),
};

export default SignIn.extend({
  token: attr('string'),

  jwt: computed('token', function () {
    let token = this.token;

    if (isEmpty(token)) {
      token = '..';
    }

    const parts = token.split(/\./g);
    const header = JSON.parse(atob(parts.shift() || btoa('{}')));
    const payload = JSON.parse(atob(parts.shift() || btoa('{}')));

    return assign(jwtSchema, header, payload);
  }),

  expiresAt: computed('jwt', function () {
    const { exp } = this.jwt;

    return new Date(parseInt(exp));
  }),

  commencesAt: computed('jwt', function () {
    const { nbf } = this.jwt;

    return new Date(parseInt(nbf));
  }),

  issuedAt: computed('jwt', function () {
    const { iat } = this.jwt;

    return new Date(parseInt(iat));
  }),

  hasExpired: computed('expiresAt', function () {
    const time = new Date().getTime();
    const expiresAt = this.expiresAt.getTime();

    return time > expiresAt;
  }),

  hasNotExpired: not('hasExpired'),

  hasCommenced: computed('commencesAt', function () {
    const time = new Date().getTime();
    const commencesAt = this.commencesAt.getTime();

    return time > commencesAt;
  }),

  isValid: computed('hasCommenced', 'hasNotExpired', function () {
    const hasCommenced = this.hasCommenced;
    const hasNotExpired = this.hasNotExpired;

    return hasCommenced && hasNotExpired;
  }),
});
