import {
  validator,
  buildValidations,
} from '@summit-electric-supply/ember-cp-validations';
import Model, { attr } from '@ember-data/model';

const Validations = buildValidations({
  currentPassword: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      message: 'Password must not be blank.',
    }),
  ],
  unconfirmedEmail: {
    description: 'Email Address',
    validators: [
      validator('format', {
        type: 'email',
        allowNonTld: false,
      }),
    ],
  },
});

export default class CustomerChangeEmailModel extends Model.extend(Validations) {
  @attr('string')
  declare currentPassword?: string;

  @attr('string')
  declare unconfirmedEmail?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'customer-change-email': CustomerChangeEmailModel;
  }
}
