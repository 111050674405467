import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import { EXCEPTION_1070 } from 'shoelace/exceptions/mod';
import type Classification from 'shoelace/models/classification';
//import type RollbarService from 'shoelace/services/rollbar';
import { ArrayPromiseProxy } from 'shoelace/utils/async-helpers';
import type StoreService from '@ember-data/store';
import type Account from 'shoelace/models/account';

export default class AdminAccountCatalogController extends Controller {
  //@service declare rollbar: RollbarService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  @action async giveProductToCatalog(
    productGroupsChild: [],
    catalog: Classification & ArrayPromiseProxy<Classification>,
    savedCatalog: Classification & ArrayPromiseProxy<Classification>,
    account: Account & ArrayPromiseProxy<Account>,
  ): Promise<void> {
    try {
      savedCatalog.forEach(child => {
        if (productGroupsChild.includes(child)) {
          productGroupsChild.filter(kid => {
            kid === child;
          });
        }
      });

      await productGroupsChild.forEach(classification => {
        this.store
          .createRecord('classification-catalog', {
            catalog,
            classification,
          })
          .save();
      });

      this.router.transitionTo('admin.account', account.get('id'));
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1070, error);
      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/account/catalog': AdminAccountCatalogController;
  }
}
