import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { EXCEPTION_1049 } from 'shoelace/exceptions/mod';

export default class StoreServicesMyProjectsReleaseIndexController extends Controller {
  queryParams = ['page', 'project', 'search', 'size', 'sort'];

  @service store;
  @service session;
  @service router;
  @service notice;

  @tracked isValidated = false;
  @tracked page = 1;
  @tracked project = null;
  @tracked size = 100;
  @tracked sort = '-updated-at';
  @tracked term = '';

  @tracked isValidationVisible = false;

  identity = x => x;

  async validate(model) {
    const { validations } = await model.validate();

    return validations?.isValid;
  }

  generateNewRequestNumber(requestNumber) {
    const parts = requestNumber.split('_copy_');

    const original = parts?.firstObject?.trim();
    const count = (parseInt(parts?.lastObject || 0) || 0) + 1;

    return `${original}_copy_${count}`;
  }

  @action async duplicate(accountProjectRelease, accountProjectReleaseLineItems) {
    try {
      const { isConfirmed } = await this.notice.confirm(
        `Duplicate`,
        `Are you sure you want to duplicate this release?`,
      );

      if (isConfirmed) {
        const dupe = this.store.createRecord(
          'account-project-release',
          JSON.parse(JSON.stringify(accountProjectRelease)),
        );

        const originalRequestNumber = dupe.requestNumber;

        dupe['status'] = 'new';
        dupe['name'] = `${dupe.name} (copy)`;
        dupe['requestNumber'] = this.generateNewRequestNumber(dupe.requestNumber);
        dupe['customer'] = this.session.customer;
        dupe['createdAt'] = Date.now();
        dupe['updatedAt'] = Date.now();
        dupe['accountProject'] = await accountProjectRelease.get(
          'accountProject',
        );
        try {
          // if first save fails, requestNumber is already taken. Generate random one and retry save
          await dupe.save();
          // must save before duplicating line items
        } catch (error) {
          dupe['requestNumber'] = `${originalRequestNumber}_${Date.now()}`;
          await dupe.save();
        }
        this.model.accountProjectReleaseLineItems.map(lineitem => {
          this.store
            .createRecord('account-project-release-line-item', {
              quantity: lineitem.quantity,
              accountProjectOrderLineItem: lineitem.get(
                'accountProjectOrderLineItem',
              ),
              release: dupe,
              notes: lineitem.notes,
              accountProjectRelease: dupe,
            })
            .save();
        });
        this.router.transitionTo(
          'store.services.my-projects.releases.my-releases',
        );
      }
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1049, error);

      throw error;
    }
  }

  @action async print(accountProjectRelease) {
    const validate = accountProjectRelease.showQuantityProblems;

    if (!validate || (await this.checkOrderValid(accountProjectRelease))) {
      window.print();
    } else {
      await this.notice.error(
        'There are problems with this release that must be fixed before you can print to submit. Please try again.',
      );
    }
  }

  async checkOrderValid(accountProjectRelease) {
    const lineItems = await accountProjectRelease.get(
      'accountProjectReleaseLineItems',
    );

    if (lineItems.length > 0) {
      const isReleaseValid = await this.validate(accountProjectRelease);

      const isLineItemsValid = await Promise.all(
        lineItems.map(lineItem => {
          return this.validate(lineItem, this);
        }),
      );

      const isAllValid = [isReleaseValid, ...isLineItemsValid].every(
        this.identity,
      );

      if (isAllValid) {
        this.isValidationVisible = false;
        await accountProjectRelease.save();
        return true;
      }
    }

    this.isValidationVisible = true;
    return false;
  }

  @action async saveAccountProjectRelease(accountProjectRelease) {
    await accountProjectRelease.validate();

    this.isValidationVisible = true;

    const needSaved = accountProjectRelease
      .get('accountProjectReleaseLineItems')
      .filter(li => li.get('hasDirtyAttributes'))
      .concat([accountProjectRelease])
      .concat(
        accountProjectRelease
          .get('accountProjectReleaseSigners')
          .filter(signer => signer.get('hasDirtyAttributes')),
      );

    await Promise.all(needSaved.map(li => li.save()));
    await this.notice.success('Saved.');
  }

  @action async removeLineItem(
    accountProjectRelease,
    accountProjectReleaseLineItem,
  ) {
    accountProjectRelease
      .get('accountProjectReleaseLineItems')
      .removeObject(accountProjectReleaseLineItem);

    await Promise.all([
      accountProjectRelease.save(),
      accountProjectReleaseLineItem.destroyRecord(),
    ]);
  }
}
