import Controller from '@ember/controller';
import { action } from '@ember/object';
import type Customer from 'shoelace/models/customer';
import RbacAccountCustomerRoleModel from 'shoelace/models/rbac-account-customer-role';
import type StoreService from '@ember-data/store';
import { service } from '@ember/service';

export default class AdminManageCustomerPermissionsController extends Controller {
  @service declare store: StoreService;
  @action async removeRole(
    customer: Customer,
    rbacAccountCustomerRole: RbacAccountCustomerRoleModel
  ): Promise<void> {
    try {
      if (rbacAccountCustomerRole) {
        const rbac_account_customer_id = await rbacAccountCustomerRole.get(
          'id'
        );
        const rbac_account_role_details = await this.store.query(
          'rbac-account-customer-role',
          {
            filter: {
              customer_id: rbac_account_customer_id,
            },
          }
        );
        if (rbac_account_role_details.length != 0) {
          rbac_account_role_details.forEach(async (rbac_account_role) => {
            const recordQuote = await this.store.findRecord(
              'rbac-account-customer-permission',
              rbac_account_role.id
            );
            await recordQuote.destroyRecord();
          });
        }
      }

      const recordQuote = await this.store.findRecord(
        'rbac-account-customer-role',
        rbacAccountCustomerRole.id
      );
      await recordQuote.destroyRecord();
    } catch (error) {
      throw error;
    }
  }

  @action async removeGrant(
    rbacAccountCustomerRole: RbacAccountCustomerRoleModel
  ): Promise<void> {
    try {
      await rbacAccountCustomerRole.destroyRecord();
    } catch (error) {
      throw error;
    }
  }

  @action async addGrant(
    rbacAccountCustomerRoleForExistingone: RbacAccountCustomerRoleModel,
    rbacAccountCustomerRole: RbacAccountCustomerRoleModel,
    role_id: string
  ): Promise<void> {
    try {
      if (
        rbacAccountCustomerRoleForExistingone &&
        rbacAccountCustomerRole &&
        role_id
      ) {
        await rbacAccountCustomerRoleForExistingone.destroyRecord();
      } else {
        const rbac_account_customer = await rbacAccountCustomerRole.get('id');
        const rbac_account_role_details = await this.store.query(
          'rbac-account-customer-permission',
          {
            filter: {
              rbac_account_customer_role_id: rbac_account_customer,
              rbac_permission_id: role_id,
            },
          }
        );
        if (rbac_account_role_details.length != 0) {
          rbac_account_role_details.forEach(async (rbac_account_role) => {
            const p_role_id = rbac_account_role.rbac_permission_id;

            if (p_role_id === role_id) {
              const recordQuote = await this.store.findRecord(
                'rbac-account-customer-permission',
                rbac_account_role.id
              );
              await recordQuote.destroyRecord();
            }
          });
        } else {
          const rbac_account_customer_id = await rbacAccountCustomerRole.get(
            'id'
          );
          await this.store
            .createRecord('rbac-account-customer-permission', {
              rbac_permission_id: role_id,
              rbac_account_customer_role_id: rbac_account_customer_id,
            })
            .save();
        }
      }
    } catch (error) {
      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/manage-customer/permissions': AdminManageCustomerPermissionsController;
  }
}
