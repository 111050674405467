import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import { computed } from '@ember/object';

export default class StoreAccountQuotesController extends Controller {
  queryParams = [
    'search',
    'sortAscending',
    'sortProperty',
    'quoteNotFound',
    'invalidSearch',
  ];

  @tracked search;
  @tracked sortProperty = null;
  @tracked sortAscending = null;
  @tracked invalidSearch = null;
  @tracked quoteNotFound = null;

  get sort() {
    if (this.sortAscending) {
      return `${this.sortProperty}`;
    } else {
      return `${this.sortProperty}:desc`;
    }
  }

  @computed('search')
  get hasSearch() {
    return !!this.search;
  }
}
