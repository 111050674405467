import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type RbacPermissionModel from 'shoelace/models/rbac-permission';

export default class RbacAccountPermissionModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('date') updatedAt!: null | Date;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('rbac-permission')
  rbacPermission!: AsyncBelongsTo<RbacPermissionModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'rbac-account-permission': RbacAccountPermissionModel;
  }
}
