import Route from '@ember/routing/route';
import { refreshModel } from '../../../../../utils/query-params';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

const decision = 'null';

class StoreServicesMyProjectsReleasesAssignedToMeRoute extends Route {
  @service store;

  queryParams = {
    page: refreshModel,
    project: refreshModel,
    search: refreshModel,
    size: refreshModel,
    sort: refreshModel,
  };

  async model({ project, page: number, search, size, sort }) {
    const {
      accountProjects,
      accountProjectsIds,
      customerId: customer,
    } = this.modelFor('store.services.my-projects');

    const accountProjectReleaseSigners = await this.store.query(
      'account-project-release-signer',
      {
        filter: {
          'account-project-release.account-project':
            project ?? accountProjectsIds.join(','),
          customer,
          decision,
          search,
        },
        include: [
          'account-project-release',
          'account-project-release.account-project',
          'customer',
        ].join(','),
        page: {
          number,
          size,
        },
        sort,
      }
    );

    return {
      accountProjectReleaseSigners,
      accountProjects,
    };
  }
}
export default instrumentRoutePerformance(
  StoreServicesMyProjectsReleasesAssignedToMeRoute
);
