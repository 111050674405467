import Helper from '@ember/component/helper';

export default Helper.extend({
  compute([string, delimiter]) {
    try {
      return string.split(delimiter);
    } catch (e) {
      return [];
    }
  },
});
