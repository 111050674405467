import Route from '@ember/routing/route';
import { instrumentRoutePerformance } from '@sentry/ember';
import type Store from '@ember-data/store';
import { getOwner } from '@ember/application';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Session from 'ember-simple-auth/services/session';
import type NoticeService from 'shoelace/services/notice';
import { storageFor } from 'ember-local-storage';

interface SsoAuthArgs {
}

class SsoAuthRoute extends Route<SsoAuthArgs> {
  @service declare notice: NoticeService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare session: Session;

  @storageFor('customer') storage: any;
  @storageFor('service-center') localStorageServiceCenter: any;

  @tracked isInvalidAccount = false;
  @tracked target = 'index';
  @tracked exceptRedirect = [
    '/',
    'register.index',
    'forgot-password.index',
    'forgot-password.confirmation',
    'register.confirmation',
    'store.customers.confirmation.fulfilled'
  ];

  async model() {
    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get('code');
    this.authenticateWithAzure(authorizationCode);
  }

async authenticateWithAzure(authorizationCode: String) {
const data = {
data: {
  type: 'tokens',
  attributes: {
    authorization_code: authorizationCode,
  },
},
};

try {
if (authorizationCode) {
  await this.session.authenticate('authenticator:jwt', data, 'login');

  const customer = await this.store.findRecord('customer', this.session?.id);
  window?.$zoho?.salesiq?.visitor?.name(customer?.name);
  window?.$zoho?.salesiq?.visitor?.email(customer?.email);

  if (!Number.isNaN(customer?.cellPhone)) {
    window?.$zoho?.salesiq?.visitor?.contactnumber(customer?.cellPhone);
  }

  let accountId = customer?.get?.('account.id'); 
  let accountName = customer?.get?.('account.name');

  if (accountId) {
    window?.$zoho?.salesiq?.visitor?.info({
      "accountid" :  accountId,
      "accountname" : accountName,
      "officephone" : (!Number.isNaN(customer?.officePhone)) ? customer?.officePhone : ""
    }); 
  }

  this.storage?.set('name', accountName);
  this.storage?.set('id', customer?.id);
  this.storage?.set('accountId', accountId);
  this.localStorageServiceCenter?.set('id', customer?.get('serviceCenter.id'));


  if (!customer.get('firstName') || !customer.get('lastName')) {
    this.session.set(
      'attemptedTransition',
      this.router.transitionTo('store.customer.edit', customer.id),
    );


  } else {
    if (
      this.exceptRedirect.includes(
        this.session.attemptedTransition)
    ) {
      if (
        this.exceptRedirect.includes(
          this.session.attemptedTransition.targetName,
        )
      ) {

        this.session.set('attemptedTransition', null);
        getOwner(this)
          .lookup('route:application')
          .set('routeAfterAuthentication', this.target);
      }
    }

    const attemptedTransition = this.session.get('attemptedTransition');
    const cookies = getOwner(this).lookup('service:cookies');
    const redirectTarget = cookies.read('ember_simple_auth-redirectTarget');
    console.log("redirectTarget", redirectTarget);

    if (attemptedTransition) {
      attemptedTransition.retry();
      console.log("attemptedTransition inside", attemptedTransition);

      this.session.set(
        'attemptedTransition',
        this.router.transitionTo('store.customer', customer.id),
      );
    } else if (redirectTarget) {
      console.log("redirectTarget inside", redirectTarget);
      this.router.transitionTo(redirectTarget);
      cookies.clear('ember_simple_auth-redirectTarget');
    } else {
      const routeAfterAuthentication = getOwner(this)
        .lookup('route:application')
        .get('routeAfterAuthentication');
      if (routeAfterAuthentication) {
        console.log("redirectTarget else", getOwner(this)
          .lookup('route:application')
          .get('routeAfterAuthentication'));

        this.router.transitionTo(
          getOwner(this)
            .lookup('route:application')
            .get('routeAfterAuthentication'),
        );
      } else {
        this.session.set(
          'attemptedTransition',
          this.router.transitionTo('store.customer', customer.id),
        );
      }
    }
  }
}
} catch (error) {
  this.isInvalidAccount = false;
  await this.notice.error(
    'There was an error while trying to sign in with your Summit ID'
  );
  this.router.transitionTo('/login').then(() => window.location.reload());
}
}
}
export default instrumentRoutePerformance(SsoAuthRoute);
