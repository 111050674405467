import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
// import type AccountModel from 'shoelace/models/account';
import type RbacRoleModel from 'shoelace/models/rbac-role';
import RbacAccountCustomerRoleModel from './rbac-account-customer-role';

export default class RbacAccountRoleModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('date') updatedAt!: null | Date;
  @attr('string') rbac_role_id!: null | string;
  @attr('string') rbac_account_customer_role_id!: null | string;

  // @belongsTo('account')
  // account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('rbac-role')
  rbacRole!: AsyncBelongsTo<RbacRoleModel>;

  @belongsTo('rbac-account-customer-role')
  rbacAccountCustomerRole!: AsyncBelongsTo<RbacAccountCustomerRoleModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'rbac-account-role': RbacAccountRoleModel;
  }
}
