import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @model}}\n  <LinkTo\n    class=\"flex flex-row items-start mx-2 justify-start text-start\"\n    @model={{@model}}\n    @query={{this.query}}\n    @route={{@route}}\n    ...attributes\n  >\n    {{yield}}\n    <FaIcon class=\"ml-1\" @icon={{this.icon}} />\n  </LinkTo>\n{{else}}\n  <LinkTo\n    class=\"flex flex-row items-start mx-2 justify-start text-start\"\n    @query={{this.query}}\n    @route={{@route}}\n    ...attributes\n  >\n    {{yield}}\n    <FaIcon class=\"ml-1\" @icon={{this.icon}} />\n  </LinkTo>\n{{/if}}\n", {"contents":"{{#if @model}}\n  <LinkTo\n    class=\"flex flex-row items-start mx-2 justify-start text-start\"\n    @model={{@model}}\n    @query={{this.query}}\n    @route={{@route}}\n    ...attributes\n  >\n    {{yield}}\n    <FaIcon class=\"ml-1\" @icon={{this.icon}} />\n  </LinkTo>\n{{else}}\n  <LinkTo\n    class=\"flex flex-row items-start mx-2 justify-start text-start\"\n    @query={{this.query}}\n    @route={{@route}}\n    ...attributes\n  >\n    {{yield}}\n    <FaIcon class=\"ml-1\" @icon={{this.icon}} />\n  </LinkTo>\n{{/if}}\n","moduleName":"shoelace/components/amps/sort-link-to.hbs","parseOptions":{"srcName":"shoelace/components/amps/sort-link-to.hbs"}});
import Component from '@glimmer/component';

/**
 * <Amps::SortLinkTo @ascendingIcon="sort-up"
 *                   @ascendingValue="account-project-release.request-number"
 *                   @descendingIcon="sort-down"
 *                   @descendingValue="-account-project-release.request-number"
 *                   @indeterminateIcon="sort"
 *                   @indeterminateValue="-account-project-release.request-number"
 *                   @field="account-project-release.request-number"
 *                   @key="sort"
 *                   @route="store.services.my-projects.releases.assigned-to-me"
 *                   @value="account-project-release.request-number">
 */
export default class AmpsSortLinkToComponent extends Component {
  get key() {
    return this.args.key ?? 'sort';
  }

  get ascendingIcon() {
    return this.args.ascendingIcon ?? 'sort-up';
  }

  get descendingIcon() {
    return this.args.descendingIcon ?? 'sort-down';
  }

  get indeterminateIcon() {
    return this.args.indeterminateIcon ?? 'sort';
  }

  get ascendingValue() {
    return this.args.ascendingValue ?? this.args.field;
  }

  get descendingValue() {
    return this.args.descendingValue ?? `-${this.args.field}`;
  }

  get indeterminateValue() {
    return this.args.indeterminateValue ?? `-${this.args.field}`;
  }

  get isAscending() {
    return this.args.value === this.ascendingValue;
  }

  get isDescending() {
    return this.args.value === this.descendingValue;
  }

  get isIndeterminate() {
    return !this.isAscending && !this.isDescending;
  }

  get icon() {
    if (this.isIndeterminate) {
      return this.indeterminateIcon;
    }

    if (this.isAscending) {
      return this.ascendingIcon;
    }

    if (this.isDescending) {
      return this.descendingIcon;
    }

    return this.indeterminateIcon;
  }

  get value() {
    if (this.isIndeterminate) {
      return this.indeterminateValue;
    }

    if (this.isAscending) {
      return this.descendingValue;
    }

    if (this.isDescending) {
      return this.ascendingValue;
    }

    return this.indeterminateValue;
  }

  get query() {
    const query = this.args.query ?? {};

    return {
      ...query,
      page: 1,
      [this.key]: this.value,
    };
  }
}
