import Model, { attr } from '@ember-data/model';
import { not } from '@ember/object/computed';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';

const Validations = buildValidations({
  firstName: {
    validators: [
      validator('presence', {
        presence: true,
        message: 'Please enter your first name.',
      }),
    ],
  },

  lastName: {
    validators: [
      validator('presence', {
        presence: true,
        message: 'Please enter your last name.',
      }),
    ],
  },

  email: {
    validators: [
      validator('ds-error', {
        message: 'Email has been taken.',
      }),
      validator('presence', {
        presence: true,
        message: 'Email must not be blank.',
      }),
      validator('format', {
        type: 'email',
        message: 'Invalid email format.',
      }),
    ],
  },

  password: {
    validators: [
      validator('presence', {
        presence: true,
        message: 'Password must not be blank.',
      }),
    ],
  },

  passwordConfirmation: {
    validators: [
      validator('presence', true),
      validator('confirmation', {
        on: 'password',
        message: 'Passwords do not match.',
      }),
    ],
  },

  companyName: {
    validators: [
      validator('presence', {
        presence: true,
        message: 'Company Name must not be blank.',
        disabled: not('model.hasAccount'),
      }),
    ],
  },

  phoneNumber: {
    validators: [
      validator('presence', {
        presence: true,
        message: 'Phone number must not be blank.',
        disabled: not('model.hasAccount'),
      }),
      validator('format', {
        type: 'phone',
        disabled: not('model.hasAccount'),
      }),
    ],
  },

  accountNumber: {
    validators: [
      validator('presence', {
        presence: true,
        message: 'Account Number must not be blank.',
        disabled: not('model.hasAccount'),
      }),
      validator('length', {
        min: 1,
        max: 10,
        message: 'An account number is between {min} and {max} numbers.',
        disabled: not('model.hasAccount'),
      }),
    ],
  },

  jobTitle: {
    validators: [
      validator('presence', {
        presence: true,
        message: 'Password must not be blank.',
        disabled: not('model.hasAccount'),
      }),
    ],
  },
});

export default class CustomerRegistrationModel extends Model.extend(Validations) {
  @attr('string')
  declare firstName?: string;

  @attr('string')
  declare lastName?: string;

  @attr('string')
  declare email?: string;

  @attr('string')
  declare password?: string;

  @attr('string')
  declare passwordConfirmation?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'customer-registration': CustomerRegistrationModel;
  }
}
