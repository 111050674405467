import Route from '@ember/routing/route';
import { refreshModel } from 'shoelace/utils/query-params';
import type AccountProject from 'shoelace/models/account-project';
import type ArrayProxy from '@ember/array/proxy';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';
import type RouterService from '@ember/routing/router';
import SessionService from 'shoelace/services/-session';

export interface RouteModel {
  accountProjects: ArrayProxy<AccountProject>;
}

export interface RouteParams {
  page?: string;
  size?: string;
  sort?: string;
  search?: string[];
}

class AdminAccountProjectsIndexRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare router: RouterService; 
 
  queryParams = {
    page: refreshModel,
    search: refreshModel,
    size: refreshModel,
    sort: refreshModel,
  };

  async model({
    page: number,
    search,
    size,
    sort,
  }: RouteParams): Promise<RouteModel> {

    const customer = await this.session.get('customer');

    if (customer) {
      if (customer?.hasRbacAdminCredit || customer?.hasRbacCustomerAdmin) {
        this.router.transitionTo('store.customer', customer.id);
      } 
    }

    const accountProjects = this.store.query('account-project', {
      filter: {
        search,
      },
      page: {
        number,
        size,
      },
      sort,
    });

    return {
      accountProjects,
    };
  }
}
export default instrumentRoutePerformance(AdminAccountProjectsIndexRoute);