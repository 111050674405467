import { A } from '@ember/array';
import { isEmpty, isPresent } from '@ember/utils';
import RSVP from 'rsvp';
import { group } from '../../helpers/group';
import ProtectedRoute from '../../mixins/protected-route';
import { idv4 } from '../../utils/bs-uuid';
import Route from '@ember/routing/route';
import { refreshModel } from '../../utils/query-params';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

const statuses = A([
  {
    key: 'all',
    value: 'All Documents',
    filter() {
      return true;
    },
  },
  {
    key: 'credits',
    value: 'Credits',
    filter(item) {
      return item.get('isCredit');
    },
  },
  {
    key: 'debits',
    value: 'Debits',
    filter(item) {
      return item.get('isDebit');
    },
  },
  {
    key: 'invoices',
    value: 'Invoices',
    filter(item) {
      return item.get('documentType') === 'invoice';
    },
  },
  {
    key: 'service-charges',
    value: 'Service Charges',
    filter(item) {
      return item.get('documentType') === 'service-charge';
    },
  },
  {
    key: 'tax-adjustments',
    value: 'Tax Adjustments',
    filter(item) {
      return item.get('documentType') === 'tax-adjustment';
    },
  },
  {
    key: 'tax-credits',
    value: 'Tax Credits',
    filter(item) {
      return item.get('documentType') === 'tax-credit';
    },
  },
]);

export const sortProperties = A([
  { key: 'documentDate', value: 'Date' },
  { key: 'documentNumber', value: '#' },
  { key: 'documentType', value: 'Type' },
  { key: 'poNumber', value: 'PO #' },
  { key: 'orderNumber', value: 'Order #' },
  { key: 'dueDate', value: 'Due Date' },
  { key: 'amount', value: 'Amount' },
]);

export const baseJobAccounts = A([
  {
    key: 'all',
    value: 'All Job Accounts',
    filter() {
      return true;
    },
  },
  {
    key: 'shop-account',
    value: 'Shop Account',
    filter(item) {
      return isEmpty(get(item, 'accountJobAccount.id'));
    },
  },
]);

class StoreAccountsRoute extends Route {
  @service store;
  @service session;
  @service notice;

  titleToken = 'Bill Pay';

  queryParams = {
    jobAccount: {
      refreshModel: true,
    },
    status: {
      refreshModel: true,
    },
    sortBy: { 
      refreshModel: true 
    },
    jobAccountFilter: { 
      refreshModel: true 
    },
    typeFilter: { 
      refreshModel: true 
    }, 
    docNumberFilter: { 
      refreshModel: true 
    },
    poNumberFilter: { 
      refreshModel: true 
    }, 
    orderNumberFilter: { 
      refreshModel: true 
    }, 
    amountFilter: { 
      refreshModel: true 
    }, 
  };

  async beforeModel(transition) {
    if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }
  }

  model(params) {
    const account = this.session.customer?.account?.id;
    const { jobAccount: jobAccountParam, status: statusParam, sortBy: sortByParam, jobAccountFilter: jobAccountFilterParam, typeFilter: typeFilterParam, docNumberFilter:docNumberFilterParam, poNumberFilter: poNumberFilterParam, orderNumberFilter: orderNumberFilterParam, amountFilter: amountFilterParam } = params;

    const query = this.store.query('account-open-item', {
      filter: { account: account },
      include: 'account,account-job-account,account-open-item-receipt',
    });

    const receiptsQuery = this.store.query('account-open-item-receipt', {
      filter: { account: account },
      include: 'account,customer,account-open-items',
    });

    const accountOpenItemReceipts = receiptsQuery.then((receipts) => {
      return receipts.filter((receipt) => receipt.isPending);
    });

    const jobAccounts = query
      .then((items) => {
        return items
          .mapBy('accountJobAccount.id')
          .uniq()
          .sort()
          .reverse()
          .filter((id) => isPresent(id))
          .map((key) => {
            const jobAccount = this.store.peekRecord(
              'account-job-account',
              key
            );
            const value = jobAccount ? `${jobAccount.ktext} (${key})` : '';

            return {
              key,
              value,
              filter(item) {
                return item.accountJobAccount.id === this.key;
              },
            };
          });
      })
      .then((jobAccounts) => baseJobAccounts.concat(jobAccounts));

    const jobAccount = jobAccounts.then(
      (jobAccounts) =>
        jobAccounts.findBy('key', jobAccountParam) || jobAccounts.firstObject
    );

    const status = statuses.findBy('key', statusParam) || statuses.firstObject;

    const accountOpenItemReceipt = this.store.createRecord(
      'account-open-item-receipt',
      {
        account: this.session.customer?.account,
        customer: this.session.customer,
        remittanceId: idv4(30),
        status: 'created',
      }
    );
    const accountOpenItems = RSVP.hash({
      accountOpenItemReceipts,
      jobAccount,
      query,
    }).then((model) => {
      const { jobAccount, query } = model;

      return query.filter((item) => {
        return jobAccount.filter(item) && status.filter(item) && item.isPayable;
      });
    });

    const groups = accountOpenItems.then((items) =>
      group('accountJobAccount.id', items)
    );

    const model = {
      accountOpenItemReceipt,
      accountOpenItemReceipts,
      accountOpenItems,
      groups,
      jobAccount,
      jobAccounts,
      openItemsDate: new Date(),
      sortDirection: null,
      sortProperties,
      sortProperty: null,
      status,
      statuses,
    };

    return RSVP.hash(model);
  }
}
export default instrumentRoutePerformance(StoreAccountsRoute);