import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type ServiceCenterType from 'shoelace/models/service-center-type';
import type ServiceCenter from 'shoelace/models/service-center';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface RouteModel {
  newServiceCenter: ServiceCenter;
  serviceCenterTypes: ArrayProxy<ServiceCenterType>;
}

class AdminServiceCentersNewServiceCenterRoute extends Route {
  @service declare store: StoreService;
 
  model(): RouteModel {
    const newServiceCenter = this.store.createRecord('service-center');
    const serviceCenterTypes = this.store.findAll('service-center-type');

    return {
      newServiceCenter,
      serviceCenterTypes,
    };
  }
}

export default instrumentRoutePerformance(
  AdminServiceCentersNewServiceCenterRoute
);