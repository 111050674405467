import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.outputRichData}}\n  {{#in-element this.inElement insertBefore=null}}\n    <script type=\"application/ld+json\">\n      {{this.outputRichData}}\n    </script>\n  {{/in-element}}\n{{/if}}", {"contents":"{{#if this.outputRichData}}\n  {{#in-element this.inElement insertBefore=null}}\n    <script type=\"application/ld+json\">\n      {{this.outputRichData}}\n    </script>\n  {{/in-element}}\n{{/if}}","moduleName":"shoelace/components/product/rich-data-product.hbs","parseOptions":{"srcName":"shoelace/components/product/rich-data-product.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ProductModel from 'shoelace/models/product';
import ServiceCenterModel from 'shoelace/models/service-center';

interface ProductRichDataComponentArgs {
  product: ProductModel;
  serviceCenter?: ServiceCenterModel;
  price?: number;
}

export default class ProductRichDataComponent extends Component<ProductRichDataComponentArgs> {
  @tracked product: ProductModel = this.args.product;
  @tracked serviceCenter?: ServiceCenterModel = this.args.serviceCenter;
  @tracked price = this.args.price;

  get outputRichData() {
    const nameProduct = this.product.name;
    const descriptionProduct = this.product.description;

    if (this.price && this.price != 0) {
      return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Product',
        '@id': this.product.id,
        productID: this.product.id,
        name: nameProduct?.replace(/\W/g, ' '),
        image: this.product.imageUrl,
        brand: {
          '@type': 'Organization',
          name: this.product.manufacturerName,
        },
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '5',
          reviewCount: 5,
        },
        review: [
          {
            '@type': 'Review',
            author: {
              name: 'John Feview',
              '@type': 'person',
            },
            datePublished: '2022-04-11',
            headline: nameProduct?.replace(/\W/g, ' '),
            reviewBody: descriptionProduct?.replace(/\W/g, ' '),
            name: nameProduct?.replace(/\W/g, ' '),
            reviewRating: {
              '@type': 'Rating',
              bestRating: '5',
              ratingValue: '5',
              worstRating: '5',
            },
          },
        ],
        description: descriptionProduct?.replace(/\W/g, ' '),
        gtin14: this.product.upc,
        mpn: this.product.manufacturerPartNumber,
        sku: this.product.summitPartNumber,
        offers: {
          '@type': 'Offer',
          '@id': 'https://www.summit.com/store/products/' + this.product.id,
          gtin: this.product.upc,
          mpn: this.product.manufacturerPartNumber,
          sku: this.product.summitPartNumber,
          availableDeliveryMethod: 'http://schema.org/OnSitePickup',
          availability: 'https://schema.org/InStock',
          availableAtOrFrom: {
            '@type': 'Place',
            branchCode: this.serviceCenter?.longName,
          },
          priceCurrency: 'USD',
          priceValidUntil: '2040-12-30',
          price: this.price,
          itemCondition: 'http://schema.org/NewCondition',
          image: this.product.imageUrl,
          url: 'https://www.summit.com/store/products/' + this.product.id,
        },
      });
    }
  }

  get inElement() {
    return document.head;
  }
}
