import { helper } from '@ember/component/helper';

export function uniqueDocumentTypes([groups]) {
  let uniqueSet = new Set();

  if (!groups || !Array.isArray(groups)) {
    return [];
  }

  groups.forEach((openItem) => {
    if (openItem && Array.isArray(openItem.children)) {
      openItem.children.forEach((openItemNew) => {
        if (openItemNew && openItemNew.documentType) {
          uniqueSet.add(openItemNew.documentType);
        }
      });
    }
  });

  return Array.from(uniqueSet);
}

export default helper(uniqueDocumentTypes);