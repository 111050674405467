import StoreService from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  EXCEPTION_1048,
  EXCEPTION_1049,
  EXCEPTION_1050,
  EXCEPTION_1051,
} from 'shoelace/exceptions/mod';
import CustomerModel from 'shoelace/models/customer';
import ProcurementModel from 'shoelace/models/procurement';
import ProductModel from 'shoelace/models/product';
import OrderingDictionary from 'shoelace/order_dictionary';
import KartService, { Item } from 'shoelace/services/kart';
import type ProcurementService from 'shoelace/services/procurement';
import { v1 } from 'uuid';
//import type RollbarService from 'shoelace/services/rollbar';

export default class SecureCheckoutIndexController extends Controller {
@service declare store: StoreService;

@service declare kart: KartService;

@service declare procurement: ProcurementService;

//@service declare rollbar: RollbarService;

  @action async onRemoveUnorderable(): Promise<void> {
    this.kart.unorderableLineItems.forEach(item => {
      this.kart.removeItem(item.product);
    });
  }

  @action async onRequestQuote(items: Item[]): Promise<ProcurementModel> {
    try {
      const quote = await this.procurement.createProcurement();

      quote.kind = 'quote';
      quote.lineItems = items.map(item => {
        return {
          matnr: item.product.matnr,
          quantity: item.quantity,
          message: item.message,
        };
      });

      await quote.save();
      await this.onRemoveUnorderable();

      return quote;
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1048, error);

      throw error;
    }
  }

  @action async onRemoveItem(
    product: ProductModel | string | number,
  ): Promise<void> {
    await this.kart.removeItem(product);
  }

  @action async onSetMessage(
    product: ProductModel,
    message: string,
  ): Promise<void> {
    await this.kart.setMessage(product, message);
  }

  @action async onSetShipToAddress(
    product: ProductModel,
    address: string,
  ): Promise<void> {
    await this.kart.setShipToAddress(product, address);
  }

  @action async onSetQuantity(
    product: ProductModel,
    quantity: number,
  ): Promise<void> {
    await this.kart.setQuantity(product, quantity);
  }

  @action async onSetCounterOrder(isChecked: boolean): Promise<void> {
    const procurement = this.procurement.procurement;

    if (procurement) {
      if (isChecked) {
        procurement.set('kind', OrderingDictionary.KIND.SAPCOUNTERORDER);
        procurement.set('accountAddress', procurement.get('willCallShipTo'));
        procurement.set(
          'deliveryMethod',
          OrderingDictionary.DELIVERY_METHOD.WILL_CALL,
        );
        procurement.set(
          'paymentMethod',
          OrderingDictionary.PAYMENT_METHOD.WILL_CALL,
        );
      } else {
        procurement.set('kind', OrderingDictionary.KIND.SAPORDER);
      }
    }
  }

  @action async onEmailCartItems(
    items: Item[],
    customer: CustomerModel,
    to_email: string,
    message: string,
  ): Promise<void> {
    try {
      const legacyCart = await this.kart.generateLegacyCart(
        null,
        items,
        customer,
      );

      const cartInfo = this.store.createRecord('cart-info');
      cartInfo.set('cart_id', legacyCart.get('id'));
      cartInfo.set('customer_id', customer.id);
      cartInfo.set('customer', customer);
      cartInfo.set('email', customer.email);
      cartInfo.set('message', message);
      cartInfo.set('to_email', to_email);
      cartInfo.set('product', null);

      await cartInfo.save();
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1049, error);

      throw error;
    }
  }

  @action async onEmailCutsheets(
    items: Item[],
    customer: CustomerModel,
  ): Promise<void> {
    try {
      const products = items.map(item => item.product);
      const cutsheet = this.store.createRecord('email-product-cutsheet');
      cutsheet.id = v1();

      cutsheet.set('customer', customer);
      cutsheet.set('products', products);

      await cutsheet.save();
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1050, error);

      throw error;
    }
  }

  @action async onSaveAsList(
    items: Item[],
    title: string,
    customer: CustomerModel,
  ): Promise<void> {
    try {
      await this.kart.generateLegacyCart(title, items, customer);

      items.forEach(item => {
        this.kart.removeItem(item.product);
      });
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1051, error);

      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'secure-checkout/index': SecureCheckoutIndexController;
  }
}
