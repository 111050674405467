import Model, { attr, belongsTo } from '@ember-data/model';

export default class AccountStatusModel extends Model {
  @attr('boolean') overCreditLimit;
  @attr('string') zterm;
  @attr('boolean') saveQuote;
  @attr('boolean') saveOrder;
  @attr('boolean') invalidCust;
  @attr('number') deltaToLimit;

  @belongsTo('account') account;

  get sapOrderFlag() {
    const overCreditLimit = this.overCreditLimit;
    const saveQuote = this.saveQuote;
    const saveOrder = this.saveOrder;
    const invalidCust = this.invalidCust;

    return overCreditLimit || saveQuote || saveOrder || invalidCust;
  }
}
