import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type RouterService from '@ember/routing/router';
import type CustomerModel from 'shoelace/models/customer';
import type SessionService from 'shoelace/services/session';
import { instrumentRoutePerformance } from '@sentry/ember';

class CustomerListsRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare session: SessionService;

  titleToken = 'Saved Lists';

  async model() {
    const customer: CustomerModel | undefined = this.session.customer;
    const customerId = customer?.get('id');
    let carts;

    if (customerId) {
      carts = await this.store.query('cart', {
        filter: {
          'customer-id': customerId,
          kind: 'list',
        },
      });
    } else {
      this.router.transitionTo('login');
    }
    return {
      carts,
      customer,
    };
  }
}
export default instrumentRoutePerformance(CustomerListsRoute);
