import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class CustomerRoute extends Route {
  @service session;
  @service store;
  @service router;

  async beforeModel(transition) {
    if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }
  }

  async model(customer) {
    return await this.store.findRecord('customer', customer.id);
  }
}
export default instrumentRoutePerformance(CustomerRoute);
