import { inject as service } from '@ember/service';
import config from 'shoelace/config/environment';
import Route from '@ember/routing/route';
import { instrumentRoutePerformance } from '@sentry/ember';

const dontRefreshModel = { refreshModel: false };

class IndexRoute extends Route {
  @service store;
  @service router;

  queryParams = {
    page_id: dontRefreshModel,
    preview: dontRefreshModel,
  };

  beforeModel() {
    const domainReload = config.domainReload;

    if (domainReload) {
      window.location.reload();
    }
  }

  async model({ page_id, preview }) {
    if (page_id && preview) {
      const post = await this.store.findRecord('post', page_id);

      return this.router.transitionTo(
        'page',
        `${post.get('section')}/preview-new-page`,
        { queryParams: { page_id, preview } }
      );
    }

    const signIn = this.store.createRecord('sign-in');

    const productCategories = await this.store.findRecord(
      'category',
      '0f379757-1f89-51c8-adf5-716097eceb87',
      {
        include: ['categories'].join(','),
      }
    );

    const categories = productCategories.categories.filter(
      (category) =>
        category.id === '0c27f58a-4c0f-5467-9826-26cb68bea011' ||
        category.id === '21f08eae-5f6c-542a-ab3c-16488a5528c6' ||
        category.id === '485db7d0-4d0c-593b-a40d-b615a9569d6a' ||
        category.id === '4dc2d786-43c0-59d2-b6f1-d5364f6a38cc' ||
        category.id === '6133dbed-cad5-5bd8-97dc-f8f3bef136d3' ||
        category.id === 'acaa8343-3d42-59bd-bd24-bb2db6ffa9de' ||
        category.id === 'b2ad9d35-4f90-5a8b-af3f-c91140c5c0b1' ||
        category.id === 'b5b4c3a5-2a25-53c3-ac69-8b1dba4a9a2a' ||
        category.id === 'cd614dae-7820-55cc-b2cb-2a9b7c0a62bb' ||
        category.id === 'd8713022-d251-537e-a1e4-2f8d500b2289' ||
        category.id === 'dfda5a96-a05d-524b-829c-800fd9200647' ||
        category.id === 'e0fedf15-9fa3-5e12-9a9c-3d64940e577c'
    );

    return {
      signIn,
      categories,
    };
  }
}
export default instrumentRoutePerformance(IndexRoute);
