import Model from '@ember-data/model';
import { attr } from '@ember-data/model';

export default class ManufacturerModel extends Model {
  @attr('string') name!: null | string;
  @attr('string') logoUrl!: null | string;

  toJSON() {
    const id = this.id;
    const name = this.name;
    const logoUrl = this.logoUrl;

    return { id, name, logoUrl };
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    manufacturer: ManufacturerModel;
  }
}
