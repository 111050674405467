import StorageObject from 'ember-local-storage/local/object';

const Storage = StorageObject.extend();

// Uncomment if you would like to set initialState
Storage.reopenClass({
  initialState() {
    return {
      cart_id: -1,
      cart_ids: [],
    };
  },
});

export default Storage;
