import type ObjectProxy from '@ember/object/proxy';
import Route from '@ember/routing/route';
import type AccountProject from 'shoelace/models/account-project';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';
import type RouterService from '@ember/routing/router';
import SessionService from 'shoelace/services/-session';

export interface RouteModel {
  accountProject: ObjectProxy<AccountProject>;
}

export interface RouteParams {
  account_project_id: string | number;
}

class AdminAccountProjectRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare router: RouterService; 

  async model({ account_project_id: id }: RouteParams): Promise<AccountProject> {

    const customer = await this.session.get('customer');

    if (customer) {
      if (customer?.hasRbacCustomerAdmin) {
        this.router.transitionTo('store.customer', customer.id);
      } 
    }

    return await this.store.findRecord('account-project', id);
  }
}

export default instrumentRoutePerformance(
  AdminAccountProjectRoute
);