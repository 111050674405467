import Route from '@ember/routing/route';
import { refreshModel } from '../../../utils/query-params';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import AccountModel from 'shoelace/models/account';
import ObjectProxy from '@ember/object/proxy';
import { instrumentRoutePerformance } from '@sentry/ember';
import SessionService from 'shoelace/services/-session';
import type RouterService from '@ember/routing/router';

export interface RouteParams {
  page: string | number;
  size: string | number;
  sort: string | number;
}

class AdminAccountIndexRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare router: RouterService; 

  queryParams = {
    page: refreshModel,
    size: refreshModel,
    sort: refreshModel,
  };

  async model({ page: number, size, sort }: RouteParams) {
    const account = (await this.modelFor(
      'admin.account'
    )) as ObjectProxy<AccountModel>;
    const customer = await this.session.get('customer');

    if (customer) {
      if (customer?.hasRbacCustomerAdmin) {
        this.router.transitionTo('store.customer', customer.id);
      } 
    }
    

    const customers = await this.store.query('customer', {
      filter: {
        accounts: account.get('id'),
      },
      page: {
        number,
        size,
      },
      sort,
    });

    return {
      account,
      customers,
    };
  }
}

export default instrumentRoutePerformance(AdminAccountIndexRoute);