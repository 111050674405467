import Model, { attr } from '@ember-data/model';

export default class GeocodeModel extends Model {
  @attr('string') city;
  @attr('string') countryCode;
  @attr('string') countryName;
  @attr('string') county;
  @attr('string') houseNumber;
  @attr('string') lat;
  @attr('string') lng;
  @attr('string') postalCode;
  @attr('string') queryScore;
  @attr('string') stateCode;
  @attr('string') state;
  @attr('string') street;
  @attr('string') title;
}
