import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { EXCEPTION_1013 } from 'shoelace/exceptions/mod';
import { DateRange } from '../../utils/range';
import { instrumentRoutePerformance } from '@sentry/ember';

const dontRefreshModel = { refreshModel: false };
const refreshModel = { refreshModel: true };

export const sortProperties = [
  { key: 'clearedAt', value: 'Date' },
  { key: 'clearingDocNo', value: 'Payment #' },
  { key: 'lineItemsTotal', value: 'Amount' },
];

class StoreAccountPaymentsRoute extends Route {
  @service session;
  @service store;
  @service router;

  

  titleToken = 'Payment History';

  queryParams = {
    query: refreshModel,
    range: refreshModel,
    sortAscending: dontRefreshModel,
    sortProperty: dontRefreshModel,
  };
  async beforeModel(transition) {
    if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }
    const controller = this.controller;
    if (controller) controller.paymentNotFound = null;
    const customer = await this.session.get('customerRecord');

    if (customer) {
      if ((!customer?.hasCustomerFinancial) && (customer?.hasRbacCustomer)) {
        this.router.transitionTo('store.customer', customer.id);
      } 
      return await customer.get('account');
    }
  }

  async model({
    query = '',
    range,
    sortAscending = '',
    sortProperty = 'clearedAt',
    paymentNotFound = null
  }) {
    const account = this.session.customer.account.id;
    const dateRange = new DateRange(range);
    const customer = this.session.customer;
    const sort = sortAscending ? `-${sortProperty}` : sortProperty;

    let accountPayments;

    const filter = {
      ...dateRange.toFilter(),
      'account-id': account,
    };

    accountPayments = await this.store.query('account-payment', {
      filter,
      sort,
      include: 'account-payment-line-items',
    });

    accountPayments.map(async (payment) => {
      try {
        const clearingDocNo = payment.id.split('-')[1];

        const lineItems = await payment.accountPaymentLineItems;
        const lineItemsTotal = lineItems
          .map((li) => {
            return li.isCredit ? -li.get('total') : li.get('total');
          })
          .reduce((a, b) => a + b, 0);

        payment.clearingDocNo = clearingDocNo;
        payment.lineItemsTotal = lineItemsTotal;

        return payment;
      } catch (error) {
      }
    });

    return {
      accountPayments: accountPayments || [],
      dateRange,
      query,
      customer,
      sortAscending: !!sortAscending,
      sortProperties,
      sortProperty,
      paymentNotFound: paymentNotFound || null,
    };
  }
  setupController(controller, model) {
    super.setupController(controller, model);

    controller.sortProperty = model.sortProperty;
    controller.sortAscending = model.sortAscending;
  }
}
export default instrumentRoutePerformance(StoreAccountPaymentsRoute);
