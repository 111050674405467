import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @model}}\n  <LinkTo\n    class=\"d-flex align-items-center\"\n    @model={{@model}}\n    @query={{this.query}}\n    @route={{@route}}\n    ...attributes\n  >\n    {{yield}}\n  </LinkTo>\n{{else}}\n  <LinkTo\n    class=\"d-flex align-items-center\"\n    @query={{this.query}}\n    @route={{@route}}\n    ...attributes\n  >\n    {{yield}}\n  </LinkTo>\n{{/if}}", {"contents":"{{#if @model}}\n  <LinkTo\n    class=\"d-flex align-items-center\"\n    @model={{@model}}\n    @query={{this.query}}\n    @route={{@route}}\n    ...attributes\n  >\n    {{yield}}\n  </LinkTo>\n{{else}}\n  <LinkTo\n    class=\"d-flex align-items-center\"\n    @query={{this.query}}\n    @route={{@route}}\n    ...attributes\n  >\n    {{yield}}\n  </LinkTo>\n{{/if}}","moduleName":"shoelace/components/amps/toggleable-link-to.hbs","parseOptions":{"srcName":"shoelace/components/amps/toggleable-link-to.hbs"}});
import Component from '@glimmer/component';

/**
 * <Amps::ToggleableLinkTo @key="toggled"
 *                         @model={{null}}
 *                         @offValue={{null}}
 *                         @onValue={{null}}
 *                         @route="store.services.my-projects.releases.assigned-to-me"
 *                         @value="bar">
 */
export default class AmpsToggleableLinkToComponent extends Component {
  get key() {
    return this.args.key ?? 'toggled';
  }

  get offValue() {
    return this.args.offValue ?? null;
  }

  get onValue() {
    return this.args.onValue ?? null;
  }

  get isOn() {
    return this.args.value === this.onValue;
  }

  get value() {
    if (this.isOn) {
      return this.offValue;
    }

    return this.onValue;
  }

  get query() {
    const query = this.args.query ?? {};

    return {
      ...query,
      [this.key]: this.value,
    };
  }
}
