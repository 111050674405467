import { belongsTo } from 'ember-data/relationships';
import { hasMany } from '@ember-data/model';
import { attr } from '@ember-data/model';
import Model from '@ember-data/model';
import { get, computed } from '@ember/object';

export default Model.extend({
  createdAt: attr('date'),
  expectedAt: attr('date'),
  expiresAt: attr('date'),
  validAt: attr('date'),
  jobAccountName: attr('string'),
  numberOfItems: attr('number'),
  purchaseOrder: attr('string'),
  salesTax: attr('number'),
  subtotal: attr('number'),
  total: attr('number'),
  email: attr('string'),

  account: belongsTo('account'),
  accountJobAccount: belongsTo('account-job-account'),
  accountQuoteBillAddress: belongsTo('account-quote-address'),
  accountQuoteFundAddress: belongsTo('account-quote-address'),
  accountQuoteShipAddress: belongsTo('account-quote-address'),
  accountQuoteSoldAddress: belongsTo('account-quote-address'),

  accountQuoteComments: hasMany('account-quote-comment'),
  accountQuoteLineItems: hasMany('account-quote-line-item'),

  sortedLineItems: computed('accountQuoteLineItems', function () {
    const a = this.accountQuoteLineItems;
    const lineNumber = id => id.split('-')[1];
    return a.toArray().sort((a, b) => {
      const a_ln = lineNumber(a.id),
        b_ln = lineNumber(b.id);
      return a_ln - b_ln;
    });
  }),
});
