import { Ability } from 'ember-can';
import { inject as service } from '@ember/service';

export default class ServiceCenterAbility extends Ability {
  @service session;

  // get customerPermissions() {
  //   return (
  //     this.session.customer
  //       ?.get?.('roles')
  //       .filterBy?.('customer.id', `${this.session.id}`)
  //       .mapBy?.('grants')
  //       .map?.(grant => grant.mapBy('permission.name'))
  //       .flat?.()
  //       .uniq?.() ?? []
  //   );
  // }

  // get accountPermissions() {
  //   return (
  //     this.session.customer
  //       ?.get?.('roles')
  //       .filterBy?.('account.id', this.session.customer?.get?.('account.id'))
  //       .mapBy?.('grants')
  //       .map?.(grant => grant.mapBy('permission.name'))
  //       .flat?.()
  //       .uniq?.() ?? []
  //   );
  // }

  // get accountCustomerPermissions() {
  //   return (
  //     this.session.customer
  //       ?.get?.('roles')
  //       .filter?.(role => {
  //         return (
  //           role.get('account.id') === this.session.customer.get('account.id') &&
  //           role.get('customer.id') === `${this.session.id}`
  //         );
  //       })
  //       .mapBy?.('grants')
  //       .map?.(grant => grant.mapBy('permission.name'))
  //       .flat?.()
  //       .uniq?.() ?? []
  //   );
  // }

  /**
   * This checks for a poorly named permission called `service_center::read`, which is an explicit
   * permission when set which overrides the default implicit permission of reading all
   * service centers.
   *
   * When this permission is found on the customer, regardless of account, the customer is gated
   * to a specific service center set on their customer record.
   */
  // get canRead() {
  //   return (
  //     this.session.isInternal ||
  //     !this.customerPermissions.includes('service_center::read')
  //   );
  // }
}
