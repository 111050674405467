import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { EXCEPTION_1007 } from 'shoelace/exceptions/mod';
import jwt from '../../../utils/jwt';
import { refreshModel } from '../../../utils/query-params';
import { isEmpty } from '@ember/utils';
import { instrumentRoutePerformance } from '@sentry/ember';
import { storageFor } from 'ember-local-storage';

class PunchoutsPo2goSetupRoute extends Route {
  @service account;
  @service intl;
  @service procurement;
  @service serviceCenter;
  @service session;
  @service store;
  @service router;

  @storageFor('customer') localCustomer;
  @storageFor('service-center') localStorageServiceCenter;

  titleToken = 'Login';

  queryParams = {
    pos: {
      refreshModel,
    },
  };

  async model({ pos: sessionId }) {
    /* break us out of any containing iframes */
    debugger;
    // const now = Date();
    // const formatted_date = now.toUTCString().replace('GMT', 'UTC');
    document.cookie = `name-of-the-cookie=1; expires=${Date()}; secure=1; SameSite=none;`;
    // try {
    //   // if (top !== self) {
    //   //   top.location.replace(self.location.href);
    //   // }
    // } catch (_) {
    //   this.router.transitionTo('punchouts.po2go.landing', {
    //     queryParams: {
    //       pos: sessionId,
    //     },
    //   });
    // }

    // post to bh
    // bh will come back with jsonapi:token
    // that will contain punchout info on metadata
    // if it doesn't, must have been an invalid start_url
    try {
      await this.session.invalidate('authenticator:jwt');
    } catch (_) {}

    try {
      await this.session.invalidate('authenticator:po2go');
    } catch (_) {}

    try {
      const data = {
        data: {
          type: 'tokens',
          attributes: {
            sessionId,
          },
        },
      };

      await this.session.authenticate('authenticator:po2go', data);

      const accounts = jwt(this.session.token).punchout?.accounts;
      if (isEmpty(accounts)) {
        throw new Error('punchout token has empty accounts array');
      } else {
        this.procurement.procurement = undefined;
        await this.procurement.createRecord();

        if (accounts.length > 1) {
          const customer = this.session.customer;
          const currentAccount = await customer.get('account');

          this.localCustomer?.set('id', customer.get('id'));
          this.localCustomer?.set('accountId', currentAccount.get('id'));
          this.localCustomer?.set('name', currentAccount.get('name'));

          if (currentAccount && currentAccount.get('id')) {
            if (!accounts.includes(parseInt(currentAccount.get('id')))) {
              const id = accounts.shift();
              const account = await this.store.findRecord('account', id, {
                include: ['service-center'].join(','),
              });

              await this.account.setAccount(account, this.session.customer);
              await this.serviceCenter.setById(account.get('serviceCenter.id'));
              this.localStorageServiceCenter?.set(
                'id',
                account.get('serviceCenter.id')
              );
            }
          }

          this.router.transitionTo('punchouts.po2go.pick-plant');
        } else {
          const customer = this.session.customer;

          const id = accounts.shift();
          if (id === 202409182) {
            this.router.transitionTo('punchouts.po2go.landing-error');
          } else {
            const account = await this.store.findRecord('account', id, {
              include: ['service-center'].join(','),
            });
            this.localCustomer?.set('id', customer.get('id'));
            this.localCustomer?.set('accountId', account.get('id'));
            this.localCustomer?.set('name', account.get('name'));

            await this.account.setAccount(account, this.session.customer);
            await this.serviceCenter.setById(account.get('serviceCenter.id'));
            this.localStorageServiceCenter?.set(
              'id',
              account.get('serviceCenter.id')
            );

            window.location.replace('/store/categories');
          }
        }
      }
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1007, error);

      throw error;
    }
  }
}
export default instrumentRoutePerformance(PunchoutsPo2goSetupRoute);
