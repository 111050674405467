import Helper from '@ember/component/helper';

export default Helper.extend({
  compute([array, defaultValue = '']) {
    try {
      const local_a = 'toArray' in array ? array.toArray() : array;
      if (local_a.length > 0) {
        return local_a[0];
      } else {
        return defaultValue;
      }
    } catch (e) {
      return defaultValue;
    }
  },
});
