import { inject as service } from '@ember/service';
import { DateRange } from '../../utils/range';
import Route from '@ember/routing/route';
import { instrumentRoutePerformance } from '@sentry/ember';

const refreshModel = { refreshModel: true };

export const sortProperties = [
  { key: 'createdAt', value: 'Date' },
  { key: 'accountJobAccount.label', value: 'Job Account' },
];

class StoreAccountHistoryStatementsRoute extends Route {
  @service session;
  @service store;
  @service router;

  titleToken = 'Statements';

  queryParams = {
    range: refreshModel,
    accountId: refreshModel,
  };

  async beforeModel(transition) {
    if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }

    const customer = await this.session.get('customer');

    if (customer) {
      if ((!customer?.hasCustomerFinancial) && (!customer?.hasCustomerDefault) && (customer?.hasRbacCustomer)) {
        this.router.transitionTo('store.customer', customer.id);
      } 
      return await customer.get('account');  
    }
  }

  async model({ range,accountId }) {
    const account = this.session.get('customerRecord.account.id');
    const customer = this.session.get('customer');

    const dateRange = new DateRange(range);

    const filter = {
      'account-id': account,
    };

    const include = ['account-job-account'].join(',');

    // Fetch account history statements
    const accountHistoryStatements = await this.store.query(
      'account-history-statement',
      { filter, include }
    );

    
    const filteredStatements = this.filterByDateRange(accountHistoryStatements, dateRange);

    
    const groups = filteredStatements
    .filter((statement) => {
      if (!accountId || accountId === "All") {
        return true;
      }
      if (accountId === "Shop Account") {
        return !statement.accountJobAccountId
      }
      return accountId === statement.accountJobAccountId;
    })
      .mapBy('accountJobAccountId')
      .uniq()
      .map((accountJobAccountId) => {
        const items = filteredStatements.filterBy(
          'accountJobAccountId',
          accountJobAccountId
        );
        const value = items[0];

        return {
          items,
          value,
        };
      });

      const groupsData = filteredStatements
      .mapBy('accountJobAccountId')
      .uniq()
      .map((accountJobAccountId) => {
        const items = accountHistoryStatements.filterBy(
          'accountJobAccountId',
          accountJobAccountId
        );
        const value = items[0];

        return {
          items,
          value,
        };
      });

    return {
      accountHistoryStatements: filteredStatements,
      groups,
      customer,
      sortProperties,
      dateRange,
      accountId,
      range,
      groupsData,
    };
  }

  filterByDateRange(statements, dateRange) {
    return statements.filter(statement => {
      const createdAt = new Date(statement.createdAt);
      return createdAt >= dateRange.start && createdAt <= dateRange.end;
    });
  }
}

export default instrumentRoutePerformance(StoreAccountHistoryStatementsRoute);
