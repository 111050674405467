import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { decamelize } from '@ember/string';
import type ProductModel from 'shoelace/models/product';

export default class ExternalFileModel extends Model {
  @attr('string') url!: null | string;
  @attr('date') createdAt!: null | Date;
  @attr('date') updatedAt!: null | Date;
  @attr('string') kind!: null | string;
  @attr('number') filesize!: null | number;
  @attr('number') width!: null | number;
  @attr('number') height!: null | number;

  @belongsTo('product')
  product!: AsyncBelongsTo<ProductModel>;

  @computed('kind')
  get name(): string {
    return decamelize(this.kind ?? '')
      .split('_')
      .map(a => `${a.slice(0, 1).toUpperCase()}${a.slice(1)}`)
      .join(' ');
  }

  @computed('kind')
  get prettyName(): string {
    const kind = this.kind ?? '';

    switch (kind) {
      case 'Attachment':
        return 'Cut Sheet';
      case 'AttachmentMirror':
        return 'Cut Sheet';
      case 'Msds':
        return 'MSDS';
      case 'MsdsMirror':
        return 'MSDS';
      case 'InstallationSheet':
        return 'Installation Sheet';
      case 'InstallationSheetMirror':
        return 'Installation Sheet';
      default:
        return kind;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'external-file': ExternalFileModel;
  }
}
