import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { not } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';
import type AccountVerificationStatusModel from 'shoelace/models/account-verification-status';
import type CustomerModel from 'shoelace/models/customer';
import type ServiceCenterModel from 'shoelace/models/service-center';

const validations = buildValidations({
  firstName: {
    description: 'First Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'First name must not be blank.',
      }),
    ],
  },
  lastName: {
    description: 'Last Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Last name must not be blank.',
      }),
    ],
  },
  jobTitle: {
    description: 'Location',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Job Title must not be blank.',
      }),
    ],
  },
  phoneNumber: {
    description: 'Phone Number',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Phone number must not be blank.',
      }),
      validator('format', {
        type: 'phone',
      }),
      validator('length', {
        min: 10,
        max: 11,
        message: 'Phone number must be 10 or 11 digits',
      }),
    ],
  },
  companyName: {
    description: 'Company Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Company Name must not be blank.',
      }),
    ],
  },
  approverFirstName: {
    description: 'First Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Approver`s first name must not be blank.',
      }),
    ],
  },
  approverLastName: {
    description: 'Last Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Approver`s last name must not be blank.',
      }),
    ],
  },
  approverJobTitle: {
    description: 'Location',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Approver`s Job Title must not be blank.',
      }),
    ],
  },
  approverPhoneNumber: {
    description: 'Phone Number',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Phone number must not be blank.',
      }),
      validator('format', {
        type: 'phone',
      }),
    ],
  },
  approverEmail: {
    description: 'From Email Address',
    validators: [
      validator('format', {
        type: 'email',
        allowNonTld: false,
        message: 'Your email must not be blank.',
      }),
    ],
  },
  accountNumber: {
    description: 'Account Number',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Account Number must not be blank.',
      }),
      validator('length', {
        min: 1,
        max: 10,
        message: 'An account number is between {min} and {max} numbers.',
      }),
    ],
  },
  approvedAccountNumber: {
    description: 'Approved Account Number',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Approved Account Number must not be blank.',
      }),
      validator('length', {
        min: 1,
        max: 10,
        message: 'An account number is between {min} and {max} numbers.',
      }),
    ],
  },
  requestedAccounts: {
    description: 'Approved Account Number',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Approved Account Number must not be blank.',
        disabled: not('model.shouldValidateRequestedAccounts'),
      }),
      validator('format', {
        regex: /^(\d{1,10})(,\d{1,10})*$/,
        message:
          'Every account number must be between 1 and 10 numbers. Separate with a comma "," if multiple accounts',
        disabled: not('model.shouldValidateRequestedAccounts'),
      }),
    ],
  },
  serviceCenter: {
    description: 'Service Center',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Please select a service center.',
      }),
    ],
  },
});

export default class AccountVerificationModel extends Model.extend(validations) {
  @attr('boolean') dismissed!: null | boolean;
  @attr('boolean') hasAuthority!: null | boolean;
  @attr('boolean') isCreditAdminRequired!: null | boolean;
  @attr('date') createdAt!: null | Date;
  @attr('date') updatedAt!: null | Date;
  @attr('string') accountNumber!: null | string;
  @attr('string') approvedAccountNumber!: null | string;
  @attr('string') approverEmail!: null | string;
  @attr('string') approverFirstName!: null | string;
  @attr('string') approverJobTitle!: null | string;
  @attr('string') approverLastName!: null | string;
  @attr('string') approverPhoneNumber!: null | string;
  @attr('string') companyName!: null | string;
  @attr('string') contactEmail!: null | string;
  @attr('string') contactFirstName!: null | string;
  @attr('string') contactJobTitle!: null | string;
  @attr('string') contactLastName!: null | string;
  @attr('string') contactPhoneNumber!: null | string;
  @attr('string') firstName!: null | string;
  @attr('string') jobTitle!: null | string;
  @attr('string') lastName!: null | string;
  @attr('string') notes!: null | string;
  @attr('string') phoneNumber!: null | string;
  @attr('string') requestedAccess!: null | string;
  @attr('string') status!: null | string;
  @attr('string') rbacRoleId!: null | string;
  @attr('string') accountVerificationStatusId!: null | string;

  @belongsTo('account-verification-status')
  accountVerificationStatus!: AsyncBelongsTo<AccountVerificationStatusModel>;

  @belongsTo('customer')
  customer!: AsyncBelongsTo<CustomerModel>;

  @belongsTo('service-center')
  serviceCenter!: AsyncBelongsTo<ServiceCenterModel>;

  @not('hasAuthority') isRequired!: boolean;

  @tracked requestedAccounts?: string;
  @tracked shouldValidateRequestedAccounts?: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-verification': AccountVerificationModel;
  }
}
