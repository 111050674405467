import Route from '@ember/routing/route';
import { instrumentRoutePerformance } from '@sentry/ember';
import { inject as service } from '@ember/service';

const refreshModel = { refreshModel: true };

class StoreCustomersUnlockIndexRoute extends Route {
  @service store;
  
  queryParams = {
    unlock_token: refreshModel,
  };

  model({ unlock_token: unlockToken }) {
    return this.store.createRecord('unlock', {
      unlockToken,
    });
  }
}
export default instrumentRoutePerformance(
  StoreCustomersUnlockIndexRoute
);