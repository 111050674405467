import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class VideoPlayerComponent extends Component {
  @service store;
  @service session;
  @service serviceCenter;

  @tracked tagName = this.args.tagName ?? '';
  @tracked externalFile = this.args.externalFile;
  @tracked mode = this.args.mode ?? 'thumbnail';
  @tracked alt = this.args.alt ?? 'video';
  @tracked width = this.args.width;
  @tracked height = this.args.height;

  @computed('externalFile.url')
  get isYoutube() {
    const url = this.externalFile.url;
    return url.includes('youtube.com');
  }

  @computed('isYoutube', 'externalFile.url')
  get youtubeEmbedId() {
    const url = this.externalFile.url;
    const ytLink = 'http://youtu.be/';
    if (url.startsWith(ytLink)) return url.slice(ytLink.length);
    return null;
  }

  @computed('isYoutube', 'externalFile.url')
  get youtubeVideoId() {
    if (this.isYoutube) {
      const url = this.externalFile.url;
      const match = /youtube.com\/v\/\w+/g.exec(url);
      const match2 = /youtube.com\/embed\/\w+/g.exec(url);
      if (match) {
        return match[0].replace('youtube.com/v/', '');
      } else if (match2) {
        return match2[0].replace('youtube.com/embed/', '');
      } else {
        const query = url
          .split('?')[1]
          ?.split('&')
          ?.reduce((qp, part) => {
            const [key, value] = part.split('=');
            qp[key] = value;
            return qp;
          }, {});
        return query?.v || null;
      }
    } else {
      return null;
    }
  }

  @computed('isYoutube', 'youtubeVideoId')
  get thumbnailUrl() {
    if (this.isYoutube) {
      return `https://img.youtube.com/vi/${this.youtubeVideoId}/hqdefault.jpg`;
    } else {
      return 'https://s3.amazonaws.com/summit-website-assets/images/product-video-placeholder.jpg';
    }
  }
}
