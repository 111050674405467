import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type AccountHistoryInvoiceModel from 'shoelace/models/account-history-invoice';
import type AccountHistoryInvoiceLineItemCommentModel from 'shoelace/models/account-history-invoice-line-item-comment';
import type AccountHistoryInvoiceLineItemDeliveryModel from 'shoelace/models/account-history-invoice-line-item-delivery';
import type AccountHistoryInvoiceShipAddressModel from 'shoelace/models/account-history-invoice-ship-address';
import type AccountHistoryOrderModel from 'shoelace/models/account-history-order';
import type AccountHistoryReturnModel from 'shoelace/models/account-history-return';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type ProductModel from 'shoelace/models/product';

export default class AccountHistoryInvoiceLineItemModel extends Model {
  @attr('string') customerPartNumber!: null | string;
  @attr('number') discount!: null | number;
  @attr('date') expectedAt!: null | Date;
  @attr('date') createdAt!: null | Date;
  @attr('boolean') isLotKit!: null | boolean;
  @attr('boolean') isSummitBuck!: null | boolean;
  @attr('string') jobAccountName!: null | string;
  @attr('string') manufacturerPartNumber!: null | string;
  @attr('string') name!: null | string;
  @attr('number') numberPerUnit!: null | number;
  @attr('number') position!: null | number;
  @attr('number') pricePerUnit!: null | number;
  @attr('number') price!: null | number;
  @attr('string') purchaseOrder!: null | string;
  @attr('number') quantity!: null | number;
  @attr('number') salesTax!: null | number;
  @attr('string') shippingType!: null | string;
  @attr('string') status!: null | string;
  @attr('number') totalLot!: null | number;
  @attr('number') total!: null | number;
  @attr('string') trackingNumber!: null | string;
  @attr('string') unitOfMeasure!: null | string;
  @attr('string') unknownTextFlag!: null | string;
  @attr('string') upc!: null | string;
  @attr('string') accountHistoryOrderId!: null | string;
  @attr('string') accountHistoryReturnId!: null | string;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-job-account')
  accountJobAccount!: AsyncBelongsTo<AccountJobAccountModel>;

  @belongsTo('account-history-invoice-ship-address')
  accountHistoryInvoiceShipAddress!: AsyncBelongsTo<AccountHistoryInvoiceShipAddressModel>;

  @belongsTo('account-history-invoice')
  accountHistoryInvoice!: AsyncBelongsTo<AccountHistoryInvoiceModel>;

  @belongsTo('account-history-order')
  accountHistoryOrder!: AsyncBelongsTo<AccountHistoryOrderModel>;

  @belongsTo('account-history-return')
  accountHistoryReturn!: AsyncBelongsTo<AccountHistoryReturnModel>;

  @belongsTo('product')
  product!: AsyncBelongsTo<ProductModel>;

  @hasMany('account-history-invoice-line-item-comment')
  accountHistoryInvoiceLineItemComments!: AsyncHasMany<AccountHistoryInvoiceLineItemCommentModel>;

  @hasMany('account-history-invoice-line-item-delivery')
  accountHistoryInvoiceLineItemDeliveries!: AsyncHasMany<AccountHistoryInvoiceLineItemDeliveryModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-invoice-line-item': AccountHistoryInvoiceLineItemModel;
  }
}
