import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AccountProjectModel from 'shoelace/models/account-project';
import type AccountProjectDrawingModel from 'shoelace/models/account-project-drawing';
import type AccountProjectOrderModel from 'shoelace/models/account-project-order';

export default class AccountProjectCircuitModel extends Model {
  @attr('string') name!: null | string;

  @belongsTo('account-project')
  accountProject!: AsyncBelongsTo<AccountProjectModel>;

  @hasMany('account-project-drawing')
  accountProjectDrawings!: AsyncHasMany<AccountProjectDrawingModel>;

  @hasMany('account-project-order')
  accountProjectOrders!: AsyncHasMany<AccountProjectOrderModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-project-circuit': AccountProjectCircuitModel;
  }
}
