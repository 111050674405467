import { A } from '@ember/array';
import { Ability } from 'ember-can';
import { computed } from '@ember/object';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default Ability.extend({
  session: service(),
  acl: computed('session.model.customer.acl', function () {
    const acl = get(this, 'session.model.customer.acl');

    if (isEmpty(acl)) {
      return A();
    } else {
      return acl;
    }
  }),

  canManageEverything: computed('acl', function () {
    const acl = this.acl;

    return acl.includes('all::manage');
  }),
});
