import Model from '@ember-data/model';
import { attr } from '@ember-data/model';
import {
  validator,
  buildValidations,
} from '@summit-electric-supply/ember-cp-validations';

const Validations = buildValidations({
  email: {
    description: 'Email Address',
    validators: [
      validator('format', {
        type: 'email',
        allowNonTld: false,
      }),
    ],
  },
});

export default Model.extend(Validations, {
  email: attr('string'),
});
