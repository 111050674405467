import Helper from '@ember/component/helper';

export default Helper.extend({
  compute([categoryFromSearch, categoryFromDatabase]) {
    const models = categoryFromDatabase.toArray().reduce((models, category) => {
      models[category.get('name')] = category;
      return models;
    }, {});
    const parts = categoryFromSearch.name.split('/');
    const thisOne = parts[parts.length - 1];
    return thisOne in models ? models[thisOne] : null;
  },
});
