import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class BsListPickerComponent extends Component {
  @service session;
  @service cart;
  @service store;
  @tracked tagName = '';

  @computed('session.customer', 'store')
  get model() {
    const customer = this.session.customer;

    const lists = this.store.query('cart', {
      filter: {
        'customer-id': customer.get('id'),
        kind: 'list',
      },
    });

    return {
      customer,
      lists,
    };
  }

  @computed('session.isAuthenticated', 'store')
  get customer() {
    const id = this.session?.get('customer.id');

    if (isPresent(id)) {
      return this.store.find('customer', id);
    } else {
      return null;
    }
  }

  @action async selectList(listId, product) {
    const list = await this.store.peekRecord('cart', listId);
    const cartLineItem = await this.store;

    const lineItems = await list.get('cartLineItems');

    await Promise.all(lineItems.map(li => li.get('product')));

    const quantity = await product.minQty;

    const lineItem = lineItems.find(item => {
      return item.get('product.id') === product.id;
    });

    if (lineItem) {
      const existingQuantity = parseInt(lineItem.get('quantity'), 10);
      lineItem.set('quantity', existingQuantity + parseInt(quantity));
      return lineItem.save();
    } else {
      return cartLineItem
        .createRecord('cart-line-item', {
          product: product,
          quantity: product.minQty,
          cart: list,
        })
        .save();
    }
  }

  @action async lineItemFor(product) {
    const lineItems = await this.lineItems;

    await Promise.all(lineItems.map(li => li.get('product')));

    const lineItem = lineItems.find(item => {
      return item.get('product.id') === product.id;
    });

    return lineItem;
  }
}
