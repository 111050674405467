import Route from '@ember/routing/route';
import { instrumentRoutePerformance } from '@sentry/ember';
import { inject as service } from '@ember/service';

class ForgotPasswordRoute extends Route {
  @service store;

  model() {
    return this.store.createRecord('customer-forgot-password');
  }
}
export default instrumentRoutePerformance(ForgotPasswordRoute);
