import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class StoreServicesMyProjectsOrderIndexController extends Controller {
  queryParams = ['search', 'sort', 'toggled'];

  @tracked sort = '-available-at';
  @tracked search = [];
  @tracked toggled = null;
}
