import ApplicationSerializer, { exclude } from './application';

export default class DynamicTranslationSerializer extends ApplicationSerializer {
  attrs = {
    generated_key: exclude,
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'dynamic-translation': DynamicTranslationSerializer;
  }
}
