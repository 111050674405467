import {
  validator,
  buildValidations,
} from '@summit-electric-supply/ember-cp-validations';
import Model, { attr } from '@ember-data/model';

const Validations = buildValidations({
  password: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      message: 'Password must not be blank.',
    }),
  ],
  passwordConfirmation: [
    validator('ds-error'),
    validator('presence', true),
    validator('confirmation', {
      on: 'password',
      message: 'Passwords do not match.',
    }),
  ],
});

export default class CustomerResetPasswordModel extends Model.extend(
  Validations,
) {
  @attr('string') password;
  @attr('string') passwordConfirmation;
  @attr('string') resetPasswordToken;
}
