import Ember from 'ember';
import { get, set } from '@ember/object';
import { inject } from '@ember/service';

const // need to update this based on prod db permissions table
  PERMISSION_MANAGE = '12',
  PERMISSION_ORDERING = '4',
  PERMISSION_FINANCIALS = '5';

export default Ember.Mixin.create({
  store: inject(),

  init() {
    this.store.findAll('permission').then(permissions => {
      const desired = {
        [PERMISSION_FINANCIALS]: 2,
        [PERMISSION_ORDERING]: 1,
        [PERMISSION_MANAGE]: 3,
      };
      set(
        this,
        'permissions',
        permissions
          .toArray()
          .filter(p => Object.keys(desired).indexOf(p.get('id')) != -1)
          .sort((a, b) => {
            return (desired[a.id] || a.id) - (desired[b.id] || b.id);
          }),
      );
    });
    this._super(...arguments);
  },
});
