import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import TokenAuthorizerMixin from 'ember-simple-auth-token/mixins/token-authorizer';
import CacheAdapter from './cache';

export default class ApplicationAdapter extends CacheAdapter.extend(
  TokenAuthorizerMixin,
) {
  @service cache;

  coalesceFindRequests = true;
  namespace = 'api';
  useFetch = true;

  get headers() {
    const headers = {};
    const token = get(this.session.data.authenticated, this.tokenPropertyName);

    if (this.session.isAuthenticated && !isEmpty(token)) {
      headers[this.authorizationHeaderName] = this.authorizationPrefix + token;
    }

    return headers;
  }
}
