/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./app/**/*.hbs'],
  important: true,
  theme: {
    screens: {
      'sm': '768px',
      'md': '1023px',
      'lg': '1200px',
      'xl': '1536px',
      '2xl': '1536px',
    },
    height: {
      0: '0px;',
      px: '1px;',
      0.5: '0.125rem',
      1: '0.25rem',
      1.5: '0.375rem',
      2: '0.5rem',
      2.5: '0.625rem',
      3: '0.75rem',
      3.5: '0.875rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
      11: '2.75rem',
      12: '3rem',
      14: '3.5rem',
      16: '4rem',
      20: '5rem',
      24: '6rem',
      28: '7rem',
      32: '8rem',
      36: '9rem',
      40: '10rem',
      44: '11rem',
      48: '12rem',
      52: '13rem',
      56: '14rem',
      60: '15rem',
      64: '16rem',
      72: '18rem',
      80: '20rem',
      96: '24rem',
      112: '28rem',
      128: '32rem',
      '1/2': '50%;',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      full: '100%',
      screen: '100vh',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
      auto: 'auto;',
    },
    extend: {
      screens: {
        print: { raw: 'print' },
      },
      spacing: {
        1.5: '0.375rem',
      },
      width: {
        inherit: 'inherit',
      },
      backgroundImage: {
        check: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")`,
        exclamation: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")`,
      },
      backgroundPosition: {
        'right-2': 'right 0.5rem center',
        'right-4': 'right 1rem center',
      },
      backgroundSize: {
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        7: '1.75rem',
        8: '2rem',
      },
      cursor: {
        auto: 'auto',
        default: 'default',
        pointer: 'pointer',
        wait: 'wait',
        text: 'text',
      },
      colors: {
        black: '#000',
        white: '#fff',
        indigo: {
          200: '#4d7781',
          300: '#33636f',
          400: '#1a505d',
          500: '#003c4b',
          600: '#003644',
          700: '#00303c',
          800: '#002a35',
          900: '#00364b',
        },
        blue: {
          200: '#60a7ca',
          300: '#499ac2',
          400: '#338ebb',
          500: '#1c81b3',
          600: '#1974a1',
          700: '#16678f',
          800: '#145a7d',
          900: '#0080bb'
        },
        orange: {
          200: '#f09876',
          300: '#ee8962',
          400: '#ec7b4f',
          500: '#ea6c3b',
          600: '#DF6A2E',
          700: '#bb562f',
          800: '#a44c29',
        },
        green: {
          100: '#c6f6d5',
          200: '#82cd7f',
          300: '#70c56d',
          400: '#5ebe5a',
          500: '#6ABF4B',
          600: '#44a541',
          700: '#3d923a',
          800: '#358032',
        },
        yellow: {
          50: '#fffff0',
          100: '#fefcbf',
          200: '#ffe94d',
          300: '#ffe533',
          400: '#ffe21a',
          500: '#ffdf00',
          600: '#F4B223',
          700: '#ccb200',
          800: '#b39c00',
        },
        red: {
          100: '#fed7d7',
          200: '#f2606b',
          300: '#f14955',
          400: '#ef3340',
          500: '#ed1c2b',
          600: '#d51927',
          700: '#be1622',
          800: '#a6141e',
        },
        'dark-gray': {
          200: '#7a797b',
          300: '#676668',
          400: '#b0b2b4',
          500: '#414042',
          600: '#3b3a3b',
          700: '#343335',
          800: '#2e2d2e',
        },
        gray: {
          50: '#f7fafc',
          100: '#f5f5f5',
          200: '#c1c3c5',
          300: '#b9babd',
          400: '#b9babd',
          500: '#a7a9ac',
          600: '#96989b',
          700: '#86878a',
          800: '#757678',
        },
        'light-gray': {
          100: '#F7FAFC',
          200: '#eeeeef',
          300: '#ebeced',
          400: '#e9e9ea',
          500: '#e6e7e8',
          600: '#cfd0d1',
          700: '#b8b9ba',
          800: '#a1a2a2',
        },
      },
      fontSize: {
        xs: '0.66667rem',
        sm: '0.75rem',
        md: '0.875rem',
        'm': '1rem',
        'l': '1.125rem',
        'xl': '1.375rem',
        '2xl': '1.5rem',
        '4xl': '2.375rem',
      },
      fontFamily: {
        sans: [
          'Fira Sans',
          'sans-serif',
          'ui-sans-serif',
          'system-ui',
          '-apple-system',
          'BlinkMacSystemFont',
          'Segoe UI',
          'Roboto',
          'Helvetica Neue',
          'Arial',
          'Noto Sans',
          'Apple Color Emoji',
          'Segoe UI Emoji',
          'Segoe UI Symbol',
          'Noto Color Emoji',
        ],
        serif: [
          'ui-serif',
          'Georgia',
          'Cambria',
          '"Times New Roman"',
          'Times',
          'serif',
        ],
        mono: [
          'ui-monospace',
          'SFMono-Regular',
          'Menlo',
          'Monaco',
          'Consolas',
          '"Liberation Mono"',
          '"Courier New"',
          'monospace',
        ],
      },
    },
    variants: {
      backgroundColor: ['responsive', 'hover', 'focus'],
      display: ['responsive', 'hover', 'focus'],
      maxHeight: ['focus'],
      textColor: ['responsive', 'hover', 'focus', 'group-focus'],
      borderColor: ['responsive', 'hover', 'focus', 'focus-within'],
      width: ['responsive', 'hover', 'focus'],
      height: ['responsive', 'hover', 'focus'],
    },
    plugins: [],
  },
};
