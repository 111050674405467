import Helper from '@ember/component/helper';
import numeral from 'numeral';

export default Helper.extend({
  compute(params) {
    let decimal = params[0];

    return numeral(decimal * -1.0).format('$0,0.00[00]');
  },
});
