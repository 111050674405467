import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

const refreshModel = { refreshModel: true };

class StoreCustomersConfirmationIndexRoute extends Route {
  @service declare router: RouterService;
  @service declare store: StoreService;

  queryParams = {
    token: refreshModel,
  };

  model() {
    const signIn = this.store.createRecord('sign-in');
    return signIn;
  }
}
export default instrumentRoutePerformance(StoreCustomersConfirmationIndexRoute);
