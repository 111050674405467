import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type SessionService from 'shoelace/services/session';
import type Transition from '@ember/routing/-private/transition';
import type CustomerModel from 'shoelace/models/customer';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface RouteModel {
  customer: CustomerModel;
}
export interface RouteParams {
  customer_id: string | number;
}

class CustomerRoute extends Route {
  @service declare session: SessionService;
  @service declare store: StoreService;

  beforeModel(transition: Transition): void {
    this.session.requireAuthentication(transition, 'login');
  }
  async model({ customer_id: id }: RouteParams): Promise<CustomerModel> {
    return await this.store.findRecord('customer', id);
  }
}
export default instrumentRoutePerformance(CustomerRoute);
