import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { belongsTo } from '@ember-data/model';
import type ClassificationModel from 'shoelace/models/classification';
import type CustomerModel from 'shoelace/models/customer';

export default class ClassificationPermission extends Model {
  @belongsTo('customer')
  customer!: AsyncBelongsTo<CustomerModel>;

  @belongsTo('classification')
  classification!: AsyncBelongsTo<ClassificationModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'classification-permission': ClassificationPermission;
  }
}
