import type ObjectProxy from '@ember/object/proxy';
import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type ServiceCenter from 'shoelace/models/service-center';
import type ServiceCenterType from 'shoelace/models/service-center-type';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface AdminServiceCenterEditServiceCenterRouteModel {
  serviceCenter: ObjectProxy<ServiceCenter>;
  serviceCenterTypes: ArrayProxy<ServiceCenterType>;
}

class AdminServiceCenterEditServiceCenterRoute extends Route {
  @service declare store: StoreService;
 
    async model(): Promise<AdminServiceCenterEditServiceCenterRouteModel> {
    const serviceCenter = (await this.modelFor(
      'admin.service-center',
    )) as ObjectProxy<ServiceCenter>;

    const serviceCenterTypes = this.store.findAll('service-center-type');

    return {
      serviceCenter,
      serviceCenterTypes,
    };
  }
}

export default instrumentRoutePerformance(
  AdminServiceCenterEditServiceCenterRoute
);