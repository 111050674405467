import type { AsyncBelongsTo } from '@ember-data/model';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Customer from 'shoelace/models/customer';
import type SessionService from 'shoelace/services/session';

export default class AdminManageCustomerIndexController extends Controller {
  @service declare session: SessionService;

  @action async onDeleteCustomer(
    customer: any
  ): Promise<void> {
    try {
      await customer.destroyRecord();
    } catch (error) {
      throw error;
    }
  }
  @action async onDisableCustomer(
    customer: AsyncBelongsTo<Customer>,
  ): Promise<void> {
    try {
      customer.set('isDisabled', true);
      (await customer).save();
    } catch (error) {
      throw error;
    }
  }
  @action async onEnableCustomer(
    customer: AsyncBelongsTo<Customer>,
  ): Promise<void> {
    try {
      customer.set('isDisabled', false);
      (await customer).save();
    } catch (error) {
      throw error;
    }
  }

  @action async onSwitchCustomer(
    customer: any
  ): Promise<void> {
    try {
      const id = parseInt(customer.get('id'));
      this.session.switchCustomer(id);
    } catch (error) {
      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/manage-customer/index': AdminManageCustomerIndexController;
  }
}
