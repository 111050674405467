import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#in-element this.inElement insertBefore=null}}\n  <script type=\"application/ld+json\">\n    {{this.outputRichData}}\n  </script>\n{{/in-element}}", {"contents":"{{#in-element this.inElement insertBefore=null}}\n  <script type=\"application/ld+json\">\n    {{this.outputRichData}}\n  </script>\n{{/in-element}}","moduleName":"shoelace/components/manufacture/rich-data.hbs","parseOptions":{"srcName":"shoelace/components/manufacture/rich-data.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ManufacturerModel from 'shoelace/models/manufacturer';

interface ManufactureRichDataComponentArgs {
  manufacturer?: ManufacturerModel;
}

export default class ManufactureRichDataComponent extends Component<ManufactureRichDataComponentArgs> {
  @tracked manufacturer?: ManufacturerModel = this.args.manufacturer;

  get outputRichData() {
    if (this.manufacturer?.get('name')) {
      return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: 'https://www.summit.com/store/manufacturers/' + this.manufacturer.id,
        name: this.manufacturer.name,
        logo: this.manufacturer.logoUrl,
      });
    }
  }

  get inElement() {
    return document.head;
  }
}
