import type { AsyncBelongsTo } from '@ember-data/model';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  EXCEPTION_1029,
  EXCEPTION_1047,
  EXCEPTION_1072,
  EXCEPTION_1073,
} from 'shoelace/exceptions/mod';
import type Customer from 'shoelace/models/customer';
//import type RollbarService from 'shoelace/services/rollbar';
import type SessionService from 'shoelace/services/session';

export default class AdminCustomerIndexController extends Controller {
  @service declare session: SessionService;
  //@service declare rollbar: RollbarService;

  @action async onDeleteCustomer(
    customer: AsyncBelongsTo<Customer>,
  ): Promise<void> {
    try {
      await customer.destroyRecord();
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1029, error);
      throw error;
    }
  }
  @action async onDisableCustomer(
    customer: AsyncBelongsTo<Customer>,
  ): Promise<void> {
    try {
      customer.set('isDisabled', true);
      (await customer).save();
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1072, error);
      throw error;
    }
  }
  @action async onEnableCustomer(
    customer: AsyncBelongsTo<Customer>,
  ): Promise<void> {
    try {
      customer.set('isDisabled', false);
      (await customer).save();
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1073, error);
      throw error;
    }
  }

  @action async onSwitchCustomer(
    customer: AsyncBelongsTo<Customer>,
  ): Promise<void> {
    try {
      const id = parseInt(customer.get('id'));
      this.session.switchCustomer(id);
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1047, error);
      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/customer/index': AdminCustomerIndexController;
  }
}
