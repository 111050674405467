import type StoreService from '@ember-data/store';
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type AccountModel from 'shoelace/models/account';
import AccountAddressModel from 'shoelace/models/account-address';
import type CustomerModel from 'shoelace/models/customer';
import type AccountJobAccountService from 'shoelace/services/account-job-account';
import type CatalogService from 'shoelace/services/catalog';
import type { BootOptions } from 'shoelace/services/coordinator';
import type KartService from 'shoelace/services/kart';
import type ProcurementService from 'shoelace/services/procurement';
import type SessionService from 'shoelace/services/session';

export default class AccountService extends Service {
  @service declare accountJobAccount: AccountJobAccountService;
  @service declare catalog: CatalogService;
  @service declare kart: KartService;
  @service declare procurement: ProcurementService;
  @service declare session: SessionService;
  @service declare store: StoreService;

  @tracked account?: AccountModel;
  @tracked validAccountAddresses?: AccountAddressModel[];

  async onCoordinatorSetup(options?: BootOptions): Promise<void> {
    if (options?.account) {
      this.account = options.account;
    } else {
      const customer = options?.customer ?? this.session.customer;

      if (customer) {
        this.account = (await customer.get('account')) ?? undefined;
      }
    }
  }

  async onCoordinatorTeardown(): Promise<void> {
    this.account = undefined;
    this.validAccountAddresses = undefined;
  }

  @action async setAccount(
    account: AccountModel | number | string,
    customer?: CustomerModel | number | string
  ): Promise<void> {
    let someCustomer: CustomerModel | undefined;

    if (typeof customer === 'number' || typeof customer === 'string') {
      if (this.store.hasRecordForId('customer', customer)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        someCustomer = this.store.peekRecord('customer', customer)!;
      } else {
        someCustomer = await this.store.findRecord('customer', customer);
      }
    } else if (customer) {
      someCustomer = customer;
    } else {
      someCustomer = this.session.customer;
    }

    if (someCustomer) {
      let someAccount: AccountModel;

      if (typeof account === 'number' || typeof account === 'string') {
        if (this.store.hasRecordForId('account', account)) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          someAccount = this.store.peekRecord('account', account)!;
        } else {
          someAccount = await this.store.findRecord('account', account);
        }
      } else {
        someAccount = account;
      }

      this.account = someAccount;

      await someCustomer.set('account', this.account);

      if (this.procurement.procurement) {
        this.procurement.procurement.set('account', this.account);
        this.procurement.procurement.set('isCustomerCashTerm', null);
        this.procurement.procurement.set('accountAddress', null);
      }

      await this.accountJobAccount.setAccountJobAccount(
        undefined,
        someCustomer
      );

      this.catalog.catalog = undefined;
      if (someCustomer.hasRbacAssociate || someCustomer.hasRbacAdmin) {
        const rbac_account_role_details = await this.store.query(
          'rbac-account-customer-role',
          {
            filter: {
              account_id: account.id,
              customer_id: someCustomer.id,
            },
          }
        );

        if (rbac_account_role_details.length == 0) {
          await this.store
            .createRecord('rbac-account-customer-role', {
              account_id: account.id,
              customer_id: someCustomer.id,
            })
            .save();
        }
      }
      // await someCustomer.get('acls')?.reload();
      // await someCustomer.get('aclPermissions')?.reload();
      // await someCustomer.get('aclAccountPermissions')?.reload();

      await this.kart.setAccount(this.account);
      await this.catalog.loadCatalogs();
      await this.loadValidAccountAddresses();
    }
  }

  @action async loadValidAccountAddresses(): Promise<void> {
    const accountJobAccount = this.accountJobAccount.accountJobAccount;
    const accountJobAccounts = await this.account?.get('accountJobAccounts');
    const accountAddresses = await this.store.query('account-address', {
      filter: {
        account: this.account?.id,
      },
      include: 'account-job-account',
    });

    if (accountAddresses && accountJobAccount) {
      this.validAccountAddresses = accountAddresses.filter((address) => {
        return address.accountJobAccount?.get('id') == accountJobAccount.id;
      });
    } else {
      const JobAccountIdsToRemove = accountJobAccounts?.map(
        (jobAccount) => jobAccount.id
      );
      const filteredAddresses = accountAddresses?.filter((address) => {
        return !JobAccountIdsToRemove?.includes(
          address.accountJobAccount.get('id')!
        );
      });

      this.validAccountAddresses = filteredAddresses;
    }

    if (
      this.procurement.procurement &&
      !this.procurement.procurement?.accountAddress.get('id')
    ) {
      const accountAddress =
        this.validAccountAddresses?.firstObject ??
        this.store.createRecord('account-address');

      this.procurement.procurement.set('accountAddress', accountAddress);
      this.procurement.procurement.set('activeAccountAddress', accountAddress);
    }

    let serviceCenter = await this.account?.get('serviceCenter');
    let customer = this.session.customer;

    await customer?.set('serviceCenter', serviceCenter);
  }
}

declare module '@ember/service' {
  interface Registry {
    account: AccountService;
  }
}
