import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';

export default class AccountHistoryAllOrderModel extends Model {
  @attr('string') category!: null | string;
  @attr('date') createdAt!: null | Date;
  @attr('date') expectedAt!: null | Date;
  @attr('string') jobAccountName!: null | string;
  @attr('string') kind!: null | string;
  @attr('number') numberOfItems!: null | number;
  @attr('string') overallStatus!: null | string;
  @attr('string') purchaseOrder!: null | string;
  @attr('number') salesTax!: null | number;
  @attr('string') shippingType!: null | string;
  @attr('string') status!: null | string;
  @attr('number') subtotal!: null | number;
  @attr('number') total!: null | number;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-job-account')
  accountJobAccount!: AsyncBelongsTo<AccountJobAccountModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-all-order': AccountHistoryAllOrderModel;
  }
}
