import Component from '@ember/component';
import { get } from '@ember/object';
import { idv4 } from '../utils/bs-uuid';

export default Component.extend({
  tagName: 'form',
  attributeBindings: ['action', 'method'],

  actions: {
    generateRemittanceAndSubmit(model) {
      model.remittanceId = idv4(30);

      return model.save().then(() => this.element.submit());
    },
  },
});
