import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{!--\n<div class=\"block relative print:hidden lg:hidden z-10\" {{did-insert this.onScroll}}>\n  {{! mobile }}\n  <Apex::Header::MobileScreen\n    @isStaticLoad={{this.isStaticLoad}}\n    @isScrollingUp={{this.isScrollingUp}}\n    @isScrollingDown={{this.isScrollingDown}}\n    @rootCategoryID='Product-Categories'\n\n  />\n</div>\n<div class=\"hidden lg:block z-10 relative\">\n  {{! desktop }}\n  <Apex::Header::IpadScreenHorizontal @rootCategoryID='Product-Categories' />\n</div> --}}\n\n<Apex::Kart as |kart|>\n  <Drupal::Header @itemsInCart={{kart.items.length}} />\n</Apex::Kart>\n", {"contents":"{{!--\n<div class=\"block relative print:hidden lg:hidden z-10\" {{did-insert this.onScroll}}>\n  {{! mobile }}\n  <Apex::Header::MobileScreen\n    @isStaticLoad={{this.isStaticLoad}}\n    @isScrollingUp={{this.isScrollingUp}}\n    @isScrollingDown={{this.isScrollingDown}}\n    @rootCategoryID='Product-Categories'\n\n  />\n</div>\n<div class=\"hidden lg:block z-10 relative\">\n  {{! desktop }}\n  <Apex::Header::IpadScreenHorizontal @rootCategoryID='Product-Categories' />\n</div> --}}\n\n<Apex::Kart as |kart|>\n  <Drupal::Header @itemsInCart={{kart.items.length}} />\n</Apex::Kart>\n","moduleName":"shoelace/components/apex/header.hbs","parseOptions":{"srcName":"shoelace/components/apex/header.hbs"}});
import { action, computed } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { storageFor } from 'ember-local-storage';

export default class ApexHeader extends Component {
  @storageFor('customer') storage: any;

  @tracked isScrollingUp = false;
  @tracked isScrollingDown = false;
  @tracked isStaticLoad = true;
  @tracked scrollPosition = 0;

  @computed('storageId')
  get localStorageCustomerId(): string {
    return this.storage?.get('id') || "";
  }

  @computed('storageName')
  get localStorageCustomeName(): string {
    return this.storage?.get('name') || "";
  }

  @action onScroll() {
    window.addEventListener('scroll', checkScrollDirection.bind(this));
  }
}

function checkScrollDirection(this: any) {
  const newWindowPosition = window.scrollY;

  if (this.scrollPosition <= 28) {
    this.isStaticLoad = true;
  } else {
    this.isStaticLoad = false;
  }

  if (!(this.scrollPosition === newWindowPosition)) {
    if (this.scrollPosition < newWindowPosition) {
      this.isScrollingUp = false;
      this.isScrollingDown = true;
    } else {
      this.isScrollingUp = true;
      this.isScrollingDown = false;
    }
    this.scrollPosition = window.scrollY;
  }
}
