import Controller from '@ember/controller';
import { action } from '@ember/object';
import ObjectProxy from '@ember/object/proxy';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import {
  EXCEPTION_1043,
  EXCEPTION_1044,
  EXCEPTION_1045,
} from 'shoelace/exceptions/mod';
import type ServiceCenter from 'shoelace/models/service-center';
import type ServiceCenterDateHour from 'shoelace/models/service-center-date-hour';
//import type RollbarService from 'shoelace/services/rollbar';

export default class AdminServiceCenterEditServiceCenterDaysController extends Controller {
//@service declare rollbar: RollbarService;

@service declare router: RouterService;

  @action async onEditServiceCenterDaysHours(
    serviceCenter: ServiceCenter & ObjectProxy<ServiceCenter>,
    serviceCenterDateHours: ServiceCenterDateHour &
      ObjectProxy<ServiceCenterDateHour>,
  ): Promise<void> {
    try {
      const serviceCenterId = await serviceCenter.get('id');

      serviceCenterDateHours.forEach(serviceCenterDateHour => {
        if (serviceCenterDateHour.hasDirtyAttributes) {
          const closeHours = serviceCenterDateHour.get('overrideDate');

          if (Array.isArray(closeHours)) {
            const updatedTime = closeHours[0];

            serviceCenterDateHour.set('overrideDate', updatedTime);
          }
          serviceCenterDateHour.save();
        }
      });
      this.router.transitionTo('admin.service-center', serviceCenterId);
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1043, error);
      throw error;
    }
  }

  @action async onRemoveDate(
    serviceCenterDateHour: ServiceCenterDateHour &
      ObjectProxy<ServiceCenterDateHour>,
  ): Promise<void> {
    try {
      await serviceCenterDateHour.destroyRecord();
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1044, error);
      throw error;
    }
  }

  @action async addDaysServiceCenter(
    serviceCenter: ServiceCenter & ObjectProxy<ServiceCenter>,
    serviceCenterDateHour: ServiceCenterDateHour &
      ObjectProxy<ServiceCenterDateHour>,
  ): Promise<void> {
    try {
      const serviceCenterId = await serviceCenter.get('id');

      await serviceCenterDateHour.save();

      this.router.transitionTo('admin.service-center', serviceCenterId);
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1045, error);
      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/service-center/edit-service-center-days': AdminServiceCenterEditServiceCenterDaysController;
  }
}
