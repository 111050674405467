import type CartLineItemModel from 'shoelace/models/cart-line-item';
import type ServiceCenterModel from 'shoelace/models/service-center';
import type AccountModel from 'shoelace/models/account';
import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';

export default class cartPriceModel extends Model {
  @attr('number') price!: null | number;
  @attr('number') unitPrice!: null | number;
  @attr('number') extendedPrice!: null | number;
  @attr('string') formattedPrice!: null | string;
  @attr('string') formattedUnitPrice!: null | string;
  @attr('string') formattedExtendedPrice!: null | string;
  @attr('number') pricingQuantity!: null | number;
  @attr('number') requestedQuantity!: null | number;
  @attr('string') unit!: null | string;
  @attr('string') matnr!: null | string;

  @belongsTo('account')
  declare account?: AsyncBelongsTo<AccountModel>;

  @belongsTo('cart-line-item')
  declare cartLineItem?: AsyncBelongsTo<CartLineItemModel>;

  @belongsTo('service-center')
  declare serviceCenter?: AsyncBelongsTo<ServiceCenterModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    cartPrice: cartPriceModel;
  }
}
