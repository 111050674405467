import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class StoreServicesMyProjectsReleaseOrderController extends Controller {
  queryParams = ['search', 'sort', 'toggled'];

  @service notice;
  @service router;
  @service store;

  @tracked sort = '-available-at';
  @tracked search = [];
  @tracked toggled = null;
  @tracked lineItemsToAdd = [];

  @action async push(accountProjectOrderLineItem) {
    if (accountProjectOrderLineItem.get('remainingQuantity') <= 0) {
      const { isConfirmed } = await this.notice.confirm(
        `The requested item has already been released.`,
        `Are you sure you would like to add this material anyway? Your release will be unable to be approved if the requested quantity exceeds what is available.`,
      );
      if (!isConfirmed) {
        return;
      }
    }
    this.lineItemsToAdd = [...this.lineItemsToAdd, accountProjectOrderLineItem];
  }

  @action pop(accountProjectOrderLineItem) {
    this.lineItemsToAdd = this.lineItemsToAdd.filter(
      lineItem => lineItem.get('id') !== accountProjectOrderLineItem.get('id'),
    );
  }

  @(task(function* (accountProjectRelease) {
    for (let i = 0; i < this.lineItemsToAdd.length; i++) {
      const accountProjectOrderLineItem = this.lineItemsToAdd[i];
      const quantity = accountProjectOrderLineItem.get('remainingQuantity');
      const item = this.store.createRecord('account-project-release-line-item', {
        quantity,
        accountProjectOrderLineItem,
        accountProjectRelease,
      });

      accountProjectRelease.accountProjectReleaseLineItems.pushObject(item);

      yield item.save();
    }

    yield accountProjectRelease.save();

    this.lineItemsToAdd = [];

    this.router.transitionTo(
      'store.services.my-projects.release',
      accountProjectRelease,
    );
  }).drop())
  save;
}
