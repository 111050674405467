import type ObjectProxy from '@ember/object/proxy';
import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type ServiceCenter from 'shoelace/models/service-center';
import type ServiceCenterType from 'shoelace/models/service-center-type';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';
import type RouterService from '@ember/routing/router';
import SessionService from 'shoelace/services/-session';


export interface AdminServiceCenterIndexRouteModel {
  serviceCenter: ObjectProxy<ServiceCenter>;
  serviceCenterTypes: ArrayProxy<ServiceCenterType>;
}

class AdminServiceCenterIndexRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare router: RouterService;

  async model(): Promise<AdminServiceCenterIndexRouteModel> {

    const customer = await this.session.get('customer');

    if (customer) {
      if (customer?.hasRbacAdminCredit || customer?.hasRbacCustomerAdmin) {
        this.router.transitionTo('store.customer', customer.id);
      } 
    }


    const serviceCenter = (await this.modelFor(
      'admin.service-center',
    )) as ObjectProxy<ServiceCenter>;

    const serviceCenterTypes = this.store.findAll('service-center-type');

    return {
      serviceCenter,
      serviceCenterTypes,
    };
  }
}

export default instrumentRoutePerformance(
  AdminServiceCenterIndexRoute
);