import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  this.share\n  this.internalCustomers\n  this.externalCustomers\n  this.showForm\n  this.isLoading\n}}", {"contents":"{{yield\n  this.share\n  this.internalCustomers\n  this.externalCustomers\n  this.showForm\n  this.isLoading\n}}","moduleName":"shoelace/components/share-list.hbs","parseOptions":{"srcName":"shoelace/components/share-list.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class ShareListComponent extends Component {
  @service cart;
  @service serviceCenter;
  @service store;
  @service session;

  @tracked list = this.args.list || null;
  @tracked toEmail = this.args.toEmail || null;
  @tracked account = this.args.account || null;
  @tracked customer = this.args.customer || null;

  @tracked showForm = true;
  @tracked customers;

  get isLoading() {
    return this._customers.isRunning;
  }

  @(task(function* () {
    const accounts = yield this.store.query('account', {
      filter: {
        id: this.account.get('id'),
      },
      include: 'customers',
      page: {
        number: 1,
        size: 100,
      },
    });

    this.customers = accounts?.firstObject?.get('customers').map(customer => {
      const name = customer.get('name');
      const email = customer.get('email');

      customer.set('display', `${name} <${email}>`);
      return customer;
    });
  }).drop())
  _customers;

  loadCustomers() {
    this._customers.perform();
    return undefined;
  }

  get internalCustomers() {
    if (this.customers) {
      return this.customers
        ?.filter(customer => !!customer.isInternal)
        .sort((a, b) => a.display.localeCompare(b.display));
    } else {
      return this.loadCustomers();
    }
  }

  get externalCustomers() {
    if (this.customers) {
      return this.customers
        ?.filter(customer => !customer.isInternal)
        .sort((a, b) => a.display.localeCompare(b.display));
    } else {
      return this.loadCustomers();
    }
  }

  @(task(function* (list = this.list, toEmail = this.toEmail) {
    const toCustomer = this.customers.findBy('email', toEmail);

    if (toCustomer) {
      const name = this.customer.name;
      const email = this.customer.email;
      const created_by = this.session.customer?.id;

      const title = `${list.title} [Shared By ${name} <${email}>]`;
      const serviceCenter = this.serviceCenter.serviceCenter;

      const newList = yield this.store
        .createRecord('cart', {
          customer: toCustomer,
          title,
          serviceCenter,
          created_by,
        })
        .save();

      const cartLineItems = list.get('cartLineItems').map(item => {
        const lineItem = this.store.createRecord('cart-line-item', {
          cart: newList,
          product: item.get('product'),
          quantity: item.get('quantity'),
          created_by,
        });
        return lineItem.save();
      });

      yield Promise.all(cartLineItems);

      const cartInfo = this.store.createRecord('cart-info');
      cartInfo.set('cart', list);
      cartInfo.set('email', this.customer.email);
      cartInfo.set('to_email', toCustomer.email);
      cartInfo.set('customer', this.customer);

      yield cartInfo.save();

      this.showForm = false;
    } else {
      alert(
        'Unable to share this list with users that are not assigned to your account.',
      );
    }
  }).drop())
  share;
}
