import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type CustomerModel from 'shoelace/models/customer';
import type ServiceCenterModel from 'shoelace/models/service-center';
import type CartLineItemModel from 'shoelace/models/cart-line-item';

export default class CartModel extends Model {
  @attr('string') title!: null | string;
  @attr('string') created_by!: null | string;
  @attr('string') updatedAt!: null | Date;
  @attr('string') active!: null | boolean;

  @hasMany('cart-line-item')
  'cart-line-items'!: AsyncHasMany<CartLineItemModel>;

  @belongsTo('customer')
  customer!: AsyncBelongsTo<CustomerModel>;

  @belongsTo('service-center')
  'service-center'!: AsyncBelongsTo<ServiceCenterModel>;
}
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    cart: CartModel;
  }
}
