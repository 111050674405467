import ActiveStorage from '@algonauti/ember-active-storage/services/active-storage';
import { inject as service } from '@ember/service';
import SessionService from 'shoelace/services/session';
import ENV from 'shoelace/config/environment';

const { 'ember-simple-auth-token': emberSimpleAuthToken } = ENV;

export default class ActiveStorageService extends ActiveStorage {
  @service session

  get headers() {
    const headers = {
      'x-requested-with': 'XMLHttpRequest',
    };

    if (this.session.isAuthenticated) {
      headers[emberSimpleAuthToken?.authorizationHeaderName ?? 'Authorization'] =
        (emberSimpleAuthToken?.authorizationPrefix ?? 'Bearer ') +
        this.session.token;
    }

    return headers;
  }
}

