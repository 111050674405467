import Model from '@ember-data/model';
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { belongsTo } from 'ember-data/relationships';

export default Model.extend({
  vbeln: attr('string'),
  guebg: attr('string'),
  gueen: attr('string'),
  ktext: attr('string'),
  isActive: attr('boolean'),

  account: belongsTo('account', { async: true, inverse: null }),

  optGroupLabel: computed('isActive', function () {
    return this.isActive === true ? 'Open' : 'Closed';
  }),

  label: computed('vbeln', 'ktext', function () {
    const ktext = this.ktext ?? '';
    const vbeln = this.vbeln ?? '';

    return ktext + ' (' + vbeln + ')';
  }),

  isClosed: computed('isActive', function () {
    if (this.isActive) {
      return false;
    } else {
      return true;
    }
  }),
});
