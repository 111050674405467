import Route from '@ember/routing/route';
import {
  dontRefreshModel,
  refreshModel,
} from '../../../../../utils/query-params';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

 class StoreServicesMyProjectsReleaseOrderRoute extends Route {
  @service store;
  
  queryParams = {
    search: refreshModel,
    sort: refreshModel,
    toggled: dontRefreshModel,
  };

  async model({
    'account-project-order_id': accountProjectOrderId,
    search,
    sort,
  }) {
    const accountProjectRelease = this.modelFor(
      'store.services.my-projects.release',
    );

    const accountProjectOrder = await this.store.findRecord(
      'account-project-order',
      accountProjectOrderId,
      {
        include: ['account-order'].join(','),
      },
    );

    const accountPO = await this.store.findRecord(
      'account-order',
      accountProjectOrder.id
    );

    const accountProjectOrderLineItems = await this.store.query(
      'account-project-order-line-item',
      {
        filter: {
          'account-project-order': accountProjectOrderId,
          search,
        },
        include: [
          'account-order-line-item',
          'account-order-line-item.product',
          'account-project-order',
          'account-project-order-line-item-deliveries',
          'account-project-order-line-item-deliveries.account-project-order-line-item',
          'account-project-order-line-item-deliveries.account-project-order-line-item-delivery-attributes',
          'account-project-order-line-item-deliveries.account-project-order-line-item-delivery-attributes.account-project-order-line-item-delivery-attribute-definition',
          'account-project-order-line-item-attributes',
          'account-project-order-line-item-attributes.account-project-order-line-item-attribute-definition',
        ].join(','),
        sort,
      },
    );

    return {
      accountProjectOrder,
      accountProjectOrderLineItems,
      accountProjectRelease,
      accountPO,
    };
  }
}
export default instrumentRoutePerformance(
  StoreServicesMyProjectsReleaseOrderRoute
);