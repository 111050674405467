import JSONAPISerializer from '@ember-data/serializer/json-api';
import { dasherize } from '@ember/string';

/**
 * A whitelist for pagination keys found in the JSONAPI meta links payload.
 */
const paginationMetaKeys = ['first', 'last', 'next', 'prev'];

/**
 * A whitelist for search parameters found in parsed pagination URLs.
 */
const paginationKeys = [
  'page[limit]',
  'page[number]',
  'page[offset]',
  'page[size]',
];

/**
 * RegExp pattern to match pagination keys.
 */
const paginationName = /^page\[(\w+)\]$/;

/**
 * A URL object intended to be used by other URL constructors.
 *
 * The URL constructor will throw if you attempt to parse non-FQDN strings,
 * e.g. '/path/to/resource'. However, if you pass a base URL as an argument along with a string,
 * the URL constructor will never throw. In cases where you pass a FQDN string to the URL
 * constructor, this base URL object is ignored.
 */
const baseURL = new URL('https://summit.com');

/**
 * Merge an object together, similar to Ruby merge.
 *
 * @param {Object} into the object to merge into
 * @param {Object} from the object to merge from
 */
function merge(into, from) {
  return Object.assign(into, from);
}

/**
 * Shortcut to exclude an attr from serialization.
 */
export const exclude = { serialize: false };

/**
 * Shortcut to include an attr in serialization.
 */
export const include = { serialize: true };

export default class ApplicationSerializer extends JSONAPISerializer {
  keyForAttribute(attr) {
     return dasherize(attr)
  }

  normalizeQueryResponse(store, primaryModelClass, payload) {
    const result = super.normalizeQueryResponse(...arguments);
    result.meta = result.meta || {};

    if (payload.links) {
      result.meta.pagination = this.createPageMeta(payload.links);
    }

    return result;
  }

  createPageMeta(data) {
    return Object.keys(data)
      .filter(key =>
        paginationMetaKeys.some(paginationMetaKey => key === paginationMetaKey),
      )
      .map(key => {
        const { [key]: value } = data;
        const url = new URL(decodeURIComponent(value), baseURL);
        const searchParams = Array.from(url.searchParams.entries());
        const values = searchParams
          .filter(([key, _]) =>
            paginationKeys.some(paginationKey => key === paginationKey),
          )
          .map(([key, value]) => {
            const [_, name] = key.match(paginationName);

            return { [name]: parseInt(value) };
          })
          .reduce(merge, {});

        return { [key]: values };
      })
      .reduce(merge, {});
  }
}
