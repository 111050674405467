import Route from '@ember/routing/route';
import { refreshModel } from 'shoelace/utils/query-params';
import type AccountProject from 'shoelace/models/account-project';
import type ArrayProxy from '@ember/array/proxy';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';
import AccountVerificationModel from 'shoelace/models/account-verification';
import PermissionModel from 'shoelace/models/permission';
import RoleModel from 'shoelace/models/role';
import ServiceCenterModel from 'shoelace/models/service-center';
import CustomerNoteModel from 'shoelace/models/customer-note';
import type SessionService from 'shoelace/services/session';

export interface RouteParams {
  page?: string;
  size?: string;
  sort?: string;
  search?: string[];
}
export interface RouteModel {
  accountVerification: AccountVerificationModel;
  permissions: RoleModel[];
  roles: ArrayProxy<RoleModel>;
  serviceCenters: ArrayProxy<ServiceCenterModel>;
  customerNotes: ArrayProxy<CustomerNoteModel>;
}

class AdminAccountVerificationIndexRoute extends Route {
  @service declare session: SessionService;
  @service declare store: StoreService;

  queryParams = {
    page: refreshModel,
    size: refreshModel,
    sort: refreshModel,
  };

  async model({ page: number, size, sort }: RouteParams): Promise<RouteModel> {
    const accountVerification = (await this.modelFor(
      'admin.account-verification'
    )) as AccountVerificationModel;

    const allPermissions = await this.store.findAll('rbac-permission'); 
    const allRoles = await this.store.findAll('rbac-role'); 

    const permissions = allPermissions.filter((permission) =>
      permission.get('name').includes('customer.')
    );

    const roles = allRoles.filter((rbacRole) =>
    rbacRole.get('name').includes('customer')
  );

    permissions.forEach(async (permission) => {
      if (permission.name == "customer.default") {
        permissions.removeObject(permission);
      }
    });
    const customer = await accountVerification.get('customer');

    const serviceCenters = await this.store.query('service-center', {
      filter: {
        service_center_type: '1',
      },
      sort: 'long-name',
    });

    

    const customerNotes: ArrayProxy<CustomerNoteModel> = await this.store.query(
      'customer-note',
      {
        filter: {
          customer: customer.get('id'),
        },
      }
    );

    accountVerification.set(
      'approvedAccountNumber',
      accountVerification.get('accountNumber')
    );

    return {
      accountVerification,
      permissions,
      roles,
      serviceCenters,
      customerNotes,
    };
  }
}

export default instrumentRoutePerformance(AdminAccountVerificationIndexRoute);
