import Controller from '@ember/controller';
import moment from 'moment';
import RSVP from 'rsvp';
import { action, computed, set, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AbstractDocumentsController extends Controller {
  @tracked query = null;
  @tracked range = null;
  @tracked sortProperty = null;
  @tracked status = null;
  @tracked quoteNotFound = null;
  @tracked sortAscending = null;
  @tracked showDate = false;
  @tracked dateRangeStart = null;
  @tracked dateRangeEnd = null;
  @tracked dateRangeLabel = null;
  @tracked search = null;
  @tracked job_account = null;
  @tracked _range = null;
  @tracked selectedRange = null;

  @service notice;
  @service store;
  @service router;

  @computed('job_account', 'store')
  get jobAccountRecord() {
    const jobAccount = this.job_account;

    if (jobAccount) {
      const r = this.store.peekRecord('account-job-account', jobAccount);
      return r;
    }
    return null;
  }

  get sort() {
    if (this.sortAscending) {
      return `${this.sortProperty}`;
    } else {
      return `${this.sortProperty}:desc`;
    }
  }

  @action csv() {
    const data = this.model;
    const results = ['invoices'].reduce((results, field) => {
      return field in data ? data.field : results;
    }, []);
    const fields = [
      'account_id',
      'job_account_name',
      'id',
      'invoice_date',
      'sales_order',
      'cust_po',
      'subtotal',
      'sales_tax',
      'total_cost',
      'status',
      'cust_exp_date',
      'due_date',
      'shipping_type',
      'comment',
    ];

    const labels = {
      account_id: 'Account Number',
      job_account_name: 'Job Account',
      id: 'Invoice Number',
      invoice_date: 'Invoice Date',
      sales_order: 'Order Number',
      cust_po: 'PO',
      subtotal: 'Subtotal',
      sales_tax: 'Sales Tax',
      total_cost: 'Total',
      status: 'Status',
      cust_exp_date: 'Customer Expected Date',
      due_date: 'Due Date',
      shipping_type: 'Shipping Type',
      comment: 'Comments',
    };

    const transforms = (field, value, row) => {
      if (field == 'total_cost') {
        const code = row.invoice_type_code;
        if (['RE', 'G2'].includes(code)) return -1 * value;
        return value;
      } else {
        return value;
      }
    };
    const csvserialize = text => {
      let needsQuoted = false;
      if (text.includes('"')) {
        needsQuoted = true;
        text = text.replace(/"/g, '""');
      }
      if (text.includes('\n')) {
        needsQuoted = true;
      }
      if (text.includes(',')) {
        needsQuoted = true;
      }
      if (needsQuoted) {
        return `"${text}"`;
      } else {
        return text;
      }
    };
    const output = results
      .reduce(
        (csv, row) => {
          return csv.concat(
            fields
              .map(field =>
                csvserialize(String(transforms(field, row.field, row))),
              )
              .join(','),
          );
        },
        [fields.map(f => labels[f]).join(',')],
      )
      .join('\n');
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(
        new Blob([output], { type: 'text/csv;charset=utf-8;' }),
        'invoices.csv',
      );
    } else {
      const encodedUri = 'data:text/csv,' + encodeURI(output);
      var link = window.document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'invoices.csv');
      window.document.body.appendChild(link); // Required for FF
      link.click();
      setTimeout(() => window.document.body.removeChild(link), 100);
    }
  }

  @action togglePika() {
    this.toggleProperty('showPika');
  }

  @action showPika() {
    this.set('showPika', true);
  }

  @action hidePika() {
    this.set('showPika', false);
  }

  @action async updateStatus(status) {
    if (this.hasSearch) {
      const { isConfirmed } = await this.notice.confirm(
        `Update`,
        `You already have search results. Can't filter & search. Would you like to remove your search term?`,
      );

      if (!isConfirmed) {
        return false;
      }
      this.set('search', '');
    }
    this.set('status', status);
  }

  @action updateSortProperty(status) {
    this.set('sortProperty', status);
    this.set('sortAscending', true);
    this.router.transitionTo({
      queryParams: { sortProperty: status, sortAscending: true },
    });
  }

  @action updateDateRangeStart(startDate) {
    if (startDate.length != 10 || !moment(startDate).isValid()) return;
    set(this, 'model.loading', true);
    this.set('dateRangeStart', moment(startDate));
  }

  @action updateDateRangeEnd(endDate) {
    if (endDate.length != 10 || !moment(endDate).isValid()) return;
    set(this, 'model.loading', true);
    this.set('dateRangeEnd', moment(endDate));
  }

  @action changeCenter(unit, calendar, { target: { value } }) {
    calendar.actions.changeCenter(calendar.center.clone()[unit](value));
  }

  @action async releaseReceipt(accountOpenItemReceipt) {
    const { isConfirmed } = await this.notice.confirm(
      `Cancel`,
      `Are you OK with canceling payment on these items?`,
    );

    if (isConfirmed) {
      await accountOpenItemReceipt.destroyRecord();
      location.reload();
    }
  }

  @action saveReceiptAndRedirect(accountOpenItemReceipt) {
    return accountOpenItemReceipt
      .validate()
      .then(({ model, validations }) => {
        const isValid = validations.isValid;

        if (isValid) {
          return RSVP.resolve(model);
        } else {
          return RSVP.reject({ model, validations });
        }
      })
      .then(model => model.save())
      .then(model => this.router.transitionTo('store.bill-pay', model.id))
      .catch(() => {
      });
  }
}
