import ApplicationSerializer, { exclude } from './application';

export default class CartLineItemSerializer extends ApplicationSerializer {
  attrs = {
    isReadyIn20Partial: exclude,
    isSoldInMultiples: exclude,
    orderable: exclude,
    stocked: exclude,
  };
}
