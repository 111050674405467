import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import CustomerChangePasswordModel from 'shoelace/models/customer-change-password';
import Session from 'ember-simple-auth/services/session';

export default class StoreCustomerPasswordController extends Controller {
  @tracked isValidationVisible = false;
  @service declare router: RouterService;
  @service declare session: Session;

  @action async changePassword(
    customerChangePasswordModel: CustomerChangePasswordModel,
    event: Event,
  ) {
    event.preventDefault();

    await customerChangePasswordModel.validate();

    if (customerChangePasswordModel.validations.isValid) {
      try {
        this.isValidationVisible = false;

        await customerChangePasswordModel.save();

        return this.router.transitionTo('store.customer', this.session.id);
      } catch (error) {
        this.isValidationVisible = true;
      }
    } else {
      this.isValidationVisible = true;
    }
  }
}
