import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import ProductModel from 'shoelace/models/product';

export default class RelationshipModel extends Model {
  @attr('string') kind!: null | string;

  @belongsTo('product')
  product!: AsyncBelongsTo<ProductModel>;

  @belongsTo('product', { inverse: null })
  forProduct!: AsyncBelongsTo<ProductModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    relationship: RelationshipModel;
  }
}
