import type CartModel from 'shoelace/models/cart';
import type CartPriceModel from 'shoelace/models/cart-price';
import type CartAvailabilityModel from 'shoelace/models/cart-availability';
import type ProductModel from 'shoelace/models/product';
import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { computed } from '@ember/object';
import { not, readOnly, and } from '@ember/object/computed';

export default class CartLineItemModel extends Model {
  @attr('string') message!: null | string;
  @attr('number') quantity!: null | number;
  @attr('boolean') isSoldInMultiples!: null | boolean;
  @attr('number') sortOrder!: null | number;

  @tracked selected = false;

  @belongsTo('cart')
   cart?: AsyncBelongsTo<CartModel>;

  @belongsTo('cart-price')
   cartPrice?: AsyncBelongsTo<CartPriceModel>;

  @belongsTo('product')
   product?: AsyncBelongsTo<ProductModel>;

  @belongsTo('cart-availability')
   cartAvailability?: AsyncBelongsTo<CartAvailabilityModel>;

  @computed('product.nonBatch', 'cartAvailability.stocked')
  get isReadyIn20Partial(): string | number {
    return this.get('cartAvailability.stocked') && this.get('product.nonBatch');
  }

  @computed('cartPrice.extendedPrice')
  get cartPriceExtendedPrice(): string {
    const extendedPrice = this.get('cartPrice.extendedPrice');

    if (typeOf(extendedPrice) === 'number') {
      return extendedPrice;
    } else {
      return 0;
    }
  }

  @computed(
    'cartAvailability.{isNormallyStocked,qty}',
    'cartPrice.qty',
    'product.minQty',
    'quantity',
  )
  get valid(): string {
    let max = this.get('cartAvailability.qty');
    const min = this.get('product.minQty');
    const value = this.quantity;
    const isNormallyStocked = this.get('cartAvailability.isNormallyStocked');

    if (isNormallyStocked) {
      max = false;
    }

    if (value % min === 0 && value >= min && (value <= max || max === false)) {
      return true;
    } else {
      return false;
    }
  }

  @readOnly('cartAvailability.stocked') stocked!: false | boolean;
  @not('cartAvailability.notAvailable') orderable!: false | boolean;
  @and('cartAvailability.isSettled', 'cartPrice.isSettled')
  orderaisCartAvailabilityAndPriceSettledble!: null | string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'cart-line-item': CartLineItemModel;
  }
}
