import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';

export default class ColorBlockComponent extends Component {
  @tracked color = null;
  @tracked tagName = '';

  @computed('htmlColor')
  get css() {
    return htmlSafe(
      `width: 1em; height: 1em; background-color: ${this.htmlColor}; border: 1px solid #666; display: inline-block; vertical-align: middle;`,
    );
  }

  @computed('color')
  get htmlColor() {
    const color = this.color;
    switch (color) {
      case 'orang':
        return 'orange';
      case 'purpl':
        return 'purple';
      default:
        return color;
    }
  }
}
