import Helper from '@ember/component/helper';
import { getOwner } from '@ember/application';

export default Helper.extend({
  compute([componentName]) {
    const owner = getOwner(this);
    const lookup = owner.lookup('component-lookup:main');
    if (!lookup.componentFor) {
      return !!lookup.lookupFactory(componentName);
    }
    return !!(
      lookup.componentFor(componentName, owner) ||
      lookup.layoutFor(componentName, owner)
    );
  },
});
