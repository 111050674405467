import Model, { attr, belongsTo } from '@ember-data/model';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';

const Validations = buildValidations({
  // customer: validator('presence', {
  //   presence: true,
  //   message: 'Please select a customer.',
  // }),
  // serviceCenter: validator('presence', {
  //   presence: true,
  //   message: 'Please select a service center.',
  // }),
  address: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      message: 'Address Required',
    }),
    validator('length', {
      max: 60,
      message(type) {
        if (type === 'tooLong') {
          return 'Address must be less than {max} characters.';
        }
      },
    }),
  ],
  city: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      message: 'City Required',
    }),
    validator('length', {
      max: 40,
      message(type) {
        if (type === 'tooLong') {
          return 'City must be less than {max} characters.';
        }
      },
    }),
  ],
  company: [
    validator('ds-error'),
    validator('length', {
      max: 20,
      message(type) {
        if (type === 'tooLong') {
          return 'Company must be less than {max} characters.';
        }
      },
    }),
  ],
  country: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      message: 'Country Required',
    }),
    validator('length', {
      max: 3,
      message(type) {
        if (type === 'tooLong') {
          return 'Country must be less than {max} characters.';
        }
      },
    }),
    validator('format', {
      regex: /^US$/i,
      message: `We can't open an international account online. Please email credit@summit.com to start that process.`,
    }),
  ],
  district: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      message: 'County Required',
    }),
    validator('length', {
      max: 40,
      message(type) {
        if (type === 'tooLong') {
          return 'County must be less than {max} characters.';
        }
      },
    }),
  ],
  firstName: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      message: 'First Name Required',
    }),
    validator('length', {
      max: 40,
      message(type) {
        if (type === 'tooLong') {
          return 'First Name must be less than {max} characters.';
        }
      },
    }),
  ],
  lastName: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      message: 'Last Name Required',
    }),
    validator('length', {
      max: 40,
      message(type) {
        if (type === 'tooLong') {
          return 'Last Name must be less than {max} characters.';
        }
      },
    }),
  ],
  region: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      message: 'State Required',
    }),
    validator('length', {
      max: 3,
      message(type) {
        if (type === 'tooLong') {
          return 'State must be less than {max} characters.';
        }
      },
    }),
  ],
  telephoneNumber: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      message: 'Phone Number Required',
    }),
    validator('length', {
      max: 30,
      message(type) {
        if (type === 'tooLong') {
          return 'Phone Number must be less than {max} characters.';
        }
      },
    }),
  ],
  telephoneExt: [
    validator('ds-error'),
    validator('length', {
      max: 10,
      message(type) {
        if (type === 'tooLong') {
          return 'Phone Ext must be less than {max} characters.';
        }
      },
    }),
  ],
  zipcode: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      message: 'Zip Required',
    }),
    validator('length', {
      max: 10,
      message(type) {
        if (type === 'tooLong') {
          return 'Zip Code must be less than {max} characters.';
        }
      },
    }),
  ],
});

export default class CodAccountModel extends Model.extend(Validations) {
  @attr('string') address;
  @attr('string') city;
  @attr('string') company;
  @attr('string') country;
  @attr('string') district; // county
  @attr('string') firstName;
  @attr('string') lastName;
  @attr('string') region; // state
  @attr('string') title;
  @attr('string') telephoneNumber;
  @attr('string') telephoneExt;
  @attr('string') zipcode;

  @belongsTo('account') account;
  @belongsTo('customer') customer;
  @belongsTo('service-center') serviceCenter;
}
