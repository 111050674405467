import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreServicesMyProjectsRoute extends Route {
  @service session;
  @service store;
  @service router;

  async beforeModel(transition) {
    if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }

    const customer = await this.session.get('customerRecord');
    if (customer) {
      if (!customer?.hasCustomerProjects && customer?.hasRbacCustomer) {
        this.router.transitionTo('store.customer', customer.id);
      }
    }
  }

  async model() {
    const customer = this.session.customer;
    const customerId = customer.get('id');
    const accounts = await customer.get('accounts');
    const accountIds = accounts.map((account) => account.get('id'));

    const accountProjects = await this.store.findAll('account-project');

    const accountProjectsIds = accountProjects.map((accountProject) =>
      accountProject.get('id')
    );

    return {
      accountIds,
      accountProjects,
      accountProjectsIds,
      accounts,
      customer,
      customerId,
    };
  }
}
export default instrumentRoutePerformance(StoreServicesMyProjectsRoute);
