import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreAccountHistoryOrderRoute extends Route {
  @service session;
  @service store;
  @service router;

  async beforeModel(transition) {
     if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }
  }

  async model({ id }) {
    return await this.store.findRecord('account-history-order', id, {
      include: [
        'account-history-order-bill-address',
        'account-history-order-fund-address',
        'account-history-order-ship-address',
        'account-history-order-sold-address',
        'account-history-order-comments',
        'account-history-order-line-items',
        'account-history-order-line-items.account-history-order-line-item-comments',
        'account-history-order-line-items.account-history-order-line-item-deliveries',
        'account-history-order-line-items.account-history-order-line-item-invoices',
        'account-history-order-line-items.account-history-order-line-item-orders',
        'account-history-order-line-items.account-history-order-line-item-quotes',
        'account-history-order-deliveries',
        'account-history-order-invoices',
        'account-history-order-orders',
        'account-history-order-quotes',
      ].join(','),
    });
  }
}
export default instrumentRoutePerformance(
  StoreAccountHistoryOrderRoute
);