import { helper } from '@ember/component/helper';
import { findBy } from 'ember-composable-helpers/helpers/find-by';

export function findIn([byPath, array]) {
  return function findInCurry(value) {
    return findBy([byPath, value, array]);
  };
}

export default helper(findIn);
