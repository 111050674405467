import Route from '@ember/routing/route';
import TranslationModel from 'shoelace/models/translation';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface RouteModel {
  translation?: TranslationModel;
}

export interface RouteParams {
  translation_id: string | number;
}

class AdminDynamicTranslationRoute extends Route {
  @service declare store: StoreService;
 
  async model({ translation_id: id }: RouteParams): Promise<RouteModel> {
    return await this.store.findRecord('translation', id);
  }
}

export default instrumentRoutePerformance(
  AdminDynamicTranslationRoute
);