import type StoreService from '@ember-data/store';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type KartService from 'shoelace/services/kart';
import type ServiceCenterService from 'shoelace/services/service-center';
import type RouterService from '@ember/routing/router';
import Controller from '@ember/controller';

export default class MultiOrderPad extends Controller {
@service declare kart: KartService;
@service declare router: RouterService;
@service declare serviceCenter: ServiceCenterService;
@service declare store: StoreService;

  @tracked sc_filter = 'any';

  async model() {}

  @action async load(text: string) {
    const terms = String(text)
      .split('\n')
      .map(l => String(l).trim())
      .filter(l => !!l)
      .map(line => line.split(/[,\s]+/))
      .map(line => {
        if (line.length != 2) {
          return null;
        }
        const qty = line.pop();
        const part = line[0];
        return {
          quantity: qty,
          part: part,
        };
      })
      .filter(v => !!v);

    // merge duplicate parts
    const parts = terms.reduce((acc, cur) => {
      const part = cur.part;
      const qty = parseInt(cur.quantity);
      if (acc[part]) {
        acc[part].quantity += qty;
      } else {
        acc[part] = {
          quantity: qty,
          part: part,
        };
      }
      return acc;
    }, {});

    const uniqueTerms = Object.values(parts);

    const searches = await Promise.all(
      uniqueTerms.map((lineItem: any) => this.product(lineItem.part)),
    );

    const errors = [];
    uniqueTerms.forEach(async (term, i) => {
      try {
        const qty = parseInt(term?.quantity ?? '1');
        const product = searches[i].toArray()[0];
        if (product) {
          await this.kart.addItem(product.id, qty);
        } else {
          throw new Error();
        }
      } catch (e) {
        e;
        errors.push(term?.part);
      }
    });

    if (errors.length > 0) {
      alert(`Could not find: \n${errors?.join('\n')}`);
    } else {
      this.router.transitionTo('secure-checkout.index');
    }
  }

  product(term: any) {
    const params = {
      page: { number: 1, size: 1 },
      search: term,
      service_center_id: this.serviceCenter.id,
      sc_filter: this.sc_filter,
    };

    if (!params.service_center_id) {
      params.sc_filter = 'any';
      this.sc_filter = 'any';
    }

    const m = this.store.query('product', params); // this caches loading results (see search adapter: queryRecord)
    return m;
  }
}
