import type StoreService from '@ember-data/store';
import { action, computed } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { storageFor } from 'ember-local-storage';
import type ProductModel from 'shoelace/models/product';
import type ProductAvailabilityModel from 'shoelace/models/product-availability';
import type ProductPriceModel from 'shoelace/models/product-price';
import type ProcurementService from 'shoelace/services/procurement';
import type ProductService from 'shoelace/services/product';
import type ProductAvailabilityService from 'shoelace/services/product-availability';
import type ProductPriceService from 'shoelace/services/product-price';
import type ServiceCenterService from 'shoelace/services/service-center';
import type SessionService from 'shoelace/services/session';
import type KartService from 'shoelace/services/kart';
import type { ArrayPromiseProxy } from '../utils/async-helpers';
import { AsyncArray } from '../utils/async-helpers';

// Technically "product" but to avoid confusion with the product model.
export interface Item {
  id: number;
  quantity: number;
  message?: string;
}

export interface LineItem {
  availability: null | ProductAvailabilityModel;
  id: number;
  message?: string;
  price: null | ProductPriceModel;
  product: ProductModel;
  quantity: number;
}

export default class CartService extends Service {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare procurement: ProcurementService;
  @service declare account: any;
  @service declare productPrice: ProductPriceService;
  @service declare serviceCenter: ServiceCenterService;
  @service declare productAvailability: ProductAvailabilityService;
  @service('product') productService!: ProductService;
  @service declare kart: KartService;

  @storageFor('cart-data') storage: any;

  @tracked items: Item[] = this.localStorageProducts;

  @computed('storage')
  get localStorageProducts(): Item[] {
    return this.storage?.get('products') || [];
  }

  @action async save(): Promise<void> {
    this.storage?.set('products', this.items);
  }

  @computed(
    'account.account',
    'serviceCenter.serviceCenter',
    'session.customer.activeJobAccountNumber',
    'items.@each.{id,message,quantity}',
  )
  get lineItems(): ArrayPromiseProxy<LineItem> {
    return AsyncArray(this.computeLineItems());
  }

  async computeLineItems(): Promise<LineItem[]> {
    const account = await Promise.resolve(this.account.account);
    const serviceCenter = await Promise.resolve(this.serviceCenter);
    const jobAccount = await Promise.resolve(
      this.session.customer?.activeJobAccountNumber,
    );

    const lineItems = this.items.map(async ({ id, quantity, message }) => {
      const availability = await this.productAvailability.getAvailability(
        id,
        quantity,
        serviceCenter,
      );

      const price = await this.productPrice.getPrice(
        id,
        quantity,
        account,
        jobAccount,
        serviceCenter,
      );

      const product = await this.productService.getProduct(id);

      return {
        id,
        quantity,
        message,
        availability,
        product,
        price,
      };
    });

    return Promise.all(lineItems);
  }

  /********************
   * Cart Core Functions
   ********************/

  @action findItem(id: number): Item | undefined {
    return this.items.find(p => p.id == id);
  }

  @action async addItem(id: number, quantity: number | undefined): Promise<void> {
    return this.kart.addItem(id, quantity);

    // const item = this.findItem(id);

    // if (item) {
    //   const newQuantity =
    //     parseInt(`${item.quantity}`, 10) + parseInt(`${quantity}`, 10);

    //   this.setItemQuantity(id, 1, newQuantity);
    // } else {
    //   this.items.pushObject({ id, quantity: parseInt(`${quantity}`, 10) });

    // }

    // this.save();
  }

  @action async addItems(items: Item[]): Promise<void> {
    items.forEach(async i => {
      return this.kart.addItem(i.id, i.quantity);

      // const item = this.findItem(i.id);
      // if (item) {
      //   const newQuantity =
      //     parseInt(`${item.quantity}`, 10) + parseInt(`${i.quantity}`, 10);

      //   this.setItemQuantity(i.id, 1, newQuantity);
      // } else {
      //   this.items.pushObject({ id: i.id, quantity: i.quantity });
      // }
    });
    // this.save();
  }

  @action removeItem(id: number): void {
    this.kart.removeItem(id);

    // const item = this.findItem(id);

    // if (item) {
    //   this.items.removeObject(item);
    //   this.save();
    // }
  }

  @action removeItems(ids: number[]): void {
    ids.forEach(id => {
      this.kart.removeItem(id);
    });

    // const items = this.items.filter((p) => ids.includes(p.id));

    // this.items.removeObjects(items);
    // this.save();
  }

  @action removeAll(): void {
    this.kart.items.forEach(item => {
      this.kart.removeItem(item.product);
    });

    // this.items.clear();
    // this.save();
  }

  @action setItemQuantity(
    id: number,
    minQuantity = 0,
    newQuantity: number,
  ): void {
    const item = this.findItem(id);

    if (item) {
      const index = this.items.indexOf(item);
      const quantity = this.getValidQuantity(minQuantity, newQuantity);
      const updated = { ...item, quantity };

      this.items.replace(index, 1, [updated]);
      this.save();
    }
  }

  getValidQuantity(min: number, quantity: number): number {
    quantity = parseInt(`${quantity}`, 10) || 0;
    if (quantity < 1) return min;
    if (min && quantity % min !== 0) {
      quantity = parseInt(`${quantity / min}`, 10) * min;
    }
    return quantity || min;
  }

  /********************
   * Computeds
   ********************/

  @computed('orderableLineItems.{isFulfilled,isPending,length}')
  get subtotal(): number {
    const prices = this.orderableLineItems.getEach('price.extendedPrice');
    return prices.reduce((prev, next) => prev + next, 0);
  }

  @computed(
    // eslint-disable-next-line ember/use-brace-expansion
    'lineItems.{isFulfilled,isPending,length}',
    'lineItems.@each.availability',
  )
  get stockedLineItems(): LineItem[] {
    return this.lineItems.filterBy('availability.stocked', true);
  }

  @computed('stockedLineItems.[]')
  get stockedCt(): number {
    return this.stockedLineItems.length;
  }

  @computed('stockedCt')
  get hasStocked(): boolean {
    return this.stockedCt > 0;
  }

  @computed('hasStocked')
  get hasNotStocked(): boolean {
    return !this.hasStocked;
  }

  @computed('lineItems.{isFulfilled,isPending,length}')
  get orderableLineItems(): ArrayPromiseProxy<LineItem> {
    return AsyncArray(this.computeOrderableLineItems());
  }

  async computeOrderableLineItems(): Promise<LineItem[]> {
    try {
      await Promise.all([
        ...this.lineItems.map(lineItem => lineItem.availability),
        ...this.lineItems.map(lineItem => lineItem.product),
        ...this.lineItems.map(lineItem => lineItem.price),
      ]);
    } catch (_) {
      // console.error(e);
    }

    return this.lineItems.filterBy('availability.notAvailable', false);
  }

  @computed(
    // eslint-disable-next-line ember/use-brace-expansion
    'lineItems.{isFulfilled,isPending,length}',
    'lineItems.@each.availability',
  )
  get unorderableLineItems(): LineItem[] {
    return this.lineItems.filterBy('availability.notAvailable', true);
  }

  @computed('unorderableLineItems.length')
  get hasUnorderable(): boolean {
    return this.unorderableLineItems.length > 0;
  }

  @computed('orderableLineItems.length')
  get quotable(): boolean {
    return this.orderableLineItems.length === 0;
  }

  @computed('orderableLineItems.length')
  get orderable(): boolean {
    return this.orderableLineItems.length > 0;
  }

  @computed('orderable')
  get unorderable(): boolean {
    return !this.orderable;
  }

  @computed(
    // eslint-disable-next-line ember/use-brace-expansion
    'lineItems.{isFulfilled,isPending,length}',
    'lineItems.@each.availability',
  )
  get isReadyIn20Partial(): boolean {
    return (
      this.lineItems.isAny('availability.stocked') &&
      this.lineItems.isAny('availability.batch')
    );
  }

  @computed(
    'lineItems.{isFulfilled,isPending,length}',
    'lineItems.@each.{availability,product,price}',
  )
  get invalidLineItems(): ArrayPromiseProxy<LineItem> {
    return AsyncArray(this.computeInvalidLineItems());
  }

  async computeInvalidLineItems(): Promise<LineItem[]> {
    try {
      await Promise.all([
        ...this.lineItems.map(lineItem => lineItem.availability),
        ...this.lineItems.map(lineItem => lineItem.product),
        ...this.lineItems.map(lineItem => lineItem.price),
      ]);
    } catch (_) {
      // console.error(e);
    }

    return this.lineItems.filter(lineItem => {
      const min = parseInt(`${lineItem.product.minQty}`, 10);
      const isValidQty =
        parseInt(`${lineItem.availability?.availableQuantity}`, 10) >= 0;
      const requested = parseInt(
        `${lineItem.availability?.requestedQuantity}`,
        10,
      );
      const orderable = lineItem.availability?.notAvailable === false;
      const isMultipleOfMin = requested % min == 0;
      const isNormallyStocked = lineItem.availability?.isNormallyStocked;

      const isValid =
        orderable &&
        isMultipleOfMin &&
        isValidQty &&
        requested % min === 0 &&
        requested >= min &&
        (requested <= (lineItem.availability?.availableQuantity ?? -1) ||
          isNormallyStocked);

      return !isValid;
    });
  }

  @computed('invalidLineItems.{isFulfilled,isPending,length}')
  get hasInvalidLineItems(): boolean {
    if (this.invalidLineItems.isPending) {
      return true;
    }

    return this.invalidLineItems.isFulfilled && this.invalidLineItems.length > 0;
  }

  @computed(
    'lineItems.{isFulfilled,isPending,length}',
    'hasInvalidLineItems',
    'hasUnorderable',
    'procurement.procurement',
    'serviceCenter.id',
  )
  get isCartValid(): boolean {
    return (
      isPresent(this.serviceCenter.id) &&
      !this.hasUnorderable &&
      !this.hasInvalidLineItems &&
      this.lineItems.length > 0 &&
      isPresent(this.procurement.procurement?.get('id'))
    );
  }

  /********************
   * Legacy
   ********************/

  async generateLegacyCart(title = null, lineItems: LineItem[]): Promise<any> {
    const legacyCart = await this.store
      .createRecord('cart', {
        title: title,
        customer: this.session.customer,
        serviceCenter: this.serviceCenter.serviceCenter,
      })
      .save();

    const cartLineItems = lineItems.map(item => {
      const lineItem = this.store.createRecord('cart-line-item', {
        product: item.product,
        cart: legacyCart,
        quantity: item.quantity,
      });
      return lineItem.save();
    });

    await Promise.all(cartLineItems);

    return legacyCart;
  }
}

declare module '@ember/service' {
  interface Registry {
    cart: CartService;
  }
}
