import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type AccountHistoryQuoteModel from 'shoelace/models/account-history-quote';
import type AccountHistoryQuoteLineItemCommentModel from 'shoelace/models/account-history-quote-line-item-comment';
import type AccountHistoryQuoteShipAddressModel from 'shoelace/models/account-history-quote-ship-address';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type ProductModel from 'shoelace/models/product';

export default class AccountHistoryQuoteLineItemModel extends Model {
  @attr('date') validAt!: null | Date;
  @attr('date') expiresAt!: null | Date;
  @attr('boolean') isLotKit!: null | boolean;
  @attr('boolean') isSummitBuck!: null | boolean;
  @attr('string') name!: null | string;
  @attr('string') customerPartNumber!: null | string;
  @attr('string') jobAccountName!: null | string;
  @attr('string') purchaseOrder!: null | string;
  @attr('number') quantity!: null | number;
  @attr('string') unitOfMeasure!: null | string;
  @attr('number') numberPerUnit!: null | number;
  @attr('number') price!: null | number;
  @attr('number') total!: null | number;
  @attr('number') totalLot!: null | number;
  @attr('string') unknownItemType!: null | string;
  @attr('string') unknownTextFlag!: null | string;
  @attr('string') forceLineItemDescription!: null | string;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-job-account')
  accountJobAccount!: AsyncBelongsTo<AccountJobAccountModel>;

  @belongsTo('account-history-quote-ship-address')
  accountHistoryQuoteShipAddress!: AsyncBelongsTo<AccountHistoryQuoteShipAddressModel>;

  @belongsTo('account-history-quote')
  accountHistoryQuote!: AsyncBelongsTo<AccountHistoryQuoteModel>;

  @belongsTo('product')
  product!: AsyncBelongsTo<ProductModel>;

  @hasMany('account-history-quote-line-item-comments')
  accountHistoryQuoteLineItemComments!: AsyncHasMany<AccountHistoryQuoteLineItemCommentModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-quote-line-item': AccountHistoryQuoteLineItemModel;
  }
}
