import Model from '@ember-data/model';
import type StoreService from '@ember-data/store';
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import type ModelRegistry from 'ember-data/types/registries/model';
import { storageFor } from 'ember-local-storage';
import type AccountModel from 'shoelace/models/account';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type CustomerModel from 'shoelace/models/customer';
import type ProductModel from 'shoelace/models/product';
import type ProductAvailabilityModel from 'shoelace/models/product-availability';
import type ProductPriceModel from 'shoelace/models/product-price';
import type MetricsService from 'shoelace/services/metrics';
import { EcommerceItem } from 'shoelace/services/metrics';
import type AccountService from 'shoelace/services/account';
import type AccountJobAccountService from 'shoelace/services/account-job-account';
import type { BootOptions } from 'shoelace/services/coordinator';
import type ProcurementService from 'shoelace/services/procurement';
import type ProductAvailabilityService from 'shoelace/services/product-availability';
import type ProductPriceService from 'shoelace/services/product-price';
import type ServiceCenterService from 'shoelace/services/service-center';
import type SessionService from 'shoelace/services/-session';

export async function findModel<T extends keyof ModelRegistry>(
  store: StoreService,
  name: T,
  primaryKey?: null | number | string | Model
): Promise<ModelRegistry[T] | undefined> {
  if (primaryKey === null || primaryKey === undefined) {
    return undefined;
  }

  return peekModel(store, name, primaryKey);
}

export async function peekModel<T extends keyof ModelRegistry>(
  store: StoreService,
  name: T,
  primaryKey: number | string | Model
): Promise<ModelRegistry[T]> {
  if (typeof primaryKey === 'number' || typeof primaryKey === 'string') {
    const maybeModel = store.peekRecord(name, primaryKey);

    if (maybeModel) {
      return maybeModel;
    } else {
      return await store.findRecord(name, primaryKey);
    }
  } else {
    return primaryKey;
  }
}

export class Item {
  @tracked product: ProductModel;
  @tracked quantity: number;
  @tracked account?: AccountModel;
  @tracked accountJobAccount?: AccountJobAccountModel;
  @tracked message?: string;
  @tracked shipToAddress?: string;
  @tracked productAvailability?: ProductAvailabilityModel;
  @tracked productPrice?: ProductPriceModel;
  @tracked serviceCenter?: string | number;
  @tracked isProductAvailabilityLoading = false;
  @tracked isProductPriceLoading = false;
  @tracked isValid = false;

  constructor(product: ProductModel, quantity: number) {
    this.product = product;
    this.quantity = isNaN(quantity) ? product.minQty || 1 : quantity;
  }
}

interface LocalStorageItem {
  id: string;
  quantity: number;
  message?: string;
  shipToAddress?: string;
}

export default class KartService extends Service {
  @service declare account: AccountService;
  @service declare session: SessionService;
  @service declare accountJobAccount: AccountJobAccountService;
  @service declare metrics: MetricsService;
  @service declare productAvailability: ProductAvailabilityService;
  @service declare productPrice: ProductPriceService;
  @service declare procurement: ProcurementService;
  @service declare serviceCenter: ServiceCenterService;
  @service declare store: StoreService;

  @storageFor('cart-data') storage: any;

  @tracked items: Item[] = [];
  @tracked itemsLoading = 0;
  @tracked productsBeingAdded: ProductModel[] = [];

  async onCoordinatorSetup(_options?: BootOptions): Promise<void> {
    this.storage?.get('products').forEach((item: LocalStorageItem) => {
      this.removeItem(item.id, false);
      this.addItem(item.id, item.quantity, item.message, item.shipToAddress);
    });
  }

  async onCoordinatorTeardown(): Promise<void> {}

  @task(function* (this: any) {
    yield timeout(500);

    this.storage?.set(
      'products',
      this.items.map((item: Item) => {
        return {
          id: item.product.id,
          message: item.message,
          quantity: item.quantity,
          shipToAddress: item.shipToAddress,
        };
      })
    );

    if (this.procurement.procurement) {
      this.procurement.procurement.lineItems = this.items.map((item: Item) => {
        return {
          matnr: item.product.matnr,
          quantity: item.quantity,
          notes: item.message,
          shipToAddress: item.shipToAddress,
        };
      });
    }
  }).keepLatest()
  save;

  @action openChat() {
    if ((window as any).$zoho) {
      window?.$zoho?.salesiq?.visitor?.name(this.session?.customer?.name);
      window?.$zoho?.salesiq?.visitor?.email(this.session?.customer?.email);
    
      if (!Number.isNaN(this.session?.customer?.cellPhone)) {
        window?.$zoho?.salesiq?.visitor?.contactnumber(this.session?.customer?.cellPhone);
      }
    
      let accountId = this.session?.customer?.get?.('account.id'); 
      let accountName = this.session?.customer?.get?.('account.name');
    
      if (accountId) {
        window?.$zoho?.salesiq?.visitor?.info({
          "accountid" :  accountId,
          "accountname" : accountName,
          "officephone" : (!Number.isNaN(this.session?.customer?.officePhone)) ? this.session?.customer?.officePhone : ""
        }); 
      }
 
      window?.$zoho?.salesiq?.chat?.start();
    }
  }

  @action async addItem(
    product: ProductModel | string | number,
    quantity?: number,
    message?: string,
    shipToAddress?: string,
    account?: AccountModel,
    accountJobAccount?: AccountJobAccountModel,
    productAvailability?: ProductAvailabilityModel,
    productPrice?: ProductPriceModel,
    serviceCenterId?: string | number
  ): Promise<void> {
    this.itemsLoading++;

    const productModel = await findModel(this.store, 'product', product);

    if (productModel) {
      quantity = isNaN(quantity) ? productModel.minQty || 1 : quantity;

      this.productsBeingAdded.pushObject(productModel);

      const existingItem = this.items.find(
        (item) => item.product.id === productModel.id
      );

      if (existingItem) {
        const newQuantity =
          typeof quantity === 'string' ? parseInt(quantity, 10) : quantity;

        const existingQuantity =
          typeof existingItem.quantity === 'string'
            ? parseInt(existingItem.quantity, 10)
            : existingItem.quantity;

        await this.setQuantity(
          existingItem.product,
          existingQuantity + (newQuantity ?? productModel.minQty ?? 1)
        );
      } else {
        const item = new Item(
          productModel,
          quantity ?? productModel.minQty ?? 1
        );

        item.message = typeof message === 'string' ? message : undefined;
        item.shipToAddress =
          typeof shipToAddress === 'string' ? shipToAddress : undefined;
        item.account = account ?? this.account.account;

        if (productModel?.isPromotion) {
          item.serviceCenter = 15;
        } else {
          item.serviceCenter =
            serviceCenterId ?? this.serviceCenter.serviceCenter?.id;
        }
        item.accountJobAccount =
          accountJobAccount ?? this.accountJobAccount.accountJobAccount;

        item.productAvailability =
          productAvailability ??
          (await this.productAvailability.getSharedAvailability(
            item.product,
            item.quantity,
            item.serviceCenter
          ));

        item.isValid = this.getItemValidity(item);

        item.productPrice =
          productPrice ??
          (await this.productPrice.getPriceStrict(
            item.product,
            item.quantity,
            item.account,
            item.accountJobAccount,
            item.serviceCenter
          ));

        this.items = this.items.concat(item);

        this.save.perform();
      }

      this.productsBeingAdded.removeObject(productModel);
    }

    this.itemsLoading--;
  }

  @action async removeItem(
    product: ProductModel | string | number,
    save = true
  ): Promise<void> {
    const productModel = await findModel(this.store, 'product', product);

    if (productModel) {
      this.items = this.items.filter(
        (item) => item.product?.id !== productModel.id
      );

      if (save) {
        this.save.perform();
      }
    }
  }

  @action async setAccount(account: AccountModel): Promise<void> {
    this.items.forEach(async (item) => {
      item.account = account;
      item.shipToAddress = '';
      item.isProductPriceLoading = true;

      item.productPrice = await this.productPrice.getPriceStrict(
        item.product,
        item.quantity,
        item.account,
        item.accountJobAccount,
        item.serviceCenter
      );

      item.isProductPriceLoading = false;
    });
  }

  @action async setAccountJobAccount(
    accountJobAccount?: AccountJobAccountModel
  ): Promise<void> {
    this.items.forEach(async (item) => {
      item.accountJobAccount = accountJobAccount;
      item.isProductPriceLoading = true;

      item.productPrice = await this.productPrice.getPriceStrict(
        item.product,
        item.quantity,
        item.account,
        item.accountJobAccount,
        item.serviceCenter
      );

      item.isProductPriceLoading = false;
    });
  }

  @action async setServiceCenter(
    serviceCenter?: string | number
  ): Promise<void> {
    this.items.forEach(async (item) => {
      if (item?.product?.isPromotion) {
        item.serviceCenter = 15;
      } else {
        item.serviceCenter = this.serviceCenter.serviceCenter?.id;
      }

      // item.serviceCenter = serviceCenter;
      item.isProductAvailabilityLoading = true;
      item.isProductPriceLoading = true;

      item.productAvailability =
        await this.productAvailability.getAvailabilityStrict(
          item.product,
          item.quantity,
          item.serviceCenter
        );

      item.isProductAvailabilityLoading = false;

      item.isValid = this.getItemValidity(item);

      item.productPrice = await this.productPrice.getPriceStrict(
        item.product,
        item.quantity,
        item.account,
        item.accountJobAccount,
        item.serviceCenter
      );

      item.isProductPriceLoading = false;
    });
  }

  @action setMessage(product: ProductModel, message: string): void {
    const item = this.items.find((item) => item.product.id === product.id);

    if (item) {
      item.message = message;

      this.save.perform();
    }
  }

  @action setShipToAddress(product: ProductModel, shipToAddress: string): void {
    const item = this.items.find((item) => item.product.id === product.id);

    if (item) {
      item.shipToAddress = shipToAddress;

      this.save.perform();
    }
  }

  @action async setQuantity(
    product: ProductModel,
    quantity: number
  ): Promise<void> {
    const item = this.items.find((item) => item.product.id === product.id);

    if (quantity <= 0) {
      await this.removeItem(product);
    } else if (item) {
      const minQty = item.product.minQty ?? 1;

      item.quantity = quantity < minQty ? minQty : quantity;

      item.isProductAvailabilityLoading = true;
      item.isProductPriceLoading = true;

      item.productAvailability =
        await this.productAvailability.getAvailabilityStrict(
          item.product,
          item.quantity,
          item.serviceCenter
        );

      item.isProductAvailabilityLoading = false;

      item.isValid = this.getItemValidity(item);

      item.productPrice = await this.productPrice.getPriceStrict(
        item.product,
        item.quantity,
        item.account,
        item.accountJobAccount,
        item.serviceCenter
      );

      item.isProductPriceLoading = false;

      this.save.perform();
    }
  }

  @action getItemValidity(item: Item): boolean {
    const availableQuantity = item.productAvailability?.availableQuantity;
    const requestedQuantity = item.productAvailability?.requestedQuantity;

    const min = parseInt(`${item.product.minQty}`, 10);
    const isValidQuantity = parseInt(`${availableQuantity}`, 10) >= 0;
    const requested = parseInt(`${requestedQuantity}`, 10);

    const isOrderable = item.productAvailability?.notAvailable === false;
    const isMultipleOfMin = requested % min == 0;
    const isNormallyStocked = item.productAvailability?.isNormallyStocked;

    const isValid =
      isOrderable &&
      isMultipleOfMin &&
      isValidQuantity &&
      requested % min === 0 &&
      requested >= min &&
      (requested <= (availableQuantity ?? -1) || isNormallyStocked);

    return !!isValid;
  }

  /********************
   * Getters
   ********************/

  get isLoading(): boolean {
    return (
      this.itemsLoading > 0 ||
      this.items.isAny('isProductPriceLoading') ||
      this.items.isAny('isProductAvailabilityLoading')
    );
  }

  get orderableLineItems(): Item[] {
    return this.items.filterBy('productAvailability.notAvailable', false);
  }

  get unorderableLineItems(): Item[] {
    return this.items.filterBy('productAvailability.notAvailable', true);
  }

  get invalidLineItems(): Item[] {
    return this.items.filterBy('isValid', false);
  }

  get hasUnorderable(): boolean {
    return this.unorderableLineItems.length > 0;
  }

  get hasInvalidLineItems(): boolean {
    return this.invalidLineItems.length > 0;
  }

  get isOrderable(): boolean {
    return this.orderableLineItems.length > 0;
  }

  get subtotal(): number {
    const prices = this.orderableLineItems.getEach(
      'productPrice.extendedPrice'
    );

    return prices.reduce((prev, next) => prev + next, 0);
  }

  get isCartValid(): boolean {
    return (
      // isPresent(this.serviceCenter.id) &&
      // isPresent(this.procurement.procurement?.get('id')) &&
      !this.hasUnorderable && !this.hasInvalidLineItems && this.items.length > 0
    );
  }

  /********************
   * Metrics Helpers
   ********************/

  getKartItemsAsEcommerceItems(item_list_id?: string): EcommerceItem[] {
    return this.items.map((item, index) => {
      return this.metrics.productAsEcommerceItem(
        item.product,
        item.quantity,
        item.productPrice?.unitPrice,
        index,
        item_list_id
      );
    });
  }

  /********************
   * Legacy
   ********************/

  async generateLegacyCart(
    title: string | null = null,
    items: Item[],
    customer: CustomerModel
  ): Promise<any> {
    const legacyCart = this.store.createRecord('cart');
    legacyCart.set('title', title);
    legacyCart.set('customer', customer);
    legacyCart.set('serviceCenter', this.serviceCenter.serviceCenter);

    await legacyCart.save();

    if (Array.isArray(items)) {
      const cartLineItems = items.map((item) => {
        return this.store
          .createRecord('cart-line-item', {
            product: item.product,
            cart: legacyCart,
            quantity: item.quantity,
          })
          .save();
      });
      await Promise.all(cartLineItems);
    } else {
      this.store
        .createRecord('cart-line-item', {
          product: items,
          cart: legacyCart,
          quantity: items.minQty,
        })
        .save();
    }
    return legacyCart;
  }
}

declare module '@ember/service' {
  interface Registry {
    kart: KartService;
  }
}
