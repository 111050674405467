import type StoreService from '@ember-data/store';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { timeout } from 'ember-concurrency';
import type ServiceCenterModel from 'shoelace/models/service-center';
import type { BootOptions } from 'shoelace/services/coordinator';
import type SessionService from 'shoelace/services/session';

export default class ServiceCentersService extends Service {
  @service declare store: StoreService;
  @service declare session: SessionService;

  @tracked all: ServiceCenterModel[] = [];
  @tracked nearest?: ServiceCenterModel;
  @tracked isRunning = false;
  @service declare router: RouterService;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async onCoordinatorSetup(_options?: BootOptions): Promise<void> {
    await this.load();
  }

  async onCoordinatorTeardown(): Promise<void> {
    return;
  }

  async load(forceReload = false): Promise<void> {
    if (forceReload || isEmpty(this.all)) {
      let failures = 0;

      this.isRunning = true;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        try {
          const serviceCenters = await this.store.query('service-center', {
            filter: {
              distance: 'ip-address',
              active: 'true',
              'service-center-type.lookup': 'service-center',
            },
          });

          if (this.session.isAuthenticated) {
            const customer = await this.store.findRecord(
              'customer',
              this.session.id
            );

            this.nearest = customer.serviceCenter;
          } else {
            this.nearest = serviceCenters?.get('firstObject');
          }

          this.nearest = serviceCenters?.get('firstObject');
          this.all = serviceCenters?.sortBy('longName');

          break;
        } catch (error) {
          if (failures++ > 10) {
            this.isRunning = false;
            throw error;
          }

          await timeout(1000);
        }
      }

      this.isRunning = false;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'service-centers': ServiceCentersService;
  }
}
