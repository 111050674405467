import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class AdminServiceCentersIndexController extends Controller {
  queryParams = ['sort'];

  @tracked
  sort = 'long-name';
}

declare module '@ember/controller' {
  interface Registry {
    'admin/service-centers/index': AdminServiceCentersIndexController;
  }
}
