import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountHistoryReturnModel from 'shoelace/models/account-history-return';

export default class AccountHistoryReturnOrderModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('number') number!: null | number;

  @belongsTo('account-history-return')
  accountHistoryReturn!: AsyncBelongsTo<AccountHistoryReturnModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-return-order': AccountHistoryReturnOrderModel;
  }
}
