import type StoreService from '@ember-data/store';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type CustomerModel from 'shoelace/models/customer';
import type AccountService from 'shoelace/services/account';
import type { BootOptions } from 'shoelace/services/coordinator';
import type KartService from 'shoelace/services/kart';
import type ProcurementService from 'shoelace/services/procurement';
import type SessionService from 'shoelace/services/session';

export default class AccountJobAccountService extends Service {
@service declare account: AccountService;
@service declare procurement: ProcurementService;
@service declare kart: KartService;
@service declare store: StoreService;
@service declare session: SessionService;

  @tracked accountJobAccount?: AccountJobAccountModel;

  async onCoordinatorSetup(options?: BootOptions): Promise<void> {
    if (options?.accountJobAccount) {
      this.accountJobAccount = options.accountJobAccount;
    } else {
      const customer = options?.customer ?? this.session.customer;

      if (customer) {
        this.accountJobAccount =
          (await customer.get('accountJobAccount')) ?? undefined;

        later(this, this.account.loadValidAccountAddresses, 1000);
        // await this.account.loadValidAccountAddresses();
      }
    }
  }

  async onCoordinatorTeardown(): Promise<void> {
    this.accountJobAccount = undefined;
  }

  @action async setAccountJobAccount(
    accountJobAccount?: AccountJobAccountModel,
    customer?: CustomerModel,
  ): Promise<void> {
    const someCustomer = customer ?? this.session.customer;

    if (someCustomer) {
      this.accountJobAccount = accountJobAccount;

      someCustomer.set('accountJobAccount', accountJobAccount);

      await this.account.loadValidAccountAddresses();

      if (this.procurement.procurement) {
        this.procurement.procurement.set(
          'accountJobAccount',
          this.accountJobAccount,
        );

        const accountAddress =
          this.account.validAccountAddresses?.firstObject ??
          this.store.createRecord('account-address');

        this.procurement.procurement.set('accountAddress', accountAddress);
        this.procurement.procurement.set('activeAccountAddress', accountAddress);
      }

      await someCustomer.save();

      await this.kart.setAccountJobAccount(this.accountJobAccount);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'account-job-account': AccountJobAccountService;
  }
}
