import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type AccountVerification from 'shoelace/models/account-verification';
import type { RouteModel as ParentRouteModel } from 'shoelace/routes/admin/account-verifications';
import { refreshModel } from 'shoelace/utils/query-params';
import type StoreService from '@ember-data/store';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';
import type RouterService from '@ember/routing/router';
import SessionService from 'shoelace/services/-session';

export interface RouteModel {
  accountVerifications: ArrayProxy<AccountVerification>;
}

export interface RouteParams {
  page: string;
  size: string;
  sort: string;
}

class AdminAccountVerificationsApprovedRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare router: RouterService; 

  queryParams = {
    page: refreshModel,
    size: refreshModel,
    sort: refreshModel,
  };

  async model({ page: number, size, sort }: RouteParams): Promise<RouteModel> {

    const customer = await this.session.get('customer');

    if (customer) {
      if (customer?.hasRbacCustomerAdmin) {
        this.router.transitionTo('store.customer', customer.id);
      } 
    }
    
    const { accountVerificationStatuses } = this.modelFor(
      'admin.account-verifications'
    ) as ParentRouteModel;

    const accountVerificationStatus = accountVerificationStatuses.findBy(
      'tag',
      'approved'
    );

    const accountVerifications = await this.store.query(
      'account-verification',
      {
        filter: {
          'account-verification-status': accountVerificationStatus?.get('id'),
        },
        include: ['customer', 'customer.account'].join(','),
        page: {
          number,
          size,
        },
        sort,
      }
    );

    return {
      accountVerifications,
    };
  }
}

export default instrumentRoutePerformance(
  AdminAccountVerificationsApprovedRoute
);
