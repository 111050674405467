// number enums
if (false) {
  const dictionary = {
    PAYMENT_METHOD: {
      WILL_CALL: 1,
      ACCOUNT: 2,
      CREDIT_CARD: 3,
    },
    DELIVERY_METHOD: {
      WILL_CALL: 1,
      SUMMIT_TRUCK: 2,
      GROUND: 3,
      SECOND_DAY: 4,
      OVERNIGHT: 5,
    },
    KIND: {
      SAPORDER: 1,
      SAPQUOTE: 2,
      PUNCHOUT: 3,
      FIELDREQUEST: 4,
      QUOTETOORDER: 7,
      QUOTETOPUNCHOUT: 8,
    },
  };
}

const dictionary = {
  PAYMENT_METHOD: {
    WILL_CALL: 'will_call',
    ACCOUNT: 'business_account',
    CREDIT_CARD: 'credit_card',
  },
  DELIVERY_METHOD: {
    WILL_CALL: 'will_call',
    SUMMIT_TRUCK: 'summit_truck',
    GROUND: 'ground',
    SECOND_DAY: 'second_day',
    OVERNIGHT: 'overnight',
  },
  KIND: {
    SAPORDER: 'order',
    SAPQUOTE: 'quote',
    PUNCHOUT: 'punchout',
    FIELDREQUEST: 'field_request',
    SAPCOUNTERORDER: 'counter_order',
    QUOTETOORDER: 'quote_to_order',
    QUOTETOPUNCHOUT: 'quote_to_punchout',
  },
};

const labels = {
  PAYMENT_METHOD: {
    [dictionary.PAYMENT_METHOD.WILL_CALL]: 'Will Call',
    [dictionary.PAYMENT_METHOD.ACCOUNT]: 'Bill to Account',
    [dictionary.PAYMENT_METHOD.CREDIT_CARD]: 'Credit Card',
  },
  DELIVERY_METHOD: {
    [dictionary.DELIVERY_METHOD.WILL_CALL]: 'Will Call',
    [dictionary.DELIVERY_METHOD.SUMMIT_TRUCK]: 'Summit Truck',
    [dictionary.DELIVERY_METHOD.GROUND]: 'Ground',
    [dictionary.DELIVERY_METHOD.SECOND_DAY]: '2nd Day',
    [dictionary.DELIVERY_METHOD.OVERNIGHT]: 'Overnight',
  },
  KIND: {
    [dictionary.KIND.SAPORDER]: 'ZIO - SAP Internet Order',
    [dictionary.KIND.SAPQUOTE]: 'SAP Quote',
    [dictionary.KIND.PUNCHOUT]: 'Punchout',
    [dictionary.KIND.FIELDREQUEST]: 'Field Request',
    [dictionary.KIND.SAPCOUNTERORDER]: 'ZCO - SAP Counter Order',
    [dictionary.KIND.QUOTETOORDER]: 'Quote To Order',
    [dictionary.KIND.QUOTETOPUNCHOUT]: 'Quote To Punchout',
  },
};

function getDisplayForKind(code) {
  if (code == dictionary.KIND.SAPORDER) {
    return 'sap-order';
  } else if (code == dictionary.KIND.SAPCOUNTERORDER) {
    return 'sap-order';
  } else if (code == dictionary.KIND.PUNCHOUT) {
    return 'punchout';
  } else if (code == dictionary.KIND.QUOTETOORDER) {
    return 'quote-to-order';
  } else if (code == dictionary.KIND.QUOTETOPUNCHOUT) {
    return 'quote-to-punchout';
  }
}

function getCodeForPaymentMethod(paymentMethod) {
  if (paymentMethod == 'bill-to-account') {
    return dictionary.PAYMENT_METHOD.ACCOUNT;
  } else if (paymentMethod == 'cash') {
    return dictionary.PAYMENT_METHOD.WILL_CALL;
  } else if (paymentMethod == 'credit-card') {
    return dictionary.PAYMENT_METHOD.CREDIT_CARD;
  } else {
    throw new Error(`Undefined payment method ${paymentMethod}`);
  }
}

function getCodeForDeliveryMethod(deliveryMethod) {
  if (deliveryMethod == 'will-call') {
    return dictionary.DELIVERY_METHOD.WILL_CALL;
  } else if (deliveryMethod == 'summit-truck') {
    return dictionary.DELIVERY_METHOD.SUMMIT_TRUCK;
  } else if (deliveryMethod == 'ground') {
    return dictionary.DELIVERY_METHOD.GROUND;
  } else if (deliveryMethod == '2nd-day') {
    return dictionary.DELIVERY_METHOD.SECOND_DAY;
  } else if (deliveryMethod == 'overnight') {
    return dictionary.DELIVERY_METHOD.OVERNIGHT;
  } else {
    throw new Error(`Undefined delivery method ${deliveryMethod}`);
  }
}

export default dictionary;

export {
  getDisplayForKind,
  getCodeForPaymentMethod,
  getCodeForDeliveryMethod,
  labels,
};
