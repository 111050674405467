import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';

export function htmlSafeHelper(params) {
  const [value] = params;

  return htmlSafe(value);
}

export default helper(htmlSafeHelper);
