import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ManufacturerModel from 'shoelace/models/manufacturer';
import type ServiceCenter from 'shoelace/services/service-center';
import { refreshModel } from '../../utils/query-params';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';
import ArrayProxy from '@ember/array/proxy';

export interface StoreManufacturersRouteParams {
  service_centers?: string | undefined;
  entire_query?: boolean | undefined;
  prevbuy?: boolean | undefined;
}
export interface StoreProductsRouteModel {
  manufacturers: ArrayProxy<ManufacturerModel>;
  manufactures_list: any;
}

class ManufacturersRoute extends Route {
  @service declare serviceCenter: ServiceCenter;
  @service declare store: StoreService;
  @service router: any;
  
  queryParams = {
    service_centers: refreshModel,
    entire_query: refreshModel,
    prevbuy: refreshModel,
  };

  async model({
    service_centers,
    entire_query,
    prevbuy,
  }: StoreManufacturersRouteParams): Promise<StoreProductsRouteModel> {
  

    fetch('https://summit.com/api/indexnow', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        "urlEndPoint": this.router.currentURL,
      })
    })

    
    if (service_centers === '') {
      service_centers = this.serviceCenter?.id?.toString();
    }

    const params = {
      entire_query,
      service_centers,
      prevbuy,
    };

    const manufacturers = await this.store.query('manufacturer', params);

    const manufactures_letters = await manufacturers
      .filter((m) => parseInt(m.id) >= 1343)
      .filter((m) => m.name)
      .map((m: any) => (m.name[0] || '').toUpperCase())
      .filter((l, ix, all) => all.indexOf(l) === ix)
      .sort();

    const manufactures_list = await manufactures_letters.map((l) => {
      return {
        section: l,
        list: manufacturers
          .filter((m) => parseInt(m.id) >= 1343)
          .filter((m: any) => (m.name[0] || '').toUpperCase() == l)
          .map((m) => {
            return {
              mfrName: m.name?.toLowerCase().replace(/[^\w\s]/gi, '').replace('  ', ' ').replace(/\s+/g, '-'),
              name: m.name,
              m: m,
            };
          })
          .sort((a: any, b: any) => a.name.localeCompare(b.name)),
      };
    });

    return {
      manufacturers,
      manufactures_list,
    };
  }
}
export default instrumentRoutePerformance(ManufacturersRoute);
