import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type ProcurementService from 'shoelace/services/procurement';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface SecureCheckoutErrorRouteModel {
  id?: number | string;
}

export interface SecureCheckoutErrorRouteParams {
  id?: number | string;
}

class SecureCheckoutErrorRoute extends Route {
  @service declare procurement: ProcurementService;

  async beforeModel(transition: any) {
    try {
      const error = new Error(transition);
    } catch (error) {}
  }

  async model({
    id,
  }: SecureCheckoutErrorRouteParams): Promise<SecureCheckoutErrorRouteModel> {
    this.procurement.procurement = undefined;

    await this.procurement.createRecord();

    return {
      id,
    };
  }
}
export default instrumentRoutePerformance(SecureCheckoutErrorRoute);
