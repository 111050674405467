import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type CustomerModel from 'shoelace/models/customer';
import type AccountModel from 'shoelace/models/account';

export default class CustomerNoteModel extends Model {
  @attr('string')
  declare note?: string;

  @attr('date')
  declare createdAt?: Date;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('creator')
  creator!: AsyncBelongsTo<CustomerModel>;

  @belongsTo('customer')
  customer!: AsyncBelongsTo<CustomerModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'customer-note': CustomerNoteModel;
  }
}
