import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountHistoryInvoiceLineItemModel from 'shoelace/models/account-history-invoice-line-item';

export default class AccountHistoryInvoiceLineItemCommentModel extends Model {
  @attr('string') body!: null | string;

  @belongsTo('account-history-invoice-line-item')
  accountHistoryInvoiceLineItem!: AsyncBelongsTo<AccountHistoryInvoiceLineItemModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-invoice-line-item-comment': AccountHistoryInvoiceLineItemCommentModel;
  }
}
