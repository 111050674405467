import Helper from '@ember/component/helper';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Helper.extend({
  session: service(),

  compute() {
    return get(this, 'session.isAuthenticated');
  },
});
