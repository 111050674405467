import StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type { BootOptions } from 'shoelace/services/coordinator';
import { dasherize } from '@ember/string';

const MAX_CACHE_AGE_SECONDS = 60;
const ALLOWED_MODELS: string[] = [
  // 'account-history-invoice',
  // 'account-history-order',
  // 'account-history-quote',
  // 'account-history-return',
  // 'account-history-statement',
  // 'account-payment',
  'cart',
  'manufacturer',
  'product-availability',
  'product-part-number',
  'product-price',
];

const DATE_OVERRIDES = [
  'starts-at',
  'ends-at',
  'start-date',
  'end-date',
  'created-at',
  'updated-at',
];

interface CacheStore {
  model: string;
  cache: Map<string, any>;
}

export default class CacheService extends StoreService {
  @tracked caches: CacheStore[] = [];

  async onCoordinatorSetup(options?: BootOptions): Promise<void> {
    this.caches = ALLOWED_MODELS.map(model => {
      return {
        model,
        cache: new Map(),
      };
    });
  }

  async onCoordinatorTeardown(): Promise<void> {
    this.caches.map(cache => {
      cache.cache.clear?.();
    });
  }

  // @override
  query() {
    const model = arguments[0];
    const key = JSON.stringify([...arguments]);

    if (this.isModelAllowed(model)) {
      const result = this.getValue(model, key) ?? super.query(...arguments);

      this.setValue(model, key, result);

      this.validateValue(model, key, result);

      return result;
    }

    return super.query(...arguments);
  }

  generateKey(model: string, params: any): string {
    DATE_OVERRIDES.forEach(dateKey => {
      if (params['filter']?.[dateKey]) {
        const date = new Date(params['filter'][dateKey]);

        date.setSeconds(0);
        date.setMilliseconds(0);

        params['filter'][dateKey] = date;
      }
    });

    return JSON.stringify(params);
  }

  getValue(model: string, key: string): any {
    const cache = this.getCacheStore(model);

    if (this.isValueExpired(model, key) || !this.isValueValid(model, key)) {
      cache?.delete(key);
    }

    return cache?.get(key);
  }

  setValue(model: string, key: string, value: any): void {
    const cache = this.getCacheStore(model);

    value['_cachedAt'] = new Date();
    value['_source'] = 'cache';
    value['_isValid'] = false;

    cache?.set(key, value);
  }

  async validateValue(model: string, key: string, value: any): void {
    const count = (await value)?.data?.length ?? (await value)?.content?.length;
    const hasMeta = (await value)?.meta?.records;

    const isValid = count != undefined || hasMeta != undefined;

    if (!isValid) {
      this.removeValue(model, key);
    } else {
      const cache = this.getCacheStore(model);
      const cacheVal = cache?.get(key);

      if (cacheVal) {
        cacheVal['_isValid'] = true;
        cacheVal?._result?.data?.forEach((item: any) => {
          Object.entries(item).forEach(([jsonApiKey, jsonApiValue]) => {
            if (typeof jsonApiValue === 'object') {
              Object.entries(jsonApiValue).forEach(([modelKey, modelValue]) => {
                const dasherizedKey = dasherize(modelKey);


                if (modelKey !== dasherizedKey) {
                  item[jsonApiKey][dasherizedKey] = modelValue;
                  delete item[jsonApiKey][modelKey];
                }
              });
            }
          });
        });

        return;
      }
    }
  }

  removeValue(model: string, key: string): void {
    this.getCacheStore(model)?.delete(key);
  }

  isModelAllowed(model: string): boolean {
    return ALLOWED_MODELS.includes(model);
  }

  isValueExpired(model: string, key: string): boolean {
    const cachedAt = this.getCacheStore(model)?.get(key)?._cachedAt;

    const then = cachedAt?.getTime() || 0;
    const now = new Date().getTime();

    const diff = (now - then) / 1000;

    return diff > MAX_CACHE_AGE_SECONDS;
  }

  isValueValid(model: string, key: string): boolean {
    return this.getCacheStore(model)?.get(key)?._isValid ?? false;
  }

  getCacheStore(model: string): Map<string, any> | undefined {
    const cache = this.caches.find(cache => cache.model === model)?.cache;

    return cache;
  }

  clearCacheStore(model: string): void {
    this.getCacheStore(model)?.clear?.();
  }
}

declare module '@ember/service' {
  interface Registry {
    cache: CacheService;
  }
}
