import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class AdminAccountVerificationsNotApprovedController extends Controller {
  queryParams = ['page', 'size', 'sort'];

  @tracked page = 1;
  @tracked size = 10;
  @tracked sort = '-updated-at';
}

declare module '@ember/controller' {
  interface Registry {
    'admin/account-verifications/not-approved': AdminAccountVerificationsNotApprovedController;
  }
}
