import Model from '@ember-data/model';
import { attr } from '@ember-data/model';
import {
  validator,
  buildValidations,
} from '@summit-electric-supply/ember-cp-validations';

const Validations = buildValidations({
  first_name: [
    validator('presence', {
      presence: true,
      message: 'Please enter your first name.',
    }),
  ],
  last_name: [
    validator('presence', {
      presence: true,
      message: 'Please enter your last name.',
    }),
  ],
  email: [
    validator('presence', true),
    validator('format', {
      type: 'email',
      allowNonTld: false,
    }),
  ],
  password: [
    validator('presence', {
      presence: true,
      message: 'Password must not be blank.',
    }),
    validator('format', {
      regex: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-ZÀ-ȕa-z0-9]).{8,72}$/,
      message:
        'Password should have 8 characters with a minimum: one uppercase, one lowercase, and one special character.',
    }),
  ],
  passwordConfirmation: [
    validator('presence', true),
    validator('confirmation', {
      on: 'password',
      message: 'Passwords do not match.',
    }),
  ],
});

export default Model.extend(Validations, {
  first_name: attr('string'),
  last_name: attr('string'),
  email: attr('string'),
  password: attr('string'),
  passwordConfirmation: attr('string'),
});
