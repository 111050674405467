import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import type AccountHistoryReturnModel from 'shoelace/models/account-history-return';

export default class AccountHistoryReturnDeliveryModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('string') kind!: null | string;
  @attr('number') number!: null | number;

  @belongsTo('account-history-return')
  accountHistoryReturn!: AsyncBelongsTo<AccountHistoryReturnModel>;

  @computed('kind', 'number')
  get url(): string {
    return `/api/documents/${this.number}.${this.kind}`;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-return-delivery': AccountHistoryReturnDeliveryModel;
  }
}
