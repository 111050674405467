import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountHistoryQuoteLineItemModel from 'shoelace/models/account-history-quote-line-item';

export default class AccountHistoryQuoteLineItemCommentModel extends Model {
  @attr('string') body!: null | string;

  @belongsTo('account-history-quote-line-item')
  accountHistoryQuoteLineItem!: AsyncBelongsTo<AccountHistoryQuoteLineItemModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-quote-line-item-comment': AccountHistoryQuoteLineItemCommentModel;
  }
}
