import ApplicationSerializer, { exclude } from './application';

export default class ProcurementSerializer extends ApplicationSerializer {
  attrs = {
    updatedAt: exclude,
    errorKind: exclude,
    externalId: exclude,
    externalResult: exclude,
    procurementState: exclude,
    salesTax: exclude,
    total: exclude,
    isCustomerCashTerm: exclude,
  };
}
