import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Account from 'shoelace/models/account';

export default class AdminManageAccountController extends Controller {
  queryParams = ['page', 'size', 'sort'];

  @tracked page = 1;
  @tracked size = 10;
  @tracked sort = 'id';

  @action async onPushImage(model: Account, id: string): Promise<void> {
    model.set('imageSignedId', id);
    await model.save();
  }

  @action async onPopImage(model: Account): Promise<void> {
    model.set('imageSignedId', null);
    await model.save();
    window?.location?.reload?.();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/manage-account': AdminManageAccountController;
  }
}
