import { later } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import type AccountModel from 'shoelace/models/account';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type CustomerModel from 'shoelace/models/customer';
import type AccountService from 'shoelace/services/account';
import type AccountJobAccountService from 'shoelace/services/account-job-account';
import type AccountJobAccountsService from 'shoelace/services/account-job-accounts';
import type CatalogService from 'shoelace/services/catalog';
import type CacheService from 'shoelace/services/cache';
import type GoogleAnalyticsService from 'shoelace/services/google-analytics';
import type MetricsService from 'shoelace/services/metrics';
import type KartService from 'shoelace/services/kart';
import type NotificationService from 'shoelace/services/notifications';
import type ProcurementService from 'shoelace/services/procurement';
import type PushService from 'shoelace/services/push';
import type ServiceCenterService from 'shoelace/services/service-center';
import type ServiceCentersService from 'shoelace/services/service-centers';
import type SessionService from 'shoelace/services/session';
import type TranslationsService from 'shoelace/services/translations';

export interface BootOptions {
  account?: AccountModel;
  accountJobAccount?: AccountJobAccountModel;
  accountJobAccounts?: AccountJobAccountModel[];
  customer?: CustomerModel;
}

export default class CoordinatorService extends Service {
@service declare account: AccountService;
@service declare accountJobAccount: AccountJobAccountService;
@service declare accountJobAccounts: AccountJobAccountsService;
@service declare catalog: CatalogService;
@service declare cache: CacheService;
@service declare googleAnalytics: GoogleAnalyticsService;
@service declare kart: KartService;
@service declare notifications: NotificationService;
@service declare procurement: ProcurementService;
@service declare push: PushService;
@service declare serviceCenter: ServiceCenterService;
@service declare serviceCenters: ServiceCentersService;
@service declare session: SessionService;
@service declare translations: TranslationsService;
@service declare metrics: MetricsService;

async setup(options?: BootOptions): Promise<void> {
  await this.cache.onCoordinatorSetup(options);
  await this.notifications.onCoordinatorSetup(options);
  await this.session.onCoordinatorSetup(options);
  await this.account.onCoordinatorSetup(options);
  await this.accountJobAccount.onCoordinatorSetup(options);
  await this.accountJobAccounts.onCoordinatorSetup(options);
  await this.serviceCenters.onCoordinatorSetup(options);
  await this.serviceCenter.onCoordinatorSetup(options);
  await this.procurement.onCoordinatorSetup(options);
  await this.translations.onCoordinatorSetup(options);
  await this.kart.onCoordinatorSetup(options);

  // Do not block page waiting for non critical services to load
  later(() => this.catalog.onCoordinatorSetup(options), 100);
  later(() => this.googleAnalytics.onCoordinatorSetup(options), 100);
  later(() => this.metrics.onCoordinatorSetup(options), 100);
  later(() => this.push.onCoordinatorSetup(options), 100);
}

async teardown(): Promise<void> {
  await this.cache.onCoordinatorTeardown();
  await this.push.onCoordinatorTeardown();
  await this.metrics.onCoordinatorTeardown();
  await this.googleAnalytics.onCoordinatorTeardown();
  await this.catalog.onCoordinatorTeardown();
  await this.kart.onCoordinatorTeardown();
  await this.translations.onCoordinatorTeardown();
  await this.procurement.onCoordinatorTeardown();
  await this.serviceCenter.onCoordinatorTeardown();
  await this.serviceCenters.onCoordinatorTeardown();
  await this.accountJobAccounts.onCoordinatorTeardown();
  await this.accountJobAccount.onCoordinatorTeardown();
  await this.account.onCoordinatorTeardown();
  await this.session.onCoordinatorTeardown();
  await this.notifications.onCoordinatorTeardown();
}
}

declare module '@ember/service' {
interface Registry {
  coordinator: CoordinatorService;
}
}
