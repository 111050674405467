class EasingEquations {
  static easeOutSine(pos) {
    return Math.sin(pos * (Math.PI / 2));
  }

  static easeInOutSine(pos) {
    return -0.5 * (Math.cos(Math.PI * pos) - 1);
  }

  static easeInOutQuint(pos) {
    if ((pos /= 0.5) < 1) {
      return 0.5 * Math.pow(pos, 5);
    }

    return 0.5 * (Math.pow(pos - 2, 5) + 2);
  }
}

/**
 * Scroll to the top of the page. Ported from https://stackoverflow.com/a/26808520.
 *
 * @param {number} scrollTargetY
 * @param {number} speed
 * @param {'easeOutSine'|'easeInOutSine'|'easeInOutQuint'} easing
 */
export function scrollToTop(
  scrollTargetY = 0,
  speed = 1000,
  easing = 'easeInOutQuint',
) {
  const scrollY = window.scrollY || document.documentElement.scrollTop;
  let currentTime = 0;

  const time = Math.max(
    0.1,
    Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8),
  );

  function renderFrame() {
    currentTime += 1 / 60;

    const p = currentTime / time;
    const t = EasingEquations[easing](p);

    if (p < 1) {
      window.requestAnimationFrame(renderFrame);
      window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t);
    } else {
      window.scrollTo(0, scrollTargetY);
    }
  }

  window.requestAnimationFrame(renderFrame);
}

/**
 * Get the offset of an element in the current window view.
 *
 * @param {Element} element the target element
 * @returns { { top: number, left: number} } an object with a top and left number properties
 */
export function offset(element) {
  if (!element.getClientRects().length) {
    return { top: 0, left: 0 };
  }

  const rect = element.getBoundingClientRect();
  const window = element.ownerDocument.defaultView;

  return {
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset,
  };
}
