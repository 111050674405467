import Model, { attr } from '@ember-data/model';

export default class UnlockModel extends Model.extend() {
  @attr('string') unlockToken?: string;
  @attr('string') resetToken?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    unlock: UnlockModel;
  }
}
