import type StoreService from '@ember-data/store';
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import AccountModel from 'shoelace/models/account';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type AccountService from 'shoelace/services/account';
import type { BootOptions } from 'shoelace/services/coordinator';

export default class AccountJobAccountsService extends Service {
@service declare account: AccountService;
@service declare store: StoreService;

  @tracked accountJobAccounts?: AccountJobAccountModel[];

  async onCoordinatorSetup(options?: BootOptions): Promise<void> {
    if (options?.accountJobAccounts) {
      this.accountJobAccounts = options.accountJobAccounts;
    } else {
      const account = options?.account ?? this.account.account;

      if (account) {
        await this.loadAccountJobAccounts(account);
      }
    }
  }

  async onCoordinatorTeardown(): Promise<void> {
    this.accountJobAccounts = undefined;
  }

  @action async loadAccountJobAccounts(account: AccountModel): Promise<void> {
    const someAccount = account ?? this.account.account;

    if (someAccount) {
      const accountJobAccounts = await this.store.query('account-job-account', {
        filter: {
          account: someAccount?.get('id'),
          is_active: true,
        },
      });

      this.accountJobAccounts = accountJobAccounts.toArray();
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'account-job-accounts': AccountJobAccountsService;
  }
}
