import { decamelize, underscore } from '@ember/string';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { pluralize } from 'ember-inflector';
import RESTAdapter from '@ember-data/adapter/rest';
import TokenAuthorizerMixin from 'ember-simple-auth-token/mixins/token-authorizer';

export default class RestApiAdapter extends RESTAdapter.extend(
  TokenAuthorizerMixin,
) {
  namespace = 'store';
  useFetch = true;

  get headers() {
    const headers = {};
    const token = get(this.session.data.authenticated, this.tokenPropertyName);

    if (this.session.isAuthenticated && !isEmpty(token)) {
      headers[this.authorizationHeaderName] = this.authorizationPrefix + token;
    }

    return headers;
  }

  shouldReloadAll() {
    return true;
  }

  pathForType(type) {
    try {
      return pluralize(underscore(decamelize(type)));
    } catch (error) {
      return type || '';
    }
  }
}
