import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { notEmpty } from '@ember/object/computed';
import type ProductModel from 'shoelace/models/product';
import type ServiceCenterModel from 'shoelace/models/service-center';

export default class StockStatusModel extends Model {
  @attr('boolean') stocked!: null | boolean;
  @attr('number') eodQty!: null | number;
  @attr('number') product_id!: null | number;
  
  @belongsTo('product')
  product!: AsyncBelongsTo<ProductModel>;

  @belongsTo('service-center')
  serviceCenter!: AsyncBelongsTo<ServiceCenterModel>;

  hasServiceCenter = notEmpty('serviceCenter.code');
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'stock-status': StockStatusModel;
  }
}
