import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class ManufacturersController extends Controller {
  queryParams = [
    'entire_query',
    'service_centers',
    'prevbuy'
  ];

  @tracked prevbuy = false;
  @tracked service_centers = '';
  @tracked entire_query = false;
}
declare module '@ember/controller' {
  interface Registry {
    manufacturers: ManufacturersController;
  }
}
