import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';

export default class ProductPriceComponent extends Component {
  @service store;
  @service session;
  @service fastboot;
  @service serviceCenter;
  @service account;
  @service accountJobAccount;
  @service cache;

  @tracked product = this.args.product ?? null;
  @tracked model = this.args.model ?? null;
  @tracked productId = this.args.productId ?? this.args.product?.id ?? null;
  @tracked modalPricingContact = this.args.modalPricingContact ?? false;
  @tracked sharedHasAvailability = this.args.sharedHasAvailability ?? true;

  @computed('sharedHasAvailability')
  get isAlternateQuery() {
    return !this.sharedHasAvailability;
  }

  @computed('for-product')
  get productRecord() {
    const product = this['for-product'];
    return product;
  }

  @computed(
    'account.account.id',
    'accountJobAccount.accountJobAccount.id',
    'args.{product,productId}',
    'isDestroyed',
    'isDestroying',
    'product',
    'productId',
    'serviceCenter.serviceCenter.id',
    'store',
  )
  get model2() {
    const options = {};
    const accountId = this.account.account?.id;
    const accountJobAccountId = this.accountJobAccount.accountJobAccount?.id;
    const product = this.product;
    const productId = this.productId;
    const serviceCenterId = this.serviceCenter.serviceCenter?.id;

    if (accountId) {
      options['account-id'] = accountId;
    }

    if (accountJobAccountId) {
      options['account-job-account-id'] = accountJobAccountId;
    }

    if (productId) {
      options['product-id'] = productId;
    }

    if (serviceCenterId) {
      options['service-center-id'] = serviceCenterId;
    }

    //TODO figure this out
    if (Object.keys(options).length === 0) {
      return new RSVP.Promise();
    } else {
      return this.cache
        .query('product-price', { filter: options })
        .then(models => {
          if (!this.isDestroyed || !this.isDestroying) {
            this.model = models.get('firstObject');
          }
        });
    }
  }
}
