import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type RbacPermissionModel from 'shoelace/models/rbac-permission';
import type RbacRoleModel from 'shoelace/models/rbac-role';

export default class RbacPermissionRoleModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('date') updatedAt!: null | Date;

  @belongsTo('rbac-permission')
  rbacPermission!: AsyncBelongsTo<RbacPermissionModel>;

  @belongsTo('rbac-role')
  rbacRole!: AsyncBelongsTo<RbacRoleModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'rbac-permission-role': RbacPermissionRoleModel;
  }
}
