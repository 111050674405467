import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (or (has-block \"actions\") (has-block))}}\n  <form\n    class=\"flex flex-col flex-wrap space-y-2\"\n    novalidate=\"novalidate\"\n    ...attributes\n    {{on \"submit\" this.onSubmit}}\n    {{on \"reset\" this.onReset}}\n  >\n    {{#if (has-block)}}\n      {{yield this}}\n    {{/if}}\n    {{#if (has-block \"actions\")}}\n      <div class=\"flex-no-wrap flex-row flex justify-end min-w-full space-x-2\">\n        {{yield this to=\"actions\"}}\n      </div>\n    {{/if}}\n  </form>\n{{/if}}", {"contents":"{{#if (or (has-block \"actions\") (has-block))}}\n  <form\n    class=\"flex flex-col flex-wrap space-y-2\"\n    novalidate=\"novalidate\"\n    ...attributes\n    {{on \"submit\" this.onSubmit}}\n    {{on \"reset\" this.onReset}}\n  >\n    {{#if (has-block)}}\n      {{yield this}}\n    {{/if}}\n    {{#if (has-block \"actions\")}}\n      <div class=\"flex-no-wrap flex-row flex justify-end min-w-full space-x-2\">\n        {{yield this to=\"actions\"}}\n      </div>\n    {{/if}}\n  </form>\n{{/if}}","moduleName":"shoelace/components/block/form.hbs","parseOptions":{"srcName":"shoelace/components/block/form.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface BlockFormArgs {
  isResetting?: boolean;
  isSubmitting?: boolean;
  didSubmit?: boolean;
  onError?: (error?: Error) => Promise<void>;
  onReset?: (event?: Event) => Promise<void>;
  onSubmit?: (event?: Event) => Promise<void>;
}

export default class BlockForm extends Component<BlockFormArgs> {
  @tracked isResetting: boolean = this.args.isResetting ?? false;
  @tracked isSubmitting: boolean = this.args.isSubmitting ?? false;
  @tracked didSubmit: boolean = this.args.didSubmit ?? false;

  @action async onError(error?: Error): Promise<void> {
    try {
      await this.args?.onError?.(error);
    } catch (error) {
      error;
    }
  }

  @action async onReset(event?: Event): Promise<void> {
    try {
      this.isResetting = true;
      this.didSubmit = false;

      await this.args?.onReset?.(event);
    } catch (error) {
      await this.onError(error);
    } finally {
      this.isResetting = false;
    }
  }

  @action async onSubmit(event?: Event): Promise<void> {
    event?.preventDefault();
    event?.stopPropagation();

    try {
      this.isSubmitting = true;
      this.didSubmit = true;

      await this.args?.onSubmit?.(event);
    } catch (error) {
      await this.onError(error);
    } finally {
      this.isSubmitting = false;
    }
  }
}
