import type RouterService from '@ember/routing/router';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import CartQueryModel from 'shoelace/models/cart-query';
import type ServiceCenterService from 'shoelace/services/service-center';
import type SessionService from 'shoelace/services/session';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface RouteModel {
  cartQuery: CartQueryModel;
}

class StoreNewQuoteStoreNewQuote extends Route {
  @service declare serviceCenter: ServiceCenterService;
  @service declare session: SessionService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  async model(): Promise<RouteModel> {
    const customer = await this.session.get('customer');
    let cartQuery;

    if (customer) {
      cartQuery = this.store.createRecord('cart-query', {
        email: customer?.get('email'),
        customer_id: customer?.get('id'),
        account_id: customer?.account?.get('id'),
        phone: customer?.get('cellPhone'),
        name: customer?.get('name'),
        company: customer?.account?.get('name'),
        location: customer?.get('city'),
        state: customer?.get('state'),
        serviceCenter: this.serviceCenter.id,
      });
    } else {
      cartQuery = this.store.createRecord('cart-query', {});
    }

    return {
      cartQuery,
    };
  }
}

export default instrumentRoutePerformance(StoreNewQuoteStoreNewQuote);
