import type { AsyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany } from '@ember-data/model';
import type RbacAccountCustomerPermissionModel from 'shoelace/models/rbac-account-customer-permission';
import type RbacAccountPermissionModel from 'shoelace/models/rbac-account-permission';
import type RbacCustomerPermissionModel from 'shoelace/models/rbac-customer-permission';
import type RbacPermissionRoleModel from 'shoelace/models/rbac-permission-role';

export default class RbacPermissionModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('date') updatedAt!: null | Date;
  @attr('string') name!: null | string;
  @attr('string') route_name!: null | string;

  @hasMany('rbac-permission-role')
  rbacPermissionRoles!: AsyncHasMany<RbacPermissionRoleModel>;

  @hasMany('rbac-customer-permission')
  rbacCustomerPermissions!: AsyncHasMany<RbacCustomerPermissionModel>;

  @hasMany('rbac-account-permission')
  rbacAccountPermissions!: AsyncHasMany<RbacAccountPermissionModel>;

  @hasMany('rbac-account-customer-permission')
  rbacAccountCustomerPermissions!: AsyncHasMany<RbacAccountCustomerPermissionModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'rbac-permission': RbacPermissionModel;
  }
}
