import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import ProductModel from 'shoelace/models/product';
import { action } from '@ember/object';
//import type RollbarService from 'shoelace/services/rollbar';
import { inject as service } from '@ember/service';
import { EXCEPTION_1048 } from 'shoelace/exceptions/mod';
import CartQueryModel from 'shoelace/models/cart-query';
import ObjectProxy from '@ember/object/proxy';
import CartQuoteListModel from 'shoelace/models/cart-quote-list';
import StoreService from '@ember-data/store';

interface SelectedItem {
  quantity: string;
  product: ProductModel
}

export default class ProductsController extends Controller {
  @service declare store: StoreService;

  queryParams = [
    'search',
    'manufacturers',
    'classification',
    'sub_class',
    'sub_class1',
    'entire_query',
    'promotion',
    'service_centers',
    'catalog',
    'sizes_values',
    'prevbuy',
    'attributes',
    'page',
    'pageSize',
  ];

  //@service declare rollbar: RollbarService;

  @tracked page = 1;
  @tracked pageSize = 12;
  @tracked search = '';
  @tracked search_within = '';
  @tracked manufacturers = '';
  @tracked classification = '';
  @tracked sub_class = '';
  @tracked sub_class1 = '';
  @tracked entire_query = false;
  @tracked promotion = false;
  @tracked attributes: [] = [];
  @tracked sizes_values = '';
  @tracked productToCompare: ProductModel[] = [];
  @tracked productToSelect: SelectedItem[] = [];
  @tracked prevbuy = false;
  @tracked catalog = undefined;
  @tracked service_centers = '';
  @tracked showGrid = false;
  @tracked isModalOpen = false;

  @action async onRequestQuote(
    cartQuery: CartQueryModel & ObjectProxy<CartQueryModel>,
    cart: CartQuoteListModel & ObjectProxy<CartQuoteListModel>,
  ): Promise<void> {
    await cartQuery.validate();
    const isValid = cartQuery.get('validations.isValid');

    if (isValid && this.productToSelect.length > 0) {
      if (Array.isArray(this.productToSelect)) {
        this.productToSelect.forEach(async item => {
          const cartItem = this.store.createRecord('line-item-quote', {})
          cartItem.set('cart_quote_list_id', cart.id);
          cartItem.set('product_id', item.product.id);
          cartItem.set('quantity', item.quantity);
          await cartItem.save();
        });
      }

      cartQuery.set('cartQuoteListId', cart.id);
      cartQuery.set('source', 'blowout');

      await cartQuery.save();
      this.productToSelect = [];
      this.isModalOpen = false;
    }
  }

  @action async selectProductToCompare(product: ProductModel): Promise<void> {
    try {
      if (this.productToCompare.includes(product)) {
        this.productToCompare.removeObject(product);
      } else {
        if (this.productToCompare.length > 5) {
          return;
        }
        this.productToCompare.pushObject(product);
      }
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1048, error);
      throw error;
    }
  }

  @action async selectProductToSelect(product: ProductModel, quantity: string): Promise<void> {
    try {
      const newSelectedObject: SelectedItem = {
        product: product,
        quantity: quantity,
      };
      if (this.productToSelect.length > 0) {
        if (
          this.productToSelect.some(
            attribute =>
              attribute.product.id === product.id &&
              quantity === "0",
          )
        ) {
          this.productToSelect.forEach(attr => {
            if (attr.product.id === product.id && quantity === "0") {
              this.productToSelect.removeObject(attr);
            }
          });
        } else if (this.productToSelect.some(
          attribute =>
            attribute.product.id === product.id
        )
        ) {
          this.productToSelect.forEach(attr => {
            if (attr.product.id === product.id && attr.quantity != quantity) {
              this.productToSelect.removeObject(attr);
              this.productToSelect.pushObject(newSelectedObject);
            }
          });
        } else {
          this.productToSelect.pushObject(newSelectedObject);
        }
      } else {
        this.productToSelect.pushObject(newSelectedObject);
      }
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1048, error);
      throw error;
    }
  }

  @action async onCloseCompare(): Promise<void> {
    try {
      this.productToCompare = [];
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1048, error);
      throw error;
    }
  }

  @action async onCloseSelect(cart: CartQuoteListModel): Promise<void> {
    try {
      this.productToSelect = [];
      this.isModalOpen = false;

      const cartQuoteList = await this.store.query('line-item-quote', {
        filter: { cart_quote_list_id: cart.id },
      })
      cartQuoteList.forEach(async (cartQuote) => {
        const recordQuote = await this.store.findRecord('line-item-quote', cartQuote.id);
        await recordQuote.destroyRecord();
      });
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1048, error);
      throw error;
    }
  }

  @action onShowGrid() {
    if (this.showGrid) {
      this.showGrid = false;
    } else {
      this.showGrid = true;
    }
  }
}
declare module '@ember/controller' {
  interface Registry {
    products: ProductsController;
  }
}
