import Model from '@ember-data/model';
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { belongsTo } from 'ember-data/relationships';
import { isBlank } from '@ember/utils';

export default Model.extend({
  statement_date: attr('date'),
  contract_name: attr('string'),
  pdf: attr('string'),

  account: belongsTo('account', { async: false }),
  job_account: belongsTo('account-job-account', { async: true }),

  contractName: computed('job_account.label', function () {
    if (isBlank(this.job_account)) {
      return 'Shop Account';
    } else {
      return this.get('job_account.label');
    }
  }),
});
