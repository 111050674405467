import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class StoreServicesMyProjectsReleasesAssignedToMeController extends Controller {
  queryParams = ['page', 'project', 'search', 'size', 'sort'];

  @tracked page = 1;
  @tracked project = null;
  @tracked size = 10;
  @tracked sort = '-account-project-release.updated-at';
  @tracked term = '';
  @tracked search = [];
}
