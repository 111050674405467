import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (or (has-block \"actions\") (has-block))}}\n  <form novalidate=\"novalidate\" ...attributes {{on \"submit\" this.submit}}>\n    {{#if (has-block)}}\n      {{yield this.validation this.submit}}\n    {{/if}}\n  </form>\n{{/if}}", {"contents":"{{#if (or (has-block \"actions\") (has-block))}}\n  <form novalidate=\"novalidate\" ...attributes {{on \"submit\" this.submit}}>\n    {{#if (has-block)}}\n      {{yield this.validation this.submit}}\n    {{/if}}\n  </form>\n{{/if}}","moduleName":"shoelace/components/validating-form.hbs","parseOptions":{"srcName":"shoelace/components/validating-form.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface ValidatingFormComponentArgs {
  model: any;
  action: any;
  method: any;
  validation: any;
  validate: any;
}

export default class ValidatingFormComponent extends Component<ValidatingFormComponentArgs> {
  @tracked model = this.args.model ?? null;
  @tracked action = this.args.action ?? null;
  @tracked method = this.args.method ?? null;
  @tracked validation = this.args.validation ?? {};
  @tracked validate = this.args.validate;

  @action async submit(event) {
    const feedback = this.model;
    const form = event.target;
    const { validates, fields } = this.validate(feedback);
    this.validation = fields;

    if (validates) {
      form.action = this.action;
      form.method = this.method;

      return await form.submit();
    } else {
      event?.preventDefault();
    }
  }
}
