import Route from '@ember/routing/route';
import { refreshModel } from 'shoelace/utils/query-params';
import { action } from '@ember/object';
import ResetPasswordModel from 'shoelace/models/reset-password';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface RouteModel {
  resetPassword: ResetPasswordModel;
}

export interface RouteParams {
  reset_password_token?: string;
}

class ResetPasswordRoute extends Route {
  @service declare store: StoreService;
  
  queryParams = {
    reset_password_token: refreshModel,
  };

  model({ reset_password_token }: RouteParams) {
    const resetPassword = this.store.createRecord('reset-password', {
      reset_password_token,
    });
    return {
      resetPassword,
    };
  }

  @action willTransition() {
    const { resetPassword: model } = this.modelFor(this.fullRouteName);

    if (model.isNew) {
      model.deleteRecord();
    }
  }
}

export default instrumentRoutePerformance(
  ResetPasswordRoute
);
