import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreBillPayExitRoute extends Route {
  @service router;

  beforeModel() {
    return this.router.transitionTo('store.balance');
  }
}
export default instrumentRoutePerformance(StoreBillPayExitRoute);
