import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { EXCEPTION_1006 } from 'shoelace/exceptions/mod';
import { storageFor } from 'ember-local-storage';

export default class PunchoutsPo2goPickPlantController extends Controller {
  queryParams = ['search'];

  @service account;
  @service serviceCenter;
  @service session;
  @service router;
  
  @storageFor('customer') storage;
  @storageFor('service-center') localStorageServiceCenter;

  @tracked scheduledTransition = '';
  @tracked isSelectingAccount = false;
  @tracked search;

  @action async selectAccount(account, transition, transitionModel) {
    try {
      this.isSelectingAccount = true;
      this.scheduledTransition = transition;

      this.localStorageServiceCenter?.set('id',account.get('serviceCenter.id'));
      this.storage?.set('name', account?.name);
      this.storage?.set('accountId', account?.id);

      await this.account.setAccount(account, this.session.customer);
      await this.serviceCenter.setById(account.get('serviceCenter.id'));

      if (transitionModel) {
        this.router.transitionTo(transition, transitionModel);
      } else {
        if (transition.includes("categories")) {
          window.location.replace(transition);
        } else {
          this.router.transitionTo(transition);
        }
      }
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1006, error);

      throw error;
    } finally {
      this.isSelectingAccount = false;
      this.scheduledTransition = null;
    }
  }
}
