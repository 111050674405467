import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type NoticeService from 'shoelace/services/notice';
import type RouterService from '@ember/routing/router';
import CustomerForgotPasswordModel from 'shoelace/models/customer-forgot-password';

export default class ForgotPasswordIndexController extends Controller {
  @service declare notice: NoticeService;
  @service declare router: RouterService;

  @tracked isValidationVisible = false;

  @action async recover(model: CustomerForgotPasswordModel) {
    await model.validate();

    if (model.validations.isValid) {
      try {
        this.isValidationVisible = false;

        await model.save();

        return this.router.transitionTo('forgot-password.confirmation');
      } catch (_) {
        this.isValidationVisible = true;

        await this.notice.error(
          `Unregistered Email`,
          `<strong>${model.email}</strong> is not a registered email address. Please check the spelling and try again.`,
        );
      }
    } else {
      this.isValidationVisible = true;
    }
  }
}
