import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AccountService from 'shoelace/services/account';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreManageAccountContractsRoute extends Route {
  @service declare store: StoreService;
  @service declare account: AccountService;

  async model() {
    const accountContracts = await this.store.query('account-contract', {
      filter: {
        'account-id': this.account?.account?.id,
      },
    });

    return {
      accountContracts,
    };
  }
}
export default instrumentRoutePerformance(
  StoreManageAccountContractsRoute
);