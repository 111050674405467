import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{did-insert this.startTimer}}\n{{will-destroy this.stopTimer}}\n\n{{yield countdown}}\n", {"contents":"{{did-insert this.startTimer}}\n{{will-destroy this.stopTimer}}\n\n{{yield countdown}}\n","moduleName":"shoelace/components/recovery/reload-if-idle.hbs","parseOptions":{"srcName":"shoelace/components/recovery/reload-if-idle.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface ApexRecoveryReloadIfIdleArgs {
  countdown: number;
}

export default class ApexRecoveryReloadIfIdle extends Component<ApexRecoveryReloadIfIdleArgs> {
  @tracked countdown = this.args.countdown ?? 30;
  @tracked timer: any;

  @action startTimer() {
    try {
      this.timer = setInterval(() => {
        this.countdown -= 1;

        if (this.countdown <= 0) {
          window?.location?.reload?.();
          clearInterval(this.timer);
        }
      }, 1000);
    } catch (error) {
      console.error('ReloadIfIdle Error: ', error);
    }
  }

  @action stopTimer() {
    clearInterval(this.timer);
  }
}
