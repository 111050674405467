import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import ProtectedRoute from '../../mixins/protected-route';
import { instrumentRoutePerformance } from '@sentry/ember';

const isValidQuote = /^2\d{9}$/;

class StoreAccountQuoteRoute extends Route {
  @service session;
  @service store;
  @service router;

  beforeModel(transition) {
    if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }
  }

  async model({ id, quoteNotFound }) {
    if (!isValidQuote.test(id)) {
      return this.router.transitionTo('store.account-quotes', {
        queryParams: { search: null },
      });
    }

    if (quoteNotFound) {
      return this.router.transitionTo('store.account-quotes', {
        queryParams: { quoteNotFound: null },
      });
    }

    try {
      const customer = this.session.customer;
      const quote = await this.store.findRecord('account-history-quote', id, {
        include: [
          'account-history-quote-bill-address',
          'account-history-quote-fund-address',
          'account-history-quote-ship-address',
          'account-history-quote-sold-address',
          'account-history-quote-comments',
          'account-history-quote-line-items',
          'account-history-quote-line-items.account-history-quote-line-item-comments',
        ].join(','),
      });

      const model = { quote, customer };

      return RSVP.hash(model);
    } catch (e) {
      return this.router.transitionTo('store.account-quotes', {
        queryParams: { quoteNotFound: id },
      });
    }
  }
}
export default instrumentRoutePerformance(StoreAccountQuoteRoute);
