import Route from '@ember/routing/route';
import { instrumentRoutePerformance } from '@sentry/ember';
import { inject as service } from '@ember/service';

const refreshModel = { refreshModel: true };

class StoreCustomersEditPasswordIndexRoute extends Route {
  @service store;
  
  queryParams = {
    token: refreshModel,
  };

  model({ token: resetPasswordToken }) {
    return this.store.createRecord('customer-reset-password', {
      resetPasswordToken,
    });
  }
}

export default instrumentRoutePerformance(StoreCustomersEditPasswordIndexRoute);
