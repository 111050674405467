import type { AsyncBelongsTo } from '@ember-data/model';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router';
import { EXCEPTION_1023 } from 'shoelace/exceptions/mod';
import type Customer from 'shoelace/models/customer';
import RbacCustomerRoleModel from 'shoelace/models/rbac-customer-role';
import type StoreService from '@ember-data/store';
import { service } from '@ember/service';
//import type RollbarService from 'shoelace/services/rollbar';

export default class AdminCustomerEditController extends Controller {
  //@service declare rollbar: RollbarService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  @action async onEditCustomer(
    customer: AsyncBelongsTo<Customer>,
   role: RbacCustomerRoleModel,
   rbacroleId: string,
  ): Promise<void> {
    try {
      await customer.save();
      if(rbacroleId){
        if(role)
        {
          await role.set('rbac_role_id', rbacroleId);
          await role.save();
        } else{
            const rbacCustomerRole = await this.store
            .createRecord('rbac-customer-role', {
              customer_id: customer.id,
              rbac_role_id: rbacroleId,
            });
      
            await rbacCustomerRole.save();
        }
      }
     
      // window?.location?.reload?.();
      this.router.transitionTo('admin.customer', customer.id);
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1023, error);

      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/customer/edit': AdminCustomerEditController;
  }
}
