import Route from '@ember/routing/route';
import { inject } from '@ember/service';
import { get } from '@ember/object';
import RSVP from 'rsvp';
import fetch from 'fetch';
import quote from 'shoelace/models/quote';
import { instrumentRoutePerformance } from '@sentry/ember';

class CheckoutPunchout extends Route {
  @inject cart;
  @inject kart;
  @inject session;
  @inject router;
  @inject store;
  @inject procurement;
  @inject metrics;

  async model({ procurement_id }) {
    const procurement = this.procurement.procurement;

    if (!procurement) {
      return this.router.transitionTo('secure-checkout.index');
    }

    const hasPunchoutPermission = await this.session.customer
      ?.hasCustomerPunchout;

    if (!hasPunchoutPermission) {
      alert('You do not have permission to use punchout for this account.');
      return;
    }

    const lineItems = this.kart.items.map((item) => {
      return {
        matnr: item.product.matnr,
        quantity: item.quantity,
        notes: item.message,
        shipToAddress: item.shipToAddress,
      };
    });

    procurement.set('lineItems', lineItems);
    procurement.set('confirmedAt', new Date());

    // submit
    const expiresAt = Date.now() + 120000;
    return procurement.save().then(async () => {
      return await new Promise((done) => {
        const worker = async () => {
          await procurement.reload();
          if (expiresAt < Date.now()) {
            throw new Error();
          } else if (procurement.get('procurementState') == 'error') {
            throw new Error();
          } else if (procurement.get('procurementState') == 'success') {
            done(procurement);
          } else {
            setTimeout(worker, 1000);
          }
        };
        worker();
      });
    });
  }
  setupController(controller, model) {
    const logPurchase = async (procurement, lineItems) => {
      try {
        if (!this.metrics?.enabled) return;
        
        const cartProducts = await Promise.all(
          procurement.cart.items.map((li) => this.store.find('product', li.id))
        );

        let items = cartProducts.map((lineItem, index) => {
          return this.metrics.productAsEcommerceItem(
            lineItem,
            procurement.cart.items[index].quantity,
            procurement.externalResult.sap_prices[index].ET_PRICE_OUTPUT[0]
              .KWERT,
            index,
            'begin_checkout'
          );
        });

        this.metrics.events.ecommerce.begin_checkout({
          currency: 'USD',
          value:  procurement.get('subtotal'),
          items: items
        });

        items = cartProducts.map((lineItem, index) => {
          return this.metrics.productAsEcommerceItem(
            lineItem,
            procurement.cart.items[index].quantity,
            procurement.externalResult.sap_prices[index].ET_PRICE_OUTPUT[0]
              .KWERT,
            index,
            'add_shipping_info'
          );
        });
        
        this.metrics.events.ecommerce.add_shipping_info({
          currency: 'USD',
          value:  procurement.get('subtotal'),
          shipping_tier: 'Ground',
          items: items
        });

        items = cartProducts.map((lineItem, index) => {
          return this.metrics.productAsEcommerceItem(
            lineItem,
            procurement.cart.items[index].quantity,
            procurement.externalResult.sap_prices[index].ET_PRICE_OUTPUT[0]
              .KWERT,
            index,
            'add_payment_info'
          );
        });

        this.metrics.events.ecommerce.add_payment_info({
          currency: 'USD',
          value:  procurement.get('subtotal'),
          payment_type: procurement?.creditCard
            ? 'Credit Card'
            : procurement?.billableToAccount
            ? 'Bill to Account'
            : 'Pay At Call',
          items: items
        });

        items = cartProducts.map((lineItem, index) => {
          return this.metrics.productAsEcommerceItem(
            lineItem,
            procurement.cart.items[index].quantity,
            procurement.externalResult.sap_prices[index].ET_PRICE_OUTPUT[0]
              .KWERT,
            index,
            'purchase'
          );
        });

        this.metrics.events.ecommerce.purchase({
          currency: 'USD',
          affiliation: 'Summit.com',
          transaction_id: procurement?.orderNumber ? procurement?.orderNumber : procurement?.id,
          value: procurement.get('subtotal'),
          items: items,
        });
      } catch (e) {
        e;
      }
    };

    logPurchase(model, model.lineItems);

    this._super(...arguments);
    if (!model) {
      alert('diagnostic code 1404');
    }
    controller.set('model', model);
    // this is the HTML returned from server-side POST
    controller.set(
      'punchout',
      model.get('externalResult')['punchout_response']
    );
    if (
      !model.get('externalResult') ||
      !model.get('externalResult')['punchout_response']
    ) {
      alert('diagnostic code 1411');
    }
    try {
      this.kart.items.forEach(async (item) => {
        await this.kart.removeItem(item.product);
      });

      (async () => {
        const customer = await this.session.customer;

        const t = Date.now();
        const guard = () => {
          if (t + 5000 < Date.now()) {
            alert('diagnostic code 1453');
            worker();
            return;
          }
          if (customer.get('hasDirtyAttributes') || customer.get('isSaving')) {
            setTimeout(guard, 250);
          } else {
            worker();
          }
        };

        const worker = () => {
          RSVP.all([
            fetch('/api/tokens/1', { method: 'DELETE' }),
            new Promise((done) => {
              setTimeout(
                () => done(this.session.invalidate('authenticator:po2go')),
                100
              );
            }),
          ]).then(() => {
            window.location.href = controller.get('punchout');
          });
        };

        guard();
      })();
    } catch (e) {
      alert('diagnostic code 1419 ' + e.toString());
    }
  }
}
export default instrumentRoutePerformance(CheckoutPunchout);
