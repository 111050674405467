import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import CustomerChangePasswordModel from 'shoelace/models/customer-change-password';
import Session from 'ember-simple-auth/services/session';
import type NoticeService from 'shoelace/services/notice';

export default class StoreCustomerPasswordController extends Controller {
  @tracked isValidationVisible = false;
  @service declare router: RouterService;
  @service declare session: Session;
  @service declare notice: NoticeService;
  @tracked resetpasswordcheck = false
  @tracked lengthFlag = false
  @tracked charactersFlag = false
  @tracked lowerCharactersFlag = false
  @tracked numbersFlag = false
  @tracked specialCharactersFlag = false
  @tracked repeatedFlag = false
  @tracked numberSequenceFlag = false
  @tracked fieldNotEmpty = false
  @tracked commonPasswordsCheck = false
  @tracked repeatPasswordsCheck = false
  multipleChecks = false

  async findOverlap(s1: string, s2: string, howlong: number) {
    var a = 0;
    for (let i = howlong; i <= s1.length; i++) {
      if (i - a == howlong && s2.includes(s1.substring(a, i))) {
        return this.multipleChecks = true;
      }
      a += 1;
    }
  }

  @action async onTypePassword(params: string) {
    this.fieldNotEmpty = false;
    this.resetpasswordcheck = false;
    this.repeatPasswordsCheck = false;

    if (params) {
      this.fieldNotEmpty = true;
    }

    this.lengthFlag = false;
    const regex: RegExp = /^.{10,72}$/;
    if (regex.test(params)) {
      this.lengthFlag = true;
    }

    this.charactersFlag = false;
    const regex1: RegExp = /[A-Z]/;
    if (regex1.test(params)) {
      this.charactersFlag = true;
    }

    this.lowerCharactersFlag = false;
    const regexlow: RegExp = /[a-z]/;
    if (regexlow.test(params)) {
      this.lowerCharactersFlag = true;
    }

    this.numbersFlag = false;
    const regexnumbers: RegExp = /[0-9]/;
    if (regexnumbers.test(params)) {
      this.numbersFlag = true;
    }

    this.specialCharactersFlag = false;
    const regexspecial: RegExp = /[^A-ZÀ-ȕa-z0-9]/;
    if (regexspecial.test(params)) {
      this.specialCharactersFlag = true;
    }

    this.repeatedFlag = false;
    const regex2: RegExp = /^(?!.*(.)\1\1).*$/;
    if (!regex2.test(params)) {
      this.repeatedFlag = true;
    }

    this.commonPasswordsCheck = false;
    const regex3: RegExp = /^(?!.*summit)(?!.*sum)(?!.*pass)(?!.*word).*$/;
    if (!regex3.test(params)) {
      this.commonPasswordsCheck = true;
    }

    this.numberSequenceFlag = false;
    if (await this.findOverlap("abcdefghijklmnopqrstuvwxyz", params, 3) || await this.findOverlap("123456789", params, 3) || await this.findOverlap("qwertyuiop", params, 3) || await this.findOverlap("asdfghjkl", params, 3) || await this.findOverlap("zxcvbnm", params, 3)) {
      this.numberSequenceFlag = true;
    }
  }

  @action async changePassword(
    customerChangePasswordModel: CustomerChangePasswordModel,
    event: Event,
  ) {
    event.preventDefault();
    await customerChangePasswordModel.validate();

    if (customerChangePasswordModel.validations.isValid && this.lengthFlag && this.charactersFlag && this.lowerCharactersFlag && this.numbersFlag && this.specialCharactersFlag && !this.commonPasswordsCheck && !this.numberSequenceFlag && !this.repeatedFlag) {
      try {
        this.isValidationVisible = false;

        await customerChangePasswordModel.save();
        await this.notice.success(`Your Password Has Been Reset`);

        this.router.transitionTo('login');
      } catch (error) {
        this.isValidationVisible = true;

        if (error["errors"][0]["title"] == 'password token') {
          this.resetpasswordcheck = true;
          this.lengthFlag = true;
          this.charactersFlag = true;
          this.repeatedFlag = false;
          this.numberSequenceFlag = false;
          this.fieldNotEmpty = true;
        }

        if (error["errors"][0]["title"] == 'repeats error') {
          this.repeatPasswordsCheck = true;
          this.lengthFlag = true;
          this.charactersFlag = true;
          this.repeatedFlag = false;
          this.numberSequenceFlag = false;
          this.fieldNotEmpty = true;
        }
      }
    } else {
      this.isValidationVisible = true;
    }
  }
}
