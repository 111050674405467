import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type ServiceCenter from 'shoelace/models/service-center';
import { refreshModel } from 'shoelace/utils/query-params';
import type StoreService from '@ember-data/store';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';
import type RouterService from '@ember/routing/router';
import SessionService from 'shoelace/services/-session';

export interface AdminServiceCentersIndexRouteModel {
  serviceCenters: ArrayProxy<ServiceCenter>;
}

export interface AdminServiceCentersIndexRouteParams {
  sort?: string;
}

class AdminServiceCentersIndexRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare router: RouterService;

  
 
  queryParams = {
    sort: refreshModel,
  };

  async model({
    sort,
  }: AdminServiceCentersIndexRouteParams): Promise<AdminServiceCentersIndexRouteModel> {

    const customer = await this.session.get('customer');

    if (customer) {
      if (customer?.hasRbacAdminCredit || customer?.hasRbacCustomerAdmin) {
        this.router.transitionTo('store.customer', customer.id);
      } 
    }
    
    const serviceCenters = await this.store.query('service-center', {
      include: ['service-center-type'].join(','),
      sort,
    });

    return {
      serviceCenters,
    };
  }
}

export default instrumentRoutePerformance(
  AdminServiceCentersIndexRoute
);