import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { belongsTo, hasMany } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type CustomerModel from 'shoelace/models/customer';
import type GrantModel from 'shoelace/models/grant';

export default class RoleModel extends Model {
  @belongsTo('customer')
  customer!: AsyncBelongsTo<CustomerModel>;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @hasMany('grant')
  grants!: AsyncHasMany<GrantModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    role: RoleModel;
  }
}
