import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.priceStrict.isLoaded}}\n  {{yield this.priceStrict.value}}\n{{else}}\n  {{yield this.priceStrict}}\n{{/if}}\n\n{{!-- {{yield this.price}} --}}", {"contents":"{{#if this.priceStrict.isLoaded}}\n  {{yield this.priceStrict.value}}\n{{else}}\n  {{yield this.priceStrict}}\n{{/if}}\n\n{{!-- {{yield this.price}} --}}","moduleName":"shoelace/components/product/price.hbs","parseOptions":{"srcName":"shoelace/components/product/price.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { load } from 'shoelace/helpers/load';
import AccountModel from 'shoelace/models/account';
import AccountJobAccountModel from 'shoelace/models/account-job-account';
import ProductModel from 'shoelace/models/product';
import ServiceCenterModel from 'shoelace/models/service-center';
import type AccountService from 'shoelace/services/account';
import type AccountJobAccountService from 'shoelace/services/account-job-account';
import type ProductPriceService from 'shoelace/services/product-price';
import type ServiceCenterService from 'shoelace/services/service-center';

interface ProductPriceComponentArgs {
  product: ProductModel;
  quantity?: number;
  account?: AccountModel;
  accountJobAccount?: AccountJobAccountModel;
  serviceCenterId?: number;
}

export default class ProductPriceComponent extends Component<ProductPriceComponentArgs> {
@service declare productPrice: ProductPriceService;

@service declare account: AccountService;

@service declare accountJobAccount: AccountJobAccountService;

@service declare serviceCenter: ServiceCenterService;

  get priceStrict() {
    return load(
      this.productPrice.getPriceStrict(
        this.args.product,
        this.args.quantity ?? this.args.product?.minQty ?? 1,
        this.args.account ?? this.account.account,
        this.args.accountJobAccount ?? this.accountJobAccount.accountJobAccount,
        this.args.serviceCenterId ?? this.serviceCenter?.serviceCenter?.id,
      ),
    );
  }

  get price() {
    return this.productPrice.getPrice(
      this.args.product,
      this.args.quantity,
      this.args.account,
      this.args.accountJobAccount,
      this.args.serviceCenterId,
    );
  }
}
