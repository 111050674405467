import type CartQuoteListModel from 'shoelace/models/cart-quote-list';
import type ProductModel from 'shoelace/models/product';
import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';

export default class lineItemQuoteModel extends Model {
  @attr('number') quantity!: null | number;
  @attr('number') product_id!: null | number;
  @attr('number') cart_quote_list_id!: null | number;
  @attr('string') message!: null | string;

  @belongsTo('cart-quote-list') declare cartQuoteList?: AsyncBelongsTo<CartQuoteListModel>;
  @belongsTo('product') declare product?: AsyncBelongsTo<ProductModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'line-item-quote': lineItemQuoteModel;
  }
}
