import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (await this.product)\n  this.quantity\n  this.onSelectProperty\n  this.changeQuantity\n  this.property\n}}\n", {"contents":"{{yield\n  (await this.product)\n  this.quantity\n  this.onSelectProperty\n  this.changeQuantity\n  this.property\n}}\n","moduleName":"shoelace/components/quick-order-guides/product.hbs","parseOptions":{"srcName":"shoelace/components/quick-order-guides/product.hbs"}});
import { action } from '@ember/object';
import { read } from '../../utils';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class QuickOrderProductComponent extends Component {
  @tracked selectedProperty;
  @tracked _quantity;

  get quantity() {
    return this._quantity || this.product.minQty || 1;
  }

  get quickOrderProducts() {
    return this.args.quickOrderProducts || [];
  }

  get defaultProduct() {
    return (
      (this.quickOrderProducts && this.quickOrderProducts.firstObject) || null
    );
  }

  get defaultProperty() {
    return read(this.args.quickOrderProperty, 'id');
  }

  get property() {
    return this.selectedProperty || this.defaultProperty;
  }

  findProduct(quickOrderProducts, property) {
    let quickOrderProduct = quickOrderProducts.find(p => {
      return (
        (p.hasSize && p.size.get('id') == property) ||
        (p.hasColor && p.color.get('id') == property)
      );
    });

    if (
      !quickOrderProduct &&
      (quickOrderProducts.length == 1 ||
        quickOrderProducts.every(p => p.hasColor))
    ) {
      quickOrderProduct = quickOrderProducts[0].hasProduct
        ? quickOrderProducts[0]
        : null;
    }

    return (
      (quickOrderProduct &&
        quickOrderProduct.hasProduct &&
        quickOrderProduct.product) ||
      null
    );
  }

  get product() {
    return this.findProduct(this.quickOrderProducts, this.property);
  }

  @action onSelectProperty(property) {
    this.selectedProperty = property;
  }

  @action async changeQuantity(quantity) {
    this._quantity = await quantity;
  }
}
