import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router';
import RbacCustomerRoleModel from 'shoelace/models/rbac-customer-role';
import type StoreService from '@ember-data/store';
import { service } from '@ember/service';

export default class AdminManageCustomerEditController extends Controller {
  @service declare router: RouterService;
  @service declare store: StoreService;

  @action async onEditCustomer(
    customer: any,
   role: RbacCustomerRoleModel,
   rbacroleId: string,
  ): Promise<void> {
    try {
      await customer.save();
      if(rbacroleId){
        if(role)
        {
          await role.set('rbac_role_id', rbacroleId);
          await role.save();
        } else{
            const rbacCustomerRole = await this.store
            .createRecord('rbac-customer-role', {
              customer_id: customer.id,
              rbac_role_id: rbacroleId,
            });
      
            await rbacCustomerRole.save();
        }
      }     
      this.router.transitionTo('admin.manage-customer', customer.id);
    } catch (error) {
      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/manage-customer/edit': AdminManageCustomerEditController;
  }
}
