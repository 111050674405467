import StoreService from '@ember-data/store';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { AsyncArray } from '../utils/async-helpers';

export default class JobAccountComponent extends Component {
  // this must be declared this way until this is ported to a glimmer component.
  // some invocations of this component override this declartion with @procurement
  // in handlebars.
  @service procurement;
  @service session;
  @service store;

  @tracked modal = this.args.modal;
  @tracked selectingJobAccount = false;
  @tracked isPickingJobAccount = false;

  tagName = '';

  @computed('session.customer.account.id')
  get jobAccounts() {
    return AsyncArray(
      this.loadJobAccounts(this.session.customer?.get?.('account.id')),
    );
  }

  async loadJobAccounts(account) {
    return await this.store.query('account-job-account', {
      filter: {
        account,
        is_active: true,
      },
    });
  }

  get selectedJobAccount() {
    return (
      this.session.customer?.get?.('accountJobAccount') ??
      (this.args?.procurement ?? this.procurement.procurement).get?.(
        'accountJobAccount',
      )
    );
  }

  pickAccountJobAccount(accountJobAccount) {
    (this.args?.procurement ?? this.procurement.procurement).set?.(
      'accountJobAccount',
      accountJobAccount,
    );
  }

  async pickAccountJobAccountById(accountJobAccountId) {
    const store = this.store;
    const customer = this.session.customer;

    switch (typeof accountJobAccountId) {
      case 'string':
        const id = parseInt(`0${accountJobAccountId}`, 10) || null;

        customer?.set?.(
          'accountJobAccount',
          store.peekRecord('account-job-account', id),
        );

        break;
      case 'number':
        customer?.set?.(
          'accountJobAccount',
          store.peekRecord('account-job-account', accountJobAccountId),
        );

        break;
      case 'object':
        customer?.set?.('accountJobAccount', accountJobAccountId);

        break;
      default:
        customer?.set?.('accountJobAccount', null);
    }

    await customer?.save?.();
  }

  @action async pickJobAccount(selection) {
    try {
      this.isPickingJobAccount = true;
      this.selectingJobAccount = selection;

      this.pickAccountJobAccount(selection);
      await this.pickAccountJobAccountById(selection?.get?.('id'));

      this.modal.close();
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1003, error);

      throw error;
    } finally {
      this.isPickingJobAccount = false;
      this.selectingJobAccount = false;
    }
  }
}
