import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import type ServiceCenterModel from 'shoelace/models/service-center';
import type lineItemQuoteModel from 'shoelace/models/line-item-quote';

export default class CartQuoteListModel extends Model {
  @attr('number') serviceCenterId!: null | number;

  @hasMany('line-item-quote')
  lineItemQuote!: AsyncHasMany<lineItemQuoteModel>;

  @belongsTo('service-center', { inverse: null })
  serviceCenter!: AsyncBelongsTo<ServiceCenterModel>;
}
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'cart-quote-list': CartQuoteListModel;
  }
}
