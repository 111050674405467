import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountOrderLineItemModel from 'shoelace/models/account-order-line-item';

export default class AccountOrderLineItemDeliveryModel extends Model {
  @attr('string') name!: null | string;

  @belongsTo('account-order-line-item')
  accountOrderLineItem!: AsyncBelongsTo<AccountOrderLineItemModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-order-line-item-delivery': AccountOrderLineItemDeliveryModel;
  }
}
