import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AccountProjectModel from 'shoelace/models/account-project';
import type AccountProjectOrderLineItemAttributeModel from 'shoelace/models/account-project-order-line-item-attribute';

export default class AccountProjectOrderLineItemAttributeDefinitionModel extends Model {
  @attr('string') name!: null | string;
  @attr('number') priorityOrderAttribute!: null | number;

  @belongsTo('account-project')
  accountProject!: AsyncBelongsTo<AccountProjectModel>;

  @hasMany('account-project-order-attribute')
  accountProjectOrderLineItemAttributes!: AsyncHasMany<AccountProjectOrderLineItemAttributeModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-project-order-line-item-attribute-definition': AccountProjectOrderLineItemAttributeDefinitionModel;
  }
}
