import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { refreshModel } from '../../../utils/query-params';
import { instrumentRoutePerformance } from '@sentry/ember';
import type RouterService from '@ember/routing/router';
import SessionService from 'shoelace/services/-session';

export interface RouteParams {
  page: string | number;
  search: string | number;
  size: string | number;
  sort: string | number;
}

class AdminAccountsRoute extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService; 
  @service declare store: StoreService;

  queryParams = {
    page: refreshModel,
    search: refreshModel,
    size: refreshModel,
    sort: refreshModel,
  };
 
  async model({ page: number, search, size, sort }: RouteParams) {
  
   const customer = await this.session.get('customer');

    if (customer) {
      if (customer?.hasRbacCustomerAdmin) {
        this.router.transitionTo('store.customer', customer.id);
      } 
    }
    
    const accounts = this.store.query('account', {
      filter: {
        search,
      },
      include: ['service-center'].join(','),
      page: {
        number,
        size,
      },
      sort,
    });

    return {
      accounts,
    };
  }
}

export default instrumentRoutePerformance(
  AdminAccountsRoute
);