import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import type AccountOrderModel from 'shoelace/models/account-order';
import type AccountProjectModel from 'shoelace/models/account-project';
import type AccountProjectCircuitModel from 'shoelace/models/account-project-circuit';
import type AccountProjectDrawingModel from 'shoelace/models/account-project-drawing';
import type AccountProjectOrderAttributeModel from 'shoelace/models/account-project-order-attribute';
import type AccountProjectOrderLineItemModel from 'shoelace/models/account-project-order-line-item';

export default class AccountProjectOrderModel extends Model {
  @attr('date') availableAt!: null | Date;
  @attr('date') expectedAt!: null | Date;
  @attr('boolean') isLate!: null | boolean;

  @tracked accountProjectCircuit = null;

  @belongsTo('account-order')
  accountOrder!: AsyncBelongsTo<AccountOrderModel>;

  @belongsTo('account-project-drawing')
  accountProjectDrawing!: AsyncBelongsTo<AccountProjectDrawingModel>;

  @belongsTo('account-project')
  accountProject!: AsyncBelongsTo<AccountProjectModel>;

  @hasMany('account-project-circuit')
  accountProjectCircuits!: AsyncHasMany<AccountProjectCircuitModel>;

  @hasMany('account-project-order-attribute')
  accountProjectOrderAttributes!: AsyncHasMany<AccountProjectOrderAttributeModel>;

  @hasMany('account-project-order-line-item')
  accountProjectOrderLineItems!: AsyncHasMany<AccountProjectOrderLineItemModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-project-order': AccountProjectOrderModel;
  }
}
