import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span {{did-insert this.onDidInsert}}></span>\n\n{{#if @router}}\n    <span\n    class=\"w-full text-left text-blue-500 p-1\"\n    {{did-insert this.openModal}}\n  >\n  </span>\n{{else}}\n  <button\n    class=\"w-full text-left text-blue-500 p-1\"\n    {{on \"click\" this.openModal}}\n  >\n    {{@selectorName}}\n  </button>\n{{/if}}\n\n", {"contents":"<span {{did-insert this.onDidInsert}}></span>\n\n{{#if @router}}\n    <span\n    class=\"w-full text-left text-blue-500 p-1\"\n    {{did-insert this.openModal}}\n  >\n  </span>\n{{else}}\n  <button\n    class=\"w-full text-left text-blue-500 p-1\"\n    {{on \"click\" this.openModal}}\n  >\n    {{@selectorName}}\n  </button>\n{{/if}}\n\n","moduleName":"shoelace/components/schneider-ez-selector.hbs","parseOptions":{"srcName":"shoelace/components/schneider-ez-selector.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ENV from 'shoelace/config/environment';

// there can be multiple selectors on a page
// but only one can be open at once
// but selector uses window.postMessage
// so we need to keep track of which selector
// needs to operate

const namesToIds = {
  metering: 'E2.A019',
  'lv transformers': 'E2.A044',
  'varset low voltage capacitor banks': 'E2.A207',
  'low voltage motor control center (unit selection only)': 'E2.A210',
  'molded case circuit breakers': 'E2.A272',
  'surge protection device': 'E2.A280',
  'nema pressure & float switch': 'E2.A412',
  'nema pressure switch': 'E2.A412H',
  'altistart line of soft starters': 'E2.ATS1',
  'machine drives altivar': 'E2.ATV0',
  'zelio plug-in relays and zelio solid state relays': 'E2.C233',
  'zelio control monitoring & control relays': 'E2.C234',
  'zelio logic': 'E2.C235',
  'proximity sensors osisense xs & xt': 'E2.C410',
  'osisense xu photoelectric sensors': 'E2.C411',
  'pressure sensors osisense xm': 'E2.C412',
  'limit switches osisense xc': 'E2.C413',
  'ultrasonic sensor': 'E2.C414',
  'cordsets & connectors osisense xz': 'E2.C415',
  'rotary encoder': 'E2.C416',
  sensors: 'E2.C41X',
  'magelis line of small panel hmi (human machine interface) with keyboard':
    'E2.C420',
  'load center selector (max 240vac)': 'E2.D01A',
  'combination service entrance devices (cseds)': 'E2.D01C',
  'safety switches': 'E2.D03A',
  'miniature circuit breakers': 'E2.D07C',
  'multi 9 minature circuit breaker': 'E2.D07M',
  'tesys vls disconnect switches': 'E2.D08S1',
  'gs2 fusible disconnect switch': 'E2.D08S2',
  'lk4 non fusible disconnect switch': 'E2.D08S3',
  'md, vario disconnect switches': 'E2.D08S4',
  'i-line panelboards (merchandised)': 'E2.D09A',
  'lighting & appliance panelboards (nq)': 'E2.D09C',
  'lighting & appliance panelboards (nf)': 'E2.D09D',
  'fractional horsepower manual starters with melting alloy overload relays':
    'E2.D16A1',
  'manual switches - horsepower rated for self-protected motors': 'E2.D16A2',
  'manual starters - integral horsepower with melting alloy overload relays':
    'E2.D16A3',
  'tesys n contactors and starters': 'E2.D16D1',
  'definite purpose contactors': 'E2.D16J1',
  'type s nema starters': 'E2.D16S1',
  'thermal units selection': 'E2.D16TU',
  enclosures: 'E2.ENCL1',
  'ac contactor': 'E2.HDC1',
  'miniature circuit breaker': 'E2.HDM1',
  'advanced touchscreen/keyboard panels': 'E2.HMI00',
  'advanced optimum panels - magelis gto': 'E2.HMI01',
  'high performance modular hmi - magelis gtu': 'E2.HMI02',
  'industrial pc and displays': 'E2.HMI03',
  'small hmi controllers - magelis scu': 'E2.HMI04',
  'small panels': 'E2.HMI05',
  'small panels with keypad': 'E2.HMI06',
  'lexium 32i integrated servo drive': 'E2.MCLX1',
  'modicon line of m340/580 micro programmable automation controllers (pac)':
    'E2.MOD1',
  'modicon premium to m340/m580 converter': 'E2.MOD2',
  'modicon plcs m2xx': 'E2.MOD3',
  'harmony pushbuttons 16 mm': 'E2.PB16',
  'harmony pushbuttons 22mm': 'E2.PB22',
  'harmony pushbuttons 30mm': 'E2.PB30',
  'preventa xps mp, mc, mcm': 'E2.PCS1',
  'safety modules preventa xps': 'E2.PCS2',
  'power supplies & transformer phaseo': 'E2.PSA0',
  'zelio time - timing relays': 'E2.REL2',
  'tesys motor control solutions': 'E2.ST02',
  'linergy terminal blocks': 'E2.TBL1',
  'power distribution blocks': 'E2.TBP1',
  '9080 open power distribution blocks': 'E2.TBP2',
  'harmony 60mm towerlights xvu': 'E2.TLM1',
  'xvc monolithic tower light': 'E2.TLM2',
};

export default class SchneiderEzSelectorComponent extends Component {
  @service cart;
  @service store;
  @service router;
  @service kart;

  @tracked modalOpen = this.args.modalOpen ?? false;
  @tracked selectorName = this.args.selectorName ?? 'TEST';
  @tracked tagName = this.args.tagName ?? '';

  getProducts(seProductData) {
    return Promise.all(
      seProductData.map(async (seProduct) => {
        return {
          seParameters: seProduct,
          product: (await this._product(seProduct.catalogNo)).toArray()[0],
        };
      })
    );
  }

  get selectorId() {
    const n = this.selectorName;
    const n_l = n.toLowerCase();
    if (n_l in namesToIds) return namesToIds[n_l];
    else return n;
  }

  _product(term) {
    const params = {
      entire_query: true,
      search: term,
      page: {
        number: 1,
        size: 1,
      },
    };

    return this.store.query('product', params); // this caches loading results (see search adapter: queryRecord)
  }

  async messageListener(event) {
    // don't respond to other selectors
    if (!this.modalOpen) return;
    // don't react to other postMessages
    console.log("raw",event.data)
    if (!typeof event.data == 'object' || !typeof event.data.fn == 'string')
      return;

    if (event.data.fn == 'cnsAddToProject') {
      var partsdata = JSON.parse(event.data.data);
      const products = await this.getProducts(partsdata);
      this.modalOpen = false;
      console.log("user wants to add to cart", products)
      this.addToCart(products);
      // this.sendAction('addedToCart', products);
    }  
    if (event.data.fn == 'cnsClose') {
      this.closeModal();
      console.log("user wants to wait", event.data)
      console.log("user waited", event.data)
      await cnsOverlayClose(); // global from se-cnst-library.js
      window.location.replace("https://summit.com/square-d");
    }
  }

  @action onDidInsert() {
    this.cnsReceiveMessage = this.messageListener.bind(this);

    window.addEventListener('message', this.cnsReceiveMessage, false);
  }

  @action onWillDestroy() {
    window.removeEventListener('message', this.cnsReceiveMessage);
    window.location.replace("https://summit.com/square-d");
  }

  @action async closeModal() {
    await delay(5000);
    this.modalOpen = false;
    await messageListener()
    console("previous url is: " + window.history.previous.href)
    console("document.referrer: " + document.referrer)
  }
  addToCart(request) {
    const missing = request.filter((req) => !req.product);
    const present = request.filter((req) => !!req.product);

    this.cart.addItems(
      present.map((req) => ({
        id: req.product.id,
        quantity: req.seParameters.quantity,
      }))
    );
    let notes = '';
    if (missing.length > 0) {
      notes += missing
        .map(
          (req) => `${req.seParameters.quantity}x ${req.seParameters.catalogNo}`
        )
        .join('\n');
      alert(
        "Some products that you've selected aren't available online, but you can call Summit at 855-404-4800 and we'll see if they're available. The missing items are:\n" +
          notes
      );
    }
    this.router.transitionTo('secure-checkout.index');
  }

  @action async openModal() {
    this.modalOpen = true;
    const sid = this.selectorId;
    // Need to track how often each selector is used
    await cnsOverlayOpen(sid); // global from se-cnst-library.js
  }
}

// populate the window configs SE's CNST is expecting
Object.keys(ENV['secnst']).forEach(
  (prop) => (window[prop] = ENV['secnst'][prop])
);

