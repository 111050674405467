import Controller from '@ember/controller';
import { action } from '@ember/object';
import type Account from 'shoelace/models/account';

export default class AdminAccountProjectIndexController extends Controller {
  @action async onPushImage(model: Account, id: string): Promise<void> {
    model.set('imageSignedId', id);

    await model.save();
  }

  @action async onPopImage(model: Account): Promise<void> {
    model.set('imageSignedId', null);

    await model.save();
    window?.location?.reload?.();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/account-project/index': AdminAccountProjectIndexController;
  }
}
