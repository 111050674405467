import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

 class LinkYourBusinessRoute extends Route {
  @service session;
  @service store;

  model() {
    return this.store.createRecord('sign-in');
  }
}
export default instrumentRoutePerformance(
  LinkYourBusinessRoute
);
