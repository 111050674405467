import StoreService from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { EXCEPTION_1051 } from 'shoelace/exceptions/mod';
import CustomerModel from 'shoelace/models/customer';
import ProductModel from 'shoelace/models/product';
import KartService, { Item } from 'shoelace/services/kart';
//import type RollbarService from 'shoelace/services/rollbar';

export default class ProductController extends Controller {
@service declare store: StoreService;

@service declare kart: KartService;

//@service declare rollbar: RollbarService;

  @action async onSaveAsList(
    items: Item[],
    title: string,
    customer: CustomerModel,
  ): Promise<void> {
    try {
      await this.kart.generateLegacyCart(title, items, customer);

      items.forEach(item => {
        this.kart.removeItem(item.product);
      });
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1051, error);

      throw error;
    }
  }

  @action async onAddtoList(
    product: ProductModel,
    quantity: string,
    listId: string,
  ): Promise<void> {
    try {
      const list = await this.store.peekRecord('cart', listId);
      const cartLineItem = await this.store;

      const lineItems = await list.get('cartLineItems');

      await Promise.all(lineItems.map(li => li.get('product')));

      const quantity = await product.minQty;

      const lineItem = lineItems.find(item => {
        return item.get('product.id') === product.id;
      });

      if (lineItem) {
        const existingQuantity = parseInt(lineItem.get('quantity'), 10);
        lineItem.set('quantity', existingQuantity + parseInt(quantity));
        return lineItem.save();
      } else {
        return cartLineItem
          .createRecord('cart-line-item', {
            product: product,
            quantity: product.minQty,
            cart: list,
          })
          .save();
      }
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1051, error);

      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    product: ProductController;
  }
}
