import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreServicesMyProjectsReleaseRoute extends Route {
  @service store;
  
  async model({ 'account-project-release_id': id }) {
    return await this.store.findRecord('account-project-release', id, {
      include: ['account-project', 'account-project.account'].join(','),
    });
  }
}
export default instrumentRoutePerformance(
  StoreServicesMyProjectsReleaseRoute
);