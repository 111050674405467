import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import type AccountOrderModel from 'shoelace/models/account-order';
import type AccountOrderLineItemDeliveryModel from 'shoelace/models/account-order-line-item-delivery';
import type AccountProjectOrderLineItemModel from 'shoelace/models/account-project-order-line-item';
import type ProductModel from 'shoelace/models/product';

export default class AccountOrderLineItemModel extends Model {
  @attr('string') customerPartNumber!: null | string;
  @attr('number') deliveredQuantity!: null | number;
  @attr('string') deliveryStatus!: null | string;
  @attr('number') orderedQuantity!: null | number;
  @attr('string') shippingType!: null | string;
  @attr('number') total!: null | number;
  @attr('number') price!: null | number;
  @attr('number') unitsPerPackage!: null | number;
  @attr('string') unitOfMeasure!: null | string;
  @attr('number') totalQuantity!: null | number;
  @attr('number') position!: null | number;

  @belongsTo('account-project-order-line-item')
  accountProjectOrderLineItem!: AsyncBelongsTo<AccountProjectOrderLineItemModel>;

  @belongsTo('account-order')
  accountOrder!: AsyncBelongsTo<AccountOrderModel>;

  @belongsTo('product')
  product!: AsyncBelongsTo<ProductModel>;

  @hasMany('account-order-line-item-delivery')
  accountOrderLineItemDeliveries!: AsyncHasMany<AccountOrderLineItemDeliveryModel>;

  @readOnly('accountProjectOrderLineItem.availableAt')
  availableAt!: null | Date;

  @readOnly('unitsPerPackage')
  numberPerUnit!: null | number;

  @computed('totalQuantity', 'unitOfMeasure')
  get quantityUnit(): string {
    return `${this.totalQuantity} ${this.unitOfMeasure}`;
  }

  @computed('deliveredQuantity', 'unitOfMeasure')
  get deliveredQuantityUnit(): string {
    return `${this.deliveredQuantity} ${this.unitOfMeasure}`;
  }

  @computed('availableAt', 'accountOrder.expectedAt')
  get isLate(): boolean {
    return (this.availableAt ?? 0) > (this.accountOrder.get('expectedAt') ?? 0);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-order-line-item': AccountOrderLineItemModel;
  }
}
