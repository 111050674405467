import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ProcurementService from 'shoelace/services/procurement';
import { instrumentRoutePerformance } from '@sentry/ember';

class CheckoutPunchoutRedirect extends Route {
  @service declare procurement: ProcurementService;

  model() {
    return this.procurement.procurement;
  }
}
export default instrumentRoutePerformance(CheckoutPunchoutRedirect);
