import Route from '@ember/routing/route';
import { refreshModel } from '../../utils/query-params';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreAccountsRoute extends Route {
  @service store;

  queryParams = {
    page: refreshModel,
    search: refreshModel,
    size: refreshModel,
    sort: refreshModel,
  };

  async model({ page: number, search, size, sort }) {
    const accounts = this.store.query('account', {
      filter: {
        search,
      },
      include: ['service-center'].join(','),
      page: {
        number,
        size,
      },
      sort,
    });

    return {
      accounts,
    };
  }
}

export default instrumentRoutePerformance(StoreAccountsRoute);
