import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountHistoryOrderModel from 'shoelace/models/account-history-order';

export default class AccountHistoryOrderQuoteModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('number') number!: null | number;

  @belongsTo('account-history-order')
  accountHistoryOrder!: AsyncBelongsTo<AccountHistoryOrderModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-order-quote': AccountHistoryOrderQuoteModel;
  }
}
