import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import type AccountModel from 'shoelace/models/account';
import type AccountHistoryQuoteBillAddressModel from 'shoelace/models/account-history-quote-bill-address';
import type AccountHistoryQuoteCommentModel from 'shoelace/models/account-history-quote-comment';
import type AccountHistoryQuoteFundAddressModel from 'shoelace/models/account-history-quote-fund-address';
import type AccountHistoryQuoteLineItemModel from 'shoelace/models/account-history-quote-line-item';
import type AccountHistoryQuoteShipAddressModel from 'shoelace/models/account-history-quote-ship-address';
import type AccountHistoryQuoteSoldAddressModel from 'shoelace/models/account-history-quote-sold-address';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';

export default class AccountHistoryQuoteModel extends Model {
  @attr('date') createdAt!: null | Date;
  @attr('date') expectedAt!: null | Date;
  @attr('date') expiresAt!: null | Date;
  @attr('date') validAt!: null | Date;
  @attr('string') jobAccountName!: null | string;
  @attr('number') numberOfItems!: null | number;
  @attr('string') purchaseOrder!: null | string;
  @attr('number') salesTax!: null | number;
  @attr('number') subtotal!: null | number;
  @attr('number') total!: null | number;
  @attr('string') email!: null | string;

  // 'account-history-quote-bill-address',
  // 'account-history-quote-fund-address',
  // 'account-history-quote-ship-address',
  // 'account-history-quote-sold-address',
  // 'account-history-quote-comments',
  // 'account-history-quote-line-items',
  // 'account-history-quote-line-items.account-history-quote-line-item-comments',

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-job-account')
  accountJobAccount!: AsyncBelongsTo<AccountJobAccountModel>;

  @belongsTo('account-history-quote-bill-address')
  accountHistoryQuoteBillAddress!: AsyncBelongsTo<AccountHistoryQuoteBillAddressModel>;

  @belongsTo('account-history-quote-fund-address')
  accountHistoryQuoteFundAddress!: AsyncBelongsTo<AccountHistoryQuoteFundAddressModel>;

  @belongsTo('account-history-quote-ship-address')
  accountHistoryQuoteShipAddress!: AsyncBelongsTo<AccountHistoryQuoteShipAddressModel>;

  @belongsTo('account-history-quote-sold-address')
  accountHistoryQuoteSoldAddress!: AsyncBelongsTo<AccountHistoryQuoteSoldAddressModel>;

  @hasMany('account-history-quote-comments')
  accountHistoryQuoteComments!: AsyncHasMany<AccountHistoryQuoteCommentModel>;

  @hasMany('account-history-quote-line-items')
  accountHistoryQuoteLineItems!: AsyncHasMany<AccountHistoryQuoteLineItemModel>;

  @computed('accountHistoryQuoteLineItems.@each.id')
  get sortedLineItems(): AccountHistoryQuoteLineItemModel[] {
    const a = this.accountHistoryQuoteLineItems;
    const lineNumber = (id: string) => parseInt(`${id.split('-')[1]}`, 10);

    return a.toArray().sort((a, b) => {
      const aLn = lineNumber(a.get('id'));
      const bLn = lineNumber(b.get('id'));

      return aLn - bLn;
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-quote': AccountHistoryQuoteModel;
  }
}
