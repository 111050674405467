import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';
export interface RouteParams {
  catalog_id: string | number;
}
class AdminClassificationRoute extends Route {
  @service declare store: StoreService;
 
  async model({catalog_id: id  }: RouteParams) {
    const account = await this.modelFor('admin.account');
    const catalog = await this.store.findRecord('classification', id);

    const classificationPermissions = await this.store.query(
      'classification-permission',
      {
        filter: {
          classification: id,
        },
      },
    );

    const classificationCatalogs = await this.store.query(
      'classification-catalog',
      {
        filter: {
          catalog: id,
        },
        include: 'classification',
      },
    );

    const parents = await this.store.query('classification', {
      filter: {
        parent_id: 7,
      },
    });

    const categories = parents.map(parent => {
      const children = this.store.query('classification', {
        filter: {
          parent_id: parent.id,
        },
      });

      parent['children'] = children;

      return parent;
    });

    await Promise.all(categories.map(category => category.children));

    categories.forEach(category => {
      const checked = category.children.every(child => {
        return classificationCatalogs
          .map(catalog => catalog.get('classification.id'))
          .includes(child.get('id'));
      });
      category['checked'] = checked;
    });

    return {
      catalog,
      account,
      categories,
      classificationCatalogs,
      classificationPermissions,
    };
  }
}

export default instrumentRoutePerformance(AdminClassificationRoute);