import EmberRouter from '@ember/routing/router';
import config from 'shoelace/config/environment';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

if (typeof window !== 'undefined') {

// Check if the current URL is not the login URL and assign the current url in cache
  if (!window.location.href.includes('login') || !window.location.href.includes('forgot-password') || !window.location.href.includes('reset-password') || !window.location.href.includes('unlock')) {
    localStorage.setItem("storage:url-cache",JSON.stringify({ "url": window.location.href }));
  }
  if (window?.location?.host == 'www.summit.com') {
    let baseUrl = 'https://www.summit.com/';
    let result = window?.location?.href.replace(baseUrl, '');
    window?.location?.replace('https://summit.com/' + result)
  }
}

Router.map(function () {
  this.route('sso', function () {
    this.route('auth',{ path: '/*queryparams' },);
  });

  this.route('admin', function () {
    this.route(
      'account-open-item-receipt',
      { path: 'account-open-item-receipt/:account_open_item_receipt_id' },
      function () {}
    );

    this.route('account-open-item-receipt', function () {
      this.route('report');
    });

    this.route(
      'account-project',
      { path: 'account-projects/:account_project_id' },
      function () {}
    );

    this.route('account-projects', function () {});

    this.route(
      'account-verification',
      { path: 'account-verification/:account_verification_id' },
      function () {}
    );

    this.route('account-verifications', function () {
      this.route('approved');
      this.route('in-process');
      this.route('not-approved');
    });

    this.route('account', { path: 'account/:account_id' }, function () {
      this.route('new-customer');
      this.route('catalogs');
      this.route('catalog', { path: 'catalog/:catalog_id' });
      this.route('account-history');
    });
    
    this.route('accounts', function () {});
    this.route('manage-accounts', function () {});
    this.route('manage-customers', function () {});
    
    this.route('manage-account', { path: 'manage-account/:account_id' }, function () {
      this.route('new-customer');
    });
    
    this.route('manage-customer', { path: 'manage-customer/:customer_id' }, function () {
      this.route('edit');
      this.route('permissions');
      this.route('add-account-verification');
    });

    this.route('customer', { path: 'customer/:customer_id' }, function () {
      this.route('edit');
      this.route('permissions');
      this.route('add-account-verification');
    });
    this.route('customers', function () {});

    this.route(
      'notification',
      { path: 'notification/:notification_id' },
      function () {}
    );
    this.route('notifications', function () {
      this.route('new-notification');
    });

    this.route(
      'service-center',
      { path: 'service-center/:service_center_id' },
      function () {
        this.route('edit-service-center-hours');
        this.route('edit-service-center-days');
        this.route('edit-service-center');
      }
    );
    this.route('service-centers', function () {
      this.route('new-service-center');
    });

    this.route(
      'dynamic-translation',
      { path: 'dynamic-translation/:translation_id' },
      function () {
        this.route('edit');
      }
    );
    this.route('dynamic-translations', function () {
      this.route('new');
    });
  });

  this.route('categories', { path: 'categories/:id' });

  this.route('store', function () {
    this.route('product', { path: 'products/:id' }, function () {});
    this.route('products', function () {});
    this.route('manufacturers', function () {
      this.route('index');
    });
    this.route('product-feedback');

    this.route('customer', { path: '/customers/:id' }, function () {
      this.route('account-contracts');
      this.route('account-verifications');
      this.route('account-settings');
      this.route('edit-name');
      this.route('edit');
      this.route('email');
      this.route('list', { path: 'lists/:list_id' });
      this.route('lists');
      this.route('password');
      this.route('reports');
    });

    this.route('balance', { path: '/services/balance' }, function () {});

    this.route('bill-pay', { path: '/services/bill-pay/:id' }, function () {
      this.route('failure');
      this.route('success');
    });

    this.route('bill-pay-exit', { path: '/services/bill-pay-exit' });
    this.route('bill-pay-failure', { path: '/services/bill-pay-failure' });
    this.route('bill-pay-success', { path: '/services/bill-pay-success' });

    this.route('account-history-invoice', {
      path: '/services/account-history-invoice/:id',
    });

    this.route('account-history-invoices', {
      path: '/services/account-history-invoices',
    });

    this.route('account-history-order', {
      path: '/services/account-history-order/:id',
    });

    this.route('account-history-orders', {
      path: '/services/account-history-orders',
    });

    this.route('account-history-return', {
      path: '/services/account-history-returns/:id',
    });

    this.route('account-history-returns', {
      path: '/services/account-history-returns',
    });

    this.route('account-history-statements', {
      path: '/services/account-history-statements',
    });

    this.route(
      'account-payment',
      { path: '/services/account-payments/:id' },
      function () {}
    );

    this.route(
      'account-payments',
      { path: '/services/account-payments' },
      function () {}
    );

    this.route(
      'account-quote',
      { path: '/services/account-quotes/:id' },
      function () {}
    );

    this.route(
      'account-quotes',
      { path: '/services/account-quotes' },
      function () {}
    );
    this.route('new-quote', function () {});

    this.route(
      'account',
      { path: '/accounts/manage-users/:id' },
      function () {}
    );

    this.route('accounts', function () {});

    this.route(
      'order-confirmation-error',
      { path: '/services/order-confirmation-error' },
      function () {}
    );

    this.route(
      'order-confirmation-processing',
      { path: '/services/order-confirmation-processing/:id' },
      function () {}
    );

    this.route('review-quote-order', function () {});

    this.route(
      'order-confirmation-success',
      { path: '/services/order-confirmation-success' },
      function () {}
    );

    this.route(
      'order-confirmation',
      { path: '/services/order-confirmation/:id' },
      function () {}
    );

    this.route(
      'punchout-confirmation-error',
      { path: '/services/punchout-confirmation-error' },
      function () {}
    );

    this.route(
      'punchout-confirmation-processing',
      { path: '/services/punchout-confirmation-processing/:id' },
      function () {}
    );

    this.route(
      'punchout-confirmation',
      { path: '/services/punchout-confirmation/:id' },
      function () {}
    );

    this.route('customers', function () {
      this.route('confirmation', function () {
        this.route('fulfilled');
      });

      this.route('edit-password', function () {
        this.route('fulfilled');
      });
      this.route('unlock', function () {
        this.route('fulfilled');
      });
    });

    this.route('configurators', function () {
      this.route('selector', {
        path: ':manufacturer_id/:manufacturer_name/:selector',
      }); // manufacturer_name is just for seo
    });

    this.route('services', function () {
      this.route('my-projects', function () {
        this.route('account', { path: 'accounts/:account_id' }, function () {
          this.route(
            'project',
            { path: 'projects/:account-project_id' },
            function () {
              this.route(
                'release',
                { path: 'releases/:account-project-release_id' },
                function () {}
              );
            }
          );

          this.route('circuits');

          this.route(
            'circuit',
            { path: 'circuits/:account-project-circuit_id' },
            function () {}
          );

          this.route('drawings');

          this.route(
            'drawing',
            { path: 'drawings/:account-project-drawing_id' },
            function () {}
          );
        });

        this.route(
          'project',
          { path: 'projects/:account-project_id' },
          function () {
            this.route('late-orders');
            this.route('order', { path: 'orders/:account-project-order_id' });
          }
        );

        this.route(
          'order',
          { path: 'orders/:account-project-order_id' },
          function () {}
        );

        this.route(
          'release',
          { path: 'releases/:account-project-release_id' },
          function () {
            this.route('orders');
            this.route('order', { path: 'orders/:account-project-order_id' });
            this.route('signers');
          }
        );

        this.route('releases', function () {
          this.route('assigned-to-me');
          this.route('my-releases');
          this.route('new');
          this.route('out-for-approval');
          this.route('sent-to-summit');
        });

        this.route('projects');
        this.route('search');
      });
    });
  });

  this.route('quick-order-guides');
  this.route('quick-order-guide', { path: 'quick-order-guide/:id' });

  this.route('punchouts', function () {
    this.route('po2go', function () {
      this.route('setup');
      this.route('pick-plant');
      this.route('landing');
      this.route('landing-error');
    });
  });

  this.route('login', function () {
    this.route('jwt', { path: ':jwt' }, function () {});
  });

  this.route('forgot-password', function () {
    this.route('confirmation');
  });

  this.route('reset-password', { path: '/store/customers/password/edit' });

  this.route('register', function () {
    this.route('confirmation');
  });

  this.route('billpay');
  this.route('error/404', { path: '/*' });
  this.route('site-maintenance');
  this.route('registration-confirmation');

  this.route('checkout', function () {
    this.route(
      'punchout',
      { path: 'punchout/:procurement_id' },
      function () {}
    );
  });

  this.route('secure-checkout', { path: 'cart' }, function () {
    this.route('billing');
    this.route('confirmation');
    this.route('error');
    this.route('processing');
    this.route('review');
  });

  this.route('summit-logo');
  this.route('logos');
  this.route('link-your-business');
  this.route('account-services');
  this.route('order-pad', function () {});
  this.route('buy-online', function () {});
  this.route('multi-order-pad', function () {});
  this.route('search');
  this.route('page', { path: '*page' });
});
