import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AccountProjectModel from 'shoelace/models/account-project';
import type AccountProjectCircuitModel from 'shoelace/models/account-project-circuit';
import type AccountProjectOrderModel from 'shoelace/models/account-project-order';

export default class AccountProjectDrawingModel extends Model {
  @attr('string') name!: null | string;

  @belongsTo('account-circuit')
  accountProjectCircuit!: AsyncBelongsTo<AccountProjectCircuitModel>;

  @belongsTo('account-project')
  accountProject!: AsyncBelongsTo<AccountProjectModel>;

  @hasMany('account-project-order')
  accountProjectOrders!: AsyncHasMany<AccountProjectOrderModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-project-drawing': AccountProjectDrawingModel;
  }
}
