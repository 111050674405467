import Route from '@ember/routing/route';
import AccountVerificationModel from 'shoelace/models/account-verification';
import CustomerRegistrationModel from 'shoelace/models/customer-registration';
import type StoreService from '@ember-data/store';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface RouteModel {
  accountVerification: AccountVerificationModel;
  customerRegistration: CustomerRegistrationModel;
}

class RegisterIndexRoute extends Route {
  @service declare store: StoreService;

  async model(): Promise<RouteModel> {
    const accountVerification = this.store.createRecord('account-verification');
    const customerRegistration = this.store.createRecord(
      'customer-registration'
    );

    return {
      accountVerification,
      customerRegistration,
    };
  }
}

export default instrumentRoutePerformance(RegisterIndexRoute);
