import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface RouteParams {
  account_id: string | number;
}

class AdminAccountRoute extends Route {
  @service declare store: StoreService;

  async model({ account_id: id }: RouteParams) {
    const account = await this.store.findRecord('account', id, {
      include: [
        'customer-notes',
        'customer-notes.creator',
        'customer-notes.customer',
        'customers',
      ].join(','),
    });

    return account;
  }
}

export default instrumentRoutePerformance(
  AdminAccountRoute
);