import Helper from '@ember/component/helper';

export default Helper.extend({
  compute([text, terms]) {
    const s = String.fromCharCode(0),
      e = String.fromCharCode(1);
    return String(terms)
      .split(' ')
      .filter(n => n)
      .sort((a, b) => a.length - b.length)
      .reduce(
        (output, term) =>
          output.replace(
            new RegExp(escapeRegExp(term), 'gi'),
            match => s + match + e,
          ),
        text,
      )
      .replace(new RegExp(s, 'g'), '<span class="search-term">')
      .replace(new RegExp(e, 'g'), '</span>');
  },
});

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
