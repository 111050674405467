import JWTAuthenticator from 'ember-simple-auth-token/authenticators/jwt';
import fetch from 'fetch';

export default class AsAuthenticator extends JWTAuthenticator {
  async authenticate(token, id) {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await fetch(`/api/tokens/${id}`, { headers });
    const json = await response.json();

    return this.handleAuthResponse(json);
  }
}
