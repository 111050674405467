import Route from '@ember/routing/route';
import {
  dontRefreshModel,
  refreshModel,
} from '../../../../../utils/query-params';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

class StoreServicesMyProjectsOrderIndexRoute extends Route {
  @service store;

  queryParams = {
    search: refreshModel,
    sort: refreshModel,
    toggled: dontRefreshModel,
  };

  model(...args) {
    return this.loadModel(...args);
  }

  async loadModel({ search, sort }) {
    const accountProjectOrder1 = this.modelFor(
      'store.services.my-projects.order'
    );
    const accountProjectOrderId = accountProjectOrder1.get('id');

    const accountProjectOrder = await this.store.findRecord(
      'account-project-order',
      accountProjectOrderId,
      {
        include: ['account-order'].join(','),
      },
    );

    const accountPO = await this.store.findRecord(
      'account-order',
      accountProjectOrderId
    );
    

    const accountProject = await accountProjectOrder.get('accountProject');

    const accountProjectOrderLineItems = await this.store.query(
      'account-project-order-line-item',
      {
        filter: {
          'account-project-order': accountProjectOrderId,
          search,
        },
        include: [
          'account-order-line-item',
          'account-order-line-item.product',
          'account-project-order',
          'account-project-order-line-item-attributes',
          'account-project-order-line-item-attributes.account-project-order-line-item-attribute-definition',
          'account-project-order-line-item-deliveries',
          'account-project-order-line-item-deliveries.account-project-order-line-item',
          'account-project-order-line-item-deliveries.account-project-order-line-item-delivery-attributes',
          'account-project-order-line-item-deliveries.account-project-order-line-item-delivery-attributes.account-project-order-line-item-delivery-attribute-definition',
        ].join(','),
        sort,
      }
    );

    return {
      accountProject,
      accountProjectOrder,
      accountProjectOrderLineItems,
      accountPO,
    };
  }
}

export default instrumentRoutePerformance(
  StoreServicesMyProjectsOrderIndexRoute
);
