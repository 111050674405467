import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { belongsTo } from '@ember-data/model';
import type ClassificationModel from 'shoelace/models/classification';

export default class ClassificationCatalogModel extends Model {
  @belongsTo('classification')
  catalog!: AsyncBelongsTo<ClassificationModel>;

  @belongsTo('classification')
  classification!: AsyncBelongsTo<ClassificationModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'classification-catalog': ClassificationCatalogModel;
  }
}
