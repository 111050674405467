import Controller from '@ember/controller';
import { inject } from '@ember/service';
import { computed } from '@ember/object';

export default Controller.extend({
  session: inject(),

  isPunchout: computed(
    'session.session.authenticated.authenticator',
    function () {
      return (
        this.get('session.session.authenticated.authenticator') ==
        'authenticator:po2go'
      );
    },
  ),
});
