import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import SignInModel from 'shoelace/models/sign-in';
import { refreshModel } from 'shoelace/utils/query-params';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type SessionService from 'shoelace/services/session';
import type RouterService from '@ember/routing/router';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface LoginRouteModel {
  showContinueAsGuest: boolean;
  signIn: SignInModel;
  target: string;
}

export interface RouteParams {
  guest: boolean;
}

class LoginRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare router: RouterService;

  queryParams = {
    guest: refreshModel,
  };

  @tracked target = '';

  async beforeModel(transition: any) {
    this.target = transition?.from?.name ?? '';
    const customer = await this.session.customer;
    if (customer) {
      if (this.target !== '') {
        this.router.transitionTo(this.target);
      } else {
        this.router.transitionTo('store.customer', customer.id);
      }
    }
  }

  async model({ guest }: RouteParams): Promise<LoginRouteModel> {
    const showContinueAsGuest = !!guest;
    const signIn = this.store.createRecord('sign-in');

    return {
      showContinueAsGuest,
      signIn,
      target: this.target,
    };
  }
}
export default instrumentRoutePerformance(LoginRoute);
