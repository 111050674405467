import Route from '@ember/routing/route';
import TranslationModel from 'shoelace/models/translation';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { instrumentRoutePerformance } from '@sentry/ember';
import type RouterService from '@ember/routing/router';
import SessionService from 'shoelace/services/-session';

export interface RouteModel {
  newTranslation: TranslationModel;
}

class AdminNotificationsNewTranslationRoute extends Route {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare router: RouterService; 

  async model(): Promise<RouteModel> {

    const customer = await this.session.get('customer');

    if (customer) {
      if (customer?.hasRbacAdminCredit || customer?.hasRbacCustomerAdmin) {
        this.router.transitionTo('store.customer', customer.id);
      } 
    }
    const newTranslation = this.store.createRecord('translation');

    return {
      newTranslation,
    };
  }
}

export default instrumentRoutePerformance(
  AdminNotificationsNewTranslationRoute
);
