import Helper from '@ember/component/helper';
import { get } from '@ember/object';

const uniques = (v, i, a) => a.indexOf(v) === i;

export default Helper.extend({
  compute([array, key = null]) {
    if (key) {
      return array.map(i => get(i, key)).filter(uniques);
    } else {
      return array.filter(uniques);
    }
  },
});
