import Helper from '@ember/component/helper';

export default Helper.extend({
  compute([o, all, index]) {
    try {
      return (
        JSON.stringify(o.firstObject.data) ==
        JSON.stringify(all[index].firstObject.data)
      );
    } catch (e) {
      return false;
    }
  },
});
