import Model, { attr } from '@ember-data/model';

export default class VideoModel extends Model {
  @attr('string') youtubeId!: null | string;
  @attr('string') description!: null | string;
  @attr('string') name!: null | string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    video: VideoModel;
  }
}
