import RestApiAdapter from './rest-api';

export default class SearchAdapter extends RestApiAdapter {
  namespace = 'store';

  urlForQueryRecord() {
    return `${this.buildURL()}/products.json`;
  }

  sortQueryParams(params) {
    return Object.keys(params)
      .filter(param => !!params[param])
      .reduce((query, key) => (query[key] = params[key] && query), {});
  }
}
