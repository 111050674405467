import ApplicationSerializer, { exclude, include } from './application';

export default class AccountOpenItemReceiptSerializer extends ApplicationSerializer {
  attrs = {
    accountOpenItems: include,
    amount: exclude,
    billingAddress: exclude,
    billingAddress2: exclude,
    billingCity: exclude,
    billingCountryId: exclude,
    billingFirstname: exclude,
    billingLastname: exclude,
    billingState: exclude,
    billingZip: exclude,
    clientAccountNumber: exclude,
    failCode: exclude,
    partialAcctNumber: exclude,
    routingTransitNumber: exclude,
    transactionId: exclude,
    userDefined1: exclude,
    userDefined2: exclude,
    userDefined3: exclude,
  };
}
