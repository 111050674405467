import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { refreshModel } from '../../../utils/query-params';
import { instrumentRoutePerformance } from '@sentry/ember';
import type RouterService from '@ember/routing/router';
import SessionService from 'shoelace/services/-session';

export interface RouteParams {
  page: string | number;
  size: string | number;
  sort: string | number;
}

class AdminManageAccountsRoute extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService; 
  @service declare store: StoreService;

  queryParams = {
    page: refreshModel,
    size: refreshModel,
    sort: refreshModel,
  };
 
  async model({ page: number, size, sort }: RouteParams) {
   const customer = await this.session.get('customer');
   const accounts = await this.store.query('account', {
    filter: {
      account_customer: customer?.id,
    },
    page: {
      number,
      size,
    },
    sort,
  });

    return {
      accounts,
      customer
    };
  }
}

export default instrumentRoutePerformance(
  AdminManageAccountsRoute
);


  