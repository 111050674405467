import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { saveAs } from 'file-saver';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class StoreServicesMyProjectsProjectIndexController extends Controller {
  queryParams = ['page', 'search', 'size', 'sort'];

  @service session;

  @tracked page = 1;
  @tracked size = 10;
  @tracked sort = '-updated-at';
  @tracked term = '';
  @tracked search = [];

  @task(function* (accountProject) {
    const token = this.session.token;

    if (isPresent(token)) {
      const id = accountProject.get('id');
      const name = accountProject.get('name');
      const url = `/api/account-projects/${id}.xlsx`;

      const init = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = yield fetch(url, init);

      if (response.status < 200 || response.status >= 300) {
        const error = new Error(response.statusText);

        error.response = response;

        throw error;
      }

      const blob = yield response.blob();

      try {
        saveAs(blob, `${name}.xlsx`);
      } catch (exception) {
        console.error(exception);
      }
    } else {
      return;
    }
  })
  exportToExcel;
}
