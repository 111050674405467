import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import TranslationModel from 'shoelace/models/translation';
import { EXCEPTION_1068 } from 'shoelace/exceptions/mod';
import { inject as service } from '@ember/service';
//import type RollbarService from 'shoelace/services/rollbar';

export default class AdminDynamicTranslationsIndexController extends Controller {
  // queryParams = ['page', 'size', 'sort'];

  @tracked
  page = 1;

  @tracked
  size = 10;

  @tracked
  sort = 'id';

//@service declare rollbar: RollbarService;

  @action async onRemoveTranslation(
    translation: TranslationModel,
  ): Promise<void> {
    try {
      await translation.destroyRecord();
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1068, error);

      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/dynamic-translations/index': AdminDynamicTranslationsIndexController;
  }
}
