import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { inject } from '@ember/service';
import ArrayProxy from '@ember/array/proxy';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';

const ArrayPromiseProxy = ArrayProxy.extend(PromiseProxyMixin);

let debounce = false;

let winner = 0;

function asPromiseArray(promise) {
  return ArrayPromiseProxy.create({ promise });
}
const wordsOfString = word => String(word).split(' ').filter(w => !!w).filter((w, i, all) => all.indexOf(w) === i);
export default Component.extend({
// public properties
  search: null,
  category: null,
  manufacturers: null,
  sc_filter: null, // "any" = entire site, "selected" = just selected service center
  groupBy: null,
  prevbuy: null,
  advocator: null,
  selectedCatalogId: null,

  tagName: '',

// private
  page: 1,
  perPage: 24,
  totalResults: 0,
  localResults: 0,

  catalog: inject(),
  store: inject(),
  session: inject(),
  serviceCenter: inject(),

  logSearch: true,

  searchResponseCategories: [],
  searchResponseManufacturers: [],
  searchResponseAttributes: [],

  enable: null,

  runningQuery: false,

  showSummitApproved: true,

  // always an array, even if user passes a single or none at all
  mfrs: computed('manufacturers', function() {
    const manufacturers = get(this, 'manufacturers');
    if (typeof manufacturers == "string") {
      return manufacturers.split(',').filter(v => !!v)
    } else if (manufacturers && manufacturers instanceof Array) {
      return manufacturers.filter((v, i, all) => all.indexOf(v) == i);
    } else {
      return [];
    }
  }),

  init() {
    this._super(...arguments);
  //   set(this, 'page', 0);
  //   set(this, 'perPage', 24);
  //   set(this, 'totalResults', 0);
  //   set(this, 'localResults', 0);
  //   set(this, 'all', []);
  //   set(this, 'searchResponseCategories', []);
  //   set(this, 'searchResponseManufacturers', []);
  },

  customer: computed('session.customerRecord', function() {
    return get(this, 'session.customerRecord');
  }),

  /* public */
  within(newPhrase) {
    set(this, 'search', (get(this, 'search') || "") + " " + newPhrase);
  },

  /* public */
  hasMore: computed('all', 'sc_filter', 'localResults', 'totalResults', function() {
    const entireSite = get(this, 'sc_filter') == "any";
    const max = entireSite? get(this, 'totalResults'): get(this, 'localResults');
    const all = get(this, 'all');
    return all.length < max;
  }),

  numberOfPages: computed('sc_filter', 'localResults', 'totalResults', 'perPage', 'page', function(){
    const entireSite = get(this, 'sc_filter') == "any";
    const isSquareD = this.manufacturers == "2376" ||
      ['square d', 'square-d', 'squared'].any(term =>
        this.search?.toLowerCase()?.includes(term),
    );
    const perPage = get(this, 'perPage');
    const max = entireSite || isSquareD ? get(this, 'totalResults') : get(this, 'localResults');
    const pages = Math.ceil(max / perPage);
    return pages;
  }),

  pages: computed('numberOfPages', function() {
    const n = get(this, 'numberOfPages');
    if (n > 0) {
      return Array.from(Array(n).keys()).map(a => a + 1);
    } else {
      return [];
    }
  }),

  hasMoreThan15Results: computed('all', 'sc_filter', 'localResults', 'totalResults', function() {
    const entireSite = get(this, 'sc_filter') == "any";
    const max = entireSite? get(this, 'totalResults'): get(this, 'localResults');
    return max > 15;
  }),

  /* public */
  hasResults: computed('all.length', function() {
    const all = get(this, 'all');
    return all.length > 0;
  }),

  /* public */
  isLoading: computed('all.isPending', function() {
    const all = get(this, 'all');
    return all.length == 0 && get(all, 'isPending');
  }),

  all: computed('page', 'serviceCenter.serviceCenter.id', 'manufacturers', 'category', 'search', 'sc_filter', 'attributes', 'size', 'groupBy', 'prevbuy', 'advocator', 'selectedCatalogId', function () {
    if(debounce) clearTimeout(debounce);
    let voter = ++winner;
    return asPromiseArray(new Promise(done => {
      debounce = setTimeout(() => {
        const page = get(this, 'page');
        if (get(this, 'enable') === false) return;

        set(this, 'searchResponseCategories', []);
        set(this, 'searchResponseManufacturers', []);
        set(this, 'sizes', []);

        const params = {};
        const store = get(this, 'store');
        const searchTerms = (get(this, 'search') || "").trim() || null;
        const manufacturers = get(this, 'mfrs');
        const selectedCatalogId = get(this, 'catalog.id') || get(this, 'selectedCatalogId');
        const group = get(this, 'groupBy');
        let prevbuy = get(this, 'prevbuy');
        let advocator = get(this, 'advocator');
        const category = (function(cats) {
          if(!cats) return cats;
          if(cats.includes("%")) return cats;
          return cats.split('/').map(encodeURIComponent).filter(n=>n).join("/");
        })(get(this, 'category'));

        const attributes = get(this, 'attributes');
        const perPage = get(this, 'perPage');
        const size = get(this, 'size');

        params.service_center_id = get(this, 'serviceCenter.serviceCenter.id');
        params.sc_filter = get(this, 'sc_filter');
        if (!params.service_center_id) {
          params.sc_filter = "any";
          // set(this, 'sc_filter', "bob");
        }

        params.page = { number: page, size: perPage };

        if (searchTerms) {
          params.search = searchTerms;
        }
        if (manufacturers) {
          params.manufacturer = manufacturers.join(','); // intentionally singular for compatability reasons
        }
        if (category) {
          params.category = category;
        }

        if (attributes) {
          params.filters = attributes;
        }

        if (selectedCatalogId){
          params.catalog = selectedCatalogId;
        }

        if(size || size == "0") {
          params.size = size;
        }

        if(group) {
          if(category && group == "category" && category.split('/').length > 3) {
            set(this, 'groupBy', null);
            return;
          } else {
            params.group = group;
          }
        }

        if (prevbuy){
          if(prevbuy == "within" && (!searchTerms || searchTerms.trim() == "")) prevbuy = "only";
          params.prevbuy = prevbuy;
        }

        if (advocator){
          params.advocator = advocator;
        }
        params.log = this.logSearch;

        const m = store.query('product', params); // this caches loading results (see search adapter: queryRecord)
        m.then(m => {
          set(m, 'sc_filter', params.sc_filter);
          set(m, 'search', params.search);
          set(m, 'page', params.page);
          set(this, 'totalResults', get(m, 'meta.entire'));
          set(this, 'localResults', get(m, 'meta.local'));
          return m;
        }).then(results => {
          if(winner > voter) return;

          if(group == "category" ) {
            let prop = "meta.entire";
            if(params.sc_filter == "selected" &&  params.service_center_id) {
              prop = "meta.local";
            }
            const count = get(results, prop);
            if(count > 0 && get(results, 'meta.groups').length == 0) {
              set(this, 'groupBy', null);
              return;
            }
          }

          const resultManufacturers = get(results, 'meta.manufacturers').toArray();
          const resultCategories = get(results, 'meta.categories').toArray();
          const allCategories = get(results, 'meta.sub_class').toArray();

          // eliminate duplicate mfrs and then sort by count
          set(this, 'searchResponseManufacturers', resultManufacturers.filter((m, i, all) => {
            if(get(this, "showSummitApproved")) {
              return all.map(c => get(c, "esID")).indexOf(get(m, 'esID')) == i;
            } else {
              return m.name !="Summit Approved" && all.map(c => get(c, "esID")).indexOf(get(m, 'esID')) == i;
            }
          }).sort((b, a) => {
            return get(a, 'resultCount') - get(b, 'resultCount');
          }));
          get(this, 'store').query('manufacturer', {
            filter: {
              id: resultManufacturers.map(m => get(m, 'esID')).join(',')
            }
          }).then(mfrs => {
            mfrs.forEach(mfr => {
              get(this, 'searchResponseManufacturers').forEach(mfrR => {
                if(mfrR.esID == get(mfr, 'id')) {
                  set(mfrR, 'model', mfr);
                }
              })
            })
          });

          // eliminate duplicate cats and then sort by count
          set(this, 'searchResponseCategories', resultCategories.filter((m, i, all) => {
            return all.map(c => get(c, "esID")).indexOf(get(m, 'esID')) == i;
          }).filter(a => a.esID != get(this, 'category')).sort((b, a) => {
            return get(a, 'resultCount') - get(b, 'resultCount');
          }).map (cat=> {
            cat.children=allCategories.filter((m, i, all) => {
              if (m.name.indexOf(cat.name) == 0) {
                return true;
              } else {
                return false;
              }
            });
            return cat;
          }));
          set(this, 'sizes', get(results, 'meta.sizes').toArray());
          set(this, 'sizeUOMs', get(results, 'meta.sizes').toArray().map(size => {
            const v = get(size, 'key').split(' ')
            return v[v.length - 1]
          })
          .filter((v, i, a) => a.indexOf(v) == i)
          .filter(v => isNaN(parseFloat(v)))
          .map(uom => ({
            uom,
            uomsToShow:4,
            sizes: get(results, 'meta.sizes').toArray().filter(size => size.key.includes(uom)).filter(size => uom != "AWG" || (size.key.includes('.') == false))
          })));

          const topHit = get(results, 'meta.top_hit');
          let topHitInGroup = false;

          let actualGroup = get(results, 'meta.groups').sort((a, b) => {
            if(group == "size") {
              if(a.name == "") {
                return 1;
              } else if (b.name == "") {
                return -1;
              } else {
                return a.size - b.size;
              }
            } else if (group == "category") {
              return b.relevancy - a.relevancy;
            }
          }).map((group, ix) => {
            topHitInGroup = topHitInGroup || group.products.map(p => topHit == p.id).filter(v => v).length > 0;
            return Object.assign(group, {
              products: group.products.slice(0, 4).map (product => store.peekRecord( 'product', product.id  ))
            });
          })
          if(group == "size") {
            actualGroup = actualGroup.map(group => {
              group.name = [group.name];
              return group;
            })
          } else if (group == "category") {
            actualGroup = actualGroup.map(group => {
              group.name = group.name
                .split('/')
                .map(decodeURIComponent)
                .filter(v => !["", "Summit Hierarchy Root", "Classification 1 root"].includes(v));
              return group;
            })
          } else if (group == "manufacturer") {
            actualGroup = actualGroup.map(group => {
              group.name = [group.name];
              return group;
            });
          }
          set(this, 'groups', actualGroup);

          if(topHit) {
            const product = results.toArray().filter(product => product.id == topHit)[0];
            if(product && searchTerms) {
              const lowerCaseSearchTerms = searchTerms.toLowerCase();
              const matnr = (get(product, "matnr") || "").toLowerCase();
              const summitPartNumber = (get(product, "summitPartNumber") || "").toLowerCase();
              const manufacturerPartNumber = (get(product, "manufacturerPartNumber") || "").toLowerCase();
              const upc = get(product, "upc") || '';
              if(matnr.includes(lowerCaseSearchTerms) || summitPartNumber.includes(lowerCaseSearchTerms) || manufacturerPartNumber.includes(lowerCaseSearchTerms) || upc.includes(lowerCaseSearchTerms)) {
                topHitInGroup = false;
              }
            }
          }
          set(this, "showTopHit", topHit && !topHitInGroup); //results.length == 1 && topHit && actualGroup.length > 0 && !topHitInGroup)
          if(topHit) {
            set(this, 'topHit', topHitInGroup? null: store.peekRecord( 'product', topHit ))
          }

          set(this, 'queryParams', get(results, 'meta.query'))

          debounce = false;
          done(results);
        });
      }, 50);
    }))
  }),

  selectedServiceCenter: computed('serviceCenter.serviceCenter.id', function() {
    return get(this, 'serviceCenter.serviceCenter');
  }),

  phrases: computed('search', function() {
    return wordsOfString(get(this, 'search'));
  }),

  categoryModel: computed('category', function() {
    const key = get(this, 'category');
    if (key) {
      return get(this, 'store').peekRecord('search-response-category', key);
    } else {
      return null;
    }
  }),

  searchMeta: computed('hasMore', 'isLoading', 'hasMoreThan15Results', function() {
    const categories = get(this, 'searchResponseCategories'),
      manufacturers = get(this, 'searchResponseManufacturers'),
      attributes = get(this, 'searchResponseAttributes'),
      totalResults = get(this, 'totalResults'),
      localResults = get(this, 'localResults'),
      rawSearch = get(this, 'search'),
      hasMoreThan15Results = get(this, 'hasMoreThan15Results'),
      terms = wordsOfString(rawSearch),
      page = get(this, 'page'),
      sizes = get(this, 'sizes'),
      groups = get(this, 'groups'),
      showTopHit = get(this, 'showTopHit'),
      topHit = get(this, 'topHit'),
      query = get(this, 'queryParams'),
      pages = get(this, 'pages');
    return {
      categories,
      manufacturers,
      totalResults,
      localResults,
      attributes,
      terms,
      page,
      pages,
      rawSearch,
      within: this.within.bind(this),
      hasMore: get(this, 'hasMore'),
      hasMoreThan15Results: hasMoreThan15Results,
      isLoading: get(this, 'isLoading'),
      hasResults: get(this, 'hasResults'),
      sizes,
      groups,
      showTopHit,
      topHit,
      query,
      sizeUOMs: get(this, 'sizeUOMs'),
      promise: get(this, 'promise')
    };
  })
});
