import Helper from '@ember/component/helper';

export default Helper.extend({
  compute([string]) {
    const str = string.match(/[a-zA-Z]+/g);
    const val = string.replace(str, '').trim();
    if (/^\d+$/g.test(val)) {
      // eg "½" should fail and not send a unit.. and eg 10inches should return inches
      return str && str[0] ? str[0] : '';
    } else {
      return '';
    }
  },
});
