/* eslint-disable ember/no-get */
/* eslint-disable ember/classic-decorator-no-classic-methods */
/* eslint-disable ember/no-computed-properties-in-native-classes */
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { action, computed } from '@ember/object';
import {
  deprecatingAlias,
  not,
  notEmpty,
  readOnly,
  equal,
  or,
} from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';
import type AccountModel from 'shoelace/models/account';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type AccountVerificationModel from 'shoelace/models/account-verification';
import type PermissionModel from 'shoelace/models/permission';
import type RbacAccountCustomerPermissionModel from 'shoelace/models/rbac-account-customer-permission';
import type RbacAccountCustomerRoleModel from 'shoelace/models/rbac-account-customer-role';
import type RbacCustomerPermissionModel from 'shoelace/models/rbac-customer-permission';
import type RbacCustomerRoleModel from 'shoelace/models/rbac-customer-role';
import type RoleModel from 'shoelace/models/role';
import type ServiceCenterModel from 'shoelace/models/service-center';
import type RbacRoleModel from 'shoelace/models/rbac-role';
import type RbacPermissionModel from './rbac-permission';

const Validations = buildValidations({
  firstName: {
    description: 'First Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'First Name Required',
      }),
    ],
  },
  lastName: {
    description: 'Last Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Last Name Required',
      }),
    ],
  },
  email: {
    description: 'Email',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Email Required',
      }),
      validator('format', {
        type: 'email',
        message: 'Invalid email format',
      }),
    ],
  },
  requestedPermissions: [
    validator('presence', {
      presence: true,
      disabled: not('model.requestedPermissionsRequired'),
      message: 'Please select at least one permission for the new customer.',
    }),
  ],

  address1: validator('length', {
    max: 60,
    message: 'Address cannot be more than {max} characters.',
  }),

  address2: validator('length', {
    max: 60,
    message: 'Address cannot be more than {max} characters.',
  }),

  city: validator('length', {
    max: 40,
    message: 'City cannot be more than {max} characters.',
  }),

  state: validator('length', {
    min: 2,
    max: 3,
    message: 'State cannot be more than {max} characters and less than {min}.',
  }),

  zip: validator('length', {
    max: 10,
    message: 'Zip cannot be more than {max} characters.',
  }),

  cellPhone: validator('length', {
    max: 30,
    message: 'Cell phone number cannot be more than {max} characters.',
  }),

  officePhone: validator('length', {
    max: 30,
    message: 'Office phone number cannot be more than {max} characters.',
  }),
});

export interface SearchHistory {
  id: number;
  customer_id: number;
  service_center_id: number;
  created_at: string;
  updated_at: string;
  attribute_filters: null;
  category_path: string;
  manufacturers: number[];
  search: string;
}

export default class CustomerModel extends Model.extend(Validations) {
  @attr('string')
  declare firstName?: string;

  @attr('string')
  declare lastName?: string;

  @attr('string')
  declare email?: string;

  @attr('string')
  declare officePhone?: string;

  @attr('string')
  declare cellPhone?: string;

  @attr('string')
  declare city?: string;

  @attr('string')
  declare state?: string;

  @attr('string')
  declare zip?: string;

  @attr('boolean')
  declare isInternal?: boolean;

  @attr('string')
  declare sapLogin?: string;

  @attr('string')
  declare signInCount?: string;

  @attr('date')
  declare createdAt?: Date;

  @attr('date')
  declare lastSignInAt?: Date;

  @attr('boolean')
  declare createdAsGuest?: boolean;

  @attr('boolean')
  declare hasCustomerDefault?: boolean;

  @attr('boolean')
  declare hasCustomerFinancial?: boolean;

  @attr('boolean')
  declare hasCustomerProjects?: boolean;

  @attr('boolean')
  declare hasCustomerOrdering?: boolean;

  @attr('boolean')
  declare hasCustomerPunchout?: boolean;

  @attr('boolean')
  declare hasCustomerLineItemShipto?: boolean;

  @attr('boolean')
  declare hasCustomerRemoveRealTimeInventory?: boolean;

  @attr('boolean')
  declare hasRbacAdmin?: boolean;

  @attr('boolean')
  declare hasRbacAdminCredit?: boolean;

  @attr('boolean')
  declare hasRbacAdminMarketing?: boolean;

  @attr('boolean')
  declare hasRbacAssociate?: boolean;

  @attr('boolean')
  declare hasRbacCustomer?: boolean;

  @attr('boolean')
  declare hasCustomerManageUser?: boolean;
  
  @attr('boolean')
  declare hasRbacCustomerAdmin?: boolean;
  
  @attr('boolean')
  declare hasRbacCustomerRole?: boolean;

  @attr('boolean')
  declare hasRbacCustomerCheckout?: boolean;
  
  @attr('boolean')
  declare hasCustomerRestrictionsServiceCenter?: boolean;
  
  @attr('boolean')
  declare isDisabled?: boolean;

  @attr('date')
  declare updatedAt?: Date;

  @attr('string')
  declare address1?: string;

  @attr('string')
  declare address2?: string;

  @attr('string') 
  declare account_id?: string;

  @attr('string') 
  declare defaultServiceCenterId?: string;

  @attr()
  declare lastSearch?: SearchHistory;

  @belongsTo('account', { inverse: null })
  declare account?: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-job-account')
  declare accountJobAccount?: AsyncBelongsTo<AccountJobAccountModel>;

  @belongsTo('service-center')
  declare serviceCenter?: AsyncBelongsTo<ServiceCenterModel>;

  @hasMany('account-verification')
  accountVerifications!: AsyncHasMany<AccountVerificationModel>;

  @hasMany('account')
  accounts!: AsyncHasMany<AccountModel>;

  @hasMany('customer-note')
  customerNotes: any;

  // @hasMany('permission')
  // aclAccountPermissions!: AsyncHasMany<PermissionModel>;

  @hasMany('permission')
  aclPermissions!: AsyncHasMany<PermissionModel>;

  // @hasMany('rbac-permission')
  // rbacPermission!: AsyncHasMany<RbacPermissionModel>;

  @hasMany('rbac-role')
  rbacRole!: AsyncHasMany<RbacRoleModel>;

  @hasMany('permission')
  acls!: AsyncHasMany<PermissionModel>;

  @hasMany('rbac-account-customer-permission')
  rbacAccountCustomerPermissions!: AsyncHasMany<RbacAccountCustomerPermissionModel>;

  @hasMany('rbac-account-customer-role')
  rbacAccountCustomerRoles!: AsyncHasMany<RbacAccountCustomerRoleModel>;

  @hasMany('rbac-customer-permission')
  rbacCustomerPermissions!: AsyncHasMany<RbacCustomerPermissionModel>;

  @hasMany('rbac-customer-role')
  rbacCustomerRoles!: AsyncHasMany<RbacCustomerRoleModel>;

  @hasMany('role')
  roles!: AsyncHasMany<RoleModel>;

  @tracked requestedPermissions: string[] = [];
  @tracked requestedPermissionsRequired = false;

  @deprecatingAlias('accountJobAccount', {
    id: 'customer.jobAccount',
    until: 'next',
  })
  jobAccount!: AsyncBelongsTo<AccountJobAccountModel>;

  @notEmpty('accounts') hasAccount!: boolean;
  @notEmpty('acl') hasGlobal!: boolean;

  @readOnly('account.id') currentAccountNumber!: null | string;
  @readOnly('accountJobAccount.id') activeJobAccountNumber!: null | string;
  // @readOnly('serviceCenter.id') defaultServiceCenterId!: null | number;

  // @computed('acls.@each.name')
  // get acl(): string[] {
  //   return this.get('acls').mapBy('name');
  // }

  // @computed('aclPermissions.@each.name')
  // get permissions(): string[] {
  //   return this.get('aclPermissions').mapBy('name');
  // }

  // @computed('rbacPermission.@each.name')
  // get permissions(): string[] {
  //   return this.get('rbacPermission').mapBy('name');
  // }


  // @computed('aclAccountPermissions.@each.name')
  // get accountPermissions(): string[] {
  //   return this.get('aclAccountPermissions').mapBy('name');
  // }

  // @computed('aclPermissions.@each.name')
  // get hasAMPS(): boolean {
  //   return (
  //     this.get('aclPermissions')?.isAny('name', 'account::project_management') ??
  //     false
  //   );
  // }

  // @computed('aclPermissions.@each.name')
  // get hasCreditAdmin(): boolean {
  //   return this.get('aclPermissions')?.isAny('name', 'credit::manage') ?? false;
  // }

  // @computed('rbacRole.@each.name')
  // get hasRbacRootAdmin(): boolean {
  //   return this.get('rbacRole')?.isAny('rolename', 'root') ?? false;
  // }

  // @computed('rbacRole.@each.name')
  // get hasRbacAdmin(): boolean {
  //   return this.get('rbacRole')?.isAny('rolename', 'admin') ?? false;
  // }

  // @computed('rbacRole.@each.name')
  // get hasRbacCreditAdmin(): boolean {
  //   return this.get('rbacRole')?.isAny('rolename', 'admin.credit') ?? false;
  // }

  // @computed('rbacRole.@each.name')
  // get hasRbacCreditMarketing(): boolean {
  //   return this.get('rbacRole')?.isAny('rolename', 'admin.marketing') ?? false;
  // }

  // @computed('rbacRole.@each.name')
  // get hasRbacAssociate(): boolean {
  //   return this.get('rbacRole')?.isAny('rolename', 'associate') ?? false;
  // }


  // @computed('rbacRole.@each.name')
  // get hasCustomerDefault(): boolean {
  //   return this.get('rbacRole')?.isAny('rolename', 'customer.default') ?? false;
  // }

  // @computed('rbacRole.@each.name')
  // get hasCustomerPunchout(): boolean {
  //   return this.get('rbacRole')?.isAny('rolename', 'customer.punchout') ?? false;
  // }


  // @computed('rbacRole.@each.name')
  // get hasCustomerProjects(): boolean {
  //   return this.get('rbacRole')?.isAny('rolename', 'customer.projects') ?? false;
  // }


  // @computed('rbacRole.@each.name')
  // get hasCustomerOrdering(): boolean {
  //   return this.get('rbacRole')?.isAny('rolename', 'customer.ordering') ?? false;
  // }

  // @computed('rbacRole.@each.name')
  // get hasCustomerFinancial(): boolean {
  //   return this.get('rbacRole')?.isAny('rolename', 'customer.financial') ?? false;
  // }



  // @computed('aclAccountPermissions.@each.name')
  // get hasAccountFinancials(): boolean {
  //   return (
  //     this.get('aclAccountPermissions')?.isAny('name', 'account::financial') ??
  //     false
  //   );
  // }

  // @computed('acls.@each.name')
  // get hasGlobalAccountFinancials(): boolean {
  //   return this.get('acls')?.isAny('name', 'account::financial') ?? false;
  // }

  // @computed('aclAccountPermissions.@each.name')
  // get hasOrdering(): boolean {
  //   return (
  //     this.get('aclAccountPermissions')?.isAny('name', 'account::ecommerce') ??
  //     false
  //   );
  // }

  // @computed('aclPermissions.@each.name')
  // get hasAdmin(): boolean {
  //   return (
  //     this.get('aclPermissions')?.isAny('name', 'all::manage') ??
  //     false
  //   );
  // }

  // @computed('acls.@each.name')
  // get hasGlobalOrdering(): boolean {
  //   return this.get('acls')?.isAny('name', 'account::ecommerce') ?? false;
  // }

  // this is bad, we do not want to do actions in our model layer
  @action hasPermission(permission: string): boolean {
    return this.get('aclPermissions').isAny('name', permission);
  }

  // this is bad, we do not want to do actions in our model layer
  // @action hasAccountPermission(permission: string): boolean {
  //   return this.get('aclAccountPermissions').isAny('name', permission);
  // }

  @computed('firstName', 'lastName')
  get name(): string {
    return `${this.get('firstName') ?? ''} ${this.get('lastName') ?? ''}`;
  }

  // this is bad, needs to be refactored to use the router properly
  @computed('id')
  get lists_link(): string {
    return `/store/customers/${this.get('id')}/lists`;
  }

  @equal('router.currentRouteName', 'store.accounts.index')
  hideSearchForAccount!: boolean;
  @equal('session.session.authenticated.authenticator', 'authenticator:po2go')
  isPunchout!: boolean;
  @or('hasOrdering', 'isPunchout') hasQuotes!: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    customer: CustomerModel;
  }
}
