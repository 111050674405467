import type CartLineItemModel from 'shoelace/models/cart-line-item';
import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';

export default class CartAvailabilityModel extends Model {
  @attr('boolean') batch!: false | boolean;
  @attr('boolean') nonBatch!: false | boolean;

  @belongsTo('cart-line-item')
  cartLineItem?: AsyncBelongsTo<CartLineItemModel>;
}
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    CartAvailability: CartAvailabilityModel;
  }
}
