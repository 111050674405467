import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { action, computed } from '@ember/object';
import { not, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';
import type CartService from 'shoelace/services/cart';
import { v1 } from 'uuid';
import orderDictionary, { getDisplayForKind } from '../order_dictionary';
import AccountModel from './account';
import AccountAddressModel from './account-address';
import AccountJobAccountModel from './account-job-account';
import CustomerModel from './customer';
import ServiceCenterModel from './service-center';

const Validations = buildValidations({
  guestEmail: {
    description: 'Email Address',
    validators: [
      validator('presence', {
        presence: true,
        disabled: not('model.isGuest'),
        message: 'Email Address Required',
      }),
      validator('format', {
        type: 'email',
        disabled: not('model.isGuest'),
        message: 'Invalid email format',
      }),
    ],
  },
  billingContactFirstName: {
    description: 'Billing Contact First Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Billing Contact First Name Required',
      }),
    ],
  },
  billingContactLastName: {
    description: 'Billing Contact Last Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Billing Contact Last Name Required',
      }),
    ],
  },
  deliveryContactFirstName: {
    description: 'Delivery Contact First Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Delivery Contact First Name Required',
      }),
    ],
  },
  deliveryContactLastName: {
    description: 'Delivery Contact Last Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Delivery Contact Last Name Required',
      }),
    ],
  },
  billingContactPhone: {
    description: 'Billing Contact Phone',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Billing Contact Phone Required',
      }),
      validator('format', {
        type: 'phone',
      }),
      validator('length', {
        min: 10,
        max: 11,
        message: 'Phone number must be 10 or 11 digits',
      }),
    ],
  },
  deliveryContactPhone: {
    description: 'Delivery Contact Phone',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Delivery Contact Phone Required',
      }),
      validator('format', {
        type: 'phone',
      }),
      validator('length', {
        min: 10,
        max: 11,
        message: 'Phone number must be 10 or 11 digits',
      }),
    ],
  },
  purchaseOrder: {
    description: 'Purchase Order Number',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Purchase Order Number Required',
      }),
    ],
  },
  paymentMethod: {
    description: 'Payment Method',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Payment Method Required',
      }),
    ],
  },
  deliveryMethod: {
    description: 'Delivery Method',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Delivery Method Required',
      }),
    ],
  },
  creditCardName: {
    description: 'Credit Card Name',
    validators: [
      validator('presence', {
        presence: true,
        disabled: not('model.creditCard'),
        message: 'Credit Card Name Required',
      }),
    ],
  },
  creditCardToken: {
    description: 'Credit Card Number',
    validators: [
      validator('presence', {
        presence: true,
        disabled: not('model.creditCard'),
        message: 'Credit Card Number Required',
      }),
    ],
  },
  creditCardExpirationMonth: {
    description: 'Credit Card Month',
    validators: [
      validator('presence', {
        presence: true,
        disabled: not('model.creditCard'),
        message: 'Credit Card Month Required',
      }),
      validator('number', {
        allowString: true,
        disabled: not('model.creditCard'),
        gte: 1,
        integer: true,
        lte: 12,
        message: 'Credit Card Month Invalid',
      }),
    ],
  },
  creditCardExpirationYear: {
    description: 'Credit Card Year',
    validators: [
      validator('presence', {
        presence: true,
        disabled: not('model.creditCard'),
        message: 'Credit Card Year Required',
      }),
      validator('number', {
        allowString: true,
        disabled: not('model.creditCard'),
        gte: 21,
        integer: true,
        lte: 99,
        message: 'Credit Card Year Invalid',
      }),
    ],
  },
  creditCardCvv: {
    description: 'Credit Card CVV',
    validators: [
      validator('presence', {
        presence: true,
        disabled: not('model.creditCard'),
        message: 'Credit Card CVV Required',
      }),
      validator('length', {
        min: 3,
        max: 4,
        disabled: not('model.creditCard'),
        message: 'Credit Card CVV Invalid',
      }),
    ],
  },
  creditCardZipcode: {
    description: 'Credit Card Zip Code',
    validators: [
      validator('presence', {
        presence: true,
        disabled: not('model.creditCard'),
        message: 'Credit Card Zip Code Required',
      }),
      validator('length', {
        is: 5,
        disabled: not('model.creditCard'),
        message: 'Credit Card Zip Code Invalid',
      }),
    ],
  },
  codAccountAddress: {
    description: 'Address',
    validators: [
      validator('presence', {
        presence: true,
        disabled: reads('model.account.id'),
        message: 'Address Required',
      }),
    ],
  },
  codAccountCity: {
    description: 'City',
    validators: [
      validator('presence', {
        presence: true,
        disabled: reads('model.account.id'),
        message: 'City Required',
      }),
    ],
  },
  codAccountRegion: {
    description: 'State',
    validators: [
      validator('presence', {
        presence: true,
        disabled: reads('model.account.id'),
        message: 'State Required',
      }),
    ],
  },
  codAccountZip: {
    description: 'Zip Code',
    validators: [
      validator('presence', {
        presence: true,
        disabled: reads('model.account.id'),
        message: 'Zip Code Required',
      }),
    ],
  },
});

export default class ProcurementModel extends Model.extend(Validations) {
  @service declare cart: CartService;

  @attr('boolean') isCustomerCashTerm!: null | boolean;
  @attr('boolean') isGuest!: null | boolean;
  @attr('date') confirmedAt!: null | Date;
  @attr('date') expectedAt!: null | Date;
  @attr('date') updatedAt!: null | Date;
  @attr('number') creditCardExpirationMonth!: null | number;
  @attr('number') creditCardExpirationYear!: null | number;
  @attr('number') creditCardKind!: null | string;
  @attr('number') salesTax!: null | number;
  @attr('number') total!: null | number;
  @attr('string') billingContactFirstName!: null | string;
  @attr('string') billingContactLastName!: null | string;
  @attr('string') billingContactPhone!: null | string;
  @attr('string') billingContactPhoneExtension!: null | string;
  @attr('string') creditCardName!: null | string;
  @attr('string') creditCardToken!: null | string;
  @attr('string') creditCardZipcode!: null | string;
  @attr('string') deliveryContactFirstName!: null | string;
  @attr('string') deliveryContactLastName!: null | string;
  @attr('string') deliveryContactPhone!: null | string;
  @attr('string') deliveryContactPhoneExtension!: null | string;
  @attr('string') deliveryMethod!: null | string;
  @attr('string') errorKind!: null | string;
  @attr('string') externalId!: null | string;
  @attr('string') kind!: null | string;
  @attr('string') notes!: null | string;
  @attr('string') paymentMethod!: null | string;
  @attr('string') procurementState!: null | string;
  @attr('string') purchaseOrder!: null | string;

  @attr('string') guestEmail!: null | string;
  @attr('string') guestToken!: null | string;

  @attr('string') codAccountAddress!: null | string;
  @attr('string') codAccountCity!: null | string;
  @attr('string') codAccountCompany!: null | string;
  @attr('string') codAccountCountry!: null | string;
  @attr('string') codAccountDistrict!: null | string; // county
  @attr('string') codAccountRegion!: null | string; // state
  @attr('string') codAccountTitle!: null | string;
  @attr('string') codAccountZip!: null | string;

  @attr() externalResult!: null | Record<string, unknown>;
  @attr() inputReferences!: null | Record<string, unknown>;
  @attr() lineItems!: null | Record<string, unknown>[];

  @tracked creditCardNumber: null | string = null;
  @tracked creditCardCvv: null | string = null;
  @tracked deliveryBillingContactSame = true;
  @tracked willCallShipTo: null | AccountAddressModel = null;
  @tracked zcod: null | string = null;
  @tracked isCod = false;

  @tracked activeAccountAddress!: AccountAddressModel; // To display address under Ship in Billing -> Delivery Method

  @belongsTo('account', { inverse: null })
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-address', { inverse: null })
  accountAddress!: AsyncBelongsTo<AccountAddressModel>;

  @belongsTo('account-job-account', { inverse: null })
  accountJobAccount!: AsyncBelongsTo<AccountJobAccountModel>;

  @belongsTo('customer', { inverse: null })
  customer!: AsyncBelongsTo<CustomerModel>;

  @belongsTo('service-center', { inverse: null })
  serviceCenter!: AsyncBelongsTo<ServiceCenterModel>;

  @computed('errorKind')
  get hasAuthorizationErrorKind(): boolean {
    return /^authorization_.*$/.test(this.errorKind ?? '');
  }

  @computed('accountAddress.id', 'deliveryMethod')
  get pickup(): boolean {
    return this.deliveryMethod === 'will_call';
  }

  @computed('accountAddress', 'serviceCenter')
  get summitTruckAvailable(): boolean {
    // const serviceCenter = get(this, 'serviceCenter');
    // const shipTo = get(this, 'accountAddress');

    // console.group('TODO: Light this up');
    // console.log('is %o available to %o', shipTo, serviceCenter);
    // console.groupEnd();

    return true;
  }

  @computed('kind')
  get displayKind(): string | undefined {
    return getDisplayForKind(this.kind);
  }

  @computed('paymentMethod')
  get creditCard(): boolean {
    return this.paymentMethod === orderDictionary.PAYMENT_METHOD.CREDIT_CARD;
  }

  @computed('cart.hasUnorderable')
  get areCartContentsOrderable(): boolean {
    return !this.cart.hasUnorderable;
  }

  @computed('pickup')
  get shipOrder(): boolean {
    return !this.pickup;
  }

  @computed('account')
  get billableToAccount(): boolean {
    // const account = get(this, 'account');
    // if acocunt's terms are nt00 this should be false otherwise true?
    return true;
  }

  @action duplicate() {
    const duplicateProcurement = this.store.createRecord('procurement', {
      id: null,
      activeAccountAddress: this.activeAccountAddress,
      isCustomerCashTerm: this.isCustomerCashTerm,
      isGuest: this.isGuest,
      confirmedAt: this.confirmedAt,
      expectedAt: this.expectedAt,
      updatedAt: this.updatedAt,
      creditCardExpirationMonth: this.creditCardExpirationMonth,
      creditCardExpirationYear: this.creditCardExpirationYear,
      creditCardKind: this.creditCardKind,
      salesTax: this.salesTax,
      total: this.total,
      billingContactFirstName: this.billingContactFirstName,
      billingContactLastName: this.billingContactLastName,
      billingContactPhone: this.billingContactPhone,
      billingContactPhoneExtension: this.billingContactPhoneExtension,
      creditCardName: this.creditCardName,
      creditCardToken: this.creditCardToken,
      creditCardZipcode: this.creditCardZipcode,
      deliveryContactFirstName: this.deliveryContactFirstName,
      deliveryContactLastName: this.deliveryContactLastName,
      deliveryContactPhone: this.deliveryContactPhone,
      deliveryContactPhoneExtension: this.deliveryContactPhoneExtension,
      deliveryMethod: this.deliveryMethod,
      errorKind: this.errorKind,
      externalId: this.externalId,
      guestEmail: this.guestEmail,
      kind: this.kind,
      notes: this.notes,
      paymentMethod: this.paymentMethod,
      procurementState: this.procurementState,
      purchaseOrder: this.purchaseOrder,
      externalResult: this.externalResult,
      inputReferences: this.inputReferences,
      lineItems: this.lineItems,
    });

    duplicateProcurement.creditCardNumber = this.creditCardNumber;
    duplicateProcurement.creditCardCvv = this.creditCardCvv;
    duplicateProcurement.deliveryBillingContactSame =
      this.deliveryBillingContactSame;
    duplicateProcurement.willCallShipTo = this.willCallShipTo;
    duplicateProcurement.zcod = this.zcod;

    return duplicateProcurement;
  }

  @action clearCreditCardFields() {
    this.set('creditCardName', null);
    this.set('creditCardNumber', null);
    this.set('creditCardExpirationMonth', null);
    this.set('creditCardExpirationYear', null);
    this.set('creditCardCvv', null);
    this.set('creditCardZipcode', null);
    this.set('creditCardKind', null);
    this.set('creditCardToken', null);

    if (this.hasAuthorizationErrorKind) {
      this.set('errorKind', null);
    }
  }

  @action generateID(): string {
    this.set('id', v1());

    return this.id;
  }

  @computed('kind')
  get isKindCounterOrder(): boolean {
    return this.kind === orderDictionary.KIND.SAPCOUNTERORDER;
  }

  @computed('kind')
  get checkoutButtonLabel(): string {
    if (this.kind === orderDictionary.KIND.PUNCHOUT) {
      return 'Punchout';
    } else {
      return 'Proceed to Checkout';
    }
  }

  @computed('externalId')
  get orderNumber(): string {
    return (this.externalId ?? '').replace(/^0+/, '');
  }

  @computed('externalResult')
  get subtotal(): number {
    const externalResult = this.externalResult;

    if (!externalResult) {
      return 0;
    }

    const externalResultValidate = externalResult['sap.order.validate'] as Record<
      string,
      number
    >;

    if (!externalResultValidate) {
      return 0;
    }

    return externalResultValidate.subtotal;
  }

  @computed('externalResult')
  get creditBlocked(): boolean {
    const externalResult = this.externalResult;

    if (!externalResult) {
      return false;
    }

    const externalResultValidate = externalResult['sap.order.validate'] as Record<
      string,
      boolean
    >;

    if (!externalResultValidate) {
      return false;
    }

    return !externalResultValidate.valid;
  }

  @computed('creditCardNumber')
  get creditCardLastFour(): string {
    const cc = this.creditCardNumber?.replace(/\D+/g, '');

    return cc ? cc.substr(cc.length - 4) : '';
  }

  get isWillCall() {
    return this.paymentMethod === orderDictionary.PAYMENT_METHOD.WILL_CALL;
  }

  get isBillToAccount() {
    return this.paymentMethod === orderDictionary.PAYMENT_METHOD.ACCOUNT;
  }

  get isCreditCard() {
    return this.paymentMethod === orderDictionary.PAYMENT_METHOD.CREDIT_CARD;
  }

  get canBillToAccount() {
    return (
      this.isCustomerCashTerm !== true &&
      this.isGuest !== true &&
      isPresent(this.account?.get('id')) &&
      this.customer?.get('createdAsGuest') !== true
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    procurement: ProcurementModel;
  }
}
