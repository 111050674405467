import type StoreService from '@ember-data/store';
import { next } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import environment from 'shoelace/config/environment';
import type AccountModel from 'shoelace/models/account';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type ProductModel from 'shoelace/models/product';
import type ProductPriceModel from 'shoelace/models/product-price';
import type ServiceCenterModel from 'shoelace/models/service-center';
import type AccountService from 'shoelace/services/account';
import type AccountJobAccountService from 'shoelace/services/account-job-account';
import type ServiceCenterService from 'shoelace/services/service-center';
import { read } from '../utils';
import type { ObjectPromiseProxy } from '../utils/async-helpers';
import { AsyncObject } from '../utils/async-helpers';

const DEFAULT_ACCOUNT_ID: number = environment.defaults.account.id;
const MAX_CACHE_AGE_SECONDS = 60;
const MAX_CACHE_ENTRIES = 1000;

export default class ProductPriceService extends Service {
  @service declare account: AccountService;
  @service declare accountJobAccount: AccountJobAccountService;
  @service declare serviceCenter: ServiceCenterService;
  @service declare store: StoreService;

  cache: Map<string, Promise<ProductPriceModel>> = new Map();
  strictCache: Map<string, Promise<ProductPriceModel>> = new Map();

  async getSharedPrice(
    product: ProductModel,
    quantity: number,
    account?: AccountModel,
    accountJobAccount?: AccountJobAccountModel,
    serviceCenter?: string | number,
  ): Promise<ProductPriceModel> {

    const ProductPriceModels = await this.store.query('product-price', {
      filter: {
        'account-id': account?.id,
        'account-job-account-id': accountJobAccount?.id,
        'product-id': product?.id,
        'service-center-id': serviceCenter,
        quantity,
      },
    });

    if (ProductPriceModels.length > 1) {
      if (product.isPromotion) {
        if (ProductPriceModels?.firstObject?.id.split('-')[3] === "15") {
          const ProductPriceModel = ProductPriceModels.firstObject;
          ProductPriceModel.cachedAt = new Date();

          return ProductPriceModel;
        } else {
          if (ProductPriceModels?.lastObject?.id.split('-')[3] === "15") {
            const ProductPriceModel = ProductPriceModels.lastObject;
            ProductPriceModel.cachedAt = new Date();

            return ProductPriceModel;
          }
        }
      } else {
        if (ProductPriceModels?.firstObject?.id.split('-')[3] !== "15") {
          const ProductPriceModel = ProductPriceModels.firstObject;
          ProductPriceModel.cachedAt = new Date();

          return ProductPriceModel;
        } else {
          if (ProductPriceModels?.lastObject?.id.split('-')[3] !== "15") {
            const ProductPriceModel = ProductPriceModels.lastObject;
            ProductPriceModel.cachedAt = new Date();

            return ProductPriceModel;
          }
        }
      }
    } else {
      const ProductPriceModel = ProductPriceModels.firstObject;
      ProductPriceModel.cachedAt = new Date();

      return ProductPriceModel;
    }

    throw new Error(`could not find product-price for product ${product.id}`);
  }

  async getPriceStrict(
    product: ProductModel,
    quantity: number,
    account?: AccountModel,
    accountJobAccount?: AccountJobAccountModel,
    serviceCenterId?: string | number,
  ): Promise<ProductPriceModel> {
    const key = this.generatePriceIdKey(
      account,
      accountJobAccount,
      product,
      serviceCenterId,
      quantity,
    );

    if (this.strictCache.has(key)) {
      const cachedPrice = await this.strictCache.get(key);
      const isCacheExpired = this.isCacheExpired(cachedPrice);

      if (!isCacheExpired) {
        return cachedPrice!;
      } else {
        this.strictCache.delete(key);
      }
    }

    if (this.strictCache.size >= MAX_CACHE_ENTRIES) {
      const oldestKey = this.strictCache.keys().next().value;

      if (oldestKey) {
        this.strictCache.delete(oldestKey);
      }
    }

    this.strictCache.set(
      key,
      this.getSharedPrice(
        product,
        quantity,
        account,
        accountJobAccount,
        serviceCenterId,
      ),
    );

    return await this.strictCache.get(key)!;
  }

  generatePriceIdKey(
    account?: string | number | AccountModel,
    accountJobAccount?: string | number | AccountJobAccountModel,
    product?: string | number | ProductModel,
    serviceCenter?: string | number,
    quantity?: string | number,
  ): string {
    const accountId = read(account, 'id') ?? DEFAULT_ACCOUNT_ID;
    const accountJobAccountId = read(accountJobAccount, 'id') ?? '';
    const productId = read(product, 'id') ?? '';
    const serviceCenterId = serviceCenter ?? '';
    const quantityNumber = quantity ?? 1;

    return `${accountId}-${accountJobAccountId}-${productId}-${serviceCenterId}-${quantityNumber}`;
  }

  isCacheExpired(ProductPriceModel?: ProductPriceModel): boolean {
    if (!ProductPriceModel) {
      return true;
    } else {
      const now = new Date().getTime();
      const cachedAt = ProductPriceModel?.cachedAt?.getTime() ?? 0;
      const diff = (now - cachedAt) / 1000;

      return diff > MAX_CACHE_AGE_SECONDS;
    }
  }

  getPrice(
    product: number | string | ProductModel,
    qty: null | number | string | undefined,
    account: null | string | number | undefined | AccountModel,
    accountJobAccount:
      | null
      | string
      | number
      | undefined
      | AccountJobAccountModel,
    serviceCenter:
      | null
      | string
      | number
      | undefined
      | ServiceCenterModel
      | ServiceCenterService,
  ): ObjectPromiseProxy<ProductPriceModel> | ProductPriceModel | null {
    const quantity = qty || 1;
    const productId = read(product, 'id') ?? null;
    const accountId =
      read(account, 'id') ?? this.account.account?.get('id') ?? null;
    const serviceCenterId = serviceCenter ?? this.serviceCenter.id ?? null;
    const accountJobAccountId =
      read(accountJobAccount, 'id') ??
      this.accountJobAccount.accountJobAccount?.get('id') ??
      null;

    if (!productId || !serviceCenterId) return null;

    const idToPeek = `${accountId || environment.defaults.account.id
      }${productId}${serviceCenterId}`;
    const price = this.store.peekRecord('product-price', idToPeek);
    const success =
      price &&
      price.requestedQuantity == quantity &&
      price.accountJobAccountId === parseInt(`${accountJobAccountId}`, 10);

    if (price && success) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      price.accountJobAccountId = accountJobAccountId
        ? parseInt(`${accountJobAccountId}`, 10)
        : null;

      return price;
    } else {
      const filter = {
        quantity: quantity,
        'product-id': productId,
        'account-id': accountId,
        'service-center-id': serviceCenterId,
        'account-job-account-id': accountJobAccountId,
      };

      const key = idToPeek;

      if (!this.cache.has(key)) {
        const result = this.store
          .query('product-price', { filter })
          .then(price => {
            next(this, () => this.cache.delete(key));

            return price.firstObject!;
          });

        this.cache.set(key, result);
      }

      return AsyncObject(this.cache.get(key)!);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'product-price': ProductPriceService;
  }
}
