import Model, { attr } from '@ember-data/model';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';

const Validations = buildValidations({
  name: {
    description: 'Name', validators: [
      validator('presence', {
        presence: true,
        message: 'Name must not be blank.',
      }),
    ],
  },

  phone: {
    description: 'Phone Number',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Phone number must not be blank.',
      }),
      validator('format', {
        type: 'phone',
      }),
      validator('length', {
        min: 10,
        max: 11,
        message: 'Phone number must be 10 or 11 digits',
      }),
    ],
  },

  company: {
    description: 'Company Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Company Name must not be blank.',
      }),
    ],
  },

  email: {
    description: 'From Email Address',
    validators: [
      validator('format', {
        type: 'email',
        allowNonTld: false,
        message: 'Your email must not be blank.',
      }),
    ],
  },

  message: {
    description: 'Message',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Message field must not be blank.',
      }),
    ],
  },

  quantity: [
    validator('number', {
      integer: true, // whole numbers only
      allowString: true, // but string numbers are ok
      gte: 1, // 1 or more
      allowNone: false, // blank is unacceptable
      message: 'Must request 1 or more',
    }),
  ],

  contactPreference: {
    description: 'Contact Preference',
    validators: [
      validator('inclusion', {
        in: ['Email', 'Phone'],
      }),
    ],
  },

  location: {
    description: 'Location',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Location must not be blank.',
      }),
    ],
  },
});

export default class ProductQueryModel extends Model.extend(Validations) {
  @attr('string') declare name?: string;
  @attr('string') declare email?: string;
  @attr('string') declare phone?: string;
  @attr('string') declare company?: string;
  @attr('string') declare message?: string;
  @attr('string') declare job_account?: string;
  @attr('string') declare contactPreference?: string;
  @attr('string') declare location?: string;
  @attr('number') declare product_id?: number;
  @attr('number') declare quantity?: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'product-query': ProductQueryModel;
  }
}
