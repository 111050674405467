import Route from '@ember/routing/route';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface SecureCheckoutRouteModel {
  id?: number | string;
}

export interface SecureCheckoutRouteParams {
  id?: number | string;
}

class SecureCheckoutRoute extends Route {
  async model({
    id,
  }: SecureCheckoutRouteParams): Promise<SecureCheckoutRouteModel> {
    return {
      id,
    };
  }
}

export default instrumentRoutePerformance(
  SecureCheckoutRoute
);