import { Ability } from 'ember-can';

export default class CustomerAbility extends Ability {
  // get roles() {
  //   return this.model?.get('acl');
  // }

  // get canSwitch() {
  //   return this.model?.get('isInternal') && this.roles?.includes('all::manage');
  // }

  // get canLineItemShipTo() {
  //   return this.roles?.includes('cart::line-item-ship-to');
  // }
}
