import Controller from '@ember/controller';
import { action } from '@ember/object';
import { EXCEPTION_1040 } from 'shoelace/exceptions/mod';
//import type RollbarService from 'shoelace/services/rollbar';
import type { AsyncBelongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router';
import type ServiceCenter from 'shoelace/models/service-center';

export default class AdminServiceCentersNewServiceCenterController extends Controller {
//@service declare rollbar: RollbarService;

@service declare router: RouterService;

  @action async createServiceCenter(
    serviceCenter: AsyncBelongsTo<ServiceCenter>,
  ): Promise<void> {
    try {
      await serviceCenter.save();

      const serviceCenterId = await serviceCenter.get('id');

      this.router.transitionTo('admin.service-center', serviceCenterId);
    } catch (error) {
      //this.rollbar.critical(EXCEPTION_1040, error);
      throw error;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/service-centers/new-service-center': AdminServiceCentersNewServiceCenterController;
  }
}
