import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

const dontRefreshModel = { refreshModel: false };
const refreshModel = { refreshModel: true };

export const sortProperties = [
  { key: 'createdAt', value: 'Date' },
  { key: 'id', value: 'Quote #' },
  { key: 'purchaseOrder', value: 'PO #' },
  { key: 'total', value: 'Extended Price' },
];

class StoreAccountQuotesRoute extends Route {
  @service session;
  @service account;
  @service router;
  @service store;

  titleToken = 'Quotes';

  queryParams = {
    search: refreshModel,
    invalidSearch: refreshModel,
    sortAscending: dontRefreshModel,
    sortProperty: dontRefreshModel,
  };

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('search', null);
    }
  }

  async beforeModel(transition) {
    if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }
    const customer = await this.session.get('customerRecord');
    if (customer) {
 
      if ((!customer?.hasCustomerFinancial) && (!customer?.hasCustomerDefault) && (customer?.hasRbacCustomer)) {
        this.router.transitionTo('store.customer', customer.id);
      } 
        return await customer.get('account');  
  
    }
  }

  async model({
    search = '',
    invalidSearch,
    sortAscending = '',
    sortProperty = 'createdAt',
  }) {
    let accountQuotes = [];
    const sort = sortAscending ? `-${sortProperty}` : sortProperty;
    const controller = this.controllerFor('store.account-quotes');
    const accountId = this.session.customer.account.id;

    if (controller.hasSearch && search) {
      search = search.replace(/\s/g, '');
      return this.router.transitionTo('store.account-quote', search);
    } else if (controller.hasSearch && search === null) {
      invalidSearch = true;
    } else {
      accountQuotes = this.store.query('account-history-quote', {
        filter: {
          account: accountId,
        },
        sort,
      });
    }

    return {
      accountQuotes: accountQuotes,
      sortAscending: !!sortAscending,
      sortProperties,
      sortProperty,
      search,
      invalidSearch: invalidSearch || null,
    };
  }
  setupController(controller, model) {
    super.setupController(controller, model);

    controller.sortProperty = model.sortProperty;
    controller.sortAscending = model.sortAscending;
  }
}
export default instrumentRoutePerformance(StoreAccountQuotesRoute);
