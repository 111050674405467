import Helper from '@ember/component/helper';
import { get } from '@ember/object';

export default Helper.extend({
  compute(params) {
    let quantity = params[0];
    let invoice = params[1];

    const code = invoice.invoice_type_code;
    if (['RE', 'G2'].includes(code)) return -1 * quantity;
    return quantity;
  },
});
