
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router';
import SessionService from 'shoelace/services/-session';
export default class AdminAccountVerificationsApprovedRoute extends Route {

  @service declare session: SessionService;
  @service declare router: RouterService; 


  async model() {

    const customer = await this.session.get('customer');

    if (customer) {
      if (customer?.hasRbacCustomerAdmin) {
        this.router.transitionTo('store.customer', customer.id);
      } 
    }
   
  }
}
