import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import config from 'shoelace/config/environment';
import type { BootOptions } from 'shoelace/services/coordinator';
import type NoticeService from 'shoelace/services/notice';
import type PushService from 'shoelace/services/push';

interface Response {
  action: string;
  payload: Record<string, unknown>;
}

interface UpdateAvailablePayload {
  version?: string;
  title?: string;
  message?: string;
}

interface ForceUpdatePayload {
  version?: string;
  update_after_sec?: number;
}

export default class PushActionsService extends Service {
  @service declare notice: NoticeService;
  @service declare push: PushService;

  async onCoordinatorSetup(_options?: BootOptions): Promise<void> {}
  async onCoordinatorTeardown(): Promise<void> {}

  receive(response: Response) {
    try {
      const pushAction = this as any;

      pushAction[camelize(response.action)](response.payload);
    } catch (error) {
      // console.log('Error executing action', response?.action?.toString());
      // this.rollbar.warning(EXCEPTION_1080, error);
    }
  }

  /**
   * Actions that can be performed when message gets RECEIVED by this client
   */

  @action async updateAvailable(payload: UpdateAvailablePayload) {
    try {
      const { title, message, version } = payload;

      if (version !== config.APP.version) {
        const result = await this.notice.swal.fire({
          position: 'bottom-end',
          toast: true,
          title: title ?? `Update available!`,
          text: message ?? 'A new version of summit.com is available.',
          confirmButtonText: 'Reload To Update',
        });

        if (result.isConfirmed) {
          await this.push.acknowledge(`Updating to version ${version}`);

          window?.location?.reload?.();
        }
      }
    } catch (error) {
    }
  }

  @action forceUpdate(payload: ForceUpdatePayload) {
    try {
      const { version } = payload;

      const update_after_sec = payload.update_after_sec
        ? payload.update_after_sec * 1000
        : 10000;

      if (version !== config.APP.version) {
        setTimeout(() => {
          window?.location?.reload?.();
        }, update_after_sec);
      }
    } catch (error) {
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'push-actions': PushActionsService;
  }
}