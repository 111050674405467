import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Block::Form>\n  <:default as |form|>\n    <Block::FormGroup>\n      <Block::FormControlCheckbox class=\"w-full\">\n        <:label>\n          show project name with logo in the header\n        </:label>\n        <:default as |id ariaDescribedby className|>\n          <input\n            aria-describedby=\"{{this.ariaDescribedby}}\"\n            checked={{@accountProject.isNameVisible}}\n            class=\"{{className}}\"\n            id=\"{{this.id}}\"\n            type=\"checkbox\"\n            value=\"target.checked\"\n            {{on \"click\" (fn this.onCheckboxAccountProjectTitle @accountProject)}}\n          />\n        </:default>\n      </Block::FormControlCheckbox>\n    </Block::FormGroup>\n  </:default>\n</Block::Form>", {"contents":"<Block::Form>\n  <:default as |form|>\n    <Block::FormGroup>\n      <Block::FormControlCheckbox class=\"w-full\">\n        <:label>\n          show project name with logo in the header\n        </:label>\n        <:default as |id ariaDescribedby className|>\n          <input\n            aria-describedby=\"{{this.ariaDescribedby}}\"\n            checked={{@accountProject.isNameVisible}}\n            class=\"{{className}}\"\n            id=\"{{this.id}}\"\n            type=\"checkbox\"\n            value=\"target.checked\"\n            {{on \"click\" (fn this.onCheckboxAccountProjectTitle @accountProject)}}\n          />\n        </:default>\n      </Block::FormControlCheckbox>\n    </Block::FormGroup>\n  </:default>\n</Block::Form>","moduleName":"shoelace/components/apex/admin/manage-accounts/account-project.hbs","parseOptions":{"srcName":"shoelace/components/apex/admin/manage-accounts/account-project.hbs"}});
import { action } from '@ember/object';
import ObjectProxy from '@ember/object/proxy';
import Component from '@glimmer/component';
import AccountVerification from 'shoelace/models/account-verification';

interface ApexAdminManageAccountAccountProjectArgs {
  accountVerification?: AccountVerification & ObjectProxy<AccountVerification>;
  onCheckboxAccountProjectTitle?: () => void;
}

export default class ApexAdminManageAccountAccountProjectComponent extends Component<ApexAdminManageAccountAccountProjectArgs> {
  @action async onCheckboxAccountProjectTitle(
    accountVerification: AccountVerification & ObjectProxy<AccountVerification>,
    event: Event,
  ): Promise<void> {
    const checked = event?.target?.checked;
    accountVerification.set('isNameVisible', checked);
    await accountVerification.save();
  }
}
