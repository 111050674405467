import { helper } from '@ember/component/helper';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';

export function pick([key, action]) {
  return function pickCurry(object) {
    const value = isEmpty(object) ? undefined : get(object, key);

    if (isEmpty(action)) {
      return value;
    } else {
      return action(value);
    }
  };
}

export default helper(pick);
