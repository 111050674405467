import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import type AccountModel from 'shoelace/models/account';
import type AccountHistoryReturnModel from 'shoelace/models/account-history-return';
import type AccountHistoryReturnLineItemCommentModel from 'shoelace/models/account-history-return-line-item-comment';
import type AccountHistoryReturnLineItemDeliveryModel from 'shoelace/models/account-history-return-line-item-delivery';
import type AccountHistoryReturnLineItemInvoiceModel from 'shoelace/models/account-history-return-line-item-invoice';
import type AccountHistoryReturnLineItemOrderModel from 'shoelace/models/account-history-return-line-item-order';
import type AccountHistoryReturnLineItemQuoteModel from 'shoelace/models/account-history-return-line-item-quote';
import type AccountHistoryReturnShipAddressModel from 'shoelace/models/account-history-return-ship-address';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type ProductModel from 'shoelace/models/product';

export default class AccountHistoryReturnLineItemModel extends Model {
  @attr('string') customerPartNumber!: null | string;
  @attr('number') deliveredQuantity!: null | number;
  @attr('string') deliveryStatus!: null | string;
  @attr('boolean') isLotKit!: null | boolean;
  @attr('boolean') isSummitBuck!: null | boolean;
  @attr('string') name!: null | string;
  @attr('number') numberPerUnit!: null | number;
  @attr('number') position!: null | number;
  @attr('number') postGoodsIssueQuantity!: null | number;
  @attr('number') price!: null | number;
  @attr('number') pricePerUnit!: null | number;
  @attr('string') purchaseOrder!: null | string;
  @attr('number') quantity!: null | number;
  @attr('string') shippingType!: null | string;
  @attr('string') status!: null | string;
  @attr('number') total!: null | number;
  @attr('number') totalLot!: null | number;
  @attr('string') unitOfMeasure!: null | string;
  @attr('string') unknownItemType!: null | string;
  @attr('string') unknownTextFlag!: null | string;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-job-account')
  accountJobAccount!: AsyncBelongsTo<AccountJobAccountModel>;

  @belongsTo('account-history-return-ship-address')
  accountHistoryReturnShipAddress!: AsyncBelongsTo<AccountHistoryReturnShipAddressModel>;

  @belongsTo('account-history-return')
  accountHistoryReturn!: AsyncBelongsTo<AccountHistoryReturnModel>;

  @belongsTo('product')
  product!: AsyncBelongsTo<ProductModel>;

  @hasMany('account-history-return-line-item-comment')
  accountHistoryReturnLineItemComments!: AsyncHasMany<AccountHistoryReturnLineItemCommentModel>;

  @hasMany('account-history-return-line-item-delivery')
  accountHistoryReturnLineItemDeliveries!: AsyncHasMany<AccountHistoryReturnLineItemDeliveryModel>;

  @hasMany('account-history-return-line-item-invoice')
  accountHistoryReturnLineItemInvoices!: AsyncHasMany<AccountHistoryReturnLineItemInvoiceModel>;

  @hasMany('account-history-return-line-item-order')
  accountHistoryReturnLineItemOrders!: AsyncHasMany<AccountHistoryReturnLineItemOrderModel>;

  @hasMany('account-history-return-line-item-quote')
  accountHistoryReturnLineItemQuotes!: AsyncHasMany<AccountHistoryReturnLineItemQuoteModel>;

  @computed('deliveredQuantity', 'quantity')
  get remainingQuantity(): number {
    return (this.quantity ?? 0) - (this.deliveredQuantity ?? 0);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-return-line-item': AccountHistoryReturnLineItemModel;
  }
}
