import Model from '@ember-data/model';
import { attr } from '@ember-data/model';
import { computed, observer } from '@ember/object';
import { belongsTo } from 'ember-data/relationships';
import { isPresent } from '@ember/utils';
import { get } from '@ember/object';
import { set } from '@ember/object';

export default Model.extend({
  name: attr('string'),
  email: attr('string'),
  phone: attr('string'),
  company: attr('string'),
  to_email: attr('string'),
  message: attr('string'),
  job_account: attr('string'),
  contact_preference: attr('string'),
  extension: attr('string'),
  location: attr('string'),
  customer_id: attr('number'),
  product_id: attr('number'),
  quantity: attr('number'),
  include_order_items: attr('boolean'),
  include_request_info: attr('boolean'),

  customer: belongsTo('customer', { async: false }),
  product: belongsTo('product', { async: false }),

  _firstName: '',
  firstName: computed('name', 'lastName', '_firstName', {
    get() {
      var firstName = this._firstName;

      if (isPresent(firstName)) {
        return firstName;
      } else {
        var name = this.name;

        if (isPresent(name)) {
          var names = name.split(/\s+/);
          firstName = names[0];

          set(this, '_firstName', firstName);

          return firstName;
        } else {
          return '';
        }
      }
    },

    set(key, value) {
      var lastName = this.lastName;

      set(this, 'name', [value, lastName].join(' '));
      set(this, '_firstName', value);

      return value;
    },
  }),

  _lastName: '',
  lastName: computed('name', 'firstName', '_lastName', {
    get() {
      var lastName = this._lastName;

      if (isPresent(lastName)) {
        return lastName;
      } else {
        var name = this.name;

        if (isPresent(name)) {
          var names = name.split(/\s+/);
          lastName = names.slice(1).join(' ');

          set(this, '_lastName', lastName);

          return lastName;
        } else {
          return '';
        }
      }
    },

    set(key, value) {
      var firstName = this.firstName;

      set(this, 'name', [firstName, value].join(' '));
      set(this, '_lastName', value);

      return value;
    },
  }),

  phonePattern: computed('phone', function () {
    return '.{10,15}';
  }),

  emailRequired: computed('contact_preference', 'email', function () {
    if (this.contact_preference === this.email) {
      return true;
    } else {
      return false;
    }
  }),
  
  phoneRequired: computed('contact_preference', 'phone', function () {
    if (this.contact_preference === this.phone) {
      return true;
    } else {
      return false;
    }
  }),

  refreshValidators: observer('contact_preference', function () {
    if (typeof this.validate === 'function') {
      this.validate().then(Boolean).catch(Boolean);
    }
  }),
});
