import { AsyncBelongsTo } from '@ember-data/model';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import fetch from 'fetch';
import type Account from 'shoelace/models/account';
import type Customer from 'shoelace/models/customer';
import type SessionService from 'shoelace/services/session';

export default class AdminManageAccountNewCustomerController extends Controller {
  @service declare router: RouterService;
  @service declare session: SessionService;

  @action async onAdd(
    customer: AsyncBelongsTo<Customer>,
    account: AsyncBelongsTo<Account>,
  ): Promise<Response> {
    try {

      const token = this.session.token;
      const approver = await this.session.customer;
      const accountId = account["account"].id;

      const attributes = {
        approver: approver?.get('name'),
        first_name: customer.get('firstName'),
        last_name: customer.get('lastName'),
        email: customer.get('email'),
        phone: customer.get('officePhone'),
        account: accountId,
        permissions: customer.get('requestedPermissions')
      };

      return await fetch('/api/customers/maybe', {
        body: JSON.stringify({ data: { attributes } }),
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  @action onSuccess(account: AsyncBelongsTo<Account>): void {
    this.router.transitionTo('admin.manage-account', account["account"].id);
  }
}

declare module '@ember/controller' {
  interface Registry {
    'admin/manage-account/new-customer': AdminManageAccountNewCustomerController;
  }
}
