import ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type CartModel from 'shoelace/models/cart';
import { instrumentRoutePerformance } from '@sentry/ember';
import type KartService from 'shoelace/services/kart';
import type StoreService from '@ember-data/store';
import type SessionService from 'shoelace/services/session';
import type ServiceCenterService from 'shoelace/services/service-center';
import CartQueryModel from 'shoelace/models/cart-query';
import ProcurementService from 'shoelace/services/procurement';

export interface SecureCheckoutIndexRouteModel {
  id?: number | string;
  lists?: ArrayProxy<CartModel>;
  cartQuery: CartQueryModel;
}

export interface SecureCheckoutIndexRouteParams {
  id?: number | string;
}

class SecureCheckoutIndexRoute extends Route {
  @service declare kart: KartService;
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare serviceCenter: ServiceCenterService;
  @service declare procurement: ProcurementService;

  async model({
    id,
  }: SecureCheckoutIndexRouteParams): Promise<SecureCheckoutIndexRouteModel> {
    const customer = this.session.customer;
    let lists: any = [];
    if (customer) {
       lists =
        this.store.query('cart', {
          filter: {
            'customer-id': customer?.id,
            kind: 'list',
          },
        }) || [];
    }

    let cartQuery;
    let productList: never[] = [];

    if (customer) {
      cartQuery = this.store.createRecord('cart-query', {
        email: customer?.get('email'),
        customer_id: customer?.get('id'),
        account_id: customer?.account?.get('id'),
        phone: customer?.get('cellPhone'),
        name: customer?.get('name'),
        company: customer?.account?.get('name'),
        location: customer?.get('city'),
        state: customer?.get('state'),
        serviceCenter: this.serviceCenter.id,
      });
    } else {
      cartQuery = this.store.createRecord('cart-query', {});
    }

    return {
      id,
      lists,
      cartQuery,
    };
  }
}

export default instrumentRoutePerformance(SecureCheckoutIndexRoute);
