import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class QuickOrderPropertyGroupModel extends Model {
  @attr('string') name;

  @belongsTo('customer') customer;
  @belongsTo('quick-order-property-key') quickOrderPropertyKey;

  @hasMany('quick-order-component') quickOrderComponents;
  @hasMany('quick-order-property') quickOrderProperties;
}
