import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';
import type AccountPaymentLineItemModel from 'shoelace/models/account-payment-line-item';

export default class AccountPaymentModel extends Model {
  @attr('date') clearedAt!: null | Date;
  @attr('number') total!: null | number;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-job-account')
  accountJobAccount!: AsyncBelongsTo<AccountJobAccountModel>;

  @hasMany('account-payment-line-item')
  accountPaymentLineItems!: AsyncHasMany<AccountPaymentLineItemModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-payment': AccountPaymentModel;
  }
}
