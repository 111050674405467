import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { belongsTo } from '@ember-data/model';
import type ClassificationModel from 'shoelace/models/classification';
import type ProductModel from 'shoelace/models/product';

export default class ClassificationsProductModel extends Model {
  @belongsTo('product')
  products!: AsyncBelongsTo<ProductModel>;

  @belongsTo('classification')
  classifications!: AsyncBelongsTo<ClassificationModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'classifications-product': ClassificationsProductModel;
  }
}
