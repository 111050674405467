import Model, { attr } from '@ember-data/model';
import {
  buildValidations,
  validator,
} from '@summit-electric-supply/ember-cp-validations';

const Validations = buildValidations({
  name: {
    description: 'Name',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Name must not be blank.',
      }),
    ],
  },

  email: {
    description: 'Email Address',
    validators: [
      validator('format', {
        type: 'email',
        allowNonTld: false,
        message: 'Your email must not be blank.',
      }),
    ],
  },

  message: {
    description: 'Feedback Message',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Feedback Message field must not be blank.',
      }),
    ],
  },

  source: {
    description: 'Feedback Category',
    validators: [
      validator('inclusion', {
        in: [
          'Image',
          'Title',
          'Price',
          'Stock',
          'Description',
          'Product Specification',
          'Product Comparison',
          'Availability',
        ],
      }),
    ],
  },
});

export default class ProductFeedbackModel extends Model.extend(Validations) {
  @attr('string')
  declare name?: string;

  @attr('string')
  declare email?: string;

  @attr('string')
  declare message?: string;

  @attr('string')
  declare source?: string;

  @attr('number')
  declare product_id?: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'product-feedback': ProductFeedbackModel;
  }
}
