import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import CustomerModel from 'shoelace/models/customer';
import CustomerResetPasswordGuestModel from 'shoelace/models/customer-reset-password-guest';
import ProcurementModel from 'shoelace/models/procurement';
import { instrumentRoutePerformance } from '@sentry/ember';
import type SessionService from 'shoelace/services/-session';
import KartService, { Item } from 'shoelace/services/kart';
import type ProcurementService from 'shoelace/services/procurement';
import type AccountService from 'shoelace/services/account';
import type RouterService from '@ember/routing/router';
import type StoreService from '@ember-data/store';
import MetricsService from 'shoelace/services/metrics';

export interface SecureCheckoutConfirmationRouteModel {
  completedProcurement: ProcurementModel;
  customer: CustomerModel;
  id?: number | string;
  orderedItems: Item[];
  resetPassword: CustomerResetPasswordGuestModel;
}

export interface SecureCheckoutConfirmationRouteParams {
  id?: number | string;
}

class SecureCheckoutConfirmationRoute extends Route {
  @service declare account: AccountService;
  @service declare session: SessionService;
  @service declare procurement: ProcurementService;
  @service declare kart: KartService;
  @service declare router: RouterService;
  @service declare store: StoreService;
  @service declare metrics: MetricsService;

  async beforeModel() {
    if (this.procurement.procurement?.isNew) {
      return this.router.transitionTo('secure-checkout.billing');
    }
  }

  async afterModel() {
    this.procurement.procurement?.clearCreditCardFields();

    const accountId = this.account?.account?.id;
    if (accountId) {
      fetch(`/api/v2/cache-previously-purchased/${accountId}`, {
        method: 'POST',
      });
    }

    this.kart.items.forEach(async (item) => {
      await this.kart.removeItem(item.product);
    });

    await this.procurement.createRecord();
  }

  async model({
    id,
  }: SecureCheckoutConfirmationRouteParams): Promise<SecureCheckoutConfirmationRouteModel> {
    const orderedItems = this.kart.items;
    const completedProcurement = this.procurement.procurement!;
    const customer = this.session.customer!;
    const resetPassword = this.store.createRecord(
      'customer-reset-password-guest',
      {
        email: customer?.get('email'),
      }
    );

    return {
      completedProcurement,
      customer,
      id,
      orderedItems,
      resetPassword,
    };
  }
}

export default instrumentRoutePerformance(SecureCheckoutConfirmationRoute);
