import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class AdminAccountsIndexController extends Controller {
  queryParams = ['page', 'size', 'sort', 'search'];

  @tracked page = 1;
  @tracked size = 10;
  @tracked sort = 'id';
  @tracked search: string[] = [];
}

declare module '@ember/controller' {
  interface Registry {
    'admin/accounts/index': AdminAccountsIndexController;
  }
}
