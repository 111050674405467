import type ObjectProxy from '@ember/object/proxy';
import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type ServiceCenter from 'shoelace/models/service-center';
import type ServiceCenterDateHour from 'shoelace/models/service-center-date-hour';
import type ServiceCenterDateHourModel from 'shoelace/models/service-center-date-hour';
import { action } from '@ember/object';
import type StoreService from '@ember-data/store';
import { inject as service } from '@ember/service';
import { instrumentRoutePerformance } from '@sentry/ember';

export interface AdminServiceCenterEditServiceCenterDaysRouteModel {
  serviceCenter: ObjectProxy<ServiceCenter>;
  serviceCenterDateHours: ArrayProxy<ServiceCenterDateHour>;
  newServiceCenterDateHour: ServiceCenterDateHourModel;
}

class AdminServiceCenterEditServiceCenterDaysRoute extends Route {
  @service declare store: StoreService;
 
  async model(): Promise<AdminServiceCenterEditServiceCenterDaysRouteModel> {
    const serviceCenter = (await this.modelFor(
      'admin.service-center',
    )) as ObjectProxy<ServiceCenter>;

    const serviceCenterDateHours = await this.store.query(
      'service-center-date-hour',
      {
        filter: {
          serviceCenters: serviceCenter.get('id'),
        },
      },
    );

    const newServiceCenterDateHour = this.store.createRecord(
      'service-center-date-hour',
      {
        serviceCenter,
      },
    );

    return {
      serviceCenter,
      serviceCenterDateHours,
      newServiceCenterDateHour,
    };
  }

  @action willTransition() {
    const { newServiceCenterDateHour: model } = this.modelFor(this.fullRouteName);

    if (model.isNew) {
      model.deleteRecord();
    }
  }

}
export default instrumentRoutePerformance(
  AdminServiceCenterEditServiceCenterDaysRoute
);