import Model, { attr} from '@ember-data/model';

export default class CartStatesModel extends Model {
  @attr('string') created!: null | string;
  @attr('string') willPersist!: null | string;
}
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'cart-states': CartStatesModel;
  }
}

