import type { AsyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany } from '@ember-data/model';
import type AccountHistoryOrderModel from 'shoelace/models/account-history-order';

export default class AccountHistoryOrderShipAddressModel extends Model {
  @attr('string') city!: null | string;
  @attr('string') country!: null | string;
  @attr('string') county!: null | string;
  @attr('string') name!: null | string;
  @attr('string') name2!: null | string;
  @attr('string') poBox!: null | string;
  @attr('string') state!: null | string;
  @attr('string') street!: null | string;
  @attr('string') street2!: null | string;
  @attr('string') zipCode!: null | string;

  @hasMany('account-history-order')
  accountHistoryOrders!: AsyncHasMany<AccountHistoryOrderModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-order-ship-address': AccountHistoryOrderShipAddressModel;
  }
}
