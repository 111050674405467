import { computed } from '@ember/object';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

function asPromiseObject(promise) {
  return ObjectPromiseProxy.create({ promise });
}

export default class ModelForComponent extends Component {
  @service store;

  @tracked id = null;
  @tracked type = null;
  @tracked tagName = '';

  @computed('id', 'store', 'type')
  get model() {
    return asPromiseObject(
      new Promise(done => {
        this.store.findRecord(this.type, this.id).then(m => done(m));
      }),
    );
  }
}
