import ApplicationSerializer, { exclude } from './application';

export default class SlideSerializer extends ApplicationSerializer {
  attrs = {
    imagePreviewUrl: exclude,
    imageUrl: exclude,
    slideImageSmUrl: exclude,
    slideImageMdUrl: exclude,
    slideImageLgUrl: exclude,
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    slide: SlideSerializer;
  }
}
