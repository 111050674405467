import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.outputRichData}}\n  {{#in-element this.inElement insertBefore=null}}\n    <script type=\"application/ld+json\">\n      {{this.outputRichData}}\n    </script>\n  {{/in-element}}\n{{/if}}", {"contents":"{{#if this.outputRichData}}\n  {{#in-element this.inElement insertBefore=null}}\n    <script type=\"application/ld+json\">\n      {{this.outputRichData}}\n    </script>\n  {{/in-element}}\n{{/if}}","moduleName":"shoelace/components/apex/manufactures/rich-data-manufactures.hbs","parseOptions":{"srcName":"shoelace/components/apex/manufactures/rich-data-manufactures.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ManufacturerModel from 'shoelace/models/manufacturer';

interface RichDataManufacturesComponentArgs {
  manufactures: Array<ManufacturerModel>;
}

export default class RichDataManufacturesComponent extends Component<RichDataManufacturesComponentArgs> {
  @tracked manufactures = this.args.manufactures;

  get outputRichData() {
    const categories = [];
    const manufactures = this.manufactures._objects;

    if (manufactures) {
      for (let i = 0; i < manufactures.length; i++) {
        const baseUrl =
          'https://www.summit.com/store/mfr/' +
          manufactures[i].name.replace(/[^\w\s]/gi, '').replace('  ', ' ').replace(/\s+/g, '-').toLowerCase();

        categories.push({
          '@type': 'ListItem',
          position: i + 1,
          name: manufactures[i].name,
          item: {
            '@type': 'WebPage',
            '@id': baseUrl,
            name: manufactures[i].name,
            image: manufactures[i].logoUrl,
          },
        });
      }
    }

    return JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      name: 'Summit Electric Supply Manufacturers Brands',
      url: 'https://www.summit.com/store/manufacturers',
      itemListElement: categories,
    });
  }

  get inElement() {
    return document.head;
  }
}
