import Model, { attr, belongsTo } from '@ember-data/model';
import AccountModel from 'shoelace/models/account';
import AccountProjectModel from 'shoelace/models/account-project';

export default class TranslationModel extends Model {
  @attr('string')
  declare key?: string;

  @attr('string')
  declare value?: string;

  @attr('string')
  declare generated_key: string;

  @belongsTo('translation')
  translation?: TranslationModel;

  @belongsTo('account')
  account?: AccountModel;

  @belongsTo('account-project')
  accountProject?: AccountProjectModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    translation: TranslationModel;
  }
}
