import BaseAdapter from 'ember-metrics/metrics-adapters/base';
import { EXCEPTION_1075 } from 'shoelace/exceptions/mod';
import { inject as service } from '@ember/service';

// Recommended Events for GA4:
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events

declare const window: any;
declare const dataLayer: any;

function gtag() {
  dataLayer.push(arguments);
}

export interface GtagPageView {
  page_location?: string;
  client_id?: string;
  language?: string;
  page_encoding?: string;
  page_title?: string;
  user_agent?: string;
}

export default class Gtag extends BaseAdapter {
  config: any;

  toStringExtension() {
    return 'gtag';
  }

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    try {
      const { id } = this.config;

      const element: HTMLScriptElement = document.createElement('script');
      element.async = true;
      element.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;

      document.getElementsByTagName('head')[0].appendChild(element);

      window.dataLayer = window.dataLayer || [];
      window.gtag = window.gtag || gtag;

      window.gtag('js', new Date());
      window.gtag('config', id, this.config);
    } catch (error) {
      console.error(EXCEPTION_1075, error);

      throw error;
    }
  }

  identify() {
    const options = arguments[0];
    const user_id = options['user_id'];

    window.gtag('set', { user_id: user_id });
  }

  trackEvent() {
    let options = arguments[0];
    const eventName = options['_name'];

    delete options['_name'];

    window.gtag('event', eventName, options);
  }

  trackPage() {
    const options: GtagPageView = arguments[0];

    window.gtag('event', 'page_view', options);
  }

  alias() {
    console.log('alias gtag');
  }

  willDestroy() {
    console.log('willDestroy gtag');
  }
}
