import Helper from '@ember/component/helper';

export default Helper.extend({
  compute([array, defaultValue = '']) {
    try {
      if (array.length > 0) {
        return array[array.length - 1];
      } else {
        return defaultValue;
      }
    } catch (e) {
      return defaultValue;
    }
  },
});
