import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import type AccountModel from 'shoelace/models/account';

export default class AccountJobAccountModel extends Model {
  @attr('string') vbeln!: string;
  @attr('string') guebg!: string;
  @attr('string') gueen!: string;
  @attr('string') ktext!: string;
  @attr('string') name!: string;
  @attr('boolean') isActive!: boolean;
  @attr('date') startsAt!: Date;
  @attr('date') endsAt!: Date;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @computed('isActive')
  get optGroupLabel(): string {
    return this.isActive ? 'Open' : 'Closed';
  }

  @computed('id', 'ktext')
  get label(): string {
    return `${this.ktext} (${this.id})`;
  }

  @computed('isActive')
  get isClosed(): boolean {
    return !this.isActive;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-job-account': AccountJobAccountModel;
  }
}
