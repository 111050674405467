import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AccountModel from 'shoelace/models/account';
import type AccountHistoryReturnBillAddressModel from 'shoelace/models/account-history-return-bill-address';
import type AccountHistoryReturnCommentModel from 'shoelace/models/account-history-return-comment';
import type AccountHistoryReturnDeliveryModel from 'shoelace/models/account-history-return-delivery';
import type AccountHistoryReturnFundAddressModel from 'shoelace/models/account-history-return-fund-address';
import type AccountHistoryReturnInvoiceModel from 'shoelace/models/account-history-return-invoice';
import type AccountHistoryReturnLineItemModel from 'shoelace/models/account-history-return-line-item';
import type AccountHistoryReturnOrderModel from 'shoelace/models/account-history-return-order';
import type AccountHistoryReturnQuoteModel from 'shoelace/models/account-history-return-quote';
import type AccountHistoryReturnShipAddressModel from 'shoelace/models/account-history-return-ship-address';
import type AccountHistoryReturnSoldAddressModel from 'shoelace/models/account-history-return-sold-address';
import type AccountJobAccountModel from 'shoelace/models/account-job-account';

export default class AccountHistoryReturnModel extends Model {
  @attr('string') category!: null | string;
  @attr('date') createdAt!: null | Date;
  @attr('date') expectedAt!: null | Date;
  @attr('string') jobAccountName!: null | string;
  @attr('string') kind!: null | string;
  @attr('number') numberOfItems!: null | number;
  @attr('string') overallStatus!: null | string;
  @attr('string') purchaseOrder!: null | string;
  @attr('number') salesTax!: null | number;
  @attr('string') shippingType!: null | string;
  @attr('string') status!: null | string;
  @attr('number') subtotal!: null | number;
  @attr('number') total!: null | number;

  @belongsTo('account')
  account!: AsyncBelongsTo<AccountModel>;

  @belongsTo('account-job-account')
  accountJobAccount!: AsyncBelongsTo<AccountJobAccountModel>;

  @belongsTo('account-history-return-bill-address')
  accountHistoryReturnBillAddress!: AsyncBelongsTo<AccountHistoryReturnBillAddressModel>;

  @belongsTo('account-history-return-fund-address')
  accountHistoryReturnFundAddress!: AsyncBelongsTo<AccountHistoryReturnFundAddressModel>;

  @belongsTo('account-history-return-ship-address')
  accountHistoryReturnShipAddress!: AsyncBelongsTo<AccountHistoryReturnShipAddressModel>;

  @belongsTo('account-history-return-sold-address')
  accountHistoryReturnSoldAddress!: AsyncBelongsTo<AccountHistoryReturnSoldAddressModel>;

  @hasMany('account-history-return-comment')
  accountHistoryReturnComments!: AsyncHasMany<AccountHistoryReturnCommentModel>;

  @hasMany('account-history-return-line-item')
  accountHistoryReturnLineItems!: AsyncHasMany<AccountHistoryReturnLineItemModel>;

  @hasMany('account-history-return-delivery')
  accountHistoryReturnDeliveries!: AsyncHasMany<AccountHistoryReturnDeliveryModel>;

  @hasMany('account-history-return-invoice')
  accountHistoryReturnInvoices!: AsyncHasMany<AccountHistoryReturnInvoiceModel>;

  @hasMany('account-history-return-order')
  accountHistoryReturnOrders!: AsyncHasMany<AccountHistoryReturnOrderModel>;

  @hasMany('account-history-return-quote')
  accountHistoryReturnQuotes!: AsyncHasMany<AccountHistoryReturnQuoteModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-return': AccountHistoryReturnModel;
  }
}
