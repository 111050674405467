import Helper from '@ember/component/helper';
import { get } from '@ember/object';

export default Helper.extend({
  compute([attr]) {
    const blacklist = [
      'UNSPSC',
      'Approval',
      'Model',
      'Used On',
      'Standard Package',
      'Additional Information',
      'Vendor',
      'Vendor Name',
      'Brand Name',
      'Sub Brand',
      'Brand',
      'Image Location',
      'Commodity Code',
      'Vendor Number',
      'Vendor Short Name',
      'Upc',
      'Item Number',
      'Catalog Number',
      'UPC',
    ];
    const state = !blacklist.includes(attr.name);
    return state;
  },
});
