import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Block::Form>\n  <:default as |form|>\n    <Block::FormGroup>\n      <Block::FormControlCheckbox class=\"w-full\">\n        <:label>\n          Show account name and logo in header\n        </:label>\n        <:default as |id ariaDescribedby className|>\n          <input\n            aria-describedby=\"{{this.ariaDescribedby}}\"\n            checked={{@account.isNameVisible}}\n            class=\"{{className}}\"\n            id=\"{{this.id}}\"\n            type=\"checkbox\"\n            value=\"target.checked\"\n            {{on \"click\" (fn this.onCheckboxAccountLogoTitle @account)}}\n          />\n        </:default>\n      </Block::FormControlCheckbox>\n    </Block::FormGroup>\n  </:default>\n</Block::Form>\n", {"contents":"<Block::Form>\n  <:default as |form|>\n    <Block::FormGroup>\n      <Block::FormControlCheckbox class=\"w-full\">\n        <:label>\n          Show account name and logo in header\n        </:label>\n        <:default as |id ariaDescribedby className|>\n          <input\n            aria-describedby=\"{{this.ariaDescribedby}}\"\n            checked={{@account.isNameVisible}}\n            class=\"{{className}}\"\n            id=\"{{this.id}}\"\n            type=\"checkbox\"\n            value=\"target.checked\"\n            {{on \"click\" (fn this.onCheckboxAccountLogoTitle @account)}}\n          />\n        </:default>\n      </Block::FormControlCheckbox>\n    </Block::FormGroup>\n  </:default>\n</Block::Form>\n","moduleName":"shoelace/components/apex/admin/manage-accounts/account-logo.hbs","parseOptions":{"srcName":"shoelace/components/apex/admin/manage-accounts/account-logo.hbs"}});
import { action } from '@ember/object';
import ObjectProxy from '@ember/object/proxy';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Account from 'shoelace/models/account';

interface ApexAdminManageAccountAccountLogoArgs {
  account?: Account & ObjectProxy<Account>;
  isChecked?: boolean;
  onCheckboxAccountLogoTitle?: () => void;
}

export default class ApexAdminManageAccountAccountLogoComponent extends Component<ApexAdminManageAccountAccountLogoArgs> {
  @tracked isChecked = this.args.isChecked ?? false;

  @action async onCheckboxAccountLogoTitle(
    account: Account & ObjectProxy<Account>,
    event: Event,
  ): Promise<void> {
    const checked = event?.target?.checked;
    account.set('isNameVisible', checked);
    await account.save();
  }
}
