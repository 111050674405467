import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Block::FormGroup>\n  <Block::FormControl class=\"w-full lg:w-1/2\">\n    <:label>\n      Select\n    </:label>\n    <:default as |id ariaDescribedby className|>\n      <select\n        class={{className}}\n        data-tag=\"select\"\n        type=\"select\"\n        name=\"add-customer-to-catalog\"\n        {{on \"change\" (pick \"target.value\" this.change)}}\n      >\n        {{#if @prompt}}\n          <option disabled selected>{{@prompt}}</option>\n        {{/if}}\n        {{#each this.model as |model|}}\n          {{#if @key}}\n            <option value={{get model @key}}>{{get model @label}}</option>\n          {{else}}\n            <option value={{model}}>{{get model @label}}</option>\n          {{/if}}\n        {{/each}}\n      </select>\n    </:default>\n  </Block::FormControl>\n</Block::FormGroup>\n", {"contents":"<Block::FormGroup>\n  <Block::FormControl class=\"w-full lg:w-1/2\">\n    <:label>\n      Select\n    </:label>\n    <:default as |id ariaDescribedby className|>\n      <select\n        class={{className}}\n        data-tag=\"select\"\n        type=\"select\"\n        name=\"add-customer-to-catalog\"\n        {{on \"change\" (pick \"target.value\" this.change)}}\n      >\n        {{#if @prompt}}\n          <option disabled selected>{{@prompt}}</option>\n        {{/if}}\n        {{#each this.model as |model|}}\n          {{#if @key}}\n            <option value={{get model @key}}>{{get model @label}}</option>\n          {{else}}\n            <option value={{model}}>{{get model @label}}</option>\n          {{/if}}\n        {{/each}}\n      </select>\n    </:default>\n  </Block::FormControl>\n</Block::FormGroup>\n","moduleName":"shoelace/components/quick-order-guides/component-picker.hbs","parseOptions":{"srcName":"shoelace/components/quick-order-guides/component-picker.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class QuickOrderComponentPickerComponent extends Component {
  @tracked model    = this.args && this.args.model || [];
  @tracked onChange = this.args.onChange;
  @tracked kind     = this.args && this.args.kind;
  @tracked value;

  constructor() {
    super(...arguments);

    if (this.model.firstObject && (this.kind != "color") ) {
      this.change(this.model.firstObject.get('id'));
    }
  }

  @action change(value) {
    this.value = value;

    if (this.onChange) {
      this.args.onChange(value);
    }
  }
}