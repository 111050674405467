import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class StoreServicesMyProjectsProjectOrderController extends Controller {
  queryParams = ['page', 'search', 'size', 'sort'];

  @tracked sort = '-updated-at';
  @tracked search = [];

  @action toggleDeliveries(lineItem) {
    lineItem.toggleProperty('expanded');
  }
}
