import {
  validator,
  buildValidations,
} from '@summit-electric-supply/ember-cp-validations';
import Model, { attr } from '@ember-data/model';

const Validations = buildValidations({
  password: [
    validator('presence', {
      presence: true,
      message: 'Password must not be blank.',
    }),
    validator('format', {
      regex: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-ZÀ-ȕa-z0-9]).{8,72}$/,
      message:
        'Password should have 8 characters with a minimum: one uppercase, one lowercase, and one special character.',
    }),
  ],
  passwordConfirmation: [
    validator('presence', true),
    validator('confirmation', {
      on: 'password',
      message: 'Passwords do not match.',
    }),
  ],
});

export default class ResetPasswordModel extends Model.extend(Validations) {
  @attr('string')
  declare password?: string;

  @attr('string')
  declare passwordConfirmation?: string;

  @attr('string')
  declare reset_password_token?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'reset-password': ResetPasswordModel;
  }
}
