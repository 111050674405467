import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import AccountHistoryQuoteModel from 'shoelace/models/account-history-quote';

export default class AccountHistoryQuoteCommentModel extends Model {
  @attr('string') body!: null | string;

  @belongsTo('account-history-quotes')
  accountHistoryQuotes!: AsyncBelongsTo<AccountHistoryQuoteModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-history-quote-comment': AccountHistoryQuoteCommentModel;
  }
}
