import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { EXCEPTION_1049, EXCEPTION_1050 } from 'shoelace/exceptions/mod';
import CustomerModel from 'shoelace/models/customer';
import KartService, { Item } from 'shoelace/services/kart';
//import type RollbarService from 'shoelace/services/rollbar';
import { tracked } from '@glimmer/tracking';
import ProductModel from 'shoelace/models/product';
import CartModel from 'shoelace/models/cart';
import type StoreService from '@ember-data/store';
import { v1 } from 'uuid';

export default class StoreCustomerListController extends Controller {
@service declare kart: KartService;
@service declare store: StoreService;

//@service declare rollbar: RollbarService;

  @tracked productToCompare: ProductModel[] = [];

  @action selectProductToCompare(product: ProductModel) {
    try {
      if (this.productToCompare.includes(product)) {
        this.productToCompare.removeObject(product);
      } else {
        this.productToCompare.pushObject(product);
      }
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1048, error);
      throw error;
    }
  }

  @action onCloseCompare() {
    try {
      this.productToCompare = [];
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1048, error);
      throw error;
    }
  }

  @action async onEmailCartItems(
    items: Item[],
    customer: CustomerModel,
    to_email: string,
    message: string,
    onShare: boolean,
    list_cart: CartModel,
  ): Promise<void> {
    try {
      const cartInfo = this.store.createRecord('cart-info');
      if (onShare === false) {
        const legacyCart = await this.kart.generateLegacyCart(
          null,
          items,
          customer,
        );
        cartInfo.set('cart_id', legacyCart.get('id'));
      } else {
        cartInfo.set('cart_id', list_cart.get('id'));
      }

      cartInfo.set('customer_id', customer.id);
      cartInfo.set('customer', customer);
      cartInfo.set('email', customer.email);
      cartInfo.set('message', message);
      cartInfo.set('to_email', to_email);
      cartInfo.set('product', null);

      await cartInfo.save();
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1049, error);

      throw error;
    }
  }

  @action async onEmailCutsheets(
    items: Item[],
    customer: CustomerModel,
    onShare: boolean,
  ): Promise<void> {
    try {
      const cutsheet = this.store.createRecord('email-product-cutsheet');
      cutsheet.id = v1();
      cutsheet.set('customer', customer);
      if (onShare === true) {
        cutsheet.set('products', items);
      } else {
        const products = items.map(item => item.product);
        cutsheet.set('products', products);
      }
      await cutsheet.save();
    } catch (error) {
      //this.rollbar.error(EXCEPTION_1050, error);
      throw error;
    }
  }
}
