import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { get } from '@ember/object';
import Controller from '@ember/controller';
import AccountHistoryAllInvoiceModel from 'shoelace/models/account-history-all-invoice';

export default class StoreAccountHistoryInvoicesController extends Controller {
  queryParams = ['query', 'range', 'sortAscending', 'sortProperty', 'status'];

  @tracked query;
  @tracked range;
  @tracked sortProperty;
  @tracked status;
  @tracked sortAscending;

  get sort() {
    if (this.sortAscending) {
      return `${this.sortProperty}`;
    } else {
      return `${this.sortProperty}:desc`;
    }
  }

  @action onRange(dateRange) {
    this.range = dateRange;
  }

  @action async downloadCsv(accountHistoryInvoices) {
    const results = accountHistoryInvoices;
    const fields = [
      'id',
      'createdAt',
      'accountHistoryOrderId',
      'purchaseOrder',
      'subtotal',
      'salesTax',
      'total',
      'status',
      'expectedAt',
      'dueAt',
      'shippingType',
      'accountHistoryInvoiceComments.body',
    ];

    const labels = {
      id: 'Invoice Number',
      createdAt: 'Invoice Date',
      accountHistoryOrderId: 'Order Number',
      purchaseOrder: 'PO',
      subtotal: 'Subtotal',
      salesTax: 'Sales Tax',
      total: 'Total',
      status: 'Status',
      expectedAt: 'Customer Expected Date',
      dueAt: 'Due Date',
      shippingType: 'Shipping Type',
      'accountHistoryInvoiceComments.body': 'Comments',
    };
    const transforms = (field, value, row) => {
      if (field == 'total') {
        const code = row.invoice_type_code;
        if (['RE', 'G2'].includes(code)) return -1 * value;
        return value;
      } else {
        return value || row.get(field) || '';
      }
    };
    const csvserialize = text => {
      let needsQuoted = false;
      if (text.includes('"')) {
        needsQuoted = true;
        text = text.replace(/"/g, '""');
      }
      if (text.includes('\n')) {
        needsQuoted = true;
      }
      if (text.includes(',')) {
        needsQuoted = true;
      }
      if (needsQuoted) {
        return `"${text}"`;
      } else {
        return text;
      }
    };
    const output = results
      .reduce(
        (csv, row) => {
          return csv.concat(
            fields
              .map(field =>
                csvserialize(String(transforms(field, row[field], row))),
              )
              .join(','),
          );
        },
        [fields.map(f => labels[f]).join(',')],
      )
      .join('\n');
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(
        new Blob([output], { type: 'text/csv;charset=utf-8;' }),
        'invoices.csv',
      );
    } else {
      const blob = this.getLinkData(output);
      const data = URL.createObjectURL(blob);
      const link = window.document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', 'invoices.csv');
      window.document.body.appendChild(link); // Required for FF
      link.click();
      setTimeout(() => window.document.body.removeChild(link), 100);
    }
  }

  @action getLinkData(output) {
    return new Blob([output], { type: 'application/octet-binary' });
  }
}
