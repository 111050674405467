import { action } from '@ember/object';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class ApplicationController extends Controller {
  @service session;

  @tracked isSearchExpanded = false;

  @computed('session.session.authenticated.authenticator')
  get punchout() {
    return (
      this.session.get('session.authenticated.authenticator') ===
      'authenticator:po2go'
    );
  }

  @action closeSuggestions() {
    this.isSearchExpanded = false;
  }
}
